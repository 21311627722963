/*!*******************************************************************************************************************************************************************************************!*\
  !*** css ./node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[0].use[1]!./node_modules/postcss-loader/dist/cjs.js!./node_modules/sass-loader/dist/cjs.js!./src/assets/scss/theme.scss ***!
  \*******************************************************************************************************************************************************************************************/
@charset "UTF-8";
/* Table of contents
––––––––––––––––––––––––––––––––––––––––––––––––––

Note: Do not write any CSS in this file. Only use it to import other CSS files.
Note: '~' in the import statement means the file is imported from the node_modules directory. Bootstrap functions is an example.

- Bootstrap Functions.
- Bootstrap Mixins.
- Framework SASS Variables - override Bootstrap's SASS Vars in this file.
- Bootstrap Variables.
- Bootstrap Variables for Dark Mode.
- Bootstrap Maps.
- Framework Mixins
- Framework Utilities
- Bootstrap Core
- Framework CSS Custom Properties (CSS Variables)
- Framework Base
- Framework Components
- Framework Vendors
- Custom CSS - Add your custom CSS to this file
*/
/* Bootstrap Functions - DO NOT CHANGE ANYTHING IN HERE
–––––––––––––––––––––––––––––––––––––––––––––––––– */
/* Bootstrap Mixins - DO NOT CHANGE ANYTHING IN HERE
–––––––––––––––––––––––––––––––––––––––––––––––––– */
/* Framework SASS Variables - GO HERE TO OVERRIDE BOOTSTRAP VARS.
–––––––––––––––––––––––––––––––––––––––––––––––––– */
/* Bootstrap SASS Vars - DO NOT CHANGE ANYTHING IN HERE
__________________________________________________*/
/* Bootstrap SASS Vars for Dark Mode - DO NOT CHANGE ANYTHING IN HERE
__________________________________________________*/
/* Bootstrap SASS Maps - DO NOT CHANGE ANYTHING IN HERE
__________________________________________________*/
/* Framework Mixins
–––––––––––––––––––––––––––––––––––––––––––––––––– */
/* Framework Utilities - Needs to be imported before main bootstrap file.
–––––––––––––––––––––––––––––––––––––––––––––––––– */
/**
* - Everything in "bootstrap-api-utilities" either adds to or amends the Bootstrap utilities using their API
* - Everything else is normal CSS/SCSS
*/
/* Bootstrap API Utilities - only put code in here that uses Bootstrap's Util API */
/* Everything in these files DOES NOT use Bootstrap's API */
.link-body {
  color: var(--theme-link-body-color);
  text-decoration: none;
  position: relative;
}
.link-body:hover, .link-body:active {
  color: var(--theme-link-body-color);
}

.link-body:after {
  content: "";
  height: 6px;
  position: absolute;
  z-index: -1;
  left: 0;
  right: 0;
  bottom: 5px;
  background: var(--theme-link-body-psuedo-bg);
  transition: all ease-in 0.2s;
}

.link-body:hover:after {
  height: 50%;
}

.subtitle-sm, .subtitle-xs {
  color: #64748B;
  letter-spacing: 0.18em;
  text-transform: uppercase;
  font-size: 0.875em;
}

.subtitle-xs {
  font-size: 13px;
  font-weight: 500;
}

.link-cover:after {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  content: "";
  display: block;
}

.text-highlight {
  position: relative;
}

.text-highlight:after {
  content: "";
  height: 6px;
  position: absolute;
  z-index: -1;
  left: 0;
  right: 0;
  bottom: 5px;
  background: #91B408;
}

@media (max-width: 991.98px) {
  .typed-elem {
    min-height: 10rem;
  }
}
@media (max-width: 767.98px) {
  .typed-elem {
    min-height: 12rem;
  }
}
.tooltip-trigger {
  position: relative;
}
.tooltip-trigger:after {
  width: 100%;
  content: "";
  display: block;
  position: absolute;
  bottom: -0.15rem;
  border-bottom: 1px dashed #94A3B8;
}

@keyframes fadeIn {
  0% {
    display: none;
    opacity: 0;
  }
  1% {
    display: block;
    opacity: 0;
  }
  100% {
    display: block;
    opacity: 1;
  }
}
@keyframes fadeInUp {
  0% {
    display: none;
    opacity: 0;
    transform: translateY(10px);
  }
  1% {
    display: block;
    opacity: 0;
  }
  100% {
    display: block;
    opacity: 1;
    transform: translateY(0);
  }
}
.animation-float {
  animation: float 3s ease-in-out infinite;
}

/* loop from 1 - 10 for different floating option */
.animation-float-1 {
  animation: float 1s ease-in-out infinite;
}

.animation-float-2 {
  animation: float 2s ease-in-out infinite;
}

.animation-float-3 {
  animation: float 3s ease-in-out infinite;
}

.animation-float-4 {
  animation: float 4s ease-in-out infinite;
}

.animation-float-5 {
  animation: float 5s ease-in-out infinite;
}

.animation-float-6 {
  animation: float 6s ease-in-out infinite;
}

.animation-float-7 {
  animation: float 7s ease-in-out infinite;
}

.animation-float-8 {
  animation: float 8s ease-in-out infinite;
}

.animation-float-9 {
  animation: float 9s ease-in-out infinite;
}

.animation-float-10 {
  animation: float 10s ease-in-out infinite;
}

/* loop from 1 - 20 for different marquee timing option */
.animation-marquee-10 {
  animation: marquee 10s linear infinite;
}

.animation-marquee-20 {
  animation: marquee 20s linear infinite;
}

.animation-marquee-30 {
  animation: marquee 30s linear infinite;
}

.animation-marquee-40 {
  animation: marquee 40s linear infinite;
}

.animation-marquee-50 {
  animation: marquee 50s linear infinite;
}

.animation-marquee-60 {
  animation: marquee 60s linear infinite;
}

.animation-marquee-70 {
  animation: marquee 70s linear infinite;
}

.animation-marquee-80 {
  animation: marquee 80s linear infinite;
}

.animation-marquee-90 {
  animation: marquee 90s linear infinite;
}

.animation-marquee-100 {
  animation: marquee 100s linear infinite;
}

.animation-marquee-110 {
  animation: marquee 110s linear infinite;
}

.animation-marquee-120 {
  animation: marquee 120s linear infinite;
}

.animation-marquee-130 {
  animation: marquee 130s linear infinite;
}

.animation-marquee-140 {
  animation: marquee 140s linear infinite;
}

.animation-marquee-150 {
  animation: marquee 150s linear infinite;
}

.animation-marquee-160 {
  animation: marquee 160s linear infinite;
}

.animation-marquee-170 {
  animation: marquee 170s linear infinite;
}

.animation-marquee-180 {
  animation: marquee 180s linear infinite;
}

.animation-marquee-190 {
  animation: marquee 190s linear infinite;
}

.animation-marquee-200 {
  animation: marquee 200s linear infinite;
}

@keyframes float {
  0% {
    transform: translate3d(0, 0, 0);
  }
  50% {
    transform: translate3d(0, 7px, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes marquee {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    transform: translate3d(-100%, 0, 0);
  }
}
/* Bootstrap Core - DO NOT CHANGE ANYTHING IN HERE
–––––––––––––––––––––––––––––––––––––––––––––––––– */
/*!
 * Bootstrap  v5.3.0-alpha1 (https://getbootstrap.com/)
 * Copyright 2011-2022 The Bootstrap Authors
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root,
[data-bs-theme=light] {
  --gt-blue: #2c4bff;
  --gt-indigo: #6610f2;
  --gt-purple: #6f46ff;
  --gt-pink: #ffa69e;
  --gt-red: #db1a2a;
  --gt-orange: #ff6c60;
  --gt-yellow: #ffed03;
  --gt-green: #91B408;
  --gt-teal: #08c5d5;
  --gt-cyan: #0dcaf0;
  --gt-black: #000;
  --gt-white: #fff;
  --gt-gray: #475569;
  --gt-gray-dark: #1E293B;
  --gt-gray-100: #F1F5F9;
  --gt-gray-200: #E2E8F0;
  --gt-gray-300: #CBD5E1;
  --gt-gray-400: #94A3B8;
  --gt-gray-500: #64748B;
  --gt-gray-600: #475569;
  --gt-gray-700: #334155;
  --gt-gray-800: #1E293B;
  --gt-gray-900: #0F172A;
  --gt-primary: #91B408;
  --gt-secondary: #475569;
  --gt-success: #91B408;
  --gt-info: #42ba96;
  --gt-warning: #ffed03;
  --gt-danger: #db1a2a;
  --gt-light: #F1F5F9;
  --gt-dark: #0F172A;
  --gt-white: #fff;
  --gt-orange: #ff6c60;
  --gt-primary-rgb: 145, 180, 8;
  --gt-secondary-rgb: 71, 85, 105;
  --gt-success-rgb: 145, 180, 8;
  --gt-info-rgb: 66, 186, 150;
  --gt-warning-rgb: 255, 237, 3;
  --gt-danger-rgb: 219, 26, 42;
  --gt-light-rgb: 241, 245, 249;
  --gt-dark-rgb: 15, 23, 42;
  --gt-white-rgb: 255, 255, 255;
  --gt-orange-rgb: 255, 108, 96;
  --gt-primary-text: #233ccc;
  --gt-secondary-text: #475569;
  --gt-success-text: #749006;
  --gt-info-text: #087990;
  --gt-warning-text: #998e02;
  --gt-danger-text: #af1522;
  --gt-light-text: #475569;
  --gt-dark-text: #334155;
  --gt-primary-bg-subtle: #d5dbff;
  --gt-secondary-bg-subtle: #F1F5F9;
  --gt-success-bg-subtle: #e9f0ce;
  --gt-info-bg-subtle: #cff4fc;
  --gt-warning-bg-subtle: #fffbcd;
  --gt-danger-bg-subtle: #f8d1d4;
  --gt-light-bg-subtle: #f8fafc;
  --gt-dark-bg-subtle: #94A3B8;
  --gt-primary-border-subtle: #abb7ff;
  --gt-secondary-border-subtle: #E2E8F0;
  --gt-success-border-subtle: #d3e19c;
  --gt-info-border-subtle: #9eeaf9;
  --gt-warning-border-subtle: #fff89a;
  --gt-danger-border-subtle: #f1a3aa;
  --gt-light-border-subtle: #E2E8F0;
  --gt-dark-border-subtle: #64748B;
  --gt-white-rgb: 255, 255, 255;
  --gt-black-rgb: 0, 0, 0;
  --gt-body-color-rgb: 15, 23, 42;
  --gt-body-bg-rgb: 255, 255, 255;
  --gt-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --gt-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --gt-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --gt-body-font-family: var(--gt-font-sans-serif);
  --gt-body-font-size: 1.1rem;
  --gt-body-font-weight: 400;
  --gt-body-line-height: 1.65;
  --gt-body-color: #0F172A;
  --gt-emphasis-color: #000;
  --gt-emphasis-color-rgb: 0, 0, 0;
  --gt-secondary-color: rgba(15, 23, 42, 0.75);
  --gt-secondary-color-rgb: 15, 23, 42;
  --gt-secondary-bg: #E2E8F0;
  --gt-secondary-bg-rgb: 226, 232, 240;
  --gt-tertiary-color: rgba(15, 23, 42, 0.5);
  --gt-tertiary-color-rgb: 15, 23, 42;
  --gt-tertiary-bg: #F1F5F9;
  --gt-tertiary-bg-rgb: 241, 245, 249;
  --gt-body-bg: #fff;
  --gt-body-bg-rgb: 255, 255, 255;
  --gt-link-color: #91B408;
  --gt-link-color-rgb: 145, 180, 8;
  --gt-link-decoration: none;
  --gt-link-hover-color: #749006;
  --gt-link-hover-color-rgb: 116, 144, 6;
  --gt-code-color: #ffa69e;
  --gt-highlight-bg: #fffbcd;
  --gt-border-width: 1px;
  --gt-border-style: solid;
  --gt-border-color: #E2E8F0;
  --gt-border-color-translucent: rgba(0, 0, 0, 0.175);
  --gt-border-radius: 0.375rem;
  --gt-border-radius-sm: 0.25rem;
  --gt-border-radius-lg: 0.5rem;
  --gt-border-radius-xl: 1rem;
  --gt-border-radius-2xl: 2rem;
  --gt-border-radius-pill: 50rem;
  --gt-box-shadow: 0 0.5rem 1rem rgba(29, 48, 61, 0.06);
  --gt-box-shadow-sm: 0 4px 0.5rem rgba(29, 48, 61, 0.03);
  --gt-box-shadow-lg: 0 1rem 2.5rem rgba(29, 48, 61, 0.125);
  --gt-box-shadow-inset: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  --gt-emphasis-color: #000;
  --gt-form-control-bg: var(--gt-body-bg);
  --gt-form-control-disabled-bg: var(--gt-secondary-bg);
  --gt-highlight-bg: #fffbcd;
  --gt-breakpoint-xs: 0;
  --gt-breakpoint-sm: 576px;
  --gt-breakpoint-md: 768px;
  --gt-breakpoint-lg: 992px;
  --gt-breakpoint-xl: 1200px;
  --gt-breakpoint-xxl: 1400px;
}

[data-bs-theme=dark] {
  --gt-body-color: #64748B;
  --gt-body-color-rgb: 100, 116, 139;
  --gt-body-bg: #0F172A;
  --gt-body-bg-rgb: 15, 23, 42;
  --gt-emphasis-color: #F1F5F9;
  --gt-emphasis-color-rgb: 241, 245, 249;
  --gt-secondary-color: rgba(100, 116, 139, 0.75);
  --gt-secondary-color-rgb: 100, 116, 139;
  --gt-secondary-bg: #1E293B;
  --gt-secondary-bg-rgb: 30, 41, 59;
  --gt-tertiary-color: rgba(100, 116, 139, 0.5);
  --gt-tertiary-color-rgb: 100, 116, 139;
  --gt-tertiary-bg: #172033;
  --gt-tertiary-bg-rgb: 23, 32, 51;
  --gt-emphasis-color: #fff;
  --gt-primary-text: #8093ff;
  --gt-secondary-text: #CBD5E1;
  --gt-success-text: #bdd26b;
  --gt-info-text: #6edff6;
  --gt-warning-text: #fff468;
  --gt-danger-text: #e9767f;
  --gt-light-text: #F1F5F9;
  --gt-dark-text: #CBD5E1;
  --gt-primary-bg-subtle: #090f33;
  --gt-secondary-bg-subtle: #0F172A;
  --gt-success-bg-subtle: #1d2402;
  --gt-info-bg-subtle: #032830;
  --gt-warning-bg-subtle: #332f01;
  --gt-danger-bg-subtle: #2c0508;
  --gt-light-bg-subtle: #1E293B;
  --gt-dark-bg-subtle: #0f151e;
  --gt-primary-border-subtle: #1a2d99;
  --gt-secondary-border-subtle: #334155;
  --gt-success-border-subtle: #576c05;
  --gt-info-border-subtle: #055160;
  --gt-warning-border-subtle: #665f01;
  --gt-danger-border-subtle: #831019;
  --gt-light-border-subtle: #334155;
  --gt-dark-border-subtle: #1E293B;
  --gt-heading-color: #fff;
  --gt-link-color: #8093ff;
  --gt-link-hover-color: #abb7ff;
  --gt-link-color-rgb: 128, 147, 255;
  --gt-link-hover-color-rgb: 171, 183, 255;
  --gt-code-color: #ffcac5;
  --gt-border-color: #334155;
  --gt-border-color-translucent: rgba(255, 255, 255, 0.15);
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--gt-body-font-family);
  font-size: var(--gt-body-font-size);
  font-weight: var(--gt-body-font-weight);
  line-height: var(--gt-body-line-height);
  color: var(--gt-body-color);
  text-align: var(--gt-body-text-align);
  background-color: var(--gt-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: var(--gt-border-width) solid;
  opacity: 0.25;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  color: var(--gt-heading-color, inherit);
}

h1, .h1 {
  font-size: calc(1.4rem + 1.8vw);
}
@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 2.75rem;
  }
}

h2, .h2 {
  font-size: calc(1.345rem + 1.14vw);
}
@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 2.2rem;
  }
}

h3, .h3 {
  font-size: calc(1.3175rem + 0.81vw);
}
@media (min-width: 1200px) {
  h3, .h3 {
    font-size: 1.925rem;
  }
}

h4, .h4 {
  font-size: calc(1.29rem + 0.48vw);
}
@media (min-width: 1200px) {
  h4, .h4 {
    font-size: 1.65rem;
  }
}

h5, .h5 {
  font-size: calc(1.2625rem + 0.15vw);
}
@media (min-width: 1200px) {
  h5, .h5 {
    font-size: 1.375rem;
  }
}

h6, .h6 {
  font-size: 1.1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
  cursor: help;
  -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small, .small {
  font-size: 0.875em;
}

mark, .mark {
  padding: 0.1875em;
  background-color: var(--gt-highlight-bg);
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: rgba(var(--gt-link-color-rgb), var(--gt-link-opacity, 1));
  text-decoration: none;
}
a:hover {
  --gt-link-color-rgb: var(--gt-link-hover-color-rgb);
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--gt-font-monospace);
  font-size: 1em;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: var(--gt-code-color);
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.1875rem 0.375rem;
  font-size: 0.875em;
  color: var(--gt-body-bg);
  background-color: var(--gt-body-color);
  border-radius: 0.25rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #64748B;
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=week]):not([type=time])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: calc(1.2625rem + 0.15vw);
  font-weight: 300;
}
@media (min-width: 1200px) {
  .lead {
    font-size: 1.375rem;
  }
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: calc(1.2625rem + 0.15vw);
}
@media (min-width: 1200px) {
  .blockquote {
    font-size: 1.375rem;
  }
}
.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #475569;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: var(--gt-body-bg);
  border: var(--gt-border-width) solid var(--gt-border-color);
  border-radius: var(--gt-border-radius);
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875em;
  color: var(--gt-secondary-color);
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  --gt-gutter-x: 1.5rem;
  --gt-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--gt-gutter-x) * 0.5);
  padding-left: calc(var(--gt-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1320px;
  }
}
.row {
  --gt-gutter-x: 1.5rem;
  --gt-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--gt-gutter-y));
  margin-right: calc(-0.5 * var(--gt-gutter-x));
  margin-left: calc(-0.5 * var(--gt-gutter-x));
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--gt-gutter-x) * 0.5);
  padding-left: calc(var(--gt-gutter-x) * 0.5);
  margin-top: var(--gt-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --gt-gutter-x: 0;
}

.g-0,
.gy-0 {
  --gt-gutter-y: 0;
}

.g-1,
.gx-1 {
  --gt-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --gt-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --gt-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --gt-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --gt-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --gt-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --gt-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --gt-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --gt-gutter-x: 3rem;
}

.g-5,
.gy-5 {
  --gt-gutter-y: 3rem;
}

.g-6,
.gx-6 {
  --gt-gutter-x: 3.5rem;
}

.g-6,
.gy-6 {
  --gt-gutter-y: 3.5rem;
}

.g-7,
.gx-7 {
  --gt-gutter-x: 4rem;
}

.g-7,
.gy-7 {
  --gt-gutter-y: 4rem;
}

.g-8,
.gx-8 {
  --gt-gutter-x: 5rem;
}

.g-8,
.gy-8 {
  --gt-gutter-y: 5rem;
}

.g-9,
.gx-9 {
  --gt-gutter-x: 6rem;
}

.g-9,
.gy-9 {
  --gt-gutter-y: 6rem;
}

.g-10,
.gx-10 {
  --gt-gutter-x: 7rem;
}

.g-10,
.gy-10 {
  --gt-gutter-y: 7rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
  .g-sm-0,
  .gx-sm-0 {
    --gt-gutter-x: 0;
  }
  .g-sm-0,
  .gy-sm-0 {
    --gt-gutter-y: 0;
  }
  .g-sm-1,
  .gx-sm-1 {
    --gt-gutter-x: 0.25rem;
  }
  .g-sm-1,
  .gy-sm-1 {
    --gt-gutter-y: 0.25rem;
  }
  .g-sm-2,
  .gx-sm-2 {
    --gt-gutter-x: 0.5rem;
  }
  .g-sm-2,
  .gy-sm-2 {
    --gt-gutter-y: 0.5rem;
  }
  .g-sm-3,
  .gx-sm-3 {
    --gt-gutter-x: 1rem;
  }
  .g-sm-3,
  .gy-sm-3 {
    --gt-gutter-y: 1rem;
  }
  .g-sm-4,
  .gx-sm-4 {
    --gt-gutter-x: 1.5rem;
  }
  .g-sm-4,
  .gy-sm-4 {
    --gt-gutter-y: 1.5rem;
  }
  .g-sm-5,
  .gx-sm-5 {
    --gt-gutter-x: 3rem;
  }
  .g-sm-5,
  .gy-sm-5 {
    --gt-gutter-y: 3rem;
  }
  .g-sm-6,
  .gx-sm-6 {
    --gt-gutter-x: 3.5rem;
  }
  .g-sm-6,
  .gy-sm-6 {
    --gt-gutter-y: 3.5rem;
  }
  .g-sm-7,
  .gx-sm-7 {
    --gt-gutter-x: 4rem;
  }
  .g-sm-7,
  .gy-sm-7 {
    --gt-gutter-y: 4rem;
  }
  .g-sm-8,
  .gx-sm-8 {
    --gt-gutter-x: 5rem;
  }
  .g-sm-8,
  .gy-sm-8 {
    --gt-gutter-y: 5rem;
  }
  .g-sm-9,
  .gx-sm-9 {
    --gt-gutter-x: 6rem;
  }
  .g-sm-9,
  .gy-sm-9 {
    --gt-gutter-y: 6rem;
  }
  .g-sm-10,
  .gx-sm-10 {
    --gt-gutter-x: 7rem;
  }
  .g-sm-10,
  .gy-sm-10 {
    --gt-gutter-y: 7rem;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
  .g-md-0,
  .gx-md-0 {
    --gt-gutter-x: 0;
  }
  .g-md-0,
  .gy-md-0 {
    --gt-gutter-y: 0;
  }
  .g-md-1,
  .gx-md-1 {
    --gt-gutter-x: 0.25rem;
  }
  .g-md-1,
  .gy-md-1 {
    --gt-gutter-y: 0.25rem;
  }
  .g-md-2,
  .gx-md-2 {
    --gt-gutter-x: 0.5rem;
  }
  .g-md-2,
  .gy-md-2 {
    --gt-gutter-y: 0.5rem;
  }
  .g-md-3,
  .gx-md-3 {
    --gt-gutter-x: 1rem;
  }
  .g-md-3,
  .gy-md-3 {
    --gt-gutter-y: 1rem;
  }
  .g-md-4,
  .gx-md-4 {
    --gt-gutter-x: 1.5rem;
  }
  .g-md-4,
  .gy-md-4 {
    --gt-gutter-y: 1.5rem;
  }
  .g-md-5,
  .gx-md-5 {
    --gt-gutter-x: 3rem;
  }
  .g-md-5,
  .gy-md-5 {
    --gt-gutter-y: 3rem;
  }
  .g-md-6,
  .gx-md-6 {
    --gt-gutter-x: 3.5rem;
  }
  .g-md-6,
  .gy-md-6 {
    --gt-gutter-y: 3.5rem;
  }
  .g-md-7,
  .gx-md-7 {
    --gt-gutter-x: 4rem;
  }
  .g-md-7,
  .gy-md-7 {
    --gt-gutter-y: 4rem;
  }
  .g-md-8,
  .gx-md-8 {
    --gt-gutter-x: 5rem;
  }
  .g-md-8,
  .gy-md-8 {
    --gt-gutter-y: 5rem;
  }
  .g-md-9,
  .gx-md-9 {
    --gt-gutter-x: 6rem;
  }
  .g-md-9,
  .gy-md-9 {
    --gt-gutter-y: 6rem;
  }
  .g-md-10,
  .gx-md-10 {
    --gt-gutter-x: 7rem;
  }
  .g-md-10,
  .gy-md-10 {
    --gt-gutter-y: 7rem;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
  .g-lg-0,
  .gx-lg-0 {
    --gt-gutter-x: 0;
  }
  .g-lg-0,
  .gy-lg-0 {
    --gt-gutter-y: 0;
  }
  .g-lg-1,
  .gx-lg-1 {
    --gt-gutter-x: 0.25rem;
  }
  .g-lg-1,
  .gy-lg-1 {
    --gt-gutter-y: 0.25rem;
  }
  .g-lg-2,
  .gx-lg-2 {
    --gt-gutter-x: 0.5rem;
  }
  .g-lg-2,
  .gy-lg-2 {
    --gt-gutter-y: 0.5rem;
  }
  .g-lg-3,
  .gx-lg-3 {
    --gt-gutter-x: 1rem;
  }
  .g-lg-3,
  .gy-lg-3 {
    --gt-gutter-y: 1rem;
  }
  .g-lg-4,
  .gx-lg-4 {
    --gt-gutter-x: 1.5rem;
  }
  .g-lg-4,
  .gy-lg-4 {
    --gt-gutter-y: 1.5rem;
  }
  .g-lg-5,
  .gx-lg-5 {
    --gt-gutter-x: 3rem;
  }
  .g-lg-5,
  .gy-lg-5 {
    --gt-gutter-y: 3rem;
  }
  .g-lg-6,
  .gx-lg-6 {
    --gt-gutter-x: 3.5rem;
  }
  .g-lg-6,
  .gy-lg-6 {
    --gt-gutter-y: 3.5rem;
  }
  .g-lg-7,
  .gx-lg-7 {
    --gt-gutter-x: 4rem;
  }
  .g-lg-7,
  .gy-lg-7 {
    --gt-gutter-y: 4rem;
  }
  .g-lg-8,
  .gx-lg-8 {
    --gt-gutter-x: 5rem;
  }
  .g-lg-8,
  .gy-lg-8 {
    --gt-gutter-y: 5rem;
  }
  .g-lg-9,
  .gx-lg-9 {
    --gt-gutter-x: 6rem;
  }
  .g-lg-9,
  .gy-lg-9 {
    --gt-gutter-y: 6rem;
  }
  .g-lg-10,
  .gx-lg-10 {
    --gt-gutter-x: 7rem;
  }
  .g-lg-10,
  .gy-lg-10 {
    --gt-gutter-y: 7rem;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
  .g-xl-0,
  .gx-xl-0 {
    --gt-gutter-x: 0;
  }
  .g-xl-0,
  .gy-xl-0 {
    --gt-gutter-y: 0;
  }
  .g-xl-1,
  .gx-xl-1 {
    --gt-gutter-x: 0.25rem;
  }
  .g-xl-1,
  .gy-xl-1 {
    --gt-gutter-y: 0.25rem;
  }
  .g-xl-2,
  .gx-xl-2 {
    --gt-gutter-x: 0.5rem;
  }
  .g-xl-2,
  .gy-xl-2 {
    --gt-gutter-y: 0.5rem;
  }
  .g-xl-3,
  .gx-xl-3 {
    --gt-gutter-x: 1rem;
  }
  .g-xl-3,
  .gy-xl-3 {
    --gt-gutter-y: 1rem;
  }
  .g-xl-4,
  .gx-xl-4 {
    --gt-gutter-x: 1.5rem;
  }
  .g-xl-4,
  .gy-xl-4 {
    --gt-gutter-y: 1.5rem;
  }
  .g-xl-5,
  .gx-xl-5 {
    --gt-gutter-x: 3rem;
  }
  .g-xl-5,
  .gy-xl-5 {
    --gt-gutter-y: 3rem;
  }
  .g-xl-6,
  .gx-xl-6 {
    --gt-gutter-x: 3.5rem;
  }
  .g-xl-6,
  .gy-xl-6 {
    --gt-gutter-y: 3.5rem;
  }
  .g-xl-7,
  .gx-xl-7 {
    --gt-gutter-x: 4rem;
  }
  .g-xl-7,
  .gy-xl-7 {
    --gt-gutter-y: 4rem;
  }
  .g-xl-8,
  .gx-xl-8 {
    --gt-gutter-x: 5rem;
  }
  .g-xl-8,
  .gy-xl-8 {
    --gt-gutter-y: 5rem;
  }
  .g-xl-9,
  .gx-xl-9 {
    --gt-gutter-x: 6rem;
  }
  .g-xl-9,
  .gy-xl-9 {
    --gt-gutter-y: 6rem;
  }
  .g-xl-10,
  .gx-xl-10 {
    --gt-gutter-x: 7rem;
  }
  .g-xl-10,
  .gy-xl-10 {
    --gt-gutter-y: 7rem;
  }
}
@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }
  .g-xxl-0,
  .gx-xxl-0 {
    --gt-gutter-x: 0;
  }
  .g-xxl-0,
  .gy-xxl-0 {
    --gt-gutter-y: 0;
  }
  .g-xxl-1,
  .gx-xxl-1 {
    --gt-gutter-x: 0.25rem;
  }
  .g-xxl-1,
  .gy-xxl-1 {
    --gt-gutter-y: 0.25rem;
  }
  .g-xxl-2,
  .gx-xxl-2 {
    --gt-gutter-x: 0.5rem;
  }
  .g-xxl-2,
  .gy-xxl-2 {
    --gt-gutter-y: 0.5rem;
  }
  .g-xxl-3,
  .gx-xxl-3 {
    --gt-gutter-x: 1rem;
  }
  .g-xxl-3,
  .gy-xxl-3 {
    --gt-gutter-y: 1rem;
  }
  .g-xxl-4,
  .gx-xxl-4 {
    --gt-gutter-x: 1.5rem;
  }
  .g-xxl-4,
  .gy-xxl-4 {
    --gt-gutter-y: 1.5rem;
  }
  .g-xxl-5,
  .gx-xxl-5 {
    --gt-gutter-x: 3rem;
  }
  .g-xxl-5,
  .gy-xxl-5 {
    --gt-gutter-y: 3rem;
  }
  .g-xxl-6,
  .gx-xxl-6 {
    --gt-gutter-x: 3.5rem;
  }
  .g-xxl-6,
  .gy-xxl-6 {
    --gt-gutter-y: 3.5rem;
  }
  .g-xxl-7,
  .gx-xxl-7 {
    --gt-gutter-x: 4rem;
  }
  .g-xxl-7,
  .gy-xxl-7 {
    --gt-gutter-y: 4rem;
  }
  .g-xxl-8,
  .gx-xxl-8 {
    --gt-gutter-x: 5rem;
  }
  .g-xxl-8,
  .gy-xxl-8 {
    --gt-gutter-y: 5rem;
  }
  .g-xxl-9,
  .gx-xxl-9 {
    --gt-gutter-x: 6rem;
  }
  .g-xxl-9,
  .gy-xxl-9 {
    --gt-gutter-y: 6rem;
  }
  .g-xxl-10,
  .gx-xxl-10 {
    --gt-gutter-x: 7rem;
  }
  .g-xxl-10,
  .gy-xxl-10 {
    --gt-gutter-y: 7rem;
  }
}
.table {
  --gt-table-color: var(--gt-body-color);
  --gt-table-bg: transparent;
  --gt-table-border-color: var(--gt-border-color);
  --gt-table-accent-bg: transparent;
  --gt-table-striped-color: var(--gt-body-color);
  --gt-table-striped-bg: rgba(0, 0, 0, 0.05);
  --gt-table-active-color: var(--gt-body-color);
  --gt-table-active-bg: rgba(0, 0, 0, 0.1);
  --gt-table-hover-color: var(--gt-body-color);
  --gt-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  color: var(--gt-table-color);
  vertical-align: top;
  border-color: var(--gt-table-border-color);
}
.table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  background-color: var(--gt-table-bg);
  border-bottom-width: var(--gt-border-width);
  box-shadow: inset 0 0 0 9999px var(--gt-table-accent-bg);
}
.table > tbody {
  vertical-align: inherit;
}
.table > thead {
  vertical-align: bottom;
}

.table-group-divider {
  border-top: calc(var(--gt-border-width) * 2) solid currentcolor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem;
}

.table-bordered > :not(caption) > * {
  border-width: var(--gt-border-width) 0;
}
.table-bordered > :not(caption) > * > * {
  border-width: 0 var(--gt-border-width);
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}
.table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --gt-table-accent-bg: var(--gt-table-striped-bg);
  color: var(--gt-table-striped-color);
}

.table-striped-columns > :not(caption) > tr > :nth-child(even) {
  --gt-table-accent-bg: var(--gt-table-striped-bg);
  color: var(--gt-table-striped-color);
}

.table-active {
  --gt-table-accent-bg: var(--gt-table-active-bg);
  color: var(--gt-table-active-color);
}

.table-hover > tbody > tr:hover > * {
  --gt-table-accent-bg: var(--gt-table-hover-bg);
  color: var(--gt-table-hover-color);
}

.table-primary {
  --gt-table-color: #000;
  --gt-table-bg: #e9f0ce;
  --gt-table-border-color: #d2d8b9;
  --gt-table-striped-bg: #dde4c4;
  --gt-table-striped-color: #000;
  --gt-table-active-bg: #d2d8b9;
  --gt-table-active-color: #000;
  --gt-table-hover-bg: #d8debf;
  --gt-table-hover-color: #000;
  color: var(--gt-table-color);
  border-color: var(--gt-table-border-color);
}

.table-secondary {
  --gt-table-color: #000;
  --gt-table-bg: #dadde1;
  --gt-table-border-color: #c4c7cb;
  --gt-table-striped-bg: #cfd2d6;
  --gt-table-striped-color: #000;
  --gt-table-active-bg: #c4c7cb;
  --gt-table-active-color: #000;
  --gt-table-hover-bg: #caccd0;
  --gt-table-hover-color: #000;
  color: var(--gt-table-color);
  border-color: var(--gt-table-border-color);
}

.table-success {
  --gt-table-color: #000;
  --gt-table-bg: #e9f0ce;
  --gt-table-border-color: #d2d8b9;
  --gt-table-striped-bg: #dde4c4;
  --gt-table-striped-color: #000;
  --gt-table-active-bg: #d2d8b9;
  --gt-table-active-color: #000;
  --gt-table-hover-bg: #d8debf;
  --gt-table-hover-color: #000;
  color: var(--gt-table-color);
  border-color: var(--gt-table-border-color);
}

.table-info {
  --gt-table-color: #000;
  --gt-table-bg: #d9f1ea;
  --gt-table-border-color: #c3d9d3;
  --gt-table-striped-bg: #cee5de;
  --gt-table-striped-color: #000;
  --gt-table-active-bg: #c3d9d3;
  --gt-table-active-color: #000;
  --gt-table-hover-bg: #c9dfd8;
  --gt-table-hover-color: #000;
  color: var(--gt-table-color);
  border-color: var(--gt-table-border-color);
}

.table-warning {
  --gt-table-color: #000;
  --gt-table-bg: #fffbcd;
  --gt-table-border-color: #e6e2b9;
  --gt-table-striped-bg: #f2eec3;
  --gt-table-striped-color: #000;
  --gt-table-active-bg: #e6e2b9;
  --gt-table-active-color: #000;
  --gt-table-hover-bg: #ece8be;
  --gt-table-hover-color: #000;
  color: var(--gt-table-color);
  border-color: var(--gt-table-border-color);
}

.table-danger {
  --gt-table-color: #000;
  --gt-table-bg: #f8d1d4;
  --gt-table-border-color: #dfbcbf;
  --gt-table-striped-bg: #ecc7c9;
  --gt-table-striped-color: #000;
  --gt-table-active-bg: #dfbcbf;
  --gt-table-active-color: #000;
  --gt-table-hover-bg: #e5c1c4;
  --gt-table-hover-color: #000;
  color: var(--gt-table-color);
  border-color: var(--gt-table-border-color);
}

.table-light {
  --gt-table-color: #000;
  --gt-table-bg: #F1F5F9;
  --gt-table-border-color: #d9dde0;
  --gt-table-striped-bg: #e5e9ed;
  --gt-table-striped-color: #000;
  --gt-table-active-bg: #d9dde0;
  --gt-table-active-color: #000;
  --gt-table-hover-bg: #dfe3e6;
  --gt-table-hover-color: #000;
  color: var(--gt-table-color);
  border-color: var(--gt-table-border-color);
}

.table-dark {
  --gt-table-color: #fff;
  --gt-table-bg: #0F172A;
  --gt-table-border-color: #272e3f;
  --gt-table-striped-bg: #1b2335;
  --gt-table-striped-color: #fff;
  --gt-table-active-bg: #272e3f;
  --gt-table-active-color: #fff;
  --gt-table-hover-bg: #21283a;
  --gt-table-hover-color: #fff;
  color: var(--gt-table-color);
  border-color: var(--gt-table-border-color);
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
.form-label {
  margin-bottom: 0.5rem;
  font-size: 1rem;
  font-weight: 500;
}

.col-form-label {
  padding-top: calc(0.65rem + var(--gt-border-width));
  padding-bottom: calc(0.65rem + var(--gt-border-width));
  margin-bottom: 0;
  font-size: inherit;
  font-weight: 500;
  line-height: 1.65;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + var(--gt-border-width));
  padding-bottom: calc(0.5rem + var(--gt-border-width));
  font-size: calc(1.2625rem + 0.15vw);
}
@media (min-width: 1200px) {
  .col-form-label-lg {
    font-size: 1.375rem;
  }
}

.col-form-label-sm {
  padding-top: calc(0.25rem + var(--gt-border-width));
  padding-bottom: calc(0.25rem + var(--gt-border-width));
  font-size: 0.9625rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #64748B;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.65rem 0.75rem;
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 1.65;
  color: #0F172A;
  background-color: var(--gt-form-control-bg);
  background-clip: padding-box;
  border: var(--gt-border-width) solid #94A3B8;
  -webkit-appearance: none;
          appearance: none;
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control[type=file] {
  overflow: hidden;
}
.form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus {
  color: #0F172A;
  background-color: var(--gt-form-control-bg);
  border-color: #c8da84;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(145, 180, 8, 0.25);
}
.form-control::-webkit-date-and-time-value {
  height: 1.65em;
}
.form-control::-webkit-datetime-edit {
  display: block;
  padding: 0;
}
.form-control::placeholder {
  color: var(--gt-secondary-color);
  opacity: 1;
}
.form-control:disabled {
  background-color: var(--gt-form-control-disabled-bg);
  opacity: 1;
}
.form-control::file-selector-button {
  padding: 0.65rem 0.75rem;
  margin: -0.65rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: #0F172A;
  background-color: var(--gt-tertiary-bg);
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: var(--gt-border-width);
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: var(--gt-secondary-bg);
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.65rem 0;
  margin-bottom: 0;
  line-height: 1.65;
  color: var(--gt-body-color);
  background-color: transparent;
  border: solid transparent;
  border-width: var(--gt-border-width) 0;
}
.form-control-plaintext:focus {
  outline: 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.65em + 0.5rem + calc(var(--gt-border-width) * 2));
  padding: 0.25rem 0.5rem;
  font-size: 0.9625rem;
  border-radius: 0.25rem;
}
.form-control-sm::file-selector-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-lg {
  min-height: calc(1.65em + 1rem + calc(var(--gt-border-width) * 2));
  padding: 0.5rem 1rem;
  font-size: calc(1.2625rem + 0.15vw);
  border-radius: 0.5rem;
}
@media (min-width: 1200px) {
  .form-control-lg {
    font-size: 1.375rem;
  }
}
.form-control-lg::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}

textarea.form-control {
  min-height: calc(1.65em + 1.3rem + calc(var(--gt-border-width) * 2));
}
textarea.form-control-sm {
  min-height: calc(1.65em + 0.5rem + calc(var(--gt-border-width) * 2));
}
textarea.form-control-lg {
  min-height: calc(1.65em + 1rem + calc(var(--gt-border-width) * 2));
}

.form-control-color {
  width: 3rem;
  height: calc(1.65em + 1.3rem + calc(var(--gt-border-width) * 2));
  padding: 0.65rem;
}
.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  border: 0 !important;
  border-radius: 0;
}
.form-control-color::-webkit-color-swatch {
  border-radius: 0;
}
.form-control-color.form-control-sm {
  height: calc(1.65em + 0.5rem + calc(var(--gt-border-width) * 2));
}
.form-control-color.form-control-lg {
  height: calc(1.65em + 1rem + calc(var(--gt-border-width) * 2));
}

.form-select {
  --gt-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%231E293B' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  display: block;
  width: 100%;
  padding: 0.65rem 2.25rem 0.65rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 1.65;
  color: #0F172A;
  background-color: var(--gt-form-control-bg);
  background-image: var(--gt-form-select-bg-img), var(--gt-form-select-bg-icon, none);
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: var(--gt-border-width) solid #94A3B8;
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
          appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}
.form-select:focus {
  border-color: #c8da84;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(145, 180, 8, 0.25);
}
.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 0.75rem;
  background-image: none;
}
.form-select:disabled {
  background-color: var(--gt-form-control-disabled-bg);
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #0F172A;
}

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.9625rem;
  border-radius: 0.25rem;
}

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: calc(1.2625rem + 0.15vw);
  border-radius: 0.5rem;
}
@media (min-width: 1200px) {
  .form-select-lg {
    font-size: 1.375rem;
  }
}

[data-bs-theme=dark] .form-select {
  --gt-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%2364748B' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
}

.form-check {
  display: block;
  min-height: 1.815rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}
.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-reverse {
  padding-right: 1.5em;
  padding-left: 0;
  text-align: right;
}
.form-check-reverse .form-check-input {
  float: right;
  margin-right: -1.5em;
  margin-left: 0;
}

.form-check-input {
  --gt-form-check-bg: var(--gt-form-control-bg);
  width: 1em;
  height: 1em;
  margin-top: 0.325em;
  vertical-align: top;
  background-color: var(--gt-form-check-bg);
  background-image: var(--gt-form-check-bg-image);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: var(--gt-border-width) solid var(--gt-border-color);
  -webkit-appearance: none;
          appearance: none;
  -webkit-print-color-adjust: exact;
          print-color-adjust: exact;
}
.form-check-input[type=checkbox] {
  border-radius: 0.25em;
}
.form-check-input[type=radio] {
  border-radius: 50%;
}
.form-check-input:active {
  filter: brightness(90%);
}
.form-check-input:focus {
  border-color: #c8da84;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(145, 180, 8, 0.25);
}
.form-check-input:checked {
  background-color: #91B408;
  border-color: #91B408;
}
.form-check-input:checked[type=checkbox] {
  --gt-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}
.form-check-input:checked[type=radio] {
  --gt-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}
.form-check-input[type=checkbox]:indeterminate {
  background-color: #91B408;
  border-color: #91B408;
  --gt-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  cursor: default;
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.5em;
}
.form-switch .form-check-input {
  --gt-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  width: 2em;
  margin-left: -2.5em;
  background-image: var(--gt-form-switch-bg);
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  --gt-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23c8da84'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
  background-position: right center;
  --gt-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.form-switch.form-check-reverse {
  padding-right: 2.5em;
  padding-left: 0;
}
.form-switch.form-check-reverse .form-check-input {
  margin-right: -2.5em;
  margin-left: 0;
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

[data-bs-theme=dark] .form-switch .form-check-input:not(:checked):not(:focus) {
  --gt-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%28255, 255, 255, 0.25%29'/%3e%3c/svg%3e");
}

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
          appearance: none;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(145, 180, 8, 0.25);
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(145, 180, 8, 0.25);
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #91B408;
  border: 0;
  border-radius: 1rem;
  -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
          appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    -webkit-transition: none;
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: #dee9b5;
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--gt-tertiary-bg);
  border-color: transparent;
  border-radius: 1rem;
}
.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #91B408;
  border: 0;
  border-radius: 1rem;
  -moz-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    -moz-transition: none;
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: #dee9b5;
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--gt-tertiary-bg);
  border-color: transparent;
  border-radius: 1rem;
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: var(--gt-secondary-color);
}
.form-range:disabled::-moz-range-thumb {
  background-color: var(--gt-secondary-color);
}

.form-floating {
  position: relative;
}
.form-floating::before:not(.form-control:disabled) {
  position: absolute;
  top: var(--gt-border-width);
  left: var(--gt-border-width);
  width: calc(100% - (calc(calc(0.4125em + 0.325rem) + calc(0.825em + 0.65rem))));
  height: 1.875em;
  content: "";
  background-color: var(--gt-form-control-bg);
  border-radius: 0;
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext,
.form-floating > .form-select {
  height: calc(3.5rem + calc(var(--gt-border-width) * 2));
  line-height: 1.25;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 1rem 0.75rem;
  overflow: hidden;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  border: var(--gt-border-width) solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext {
  padding: 1rem 0.75rem;
}
.form-floating > .form-control::placeholder,
.form-floating > .form-control-plaintext::placeholder {
  color: transparent;
}
.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown),
.form-floating > .form-control-plaintext:focus,
.form-floating > .form-control-plaintext:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:-webkit-autofill,
.form-floating > .form-control-plaintext:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-control-plaintext ~ label,
.form-floating > .form-select ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:-webkit-autofill ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control-plaintext ~ label {
  border-width: var(--gt-border-width) 0;
}
.form-floating > .form-control:disabled ~ label {
  color: #475569;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-select,
.input-group > .form-floating {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus,
.input-group > .form-select:focus,
.input-group > .form-floating:focus-within {
  z-index: 5;
}
.input-group .btn {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus {
  z-index: 5;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.65rem 0.75rem;
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 1.65;
  color: #0F172A;
  text-align: center;
  white-space: nowrap;
  background-color: var(--gt-tertiary-bg);
  border: var(--gt-border-width) solid #94A3B8;
  border-radius: 0;
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: calc(1.2625rem + 0.15vw);
  border-radius: 0.5rem;
}
@media (min-width: 1200px) {
  .input-group-lg > .form-control,
  .input-group-lg > .form-select,
  .input-group-lg > .input-group-text,
  .input-group-lg > .btn {
    font-size: 1.375rem;
  }
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.9625rem;
  border-radius: 0.25rem;
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 3rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3),
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control,
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n+4),
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-control,
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: calc(var(--gt-border-width) * -1);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .form-floating:not(:first-child) > .form-control,
.input-group > .form-floating:not(:first-child) > .form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--gt-success-text);
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.9625rem;
  color: #fff;
  background-color: var(--gt-success);
  border-radius: var(--gt-border-radius);
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: var(--gt-success);
  padding-right: calc(1.65em + 1.3rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2391B408' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.4125em + 0.325rem) center;
  background-size: calc(0.825em + 0.65rem) calc(0.825em + 0.65rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: var(--gt-success);
  box-shadow: 0 0 0 0.25rem rgba(var(--gt-success-rgb), 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.65em + 1.3rem);
  background-position: top calc(0.4125em + 0.325rem) right calc(0.4125em + 0.325rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: var(--gt-success);
}
.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  --gt-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2391B408' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  padding-right: 4.125rem;
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.825em + 0.65rem) calc(0.825em + 0.65rem);
}
.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: var(--gt-success);
  box-shadow: 0 0 0 0.25rem rgba(var(--gt-success-rgb), 0.25);
}

.was-validated .form-control-color:valid, .form-control-color.is-valid {
  width: calc(3rem + calc(1.65em + 1.3rem));
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: var(--gt-success);
}
.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: var(--gt-success-text);
}
.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(var(--gt-success-rgb), 0.25);
}
.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: var(--gt-success-text);
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):valid, .input-group > .form-control:not(:focus).is-valid,
.was-validated .input-group > .form-select:not(:focus):valid,
.input-group > .form-select:not(:focus).is-valid,
.was-validated .input-group > .form-floating:not(:focus-within):valid,
.input-group > .form-floating:not(:focus-within).is-valid {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--gt-danger-text);
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.9625rem;
  color: #fff;
  background-color: var(--gt-danger);
  border-radius: var(--gt-border-radius);
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: var(--gt-danger);
  padding-right: calc(1.65em + 1.3rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23db1a2a'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23db1a2a' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.4125em + 0.325rem) center;
  background-size: calc(0.825em + 0.65rem) calc(0.825em + 0.65rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: var(--gt-danger);
  box-shadow: 0 0 0 0.25rem rgba(var(--gt-danger-rgb), 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.65em + 1.3rem);
  background-position: top calc(0.4125em + 0.325rem) right calc(0.4125em + 0.325rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: var(--gt-danger);
}
.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  --gt-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23db1a2a'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23db1a2a' stroke='none'/%3e%3c/svg%3e");
  padding-right: 4.125rem;
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.825em + 0.65rem) calc(0.825em + 0.65rem);
}
.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: var(--gt-danger);
  box-shadow: 0 0 0 0.25rem rgba(var(--gt-danger-rgb), 0.25);
}

.was-validated .form-control-color:invalid, .form-control-color.is-invalid {
  width: calc(3rem + calc(1.65em + 1.3rem));
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: var(--gt-danger);
}
.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: var(--gt-danger-text);
}
.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(var(--gt-danger-rgb), 0.25);
}
.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: var(--gt-danger-text);
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):invalid, .input-group > .form-control:not(:focus).is-invalid,
.was-validated .input-group > .form-select:not(:focus):invalid,
.input-group > .form-select:not(:focus).is-invalid,
.was-validated .input-group > .form-floating:not(:focus-within):invalid,
.input-group > .form-floating:not(:focus-within).is-invalid {
  z-index: 4;
}

.btn {
  --gt-btn-padding-x: 1.25rem;
  --gt-btn-padding-y: 1rem;
  --gt-btn-font-family: ;
  --gt-btn-font-size: 0.975rem;
  --gt-btn-font-weight: 500;
  --gt-btn-line-height: 1;
  --gt-btn-color: #0F172A;
  --gt-btn-bg: transparent;
  --gt-btn-border-width: var(--gt-border-width);
  --gt-btn-border-color: transparent;
  --gt-btn-border-radius: 0.375rem;
  --gt-btn-hover-border-color: transparent;
  --gt-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  --gt-btn-disabled-opacity: 0.65;
  --gt-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--gt-btn-focus-shadow-rgb), .5);
  display: inline-block;
  padding: var(--gt-btn-padding-y) var(--gt-btn-padding-x);
  font-family: var(--gt-btn-font-family);
  font-size: var(--gt-btn-font-size);
  font-weight: var(--gt-btn-font-weight);
  line-height: var(--gt-btn-line-height);
  color: var(--gt-btn-color);
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
  border: var(--gt-btn-border-width) solid var(--gt-btn-border-color);
  border-radius: var(--gt-btn-border-radius);
  background-color: var(--gt-btn-bg);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: var(--gt-btn-hover-color);
  background-color: var(--gt-btn-hover-bg);
  border-color: var(--gt-btn-hover-border-color);
}
.btn-check + .btn:hover {
  color: var(--gt-btn-color);
  background-color: var(--gt-btn-bg);
  border-color: var(--gt-btn-border-color);
}
.btn:focus-visible {
  color: var(--gt-btn-hover-color);
  background-color: var(--gt-btn-hover-bg);
  border-color: var(--gt-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--gt-btn-focus-box-shadow);
}
.btn-check:focus-visible + .btn {
  border-color: var(--gt-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--gt-btn-focus-box-shadow);
}
.btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
  color: var(--gt-btn-active-color);
  background-color: var(--gt-btn-active-bg);
  border-color: var(--gt-btn-active-border-color);
}
.btn-check:checked + .btn:focus-visible, :not(.btn-check) + .btn:active:focus-visible, .btn:first-child:active:focus-visible, .btn.active:focus-visible, .btn.show:focus-visible {
  box-shadow: var(--gt-btn-focus-box-shadow);
}
.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  color: var(--gt-btn-disabled-color);
  pointer-events: none;
  background-color: var(--gt-btn-disabled-bg);
  border-color: var(--gt-btn-disabled-border-color);
  opacity: var(--gt-btn-disabled-opacity);
}

.btn-primary {
  --gt-btn-color: #000;
  --gt-btn-bg: #91B408;
  --gt-btn-border-color: #91B408;
  --gt-btn-hover-color: #000;
  --gt-btn-hover-bg: #a2bf2d;
  --gt-btn-hover-border-color: #9cbc21;
  --gt-btn-focus-shadow-rgb: 123, 153, 7;
  --gt-btn-active-color: #000;
  --gt-btn-active-bg: #a7c339;
  --gt-btn-active-border-color: #9cbc21;
  --gt-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --gt-btn-disabled-color: #000;
  --gt-btn-disabled-bg: #91B408;
  --gt-btn-disabled-border-color: #91B408;
}

.btn-secondary {
  --gt-btn-color: #fff;
  --gt-btn-bg: #475569;
  --gt-btn-border-color: #475569;
  --gt-btn-hover-color: #fff;
  --gt-btn-hover-bg: #3c4859;
  --gt-btn-hover-border-color: #394454;
  --gt-btn-focus-shadow-rgb: 99, 111, 128;
  --gt-btn-active-color: #fff;
  --gt-btn-active-bg: #394454;
  --gt-btn-active-border-color: #35404f;
  --gt-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --gt-btn-disabled-color: #fff;
  --gt-btn-disabled-bg: #475569;
  --gt-btn-disabled-border-color: #475569;
}

.btn-success {
  --gt-btn-color: #000;
  --gt-btn-bg: #91B408;
  --gt-btn-border-color: #91B408;
  --gt-btn-hover-color: #000;
  --gt-btn-hover-bg: #a2bf2d;
  --gt-btn-hover-border-color: #9cbc21;
  --gt-btn-focus-shadow-rgb: 123, 153, 7;
  --gt-btn-active-color: #000;
  --gt-btn-active-bg: #a7c339;
  --gt-btn-active-border-color: #9cbc21;
  --gt-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --gt-btn-disabled-color: #000;
  --gt-btn-disabled-bg: #91B408;
  --gt-btn-disabled-border-color: #91B408;
}

.btn-info {
  --gt-btn-color: #000;
  --gt-btn-bg: #42ba96;
  --gt-btn-border-color: #42ba96;
  --gt-btn-hover-color: #000;
  --gt-btn-hover-bg: #5ec4a6;
  --gt-btn-hover-border-color: #55c1a1;
  --gt-btn-focus-shadow-rgb: 56, 158, 128;
  --gt-btn-active-color: #000;
  --gt-btn-active-bg: #68c8ab;
  --gt-btn-active-border-color: #55c1a1;
  --gt-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --gt-btn-disabled-color: #000;
  --gt-btn-disabled-bg: #42ba96;
  --gt-btn-disabled-border-color: #42ba96;
}

.btn-warning {
  --gt-btn-color: #000;
  --gt-btn-bg: #ffed03;
  --gt-btn-border-color: #ffed03;
  --gt-btn-hover-color: #000;
  --gt-btn-hover-bg: #fff029;
  --gt-btn-hover-border-color: #ffef1c;
  --gt-btn-focus-shadow-rgb: 217, 201, 3;
  --gt-btn-active-color: #000;
  --gt-btn-active-bg: #fff135;
  --gt-btn-active-border-color: #ffef1c;
  --gt-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --gt-btn-disabled-color: #000;
  --gt-btn-disabled-bg: #ffed03;
  --gt-btn-disabled-border-color: #ffed03;
}

.btn-danger {
  --gt-btn-color: #fff;
  --gt-btn-bg: #db1a2a;
  --gt-btn-border-color: #db1a2a;
  --gt-btn-hover-color: #fff;
  --gt-btn-hover-bg: #ba1624;
  --gt-btn-hover-border-color: #af1522;
  --gt-btn-focus-shadow-rgb: 224, 60, 74;
  --gt-btn-active-color: #fff;
  --gt-btn-active-bg: #af1522;
  --gt-btn-active-border-color: #a41420;
  --gt-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --gt-btn-disabled-color: #fff;
  --gt-btn-disabled-bg: #db1a2a;
  --gt-btn-disabled-border-color: #db1a2a;
}

.btn-light {
  --gt-btn-color: #000;
  --gt-btn-bg: #F1F5F9;
  --gt-btn-border-color: #F1F5F9;
  --gt-btn-hover-color: #000;
  --gt-btn-hover-bg: #cdd0d4;
  --gt-btn-hover-border-color: #c1c4c7;
  --gt-btn-focus-shadow-rgb: 205, 208, 212;
  --gt-btn-active-color: #000;
  --gt-btn-active-bg: #c1c4c7;
  --gt-btn-active-border-color: #b5b8bb;
  --gt-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --gt-btn-disabled-color: #000;
  --gt-btn-disabled-bg: #F1F5F9;
  --gt-btn-disabled-border-color: #F1F5F9;
}

.btn-dark {
  --gt-btn-color: #fff;
  --gt-btn-bg: #0F172A;
  --gt-btn-border-color: #0F172A;
  --gt-btn-hover-color: #fff;
  --gt-btn-hover-bg: #333a4a;
  --gt-btn-hover-border-color: #272e3f;
  --gt-btn-focus-shadow-rgb: 51, 58, 74;
  --gt-btn-active-color: #fff;
  --gt-btn-active-bg: #3f4555;
  --gt-btn-active-border-color: #272e3f;
  --gt-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --gt-btn-disabled-color: #fff;
  --gt-btn-disabled-bg: #0F172A;
  --gt-btn-disabled-border-color: #0F172A;
}

.btn-white {
  --gt-btn-color: #000;
  --gt-btn-bg: #fff;
  --gt-btn-border-color: #fff;
  --gt-btn-hover-color: #000;
  --gt-btn-hover-bg: white;
  --gt-btn-hover-border-color: white;
  --gt-btn-focus-shadow-rgb: 217, 217, 217;
  --gt-btn-active-color: #000;
  --gt-btn-active-bg: white;
  --gt-btn-active-border-color: white;
  --gt-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --gt-btn-disabled-color: #000;
  --gt-btn-disabled-bg: #fff;
  --gt-btn-disabled-border-color: #fff;
}

.btn-orange {
  --gt-btn-color: #000;
  --gt-btn-bg: #ff6c60;
  --gt-btn-border-color: #ff6c60;
  --gt-btn-hover-color: #000;
  --gt-btn-hover-bg: #ff8278;
  --gt-btn-hover-border-color: #ff7b70;
  --gt-btn-focus-shadow-rgb: 217, 92, 82;
  --gt-btn-active-color: #000;
  --gt-btn-active-bg: #ff8980;
  --gt-btn-active-border-color: #ff7b70;
  --gt-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --gt-btn-disabled-color: #000;
  --gt-btn-disabled-bg: #ff6c60;
  --gt-btn-disabled-border-color: #ff6c60;
}

.btn-outline-primary {
  --gt-btn-color: #91B408;
  --gt-btn-border-color: #91B408;
  --gt-btn-hover-color: #000;
  --gt-btn-hover-bg: #91B408;
  --gt-btn-hover-border-color: #91B408;
  --gt-btn-focus-shadow-rgb: 145, 180, 8;
  --gt-btn-active-color: #000;
  --gt-btn-active-bg: #91B408;
  --gt-btn-active-border-color: #91B408;
  --gt-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --gt-btn-disabled-color: #91B408;
  --gt-btn-disabled-bg: transparent;
  --gt-btn-disabled-border-color: #91B408;
  --gt-gradient: none;
}

.btn-outline-secondary {
  --gt-btn-color: #475569;
  --gt-btn-border-color: #475569;
  --gt-btn-hover-color: #fff;
  --gt-btn-hover-bg: #475569;
  --gt-btn-hover-border-color: #475569;
  --gt-btn-focus-shadow-rgb: 71, 85, 105;
  --gt-btn-active-color: #fff;
  --gt-btn-active-bg: #475569;
  --gt-btn-active-border-color: #475569;
  --gt-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --gt-btn-disabled-color: #475569;
  --gt-btn-disabled-bg: transparent;
  --gt-btn-disabled-border-color: #475569;
  --gt-gradient: none;
}

.btn-outline-success {
  --gt-btn-color: #91B408;
  --gt-btn-border-color: #91B408;
  --gt-btn-hover-color: #000;
  --gt-btn-hover-bg: #91B408;
  --gt-btn-hover-border-color: #91B408;
  --gt-btn-focus-shadow-rgb: 145, 180, 8;
  --gt-btn-active-color: #000;
  --gt-btn-active-bg: #91B408;
  --gt-btn-active-border-color: #91B408;
  --gt-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --gt-btn-disabled-color: #91B408;
  --gt-btn-disabled-bg: transparent;
  --gt-btn-disabled-border-color: #91B408;
  --gt-gradient: none;
}

.btn-outline-info {
  --gt-btn-color: #42ba96;
  --gt-btn-border-color: #42ba96;
  --gt-btn-hover-color: #000;
  --gt-btn-hover-bg: #42ba96;
  --gt-btn-hover-border-color: #42ba96;
  --gt-btn-focus-shadow-rgb: 66, 186, 150;
  --gt-btn-active-color: #000;
  --gt-btn-active-bg: #42ba96;
  --gt-btn-active-border-color: #42ba96;
  --gt-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --gt-btn-disabled-color: #42ba96;
  --gt-btn-disabled-bg: transparent;
  --gt-btn-disabled-border-color: #42ba96;
  --gt-gradient: none;
}

.btn-outline-warning {
  --gt-btn-color: #ffed03;
  --gt-btn-border-color: #ffed03;
  --gt-btn-hover-color: #000;
  --gt-btn-hover-bg: #ffed03;
  --gt-btn-hover-border-color: #ffed03;
  --gt-btn-focus-shadow-rgb: 255, 237, 3;
  --gt-btn-active-color: #000;
  --gt-btn-active-bg: #ffed03;
  --gt-btn-active-border-color: #ffed03;
  --gt-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --gt-btn-disabled-color: #ffed03;
  --gt-btn-disabled-bg: transparent;
  --gt-btn-disabled-border-color: #ffed03;
  --gt-gradient: none;
}

.btn-outline-danger {
  --gt-btn-color: #db1a2a;
  --gt-btn-border-color: #db1a2a;
  --gt-btn-hover-color: #fff;
  --gt-btn-hover-bg: #db1a2a;
  --gt-btn-hover-border-color: #db1a2a;
  --gt-btn-focus-shadow-rgb: 219, 26, 42;
  --gt-btn-active-color: #fff;
  --gt-btn-active-bg: #db1a2a;
  --gt-btn-active-border-color: #db1a2a;
  --gt-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --gt-btn-disabled-color: #db1a2a;
  --gt-btn-disabled-bg: transparent;
  --gt-btn-disabled-border-color: #db1a2a;
  --gt-gradient: none;
}

.btn-outline-light {
  --gt-btn-color: #F1F5F9;
  --gt-btn-border-color: #F1F5F9;
  --gt-btn-hover-color: #000;
  --gt-btn-hover-bg: #F1F5F9;
  --gt-btn-hover-border-color: #F1F5F9;
  --gt-btn-focus-shadow-rgb: 241, 245, 249;
  --gt-btn-active-color: #000;
  --gt-btn-active-bg: #F1F5F9;
  --gt-btn-active-border-color: #F1F5F9;
  --gt-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --gt-btn-disabled-color: #F1F5F9;
  --gt-btn-disabled-bg: transparent;
  --gt-btn-disabled-border-color: #F1F5F9;
  --gt-gradient: none;
}

.btn-outline-dark {
  --gt-btn-color: #0F172A;
  --gt-btn-border-color: #0F172A;
  --gt-btn-hover-color: #fff;
  --gt-btn-hover-bg: #0F172A;
  --gt-btn-hover-border-color: #0F172A;
  --gt-btn-focus-shadow-rgb: 15, 23, 42;
  --gt-btn-active-color: #fff;
  --gt-btn-active-bg: #0F172A;
  --gt-btn-active-border-color: #0F172A;
  --gt-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --gt-btn-disabled-color: #0F172A;
  --gt-btn-disabled-bg: transparent;
  --gt-btn-disabled-border-color: #0F172A;
  --gt-gradient: none;
}

.btn-outline-white {
  --gt-btn-color: #fff;
  --gt-btn-border-color: #fff;
  --gt-btn-hover-color: #000;
  --gt-btn-hover-bg: #fff;
  --gt-btn-hover-border-color: #fff;
  --gt-btn-focus-shadow-rgb: 255, 255, 255;
  --gt-btn-active-color: #000;
  --gt-btn-active-bg: #fff;
  --gt-btn-active-border-color: #fff;
  --gt-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --gt-btn-disabled-color: #fff;
  --gt-btn-disabled-bg: transparent;
  --gt-btn-disabled-border-color: #fff;
  --gt-gradient: none;
}

.btn-outline-orange {
  --gt-btn-color: #ff6c60;
  --gt-btn-border-color: #ff6c60;
  --gt-btn-hover-color: #000;
  --gt-btn-hover-bg: #ff6c60;
  --gt-btn-hover-border-color: #ff6c60;
  --gt-btn-focus-shadow-rgb: 255, 108, 96;
  --gt-btn-active-color: #000;
  --gt-btn-active-bg: #ff6c60;
  --gt-btn-active-border-color: #ff6c60;
  --gt-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --gt-btn-disabled-color: #ff6c60;
  --gt-btn-disabled-bg: transparent;
  --gt-btn-disabled-border-color: #ff6c60;
  --gt-gradient: none;
}

.btn-link {
  --gt-btn-font-weight: 400;
  --gt-btn-color: var(--gt-link-color);
  --gt-btn-bg: transparent;
  --gt-btn-border-color: transparent;
  --gt-btn-hover-color: var(--gt-link-hover-color);
  --gt-btn-hover-border-color: transparent;
  --gt-btn-active-color: var(--gt-link-hover-color);
  --gt-btn-active-border-color: transparent;
  --gt-btn-disabled-color: #475569;
  --gt-btn-disabled-border-color: transparent;
  --gt-btn-box-shadow: none;
  --gt-btn-focus-shadow-rgb: 123, 153, 7;
  text-decoration: none;
}
.btn-link:focus-visible {
  color: var(--gt-btn-color);
}
.btn-link:hover {
  color: var(--gt-btn-hover-color);
}

.btn-lg, .btn-group-lg > .btn {
  --gt-btn-padding-y: 0.5rem;
  --gt-btn-padding-x: 1rem;
  --gt-btn-font-size: calc(1.2625rem + 0.15vw);
  --gt-btn-border-radius: 0.5rem;
}
@media (min-width: 1200px) {
  .btn-lg, .btn-group-lg > .btn {
    --gt-btn-font-size: 1.375rem;
  }
}

.btn-sm, .btn-group-sm > .btn {
  --gt-btn-padding-y: 0.25rem;
  --gt-btn-padding-x: 0.5rem;
  --gt-btn-font-size: 0.9625rem;
  --gt-btn-border-radius: 0.25rem;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart,
.dropup-center,
.dropdown-center {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  --gt-dropdown-zindex: 1000;
  --gt-dropdown-min-width: 10rem;
  --gt-dropdown-padding-x: 0;
  --gt-dropdown-padding-y: 0.5rem;
  --gt-dropdown-spacer: 0.125rem;
  --gt-dropdown-font-size: 0.9375rem;
  --gt-dropdown-color: #0F172A;
  --gt-dropdown-bg: var(--gt-body-bg);
  --gt-dropdown-border-color: transparent;
  --gt-dropdown-border-radius: 0.375rem;
  --gt-dropdown-border-width: var(--gt-border-width);
  --gt-dropdown-inner-border-radius: calc(0.375rem - var(--gt-border-width));
  --gt-dropdown-divider-bg: #94A3B8;
  --gt-dropdown-divider-margin-y: 0.5rem;
  --gt-dropdown-box-shadow: 0 1rem 2.5rem rgba(29, 48, 61, 0.125);
  --gt-dropdown-link-color: #64748B;
  --gt-dropdown-link-hover-color: #91B408;
  --gt-dropdown-link-hover-bg: transparent;
  --gt-dropdown-link-active-color: #fff;
  --gt-dropdown-link-active-bg: #91B408;
  --gt-dropdown-link-disabled-color: #64748B;
  --gt-dropdown-item-padding-x: 1rem;
  --gt-dropdown-item-padding-y: 0.25rem;
  --gt-dropdown-header-color: #475569;
  --gt-dropdown-header-padding-x: 1rem;
  --gt-dropdown-header-padding-y: 0.5rem;
  position: absolute;
  z-index: var(--gt-dropdown-zindex);
  display: none;
  min-width: var(--gt-dropdown-min-width);
  padding: var(--gt-dropdown-padding-y) var(--gt-dropdown-padding-x);
  margin: 0;
  font-size: var(--gt-dropdown-font-size);
  color: var(--gt-dropdown-color);
  text-align: left;
  list-style: none;
  background-color: var(--gt-dropdown-bg);
  background-clip: padding-box;
  border: var(--gt-dropdown-border-width) solid var(--gt-dropdown-border-color);
  border-radius: var(--gt-dropdown-border-radius);
}
.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: var(--gt-dropdown-spacer);
}

.dropdown-menu-start {
  --bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: var(--gt-dropdown-spacer);
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: var(--gt-dropdown-spacer);
}
.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: var(--gt-dropdown-spacer);
}
.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropstart .dropdown-toggle::after {
  display: none;
}
.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: var(--gt-dropdown-divider-margin-y) 0;
  overflow: hidden;
  border-top: 1px solid var(--gt-dropdown-divider-bg);
  opacity: 1;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: var(--gt-dropdown-item-padding-y) var(--gt-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--gt-dropdown-link-color);
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  border-radius: var(--gt-dropdown-item-border-radius, 0);
}
.dropdown-item:hover, .dropdown-item:focus {
  color: var(--gt-dropdown-link-hover-color);
  background-color: var(--gt-dropdown-link-hover-bg);
}
.dropdown-item.active, .dropdown-item:active {
  color: var(--gt-dropdown-link-active-color);
  text-decoration: none;
  background-color: var(--gt-dropdown-link-active-bg);
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: var(--gt-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: var(--gt-dropdown-header-padding-y) var(--gt-dropdown-header-padding-x);
  margin-bottom: 0;
  font-size: 0.9625rem;
  color: var(--gt-dropdown-header-color);
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: var(--gt-dropdown-item-padding-y) var(--gt-dropdown-item-padding-x);
  color: var(--gt-dropdown-link-color);
}

.dropdown-menu-dark {
  --gt-dropdown-color: #CBD5E1;
  --gt-dropdown-bg: #1E293B;
  --gt-dropdown-border-color: transparent;
  --gt-dropdown-box-shadow: ;
  --gt-dropdown-link-color: #CBD5E1;
  --gt-dropdown-link-hover-color: #fff;
  --gt-dropdown-divider-bg: #94A3B8;
  --gt-dropdown-link-hover-bg: rgba(255, 255, 255, 0.15);
  --gt-dropdown-link-active-color: #fff;
  --gt-dropdown-link-active-bg: #91B408;
  --gt-dropdown-link-disabled-color: #64748B;
  --gt-dropdown-header-color: #64748B;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group {
  border-radius: 0.375rem;
}
.btn-group > :not(.btn-check:first-child) + .btn,
.btn-group > .btn-group:not(:first-child) {
  margin-left: calc(var(--gt-border-width) * -1);
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn.dropdown-toggle-split:first-child,
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:nth-child(n+3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.9375rem;
  padding-left: 0.9375rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropend .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: calc(var(--gt-border-width) * -1);
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn ~ .btn,
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  --gt-nav-link-padding-x: 1rem;
  --gt-nav-link-padding-y: 1.75rem;
  --gt-nav-link-font-size: 1rem;
  --gt-nav-link-font-weight: ;
  --gt-nav-link-color: #64748B;
  --gt-nav-link-hover-color: var(--gt-link-hover-color);
  --gt-nav-link-disabled-color: var(--gt-secondary-color);
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: var(--gt-nav-link-padding-y) var(--gt-nav-link-padding-x);
  font-size: var(--gt-nav-link-font-size);
  font-weight: var(--gt-nav-link-font-weight);
  color: var(--gt-nav-link-color);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link:hover, .nav-link:focus {
  color: var(--gt-nav-link-hover-color);
}
.nav-link.disabled {
  color: var(--gt-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  --gt-nav-tabs-border-width: 0;
  --gt-nav-tabs-border-color: var(--gt-border-color);
  --gt-nav-tabs-border-radius: var(--gt-border-radius);
  --gt-nav-tabs-link-hover-border-color: var(--gt-secondary-bg) var(--gt-secondary-bg) var(--gt-border-color);
  --gt-nav-tabs-link-active-color: #91B408;
  --gt-nav-tabs-link-active-bg: var(--gt-body-bg);
  --gt-nav-tabs-link-active-border-color: var(--gt-border-color) var(--gt-border-color) var(--gt-body-bg);
  border-bottom: var(--gt-nav-tabs-border-width) solid var(--gt-nav-tabs-border-color);
}
.nav-tabs .nav-link {
  margin-bottom: calc(-1 * var(--gt-nav-tabs-border-width));
  background: none;
  border: var(--gt-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--gt-nav-tabs-border-radius);
  border-top-right-radius: var(--gt-nav-tabs-border-radius);
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: var(--gt-nav-tabs-link-hover-border-color);
}
.nav-tabs .nav-link.disabled, .nav-tabs .nav-link:disabled {
  color: var(--gt-nav-link-disabled-color);
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: var(--gt-nav-tabs-link-active-color);
  background-color: var(--gt-nav-tabs-link-active-bg);
  border-color: var(--gt-nav-tabs-link-active-border-color);
}
.nav-tabs .dropdown-menu {
  margin-top: calc(-1 * var(--gt-nav-tabs-border-width));
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills {
  --gt-nav-pills-border-radius: 0.375rem;
  --gt-nav-pills-link-active-color: #fff;
  --gt-nav-pills-link-active-bg: #91B408;
}
.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: var(--gt-nav-pills-border-radius);
}
.nav-pills .nav-link:disabled {
  color: var(--gt-nav-link-disabled-color);
  background-color: transparent;
  border-color: transparent;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: var(--gt-nav-pills-link-active-color);
  background-color: var(--gt-nav-pills-link-active-bg);
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  --gt-navbar-padding-x: 0;
  --gt-navbar-padding-y: 0;
  --gt-navbar-color: #64748B;
  --gt-navbar-hover-color: #91B408;
  --gt-navbar-disabled-color: rgba(var(--gt-emphasis-color-rgb), 0.3);
  --gt-navbar-active-color: #91B408;
  --gt-navbar-brand-padding-y: 1.523125rem;
  --gt-navbar-brand-margin-end: 1rem;
  --gt-navbar-brand-font-size: 1.375rem;
  --gt-navbar-brand-color: #91B408;
  --gt-navbar-brand-hover-color: #91B408;
  --gt-navbar-nav-link-padding-x: 0.5rem;
  --gt-navbar-toggler-padding-y: 0.25rem;
  --gt-navbar-toggler-padding-x: 0.75rem;
  --gt-navbar-toggler-font-size: 1.375rem;
  --gt-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%2815, 23, 42, 0.75%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --gt-navbar-toggler-border-color: rgba(var(--gt-emphasis-color-rgb), 0.15);
  --gt-navbar-toggler-border-radius: 0.375rem;
  --gt-navbar-toggler-focus-width: 0.25rem;
  --gt-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: var(--gt-navbar-padding-y) var(--gt-navbar-padding-x);
}
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl,
.navbar > .container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  padding-top: var(--gt-navbar-brand-padding-y);
  padding-bottom: var(--gt-navbar-brand-padding-y);
  margin-right: var(--gt-navbar-brand-margin-end);
  font-size: var(--gt-navbar-brand-font-size);
  color: var(--gt-navbar-brand-color);
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  color: var(--gt-navbar-brand-hover-color);
}

.navbar-nav {
  --gt-nav-link-padding-x: 0;
  --gt-nav-link-padding-y: 1.75rem;
  --gt-nav-link-font-size: 1rem;
  --gt-nav-link-font-weight: ;
  --gt-nav-link-color: var(--gt-navbar-color);
  --gt-nav-link-hover-color: var(--gt-navbar-hover-color);
  --gt-nav-link-disabled-color: var(--gt-navbar-disabled-color);
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .show > .nav-link,
.navbar-nav .nav-link.active {
  color: var(--gt-navbar-active-color);
}
.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 1.75rem;
  padding-bottom: 1.75rem;
  color: var(--gt-navbar-color);
}
.navbar-text a,
.navbar-text a:hover,
.navbar-text a:focus {
  color: var(--gt-navbar-active-color);
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: var(--gt-navbar-toggler-padding-y) var(--gt-navbar-toggler-padding-x);
  font-size: var(--gt-navbar-toggler-font-size);
  line-height: 1;
  color: var(--gt-navbar-color);
  background-color: transparent;
  border: var(--gt-border-width) solid var(--gt-navbar-toggler-border-color);
  border-radius: var(--gt-navbar-toggler-border-radius);
  transition: var(--gt-navbar-toggler-transition);
}
@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 var(--gt-navbar-toggler-focus-width);
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--gt-navbar-toggler-icon-bg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--gt-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: var(--gt-navbar-nav-link-padding-x);
    padding-left: var(--gt-navbar-nav-link-padding-x);
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
  .navbar-expand-sm .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: var(--gt-navbar-nav-link-padding-x);
    padding-left: var(--gt-navbar-nav-link-padding-x);
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
  .navbar-expand-md .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: var(--gt-navbar-nav-link-padding-x);
    padding-left: var(--gt-navbar-nav-link-padding-x);
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: var(--gt-navbar-nav-link-padding-x);
    padding-left: var(--gt-navbar-nav-link-padding-x);
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: var(--gt-navbar-nav-link-padding-x);
    padding-left: var(--gt-navbar-nav-link-padding-x);
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xxl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: var(--gt-navbar-nav-link-padding-x);
  padding-left: var(--gt-navbar-nav-link-padding-x);
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-expand .offcanvas {
  position: static;
  z-index: auto;
  flex-grow: 1;
  width: auto !important;
  height: auto !important;
  visibility: visible !important;
  background-color: transparent !important;
  border: 0 !important;
  transform: none !important;
  transition: none;
}
.navbar-expand .offcanvas .offcanvas-header {
  display: none;
}
.navbar-expand .offcanvas .offcanvas-body {
  display: flex;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}

.navbar-dark {
  --gt-navbar-color: rgba(255, 255, 255, 0.55);
  --gt-navbar-hover-color: rgba(255, 255, 255, 0.75);
  --gt-navbar-disabled-color: rgba(255, 255, 255, 0.25);
  --gt-navbar-active-color: #fff;
  --gt-navbar-brand-color: #fff;
  --gt-navbar-brand-hover-color: #fff;
  --gt-navbar-toggler-border-color: rgba(255, 255, 255, 0.1);
  --gt-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

[data-bs-theme=dark] .navbar {
  --gt-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.card {
  --gt-card-spacer-y: 1rem;
  --gt-card-spacer-x: 1rem;
  --gt-card-title-spacer-y: 0.5rem;
  --gt-card-title-color: ;
  --gt-card-subtitle-color: ;
  --gt-card-border-width: var(--gt-border-width);
  --gt-card-border-color: var(--gt-border-color-translucent);
  --gt-card-border-radius: var(--gt-border-radius);
  --gt-card-box-shadow: ;
  --gt-card-inner-border-radius: calc(var(--gt-border-radius) - (var(--gt-border-width)));
  --gt-card-cap-padding-y: 0.5rem;
  --gt-card-cap-padding-x: 1rem;
  --gt-card-cap-bg: rgba(var(--gt-body-color-rgb), 0.03);
  --gt-card-cap-color: ;
  --gt-card-height: ;
  --gt-card-color: ;
  --gt-card-bg: var(--gt-body-bg);
  --gt-card-img-overlay-padding: 1rem;
  --gt-card-group-margin: 0.75rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--gt-card-height);
  word-wrap: break-word;
  background-color: var(--gt-card-bg);
  background-clip: border-box;
  border: var(--gt-card-border-width) solid var(--gt-card-border-color);
  border-radius: var(--gt-card-border-radius);
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: var(--gt-card-inner-border-radius);
  border-top-right-radius: var(--gt-card-inner-border-radius);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: var(--gt-card-inner-border-radius);
  border-bottom-left-radius: var(--gt-card-inner-border-radius);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: var(--gt-card-spacer-y) var(--gt-card-spacer-x);
  color: var(--gt-card-color);
}

.card-title {
  margin-bottom: var(--gt-card-title-spacer-y);
  color: var(--gt-card-title-color);
}

.card-subtitle {
  margin-top: calc(-0.5 * var(--gt-card-title-spacer-y));
  margin-bottom: 0;
  color: var(--gt-card-subtitle-color);
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link + .card-link {
  margin-left: var(--gt-card-spacer-x);
}

.card-header {
  padding: var(--gt-card-cap-padding-y) var(--gt-card-cap-padding-x);
  margin-bottom: 0;
  color: var(--gt-card-cap-color);
  background-color: var(--gt-card-cap-bg);
  border-bottom: var(--gt-card-border-width) solid var(--gt-card-border-color);
}
.card-header:first-child {
  border-radius: var(--gt-card-inner-border-radius) var(--gt-card-inner-border-radius) 0 0;
}

.card-footer {
  padding: var(--gt-card-cap-padding-y) var(--gt-card-cap-padding-x);
  color: var(--gt-card-cap-color);
  background-color: var(--gt-card-cap-bg);
  border-top: var(--gt-card-border-width) solid var(--gt-card-border-color);
}
.card-footer:last-child {
  border-radius: 0 0 var(--gt-card-inner-border-radius) var(--gt-card-inner-border-radius);
}

.card-header-tabs {
  margin-right: calc(-0.5 * var(--gt-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--gt-card-cap-padding-y));
  margin-left: calc(-0.5 * var(--gt-card-cap-padding-x));
  border-bottom: 0;
}
.card-header-tabs .nav-link.active {
  background-color: var(--gt-card-bg);
  border-bottom-color: var(--gt-card-bg);
}

.card-header-pills {
  margin-right: calc(-0.5 * var(--gt-card-cap-padding-x));
  margin-left: calc(-0.5 * var(--gt-card-cap-padding-x));
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: var(--gt-card-img-overlay-padding);
  border-radius: var(--gt-card-inner-border-radius);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: var(--gt-card-inner-border-radius);
  border-top-right-radius: var(--gt-card-inner-border-radius);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: var(--gt-card-inner-border-radius);
  border-bottom-left-radius: var(--gt-card-inner-border-radius);
}

.card-group > .card {
  margin-bottom: var(--gt-card-group-margin);
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion {
  --gt-accordion-color: var(--gt-body-color);
  --gt-accordion-bg: transparent;
  --gt-accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  --gt-accordion-border-color: var(--gt-border-color);
  --gt-accordion-border-width: 0;
  --gt-accordion-border-radius: 0;
  --gt-accordion-inner-border-radius: 0;
  --gt-accordion-btn-padding-x: 0;
  --gt-accordion-btn-padding-y: 1.35rem;
  --gt-accordion-btn-color: var(--gt-body-color);
  --gt-accordion-btn-bg: transparent;
  --gt-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230F172A'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --gt-accordion-btn-icon-width: 1.25rem;
  --gt-accordion-btn-icon-transform: rotate(-180deg);
  --gt-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --gt-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230F172A'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --gt-accordion-btn-focus-border-color: #c8da84;
  --gt-accordion-btn-focus-box-shadow: none;
  --gt-accordion-body-padding-x: 0;
  --gt-accordion-body-padding-y: 1rem;
  --gt-accordion-active-color: #0F172A;
  --gt-accordion-active-bg: transparent;
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--gt-accordion-btn-padding-y) var(--gt-accordion-btn-padding-x);
  font-size: 1.1rem;
  color: var(--gt-accordion-btn-color);
  text-align: left;
  background-color: var(--gt-accordion-btn-bg);
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--gt-accordion-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}
.accordion-button:not(.collapsed) {
  color: var(--gt-accordion-active-color);
  background-color: var(--gt-accordion-active-bg);
  box-shadow: inset 0 calc(-1 * var(--gt-accordion-border-width)) 0 var(--gt-accordion-border-color);
}
.accordion-button:not(.collapsed)::after {
  background-image: var(--gt-accordion-btn-active-icon);
  transform: var(--gt-accordion-btn-icon-transform);
}
.accordion-button::after {
  flex-shrink: 0;
  width: var(--gt-accordion-btn-icon-width);
  height: var(--gt-accordion-btn-icon-width);
  margin-left: auto;
  content: "";
  background-image: var(--gt-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--gt-accordion-btn-icon-width);
  transition: var(--gt-accordion-btn-icon-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}
.accordion-button:hover {
  z-index: 2;
}
.accordion-button:focus {
  z-index: 3;
  border-color: var(--gt-accordion-btn-focus-border-color);
  outline: 0;
  box-shadow: var(--gt-accordion-btn-focus-box-shadow);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  color: var(--gt-accordion-color);
  background-color: var(--gt-accordion-bg);
  border: var(--gt-accordion-border-width) solid var(--gt-accordion-border-color);
}
.accordion-item:first-of-type {
  border-top-left-radius: var(--gt-accordion-border-radius);
  border-top-right-radius: var(--gt-accordion-border-radius);
}
.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: var(--gt-accordion-inner-border-radius);
  border-top-right-radius: var(--gt-accordion-inner-border-radius);
}
.accordion-item:not(:first-of-type) {
  border-top: 0;
}
.accordion-item:last-of-type {
  border-bottom-right-radius: var(--gt-accordion-border-radius);
  border-bottom-left-radius: var(--gt-accordion-border-radius);
}
.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: var(--gt-accordion-inner-border-radius);
  border-bottom-left-radius: var(--gt-accordion-inner-border-radius);
}
.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: var(--gt-accordion-border-radius);
  border-bottom-left-radius: var(--gt-accordion-border-radius);
}

.accordion-body {
  padding: var(--gt-accordion-body-padding-y) var(--gt-accordion-body-padding-x);
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}
.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.accordion-flush .accordion-item:first-child {
  border-top: 0;
}
.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}
.accordion-flush .accordion-item .accordion-button, .accordion-flush .accordion-item .accordion-button.collapsed {
  border-radius: 0;
}

[data-bs-theme=dark] .accordion-button::after {
  --gt-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%238093ff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --gt-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%238093ff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.breadcrumb {
  --gt-breadcrumb-padding-x: 0;
  --gt-breadcrumb-padding-y: 0;
  --gt-breadcrumb-margin-bottom: 1rem;
  --gt-breadcrumb-bg: ;
  --gt-breadcrumb-border-radius: ;
  --gt-breadcrumb-divider-color: var(--gt-secondary-color);
  --gt-breadcrumb-item-padding-x: 0.5rem;
  --gt-breadcrumb-item-active-color: var(--gt-secondary-color);
  display: flex;
  flex-wrap: wrap;
  padding: var(--gt-breadcrumb-padding-y) var(--gt-breadcrumb-padding-x);
  margin-bottom: var(--gt-breadcrumb-margin-bottom);
  font-size: var(--gt-breadcrumb-font-size);
  list-style: none;
  background-color: var(--gt-breadcrumb-bg);
  border-radius: var(--gt-breadcrumb-border-radius);
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: var(--gt-breadcrumb-item-padding-x);
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: var(--gt-breadcrumb-item-padding-x);
  color: var(--gt-breadcrumb-divider-color);
  content: var(--gt-breadcrumb-divider, "/") /* rtl: var(--gt-breadcrumb-divider, "/") */;
}
.breadcrumb-item.active {
  color: var(--gt-breadcrumb-item-active-color);
}

.pagination {
  --gt-pagination-padding-x: 0.75rem;
  --gt-pagination-padding-y: 0.375rem;
  --gt-pagination-font-size: 1.1rem;
  --gt-pagination-color: var(--gt-link-color);
  --gt-pagination-bg: var(--gt-body-bg);
  --gt-pagination-border-width: var(--gt-border-width);
  --gt-pagination-border-color: var(--gt-border-color);
  --gt-pagination-border-radius: var(--gt-border-radius);
  --gt-pagination-hover-color: var(--gt-link-hover-color);
  --gt-pagination-hover-bg: var(--gt-tertiary-bg);
  --gt-pagination-hover-border-color: var(--gt-border-color);
  --gt-pagination-focus-color: var(--gt-link-hover-color);
  --gt-pagination-focus-bg: var(--gt-secondary-bg);
  --gt-pagination-focus-box-shadow: 0 0 0 0.25rem rgba(145, 180, 8, 0.25);
  --gt-pagination-active-color: #fff;
  --gt-pagination-active-bg: #91B408;
  --gt-pagination-active-border-color: #91B408;
  --gt-pagination-disabled-color: var(--gt-secondary-color);
  --gt-pagination-disabled-bg: var(--gt-secondary-bg);
  --gt-pagination-disabled-border-color: var(--gt-border-color);
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  padding: var(--gt-pagination-padding-y) var(--gt-pagination-padding-x);
  font-size: var(--gt-pagination-font-size);
  color: var(--gt-pagination-color);
  background-color: var(--gt-pagination-bg);
  border: var(--gt-pagination-border-width) solid var(--gt-pagination-border-color);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}
.page-link:hover {
  z-index: 2;
  color: var(--gt-pagination-hover-color);
  background-color: var(--gt-pagination-hover-bg);
  border-color: var(--gt-pagination-hover-border-color);
}
.page-link:focus {
  z-index: 3;
  color: var(--gt-pagination-focus-color);
  background-color: var(--gt-pagination-focus-bg);
  outline: 0;
  box-shadow: var(--gt-pagination-focus-box-shadow);
}
.page-link.active, .active > .page-link {
  z-index: 3;
  color: var(--gt-pagination-active-color);
  background-color: var(--gt-pagination-active-bg);
  border-color: var(--gt-pagination-active-border-color);
}
.page-link.disabled, .disabled > .page-link {
  color: var(--gt-pagination-disabled-color);
  pointer-events: none;
  background-color: var(--gt-pagination-disabled-bg);
  border-color: var(--gt-pagination-disabled-border-color);
}

.page-item:not(:first-child) .page-link {
  margin-left: calc(var(--gt-border-width) * -1);
}
.page-item:first-child .page-link {
  border-top-left-radius: var(--gt-pagination-border-radius);
  border-bottom-left-radius: var(--gt-pagination-border-radius);
}
.page-item:last-child .page-link {
  border-top-right-radius: var(--gt-pagination-border-radius);
  border-bottom-right-radius: var(--gt-pagination-border-radius);
}

.pagination-lg {
  --gt-pagination-padding-x: 1.5rem;
  --gt-pagination-padding-y: 0.75rem;
  --gt-pagination-font-size: calc(1.2625rem + 0.15vw);
  --gt-pagination-border-radius: 0.5rem;
}
@media (min-width: 1200px) {
  .pagination-lg {
    --gt-pagination-font-size: 1.375rem;
  }
}

.pagination-sm {
  --gt-pagination-padding-x: 0.5rem;
  --gt-pagination-padding-y: 0.25rem;
  --gt-pagination-font-size: 0.9625rem;
  --gt-pagination-border-radius: 0.25rem;
}

.badge {
  --gt-badge-padding-x: 0.65em;
  --gt-badge-padding-y: 0.35em;
  --gt-badge-font-size: 0.75em;
  --gt-badge-font-weight: 700;
  --gt-badge-color: #fff;
  --gt-badge-border-radius: 0.375rem;
  display: inline-block;
  padding: var(--gt-badge-padding-y) var(--gt-badge-padding-x);
  font-size: var(--gt-badge-font-size);
  font-weight: var(--gt-badge-font-weight);
  line-height: 1;
  color: var(--gt-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--gt-badge-border-radius);
}
.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  --gt-alert-bg: transparent;
  --gt-alert-padding-x: 1rem;
  --gt-alert-padding-y: 1rem;
  --gt-alert-margin-bottom: 1rem;
  --gt-alert-color: inherit;
  --gt-alert-border-color: transparent;
  --gt-alert-border: var(--gt-border-width) solid var(--gt-alert-border-color);
  --gt-alert-border-radius: 0.375rem;
  --gt-alert-link-color: inherit;
  position: relative;
  padding: var(--gt-alert-padding-y) var(--gt-alert-padding-x);
  margin-bottom: var(--gt-alert-margin-bottom);
  color: var(--gt-alert-color);
  background-color: var(--gt-alert-bg);
  border: var(--gt-alert-border);
  border-radius: var(--gt-alert-border-radius);
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
  color: var(--gt-alert-link-color);
}

.alert-dismissible {
  padding-right: 3rem;
}
.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1rem;
}

.alert-primary {
  --gt-alert-color: var(--gt-primary-text);
  --gt-alert-bg: var(--gt-primary-bg-subtle);
  --gt-alert-border-color: var(--gt-primary-border-subtle);
  --gt-alert-link-color: var(--gt-primary-text);
}

.alert-secondary {
  --gt-alert-color: var(--gt-secondary-text);
  --gt-alert-bg: var(--gt-secondary-bg-subtle);
  --gt-alert-border-color: var(--gt-secondary-border-subtle);
  --gt-alert-link-color: var(--gt-secondary-text);
}

.alert-success {
  --gt-alert-color: var(--gt-success-text);
  --gt-alert-bg: var(--gt-success-bg-subtle);
  --gt-alert-border-color: var(--gt-success-border-subtle);
  --gt-alert-link-color: var(--gt-success-text);
}

.alert-info {
  --gt-alert-color: var(--gt-info-text);
  --gt-alert-bg: var(--gt-info-bg-subtle);
  --gt-alert-border-color: var(--gt-info-border-subtle);
  --gt-alert-link-color: var(--gt-info-text);
}

.alert-warning {
  --gt-alert-color: var(--gt-warning-text);
  --gt-alert-bg: var(--gt-warning-bg-subtle);
  --gt-alert-border-color: var(--gt-warning-border-subtle);
  --gt-alert-link-color: var(--gt-warning-text);
}

.alert-danger {
  --gt-alert-color: var(--gt-danger-text);
  --gt-alert-bg: var(--gt-danger-bg-subtle);
  --gt-alert-border-color: var(--gt-danger-border-subtle);
  --gt-alert-link-color: var(--gt-danger-text);
}

.alert-light {
  --gt-alert-color: var(--gt-light-text);
  --gt-alert-bg: var(--gt-light-bg-subtle);
  --gt-alert-border-color: var(--gt-light-border-subtle);
  --gt-alert-link-color: var(--gt-light-text);
}

.alert-dark {
  --gt-alert-color: var(--gt-dark-text);
  --gt-alert-bg: var(--gt-dark-bg-subtle);
  --gt-alert-border-color: var(--gt-dark-border-subtle);
  --gt-alert-link-color: var(--gt-dark-text);
}

.alert-white {
  --gt-alert-color: var(--gt-white-text);
  --gt-alert-bg: var(--gt-white-bg-subtle);
  --gt-alert-border-color: var(--gt-white-border-subtle);
  --gt-alert-link-color: var(--gt-white-text);
}

.alert-orange {
  --gt-alert-color: var(--gt-orange-text);
  --gt-alert-bg: var(--gt-orange-bg-subtle);
  --gt-alert-border-color: var(--gt-orange-border-subtle);
  --gt-alert-link-color: var(--gt-orange-text);
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}
.progress,
.progress-stacked {
  --gt-progress-height: 1rem;
  --gt-progress-font-size: 0.825rem;
  --gt-progress-bg: var(--gt-secondary-bg);
  --gt-progress-border-radius: var(--gt-border-radius);
  --gt-progress-box-shadow: var(--gt-box-shadow-inset);
  --gt-progress-bar-color: #fff;
  --gt-progress-bar-bg: #91B408;
  --gt-progress-bar-transition: width 0.6s ease;
  display: flex;
  height: var(--gt-progress-height);
  overflow: hidden;
  font-size: var(--gt-progress-font-size);
  background-color: var(--gt-progress-bg);
  border-radius: var(--gt-progress-border-radius);
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: var(--gt-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--gt-progress-bar-bg);
  transition: var(--gt-progress-bar-transition);
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: var(--gt-progress-height) var(--gt-progress-height);
}

.progress-stacked > .progress {
  overflow: visible;
}

.progress-stacked > .progress > .progress-bar {
  width: 100%;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group {
  --gt-list-group-color: var(--gt-body-color);
  --gt-list-group-bg: var(--gt-body-bg);
  --gt-list-group-border-color: var(--gt-border-color);
  --gt-list-group-border-width: var(--gt-border-width);
  --gt-list-group-border-radius: var(--gt-border-radius);
  --gt-list-group-item-padding-x: 1rem;
  --gt-list-group-item-padding-y: 0.5rem;
  --gt-list-group-action-color: var(--gt-secondary-color);
  --gt-list-group-action-hover-color: var(--gt-emphasis-color);
  --gt-list-group-action-hover-bg: var(--gt-tertiary-bg);
  --gt-list-group-action-active-color: var(--gt-body-color);
  --gt-list-group-action-active-bg: var(--gt-secondary-bg);
  --gt-list-group-disabled-color: var(--gt-secondary-color);
  --gt-list-group-disabled-bg: var(--gt-body-bg);
  --gt-list-group-active-color: #fff;
  --gt-list-group-active-bg: #91B408;
  --gt-list-group-active-border-color: #91B408;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: var(--gt-list-group-border-radius);
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}
.list-group-numbered > .list-group-item::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: var(--gt-list-group-action-color);
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: var(--gt-list-group-action-hover-color);
  text-decoration: none;
  background-color: var(--gt-list-group-action-hover-bg);
}
.list-group-item-action:active {
  color: var(--gt-list-group-action-active-color);
  background-color: var(--gt-list-group-action-active-bg);
}

.list-group-item {
  position: relative;
  display: block;
  padding: var(--gt-list-group-item-padding-y) var(--gt-list-group-item-padding-x);
  color: var(--gt-list-group-color);
  background-color: var(--gt-list-group-bg);
  border: var(--gt-list-group-border-width) solid var(--gt-list-group-border-color);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: var(--gt-list-group-disabled-color);
  pointer-events: none;
  background-color: var(--gt-list-group-disabled-bg);
}
.list-group-item.active {
  z-index: 2;
  color: var(--gt-list-group-active-color);
  background-color: var(--gt-list-group-active-bg);
  border-color: var(--gt-list-group-active-border-color);
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: calc(-1 * var(--gt-list-group-border-width));
  border-top-width: var(--gt-list-group-border-width);
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child:not(:last-child) {
  border-bottom-left-radius: var(--gt-list-group-border-radius);
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child:not(:first-child) {
  border-top-right-radius: var(--gt-list-group-border-radius);
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: var(--gt-list-group-border-width);
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: calc(-1 * var(--gt-list-group-border-width));
  border-left-width: var(--gt-list-group-border-width);
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--gt-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--gt-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: var(--gt-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--gt-list-group-border-width));
    border-left-width: var(--gt-list-group-border-width);
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--gt-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--gt-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: var(--gt-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--gt-list-group-border-width));
    border-left-width: var(--gt-list-group-border-width);
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--gt-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--gt-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: var(--gt-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--gt-list-group-border-width));
    border-left-width: var(--gt-list-group-border-width);
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--gt-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--gt-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: var(--gt-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--gt-list-group-border-width));
    border-left-width: var(--gt-list-group-border-width);
  }
}
@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--gt-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--gt-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: var(--gt-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--gt-list-group-border-width));
    border-left-width: var(--gt-list-group-border-width);
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 var(--gt-list-group-border-width);
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  --gt-list-group-color: var(--gt-primary-text);
  --gt-list-group-bg: var(--gt-primary-bg-subtle);
  --gt-list-group-border-color: var(--gt-primary-border-subtle);
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  --gt-list-group-action-hover-color: var(--gt-emphasis-color);
  --gt-list-group-action-hover-bg: var(--gt-primary-border-subtle);
}
.list-group-item-primary.list-group-item-action:active {
  --gt-list-group-active-color: var(--gt-emphasis-color);
  --gt-list-group-active-bg: var(--gt-primary-text);
  --gt-list-group-active-border-color: var(--gt-primary-text);
}

.list-group-item-secondary {
  --gt-list-group-color: var(--gt-secondary-text);
  --gt-list-group-bg: var(--gt-secondary-bg-subtle);
  --gt-list-group-border-color: var(--gt-secondary-border-subtle);
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  --gt-list-group-action-hover-color: var(--gt-emphasis-color);
  --gt-list-group-action-hover-bg: var(--gt-secondary-border-subtle);
}
.list-group-item-secondary.list-group-item-action:active {
  --gt-list-group-active-color: var(--gt-emphasis-color);
  --gt-list-group-active-bg: var(--gt-secondary-text);
  --gt-list-group-active-border-color: var(--gt-secondary-text);
}

.list-group-item-success {
  --gt-list-group-color: var(--gt-success-text);
  --gt-list-group-bg: var(--gt-success-bg-subtle);
  --gt-list-group-border-color: var(--gt-success-border-subtle);
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  --gt-list-group-action-hover-color: var(--gt-emphasis-color);
  --gt-list-group-action-hover-bg: var(--gt-success-border-subtle);
}
.list-group-item-success.list-group-item-action:active {
  --gt-list-group-active-color: var(--gt-emphasis-color);
  --gt-list-group-active-bg: var(--gt-success-text);
  --gt-list-group-active-border-color: var(--gt-success-text);
}

.list-group-item-info {
  --gt-list-group-color: var(--gt-info-text);
  --gt-list-group-bg: var(--gt-info-bg-subtle);
  --gt-list-group-border-color: var(--gt-info-border-subtle);
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  --gt-list-group-action-hover-color: var(--gt-emphasis-color);
  --gt-list-group-action-hover-bg: var(--gt-info-border-subtle);
}
.list-group-item-info.list-group-item-action:active {
  --gt-list-group-active-color: var(--gt-emphasis-color);
  --gt-list-group-active-bg: var(--gt-info-text);
  --gt-list-group-active-border-color: var(--gt-info-text);
}

.list-group-item-warning {
  --gt-list-group-color: var(--gt-warning-text);
  --gt-list-group-bg: var(--gt-warning-bg-subtle);
  --gt-list-group-border-color: var(--gt-warning-border-subtle);
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  --gt-list-group-action-hover-color: var(--gt-emphasis-color);
  --gt-list-group-action-hover-bg: var(--gt-warning-border-subtle);
}
.list-group-item-warning.list-group-item-action:active {
  --gt-list-group-active-color: var(--gt-emphasis-color);
  --gt-list-group-active-bg: var(--gt-warning-text);
  --gt-list-group-active-border-color: var(--gt-warning-text);
}

.list-group-item-danger {
  --gt-list-group-color: var(--gt-danger-text);
  --gt-list-group-bg: var(--gt-danger-bg-subtle);
  --gt-list-group-border-color: var(--gt-danger-border-subtle);
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  --gt-list-group-action-hover-color: var(--gt-emphasis-color);
  --gt-list-group-action-hover-bg: var(--gt-danger-border-subtle);
}
.list-group-item-danger.list-group-item-action:active {
  --gt-list-group-active-color: var(--gt-emphasis-color);
  --gt-list-group-active-bg: var(--gt-danger-text);
  --gt-list-group-active-border-color: var(--gt-danger-text);
}

.list-group-item-light {
  --gt-list-group-color: var(--gt-light-text);
  --gt-list-group-bg: var(--gt-light-bg-subtle);
  --gt-list-group-border-color: var(--gt-light-border-subtle);
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  --gt-list-group-action-hover-color: var(--gt-emphasis-color);
  --gt-list-group-action-hover-bg: var(--gt-light-border-subtle);
}
.list-group-item-light.list-group-item-action:active {
  --gt-list-group-active-color: var(--gt-emphasis-color);
  --gt-list-group-active-bg: var(--gt-light-text);
  --gt-list-group-active-border-color: var(--gt-light-text);
}

.list-group-item-dark {
  --gt-list-group-color: var(--gt-dark-text);
  --gt-list-group-bg: var(--gt-dark-bg-subtle);
  --gt-list-group-border-color: var(--gt-dark-border-subtle);
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  --gt-list-group-action-hover-color: var(--gt-emphasis-color);
  --gt-list-group-action-hover-bg: var(--gt-dark-border-subtle);
}
.list-group-item-dark.list-group-item-action:active {
  --gt-list-group-active-color: var(--gt-emphasis-color);
  --gt-list-group-active-bg: var(--gt-dark-text);
  --gt-list-group-active-border-color: var(--gt-dark-text);
}

.list-group-item-white {
  --gt-list-group-color: var(--gt-white-text);
  --gt-list-group-bg: var(--gt-white-bg-subtle);
  --gt-list-group-border-color: var(--gt-white-border-subtle);
}
.list-group-item-white.list-group-item-action:hover, .list-group-item-white.list-group-item-action:focus {
  --gt-list-group-action-hover-color: var(--gt-emphasis-color);
  --gt-list-group-action-hover-bg: var(--gt-white-border-subtle);
}
.list-group-item-white.list-group-item-action:active {
  --gt-list-group-active-color: var(--gt-emphasis-color);
  --gt-list-group-active-bg: var(--gt-white-text);
  --gt-list-group-active-border-color: var(--gt-white-text);
}

.list-group-item-orange {
  --gt-list-group-color: var(--gt-orange-text);
  --gt-list-group-bg: var(--gt-orange-bg-subtle);
  --gt-list-group-border-color: var(--gt-orange-border-subtle);
}
.list-group-item-orange.list-group-item-action:hover, .list-group-item-orange.list-group-item-action:focus {
  --gt-list-group-action-hover-color: var(--gt-emphasis-color);
  --gt-list-group-action-hover-bg: var(--gt-orange-border-subtle);
}
.list-group-item-orange.list-group-item-action:active {
  --gt-list-group-active-color: var(--gt-emphasis-color);
  --gt-list-group-active-bg: var(--gt-orange-text);
  --gt-list-group-active-border-color: var(--gt-orange-text);
}

.btn-close {
  --gt-btn-close-color: #000;
  --gt-btn-close-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e");
  --gt-btn-close-opacity: 0.5;
  --gt-btn-close-hover-opacity: 0.75;
  --gt-btn-close-focus-shadow: 0 0 0 0.25rem rgba(145, 180, 8, 0.25);
  --gt-btn-close-focus-opacity: 1;
  --gt-btn-close-disabled-opacity: 0.25;
  --gt-btn-close-white-filter: invert(1) grayscale(100%) brightness(200%);
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: var(--gt-btn-close-color);
  background: transparent var(--gt-btn-close-bg) center/1em auto no-repeat;
  border: 0;
  border-radius: 0.375rem;
  opacity: var(--gt-btn-close-opacity);
}
.btn-close:hover {
  color: var(--gt-btn-close-color);
  text-decoration: none;
  opacity: var(--gt-btn-close-hover-opacity);
}
.btn-close:focus {
  outline: 0;
  box-shadow: var(--gt-btn-close-focus-shadow);
  opacity: var(--gt-btn-close-focus-opacity);
}
.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  -webkit-user-select: none;
          user-select: none;
  opacity: var(--gt-btn-close-disabled-opacity);
}

.btn-close-white {
  filter: var(--gt-btn-close-white-filter);
}

[data-bs-theme=dark] .btn-close {
  filter: var(--gt-btn-close-white-filter);
}

.toast {
  --gt-toast-zindex: 1090;
  --gt-toast-padding-x: 0.75rem;
  --gt-toast-padding-y: 0.5rem;
  --gt-toast-spacing: 1.5rem;
  --gt-toast-max-width: 350px;
  --gt-toast-font-size: 0.875rem;
  --gt-toast-color: ;
  --gt-toast-bg: rgba(var(--gt-body-bg-rgb), 0.85);
  --gt-toast-border-width: var(--gt-border-width);
  --gt-toast-border-color: var(--gt-border-color-translucent);
  --gt-toast-border-radius: var(--gt-border-radius);
  --gt-toast-box-shadow: var(--gt-box-shadow);
  --gt-toast-header-color: var(--gt-secondary-color);
  --gt-toast-header-bg: rgba(var(--gt-body-bg-rgb), 0.85);
  --gt-toast-header-border-color: var(--gt-border-color-translucent);
  width: var(--gt-toast-max-width);
  max-width: 100%;
  font-size: var(--gt-toast-font-size);
  color: var(--gt-toast-color);
  pointer-events: auto;
  background-color: var(--gt-toast-bg);
  background-clip: padding-box;
  border: var(--gt-toast-border-width) solid var(--gt-toast-border-color);
  box-shadow: var(--gt-toast-box-shadow);
  border-radius: var(--gt-toast-border-radius);
}
.toast.showing {
  opacity: 0;
}
.toast:not(.show) {
  display: none;
}

.toast-container {
  --gt-toast-zindex: 1090;
  position: absolute;
  z-index: var(--gt-toast-zindex);
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}
.toast-container > :not(:last-child) {
  margin-bottom: var(--gt-toast-spacing);
}

.toast-header {
  display: flex;
  align-items: center;
  padding: var(--gt-toast-padding-y) var(--gt-toast-padding-x);
  color: var(--gt-toast-header-color);
  background-color: var(--gt-toast-header-bg);
  background-clip: padding-box;
  border-bottom: var(--gt-toast-border-width) solid var(--gt-toast-header-border-color);
  border-top-left-radius: calc(var(--gt-toast-border-radius) - var(--gt-toast-border-width));
  border-top-right-radius: calc(var(--gt-toast-border-radius) - var(--gt-toast-border-width));
}
.toast-header .btn-close {
  margin-right: calc(-0.5 * var(--gt-toast-padding-x));
  margin-left: var(--gt-toast-padding-x);
}

.toast-body {
  padding: var(--gt-toast-padding-x);
  word-wrap: break-word;
}

.modal {
  --gt-modal-zindex: 1055;
  --gt-modal-width: 500px;
  --gt-modal-padding: 1rem;
  --gt-modal-margin: 0.5rem;
  --gt-modal-color: ;
  --gt-modal-bg: var(--gt-body-bg);
  --gt-modal-border-color: var(--gt-border-color-translucent);
  --gt-modal-border-width: var(--gt-border-width);
  --gt-modal-border-radius: var(--gt-border-radius-lg);
  --gt-modal-box-shadow: 0 4px 0.5rem rgba(29, 48, 61, 0.03);
  --gt-modal-inner-border-radius: calc(var(--gt-border-radius-lg) - (var(--gt-border-width)));
  --gt-modal-header-padding-x: 1rem;
  --gt-modal-header-padding-y: 1rem;
  --gt-modal-header-padding: 1rem 1rem;
  --gt-modal-header-border-color: var(--gt-border-color);
  --gt-modal-header-border-width: var(--gt-border-width);
  --gt-modal-title-line-height: 1.65;
  --gt-modal-footer-gap: 0.5rem;
  --gt-modal-footer-bg: ;
  --gt-modal-footer-border-color: var(--gt-border-color);
  --gt-modal-footer-border-width: var(--gt-border-width);
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--gt-modal-zindex);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: var(--gt-modal-margin);
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - var(--gt-modal-margin) * 2);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--gt-modal-margin) * 2);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--gt-modal-color);
  pointer-events: auto;
  background-color: var(--gt-modal-bg);
  background-clip: padding-box;
  border: var(--gt-modal-border-width) solid var(--gt-modal-border-color);
  border-radius: var(--gt-modal-border-radius);
  outline: 0;
}

.modal-backdrop {
  --gt-backdrop-zindex: 1050;
  --gt-backdrop-bg: #000;
  --gt-backdrop-opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--gt-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--gt-backdrop-bg);
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: var(--gt-backdrop-opacity);
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: var(--gt-modal-header-padding);
  border-bottom: var(--gt-modal-header-border-width) solid var(--gt-modal-header-border-color);
  border-top-left-radius: var(--gt-modal-inner-border-radius);
  border-top-right-radius: var(--gt-modal-inner-border-radius);
}
.modal-header .btn-close {
  padding: calc(var(--gt-modal-header-padding-y) * 0.5) calc(var(--gt-modal-header-padding-x) * 0.5);
  margin: calc(-0.5 * var(--gt-modal-header-padding-y)) calc(-0.5 * var(--gt-modal-header-padding-x)) calc(-0.5 * var(--gt-modal-header-padding-y)) auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: var(--gt-modal-title-line-height);
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: var(--gt-modal-padding);
}

.modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: calc(var(--gt-modal-padding) - var(--gt-modal-footer-gap) * 0.5);
  background-color: var(--gt-modal-footer-bg);
  border-top: var(--gt-modal-footer-border-width) solid var(--gt-modal-footer-border-color);
  border-bottom-right-radius: var(--gt-modal-inner-border-radius);
  border-bottom-left-radius: var(--gt-modal-inner-border-radius);
}
.modal-footer > * {
  margin: calc(var(--gt-modal-footer-gap) * 0.5);
}

@media (min-width: 576px) {
  .modal {
    --gt-modal-margin: 1.75rem;
    --gt-modal-box-shadow: 0 0.5rem 1rem rgba(29, 48, 61, 0.06);
  }
  .modal-dialog {
    max-width: var(--gt-modal-width);
    margin-right: auto;
    margin-left: auto;
  }
  .modal-sm {
    --gt-modal-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    --gt-modal-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    --gt-modal-width: 1140px;
  }
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header,
.modal-fullscreen .modal-footer {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header,
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header,
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header,
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header,
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header,
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
}
.tooltip {
  --gt-tooltip-zindex: 1080;
  --gt-tooltip-max-width: 200px;
  --gt-tooltip-padding-x: 0.5rem;
  --gt-tooltip-padding-y: 0.25rem;
  --gt-tooltip-margin: ;
  --gt-tooltip-font-size: 0.9625rem;
  --gt-tooltip-color: var(--gt-body-bg);
  --gt-tooltip-bg: var(--gt-emphasis-color);
  --gt-tooltip-border-radius: var(--gt-border-radius);
  --gt-tooltip-opacity: 0.9;
  --gt-tooltip-arrow-width: 0.8rem;
  --gt-tooltip-arrow-height: 0.4rem;
  z-index: var(--gt-tooltip-zindex);
  display: block;
  padding: var(--gt-tooltip-arrow-height);
  margin: var(--gt-tooltip-margin);
  font-family: var(--gt-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.65;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--gt-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: var(--gt-tooltip-opacity);
}
.tooltip .tooltip-arrow {
  display: block;
  width: var(--gt-tooltip-arrow-width);
  height: var(--gt-tooltip-arrow-height);
}
.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow {
  bottom: 0;
}
.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
  top: -1px;
  border-width: var(--gt-tooltip-arrow-height) calc(var(--gt-tooltip-arrow-width) * 0.5) 0;
  border-top-color: var(--gt-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow {
  left: 0;
  width: var(--gt-tooltip-arrow-height);
  height: var(--gt-tooltip-arrow-width);
}
.bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
  right: -1px;
  border-width: calc(var(--gt-tooltip-arrow-width) * 0.5) var(--gt-tooltip-arrow-height) calc(var(--gt-tooltip-arrow-width) * 0.5) 0;
  border-right-color: var(--gt-tooltip-bg);
}

/* rtl:end:ignore */
.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow {
  top: 0;
}
.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 calc(var(--gt-tooltip-arrow-width) * 0.5) var(--gt-tooltip-arrow-height);
  border-bottom-color: var(--gt-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow {
  right: 0;
  width: var(--gt-tooltip-arrow-height);
  height: var(--gt-tooltip-arrow-width);
}
.bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
  left: -1px;
  border-width: calc(var(--gt-tooltip-arrow-width) * 0.5) 0 calc(var(--gt-tooltip-arrow-width) * 0.5) var(--gt-tooltip-arrow-height);
  border-left-color: var(--gt-tooltip-bg);
}

/* rtl:end:ignore */
.tooltip-inner {
  max-width: var(--gt-tooltip-max-width);
  padding: var(--gt-tooltip-padding-y) var(--gt-tooltip-padding-x);
  color: var(--gt-tooltip-color);
  text-align: center;
  background-color: var(--gt-tooltip-bg);
  border-radius: var(--gt-tooltip-border-radius);
}

.popover {
  --gt-popover-zindex: 1070;
  --gt-popover-max-width: 276px;
  --gt-popover-font-size: 0.9625rem;
  --gt-popover-bg: var(--gt-body-bg);
  --gt-popover-border-width: var(--gt-border-width);
  --gt-popover-border-color: var(--gt-border-color-translucent);
  --gt-popover-border-radius: var(--gt-border-radius-lg);
  --gt-popover-inner-border-radius: calc(var(--gt-border-radius-lg) - var(--gt-border-width));
  --gt-popover-box-shadow: 0 0.5rem 1rem rgba(29, 48, 61, 0.06);
  --gt-popover-header-padding-x: 1rem;
  --gt-popover-header-padding-y: 0.5rem;
  --gt-popover-header-font-size: 1.1rem;
  --gt-popover-header-color: ;
  --gt-popover-header-bg: var(--gt-secondary-bg);
  --gt-popover-body-padding-x: 1rem;
  --gt-popover-body-padding-y: 1rem;
  --gt-popover-body-color: var(--gt-body-color);
  --gt-popover-arrow-width: 1rem;
  --gt-popover-arrow-height: 0.5rem;
  --gt-popover-arrow-border: var(--gt-popover-border-color);
  z-index: var(--gt-popover-zindex);
  display: block;
  max-width: var(--gt-popover-max-width);
  font-family: var(--gt-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.65;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--gt-popover-font-size);
  word-wrap: break-word;
  background-color: var(--gt-popover-bg);
  background-clip: padding-box;
  border: var(--gt-popover-border-width) solid var(--gt-popover-border-color);
  border-radius: var(--gt-popover-border-radius);
}
.popover .popover-arrow {
  display: block;
  width: var(--gt-popover-arrow-width);
  height: var(--gt-popover-arrow-height);
}
.popover .popover-arrow::before, .popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
  border-width: 0;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow {
  bottom: calc(-1 * (var(--gt-popover-arrow-height)) - var(--gt-popover-border-width));
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before, .bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  border-width: var(--gt-popover-arrow-height) calc(var(--gt-popover-arrow-width) * 0.5) 0;
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before {
  bottom: 0;
  border-top-color: var(--gt-popover-arrow-border);
}
.bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  bottom: var(--gt-popover-border-width);
  border-top-color: var(--gt-popover-bg);
}

/* rtl:begin:ignore */
.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow {
  left: calc(-1 * (var(--gt-popover-arrow-height)) - var(--gt-popover-border-width));
  width: var(--gt-popover-arrow-height);
  height: var(--gt-popover-arrow-width);
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before, .bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  border-width: calc(var(--gt-popover-arrow-width) * 0.5) var(--gt-popover-arrow-height) calc(var(--gt-popover-arrow-width) * 0.5) 0;
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before {
  left: 0;
  border-right-color: var(--gt-popover-arrow-border);
}
.bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  left: var(--gt-popover-border-width);
  border-right-color: var(--gt-popover-bg);
}

/* rtl:end:ignore */
.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow {
  top: calc(-1 * (var(--gt-popover-arrow-height)) - var(--gt-popover-border-width));
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before, .bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  border-width: 0 calc(var(--gt-popover-arrow-width) * 0.5) var(--gt-popover-arrow-height);
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before {
  top: 0;
  border-bottom-color: var(--gt-popover-arrow-border);
}
.bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  top: var(--gt-popover-border-width);
  border-bottom-color: var(--gt-popover-bg);
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: var(--gt-popover-arrow-width);
  margin-left: calc(-0.5 * var(--gt-popover-arrow-width));
  content: "";
  border-bottom: var(--gt-popover-border-width) solid var(--gt-popover-header-bg);
}

/* rtl:begin:ignore */
.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow {
  right: calc(-1 * (var(--gt-popover-arrow-height)) - var(--gt-popover-border-width));
  width: var(--gt-popover-arrow-height);
  height: var(--gt-popover-arrow-width);
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before, .bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  border-width: calc(var(--gt-popover-arrow-width) * 0.5) 0 calc(var(--gt-popover-arrow-width) * 0.5) var(--gt-popover-arrow-height);
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before {
  right: 0;
  border-left-color: var(--gt-popover-arrow-border);
}
.bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  right: var(--gt-popover-border-width);
  border-left-color: var(--gt-popover-bg);
}

/* rtl:end:ignore */
.popover-header {
  padding: var(--gt-popover-header-padding-y) var(--gt-popover-header-padding-x);
  margin-bottom: 0;
  font-size: var(--gt-popover-header-font-size);
  color: var(--gt-popover-header-color);
  background-color: var(--gt-popover-header-bg);
  border-bottom: var(--gt-popover-border-width) solid var(--gt-popover-border-color);
  border-top-left-radius: var(--gt-popover-inner-border-radius);
  border-top-right-radius: var(--gt-popover-inner-border-radius);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: var(--gt-popover-body-padding-y) var(--gt-popover-body-padding-x);
  color: var(--gt-popover-body-color);
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start,
  .carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000;
}
.carousel-dark .carousel-caption {
  color: #000;
}

[data-bs-theme=dark] .carousel .carousel-control-prev-icon,
[data-bs-theme=dark] .carousel .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
[data-bs-theme=dark] .carousel .carousel-indicators [data-bs-target] {
  background-color: #000;
}
[data-bs-theme=dark] .carousel .carousel-caption {
  color: #000;
}

.spinner-grow,
.spinner-border {
  display: inline-block;
  width: var(--gt-spinner-width);
  height: var(--gt-spinner-height);
  vertical-align: var(--gt-spinner-vertical-align);
  border-radius: 50%;
  animation: var(--gt-spinner-animation-speed) linear infinite var(--gt-spinner-animation-name);
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */;
  }
}
.spinner-border {
  --gt-spinner-width: 2rem;
  --gt-spinner-height: 2rem;
  --gt-spinner-vertical-align: -0.125em;
  --gt-spinner-border-width: 0.25em;
  --gt-spinner-animation-speed: 0.75s;
  --gt-spinner-animation-name: spinner-border;
  border: var(--gt-spinner-border-width) solid currentcolor;
  border-right-color: transparent;
}

.spinner-border-sm {
  --gt-spinner-width: 1rem;
  --gt-spinner-height: 1rem;
  --gt-spinner-border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  --gt-spinner-width: 2rem;
  --gt-spinner-height: 2rem;
  --gt-spinner-vertical-align: -0.125em;
  --gt-spinner-animation-speed: 0.75s;
  --gt-spinner-animation-name: spinner-grow;
  background-color: currentcolor;
  opacity: 0;
}

.spinner-grow-sm {
  --gt-spinner-width: 1rem;
  --gt-spinner-height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    --gt-spinner-animation-speed: 1.5s;
  }
}
.offcanvas, .offcanvas-xxl, .offcanvas-xl, .offcanvas-lg, .offcanvas-md, .offcanvas-sm {
  --gt-offcanvas-zindex: 1045;
  --gt-offcanvas-width: 400px;
  --gt-offcanvas-height: 30vh;
  --gt-offcanvas-padding-x: 1rem;
  --gt-offcanvas-padding-y: 1rem;
  --gt-offcanvas-color: var(--gt-body-color);
  --gt-offcanvas-bg: var(--gt-body-bg);
  --gt-offcanvas-border-width: var(--gt-border-width);
  --gt-offcanvas-border-color: var(--gt-border-color-translucent);
  --gt-offcanvas-box-shadow: 0 4px 0.5rem rgba(29, 48, 61, 0.03);
  --gt-offcanvas-transition: transform 0.3s ease-in-out;
  --gt-offcanvas-title-line-height: 1.65;
}

@media (max-width: 575.98px) {
  .offcanvas-sm {
    position: fixed;
    bottom: 0;
    z-index: var(--gt-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--gt-offcanvas-color);
    visibility: hidden;
    background-color: var(--gt-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--gt-offcanvas-transition);
  }
}
@media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-sm {
    transition: none;
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--gt-offcanvas-width);
    border-right: var(--gt-offcanvas-border-width) solid var(--gt-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--gt-offcanvas-width);
    border-left: var(--gt-offcanvas-border-width) solid var(--gt-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--gt-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--gt-offcanvas-border-width) solid var(--gt-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--gt-offcanvas-height);
    max-height: 100%;
    border-top: var(--gt-offcanvas-border-width) solid var(--gt-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.showing, .offcanvas-sm.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.showing, .offcanvas-sm.hiding, .offcanvas-sm.show {
    visibility: visible;
  }
}
@media (min-width: 576px) {
  .offcanvas-sm {
    --gt-offcanvas-height: auto;
    --gt-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-sm .offcanvas-header {
    display: none;
  }
  .offcanvas-sm .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 767.98px) {
  .offcanvas-md {
    position: fixed;
    bottom: 0;
    z-index: var(--gt-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--gt-offcanvas-color);
    visibility: hidden;
    background-color: var(--gt-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--gt-offcanvas-transition);
  }
}
@media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-md {
    transition: none;
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--gt-offcanvas-width);
    border-right: var(--gt-offcanvas-border-width) solid var(--gt-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--gt-offcanvas-width);
    border-left: var(--gt-offcanvas-border-width) solid var(--gt-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--gt-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--gt-offcanvas-border-width) solid var(--gt-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--gt-offcanvas-height);
    max-height: 100%;
    border-top: var(--gt-offcanvas-border-width) solid var(--gt-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.showing, .offcanvas-md.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.showing, .offcanvas-md.hiding, .offcanvas-md.show {
    visibility: visible;
  }
}
@media (min-width: 768px) {
  .offcanvas-md {
    --gt-offcanvas-height: auto;
    --gt-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-md .offcanvas-header {
    display: none;
  }
  .offcanvas-md .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 991.98px) {
  .offcanvas-lg {
    position: fixed;
    bottom: 0;
    z-index: var(--gt-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--gt-offcanvas-color);
    visibility: hidden;
    background-color: var(--gt-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--gt-offcanvas-transition);
  }
}
@media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-lg {
    transition: none;
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--gt-offcanvas-width);
    border-right: var(--gt-offcanvas-border-width) solid var(--gt-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--gt-offcanvas-width);
    border-left: var(--gt-offcanvas-border-width) solid var(--gt-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--gt-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--gt-offcanvas-border-width) solid var(--gt-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--gt-offcanvas-height);
    max-height: 100%;
    border-top: var(--gt-offcanvas-border-width) solid var(--gt-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.showing, .offcanvas-lg.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.showing, .offcanvas-lg.hiding, .offcanvas-lg.show {
    visibility: visible;
  }
}
@media (min-width: 992px) {
  .offcanvas-lg {
    --gt-offcanvas-height: auto;
    --gt-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-lg .offcanvas-header {
    display: none;
  }
  .offcanvas-lg .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1199.98px) {
  .offcanvas-xl {
    position: fixed;
    bottom: 0;
    z-index: var(--gt-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--gt-offcanvas-color);
    visibility: hidden;
    background-color: var(--gt-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--gt-offcanvas-transition);
  }
}
@media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xl {
    transition: none;
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--gt-offcanvas-width);
    border-right: var(--gt-offcanvas-border-width) solid var(--gt-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--gt-offcanvas-width);
    border-left: var(--gt-offcanvas-border-width) solid var(--gt-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--gt-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--gt-offcanvas-border-width) solid var(--gt-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--gt-offcanvas-height);
    max-height: 100%;
    border-top: var(--gt-offcanvas-border-width) solid var(--gt-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.showing, .offcanvas-xl.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.showing, .offcanvas-xl.hiding, .offcanvas-xl.show {
    visibility: visible;
  }
}
@media (min-width: 1200px) {
  .offcanvas-xl {
    --gt-offcanvas-height: auto;
    --gt-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xl .offcanvas-header {
    display: none;
  }
  .offcanvas-xl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1399.98px) {
  .offcanvas-xxl {
    position: fixed;
    bottom: 0;
    z-index: var(--gt-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--gt-offcanvas-color);
    visibility: hidden;
    background-color: var(--gt-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--gt-offcanvas-transition);
  }
}
@media (max-width: 1399.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xxl {
    transition: none;
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--gt-offcanvas-width);
    border-right: var(--gt-offcanvas-border-width) solid var(--gt-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--gt-offcanvas-width);
    border-left: var(--gt-offcanvas-border-width) solid var(--gt-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--gt-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--gt-offcanvas-border-width) solid var(--gt-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--gt-offcanvas-height);
    max-height: 100%;
    border-top: var(--gt-offcanvas-border-width) solid var(--gt-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.showing, .offcanvas-xxl.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.showing, .offcanvas-xxl.hiding, .offcanvas-xxl.show {
    visibility: visible;
  }
}
@media (min-width: 1400px) {
  .offcanvas-xxl {
    --gt-offcanvas-height: auto;
    --gt-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xxl .offcanvas-header {
    display: none;
  }
  .offcanvas-xxl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: var(--gt-offcanvas-zindex);
  display: flex;
  flex-direction: column;
  max-width: 100%;
  color: var(--gt-offcanvas-color);
  visibility: hidden;
  background-color: var(--gt-offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  transition: var(--gt-offcanvas-transition);
}
@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}
.offcanvas.offcanvas-start {
  top: 0;
  left: 0;
  width: var(--gt-offcanvas-width);
  border-right: var(--gt-offcanvas-border-width) solid var(--gt-offcanvas-border-color);
  transform: translateX(-100%);
}
.offcanvas.offcanvas-end {
  top: 0;
  right: 0;
  width: var(--gt-offcanvas-width);
  border-left: var(--gt-offcanvas-border-width) solid var(--gt-offcanvas-border-color);
  transform: translateX(100%);
}
.offcanvas.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: var(--gt-offcanvas-height);
  max-height: 100%;
  border-bottom: var(--gt-offcanvas-border-width) solid var(--gt-offcanvas-border-color);
  transform: translateY(-100%);
}
.offcanvas.offcanvas-bottom {
  right: 0;
  left: 0;
  height: var(--gt-offcanvas-height);
  max-height: 100%;
  border-top: var(--gt-offcanvas-border-width) solid var(--gt-offcanvas-border-color);
  transform: translateY(100%);
}
.offcanvas.showing, .offcanvas.show:not(.hiding) {
  transform: none;
}
.offcanvas.showing, .offcanvas.hiding, .offcanvas.show {
  visibility: visible;
}

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.offcanvas-backdrop.fade {
  opacity: 0;
}
.offcanvas-backdrop.show {
  opacity: 0.5;
}

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--gt-offcanvas-padding-y) var(--gt-offcanvas-padding-x);
}
.offcanvas-header .btn-close {
  padding: calc(var(--gt-offcanvas-padding-y) * 0.5) calc(var(--gt-offcanvas-padding-x) * 0.5);
  margin-top: calc(-0.5 * var(--gt-offcanvas-padding-y));
  margin-right: calc(-0.5 * var(--gt-offcanvas-padding-x));
  margin-bottom: calc(-0.5 * var(--gt-offcanvas-padding-y));
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: var(--gt-offcanvas-title-line-height);
}

.offcanvas-body {
  flex-grow: 1;
  padding: var(--gt-offcanvas-padding-y) var(--gt-offcanvas-padding-x);
  overflow-y: auto;
}

.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentcolor;
  opacity: 0.5;
}
.placeholder.btn::before {
  display: inline-block;
  content: "";
}

.placeholder-xs {
  min-height: 0.6em;
}

.placeholder-sm {
  min-height: 0.8em;
}

.placeholder-lg {
  min-height: 1.2em;
}

.placeholder-glow .placeholder {
  animation: placeholder-glow 2s ease-in-out infinite;
}

@keyframes placeholder-glow {
  50% {
    opacity: 0.2;
  }
}
.placeholder-wave {
  -webkit-mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
          mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
  -webkit-mask-size: 200% 100%;
          mask-size: 200% 100%;
  animation: placeholder-wave 2s linear infinite;
}

@keyframes placeholder-wave {
  100% {
    -webkit-mask-position: -200% 0%;
            mask-position: -200% 0%;
  }
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.text-bg-primary {
  color: #000 !important;
  background-color: RGBA(145, 180, 8, var(--gt-bg-opacity, 1)) !important;
}

.text-bg-secondary {
  color: #fff !important;
  background-color: RGBA(71, 85, 105, var(--gt-bg-opacity, 1)) !important;
}

.text-bg-success {
  color: #000 !important;
  background-color: RGBA(145, 180, 8, var(--gt-bg-opacity, 1)) !important;
}

.text-bg-info {
  color: #000 !important;
  background-color: RGBA(66, 186, 150, var(--gt-bg-opacity, 1)) !important;
}

.text-bg-warning {
  color: #000 !important;
  background-color: RGBA(255, 237, 3, var(--gt-bg-opacity, 1)) !important;
}

.text-bg-danger {
  color: #fff !important;
  background-color: RGBA(219, 26, 42, var(--gt-bg-opacity, 1)) !important;
}

.text-bg-light {
  color: #000 !important;
  background-color: RGBA(241, 245, 249, var(--gt-bg-opacity, 1)) !important;
}

.text-bg-dark {
  color: #fff !important;
  background-color: RGBA(15, 23, 42, var(--gt-bg-opacity, 1)) !important;
}

.text-bg-white {
  color: #000 !important;
  background-color: RGBA(255, 255, 255, var(--gt-bg-opacity, 1)) !important;
}

.text-bg-orange {
  color: #000 !important;
  background-color: RGBA(255, 108, 96, var(--gt-bg-opacity, 1)) !important;
}

.link-primary {
  color: #91B408 !important;
}
.link-primary:hover, .link-primary:focus {
  color: #a7c339 !important;
}

.link-secondary {
  color: #475569 !important;
}
.link-secondary:hover, .link-secondary:focus {
  color: #394454 !important;
}

.link-success {
  color: #91B408 !important;
}
.link-success:hover, .link-success:focus {
  color: #a7c339 !important;
}

.link-info {
  color: #42ba96 !important;
}
.link-info:hover, .link-info:focus {
  color: #68c8ab !important;
}

.link-warning {
  color: #ffed03 !important;
}
.link-warning:hover, .link-warning:focus {
  color: #fff135 !important;
}

.link-danger {
  color: #db1a2a !important;
}
.link-danger:hover, .link-danger:focus {
  color: #af1522 !important;
}

.link-light {
  color: #F1F5F9 !important;
}
.link-light:hover, .link-light:focus {
  color: #f4f7fa !important;
}

.link-dark {
  color: #0F172A !important;
}
.link-dark:hover, .link-dark:focus {
  color: #0c1222 !important;
}

.link-white {
  color: #fff !important;
}
.link-white:hover, .link-white:focus {
  color: white !important;
}

.link-orange {
  color: #ff6c60 !important;
}
.link-orange:hover, .link-orange:focus {
  color: #ff8980 !important;
}

.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--gt-aspect-ratio);
  content: "";
}
.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --gt-aspect-ratio: 100%;
}

.ratio-4x3 {
  --gt-aspect-ratio: 75%;
}

.ratio-16x9 {
  --gt-aspect-ratio: 56.25%;
}

.ratio-21x9 {
  --gt-aspect-ratio: 42.8571428571%;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

.sticky-bottom {
  position: sticky;
  bottom: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-sm-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-md-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-lg-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xxl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vr {
  display: inline-block;
  align-self: stretch;
  width: 1px;
  min-height: 1em;
  background-color: currentcolor;
  opacity: 0.25;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.align-sub {
  vertical-align: sub !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.object-fit-contain {
  -o-object-fit: contain !important;
     object-fit: contain !important;
}

.object-fit-cover {
  -o-object-fit: cover !important;
     object-fit: cover !important;
}

.object-fit-fill {
  -o-object-fit: fill !important;
     object-fit: fill !important;
}

.object-fit-scale {
  -o-object-fit: scale-down !important;
     object-fit: scale-down !important;
}

.object-fit-none {
  -o-object-fit: none !important;
     object-fit: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-0-hover:hover {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-25-hover:hover {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-50-hover:hover {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-75-hover:hover {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.opacity-100-hover:hover {
  opacity: 1 !important;
}

.opacity-5 {
  opacity: 0.05 !important;
}

.opacity-5-hover:hover {
  opacity: 0.05 !important;
}

.opacity-10 {
  opacity: 0.1 !important;
}

.opacity-10-hover:hover {
  opacity: 0.1 !important;
}

.opacity-90 {
  opacity: 0.9 !important;
}

.opacity-90-hover:hover {
  opacity: 0.9 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.overflow-x-auto {
  overflow-x: auto !important;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.overflow-x-visible {
  overflow-x: visible !important;
}

.overflow-x-scroll {
  overflow-x: scroll !important;
}

.overflow-y-auto {
  overflow-y: auto !important;
}

.overflow-y-hidden {
  overflow-y: hidden !important;
}

.overflow-y-visible {
  overflow-y: visible !important;
}

.overflow-y-scroll {
  overflow-y: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(29, 48, 61, 0.06) !important;
}

.shadow-sm {
  box-shadow: 0 4px 0.5rem rgba(29, 48, 61, 0.03) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 2.5rem rgba(29, 48, 61, 0.125) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-1 {
  top: 1% !important;
}

.top-n1 {
  top: -1% !important;
}

.top-2 {
  top: 2% !important;
}

.top-n2 {
  top: -2% !important;
}

.top-3 {
  top: 3% !important;
}

.top-n3 {
  top: -3% !important;
}

.top-4 {
  top: 4% !important;
}

.top-5 {
  top: 5% !important;
}

.top-n5 {
  top: -5% !important;
}

.top-7 {
  top: 7% !important;
}

.top-8 {
  top: 8% !important;
}

.top-9 {
  top: 9% !important;
}

.top-10 {
  top: 10% !important;
}

.top-n10 {
  top: -10% !important;
}

.top-11 {
  top: 11% !important;
}

.top-12 {
  top: 12% !important;
}

.top-n13 {
  top: -13% !important;
}

.top-13 {
  top: 13% !important;
}

.top-14 {
  top: 14% !important;
}

.top-15 {
  top: 15% !important;
}

.top-17 {
  top: 17% !important;
}

.top-20 {
  top: 20% !important;
}

.top-25 {
  top: 25% !important;
}

.top-n25 {
  top: -25% !important;
}

.top-30 {
  top: 30% !important;
}

.top-35 {
  top: 35% !important;
}

.top-40 {
  top: 40% !important;
}

.top-45 {
  top: 45% !important;
}

.top-50 {
  top: 50% !important;
}

.top-n50 {
  top: -50% !important;
}

.top-75 {
  top: 75% !important;
}

.top-n75 {
  top: -75% !important;
}

.top-90 {
  top: 90% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-1 {
  bottom: 1% !important;
}

.bottom-n1 {
  bottom: -1% !important;
}

.bottom-2 {
  bottom: 2% !important;
}

.bottom-n2 {
  bottom: -2% !important;
}

.bottom-3 {
  bottom: 3% !important;
}

.bottom-n3 {
  bottom: -3% !important;
}

.bottom-4 {
  bottom: 4% !important;
}

.bottom-5 {
  bottom: 5% !important;
}

.bottom-n5 {
  bottom: -5% !important;
}

.bottom-7 {
  bottom: 7% !important;
}

.bottom-8 {
  bottom: 8% !important;
}

.bottom-9 {
  bottom: 9% !important;
}

.bottom-10 {
  bottom: 10% !important;
}

.bottom-n10 {
  bottom: -10% !important;
}

.bottom-11 {
  bottom: 11% !important;
}

.bottom-12 {
  bottom: 12% !important;
}

.bottom-n13 {
  bottom: -13% !important;
}

.bottom-13 {
  bottom: 13% !important;
}

.bottom-14 {
  bottom: 14% !important;
}

.bottom-15 {
  bottom: 15% !important;
}

.bottom-17 {
  bottom: 17% !important;
}

.bottom-20 {
  bottom: 20% !important;
}

.bottom-25 {
  bottom: 25% !important;
}

.bottom-n25 {
  bottom: -25% !important;
}

.bottom-30 {
  bottom: 30% !important;
}

.bottom-35 {
  bottom: 35% !important;
}

.bottom-40 {
  bottom: 40% !important;
}

.bottom-45 {
  bottom: 45% !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-n50 {
  bottom: -50% !important;
}

.bottom-75 {
  bottom: 75% !important;
}

.bottom-n75 {
  bottom: -75% !important;
}

.bottom-90 {
  bottom: 90% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-1 {
  left: 1% !important;
}

.start-n1 {
  left: -1% !important;
}

.start-2 {
  left: 2% !important;
}

.start-n2 {
  left: -2% !important;
}

.start-3 {
  left: 3% !important;
}

.start-n3 {
  left: -3% !important;
}

.start-4 {
  left: 4% !important;
}

.start-5 {
  left: 5% !important;
}

.start-n5 {
  left: -5% !important;
}

.start-7 {
  left: 7% !important;
}

.start-8 {
  left: 8% !important;
}

.start-9 {
  left: 9% !important;
}

.start-10 {
  left: 10% !important;
}

.start-n10 {
  left: -10% !important;
}

.start-11 {
  left: 11% !important;
}

.start-12 {
  left: 12% !important;
}

.start-n13 {
  left: -13% !important;
}

.start-13 {
  left: 13% !important;
}

.start-14 {
  left: 14% !important;
}

.start-15 {
  left: 15% !important;
}

.start-17 {
  left: 17% !important;
}

.start-20 {
  left: 20% !important;
}

.start-25 {
  left: 25% !important;
}

.start-n25 {
  left: -25% !important;
}

.start-30 {
  left: 30% !important;
}

.start-35 {
  left: 35% !important;
}

.start-40 {
  left: 40% !important;
}

.start-45 {
  left: 45% !important;
}

.start-50 {
  left: 50% !important;
}

.start-n50 {
  left: -50% !important;
}

.start-75 {
  left: 75% !important;
}

.start-n75 {
  left: -75% !important;
}

.start-90 {
  left: 90% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-1 {
  right: 1% !important;
}

.end-n1 {
  right: -1% !important;
}

.end-2 {
  right: 2% !important;
}

.end-n2 {
  right: -2% !important;
}

.end-3 {
  right: 3% !important;
}

.end-n3 {
  right: -3% !important;
}

.end-4 {
  right: 4% !important;
}

.end-5 {
  right: 5% !important;
}

.end-n5 {
  right: -5% !important;
}

.end-7 {
  right: 7% !important;
}

.end-8 {
  right: 8% !important;
}

.end-9 {
  right: 9% !important;
}

.end-10 {
  right: 10% !important;
}

.end-n10 {
  right: -10% !important;
}

.end-11 {
  right: 11% !important;
}

.end-12 {
  right: 12% !important;
}

.end-n13 {
  right: -13% !important;
}

.end-13 {
  right: 13% !important;
}

.end-14 {
  right: 14% !important;
}

.end-15 {
  right: 15% !important;
}

.end-17 {
  right: 17% !important;
}

.end-20 {
  right: 20% !important;
}

.end-25 {
  right: 25% !important;
}

.end-n25 {
  right: -25% !important;
}

.end-30 {
  right: 30% !important;
}

.end-35 {
  right: 35% !important;
}

.end-40 {
  right: 40% !important;
}

.end-45 {
  right: 45% !important;
}

.end-50 {
  right: 50% !important;
}

.end-n50 {
  right: -50% !important;
}

.end-75 {
  right: 75% !important;
}

.end-n75 {
  right: -75% !important;
}

.end-90 {
  right: 90% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: var(--gt-border-width) var(--gt-border-style) var(--gt-border-color) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: var(--gt-border-width) var(--gt-border-style) var(--gt-border-color) !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: var(--gt-border-width) var(--gt-border-style) var(--gt-border-color) !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: var(--gt-border-width) var(--gt-border-style) var(--gt-border-color) !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: var(--gt-border-width) var(--gt-border-style) var(--gt-border-color) !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  --gt-border-opacity: 1;
  border-color: rgba(var(--gt-primary-rgb), var(--gt-border-opacity)) !important;
}

.border-secondary {
  --gt-border-opacity: 1;
  border-color: rgba(var(--gt-secondary-rgb), var(--gt-border-opacity)) !important;
}

.border-success {
  --gt-border-opacity: 1;
  border-color: rgba(var(--gt-success-rgb), var(--gt-border-opacity)) !important;
}

.border-info {
  --gt-border-opacity: 1;
  border-color: rgba(var(--gt-info-rgb), var(--gt-border-opacity)) !important;
}

.border-warning {
  --gt-border-opacity: 1;
  border-color: rgba(var(--gt-warning-rgb), var(--gt-border-opacity)) !important;
}

.border-danger {
  --gt-border-opacity: 1;
  border-color: rgba(var(--gt-danger-rgb), var(--gt-border-opacity)) !important;
}

.border-light {
  --gt-border-opacity: 1;
  border-color: rgba(var(--gt-light-rgb), var(--gt-border-opacity)) !important;
}

.border-dark {
  --gt-border-opacity: 1;
  border-color: rgba(var(--gt-dark-rgb), var(--gt-border-opacity)) !important;
}

.border-white {
  --gt-border-opacity: 1;
  border-color: rgba(var(--gt-white-rgb), var(--gt-border-opacity)) !important;
}

.border-orange {
  --gt-border-opacity: 1;
  border-color: rgba(var(--gt-orange-rgb), var(--gt-border-opacity)) !important;
}

.border-primary-subtle {
  border-color: var(--gt-primary-border-subtle) !important;
}

.border-secondary-subtle {
  border-color: var(--gt-secondary-border-subtle) !important;
}

.border-success-subtle {
  border-color: var(--gt-success-border-subtle) !important;
}

.border-info-subtle {
  border-color: var(--gt-info-border-subtle) !important;
}

.border-warning-subtle {
  border-color: var(--gt-warning-border-subtle) !important;
}

.border-danger-subtle {
  border-color: var(--gt-danger-border-subtle) !important;
}

.border-light-subtle {
  border-color: var(--gt-light-border-subtle) !important;
}

.border-dark-subtle {
  border-color: var(--gt-dark-border-subtle) !important;
}

.border-1 {
  --gt-border-width: 1px;
}

.border-2 {
  --gt-border-width: 2px;
}

.border-3 {
  --gt-border-width: 3px;
}

.border-4 {
  --gt-border-width: 4px;
}

.border-5 {
  --gt-border-width: 5px;
}

.border-opacity-10 {
  --gt-border-opacity: 0.1;
}

.border-opacity-25 {
  --gt-border-opacity: 0.25;
}

.border-opacity-50 {
  --gt-border-opacity: 0.5;
}

.border-opacity-75 {
  --gt-border-opacity: 0.75;
}

.border-opacity-100 {
  --gt-border-opacity: 1;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.w-10 {
  width: 10% !important;
}

.w-20 {
  width: 20% !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-6 {
  margin: 3.5rem !important;
}

.m-7 {
  margin: 4rem !important;
}

.m-8 {
  margin: 5rem !important;
}

.m-9 {
  margin: 6rem !important;
}

.m-10 {
  margin: 7rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-6 {
  margin-right: 3.5rem !important;
  margin-left: 3.5rem !important;
}

.mx-7 {
  margin-right: 4rem !important;
  margin-left: 4rem !important;
}

.mx-8 {
  margin-right: 5rem !important;
  margin-left: 5rem !important;
}

.mx-9 {
  margin-right: 6rem !important;
  margin-left: 6rem !important;
}

.mx-10 {
  margin-right: 7rem !important;
  margin-left: 7rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-6 {
  margin-top: 3.5rem !important;
  margin-bottom: 3.5rem !important;
}

.my-7 {
  margin-top: 4rem !important;
  margin-bottom: 4rem !important;
}

.my-8 {
  margin-top: 5rem !important;
  margin-bottom: 5rem !important;
}

.my-9 {
  margin-top: 6rem !important;
  margin-bottom: 6rem !important;
}

.my-10 {
  margin-top: 7rem !important;
  margin-bottom: 7rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-6 {
  margin-top: 3.5rem !important;
}

.mt-7 {
  margin-top: 4rem !important;
}

.mt-8 {
  margin-top: 5rem !important;
}

.mt-9 {
  margin-top: 6rem !important;
}

.mt-10 {
  margin-top: 7rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-6 {
  margin-right: 3.5rem !important;
}

.me-7 {
  margin-right: 4rem !important;
}

.me-8 {
  margin-right: 5rem !important;
}

.me-9 {
  margin-right: 6rem !important;
}

.me-10 {
  margin-right: 7rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-6 {
  margin-bottom: 3.5rem !important;
}

.mb-7 {
  margin-bottom: 4rem !important;
}

.mb-8 {
  margin-bottom: 5rem !important;
}

.mb-9 {
  margin-bottom: 6rem !important;
}

.mb-10 {
  margin-bottom: 7rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-6 {
  margin-left: 3.5rem !important;
}

.ms-7 {
  margin-left: 4rem !important;
}

.ms-8 {
  margin-left: 5rem !important;
}

.ms-9 {
  margin-left: 6rem !important;
}

.ms-10 {
  margin-left: 7rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.m-n6 {
  margin: -3.5rem !important;
}

.m-n7 {
  margin: -4rem !important;
}

.m-n8 {
  margin: -5rem !important;
}

.m-n9 {
  margin: -6rem !important;
}

.m-n10 {
  margin: -7rem !important;
}

.mx-n1 {
  margin-right: -0.25rem !important;
  margin-left: -0.25rem !important;
}

.mx-n2 {
  margin-right: -0.5rem !important;
  margin-left: -0.5rem !important;
}

.mx-n3 {
  margin-right: -1rem !important;
  margin-left: -1rem !important;
}

.mx-n4 {
  margin-right: -1.5rem !important;
  margin-left: -1.5rem !important;
}

.mx-n5 {
  margin-right: -3rem !important;
  margin-left: -3rem !important;
}

.mx-n6 {
  margin-right: -3.5rem !important;
  margin-left: -3.5rem !important;
}

.mx-n7 {
  margin-right: -4rem !important;
  margin-left: -4rem !important;
}

.mx-n8 {
  margin-right: -5rem !important;
  margin-left: -5rem !important;
}

.mx-n9 {
  margin-right: -6rem !important;
  margin-left: -6rem !important;
}

.mx-n10 {
  margin-right: -7rem !important;
  margin-left: -7rem !important;
}

.my-n1 {
  margin-top: -0.25rem !important;
  margin-bottom: -0.25rem !important;
}

.my-n2 {
  margin-top: -0.5rem !important;
  margin-bottom: -0.5rem !important;
}

.my-n3 {
  margin-top: -1rem !important;
  margin-bottom: -1rem !important;
}

.my-n4 {
  margin-top: -1.5rem !important;
  margin-bottom: -1.5rem !important;
}

.my-n5 {
  margin-top: -3rem !important;
  margin-bottom: -3rem !important;
}

.my-n6 {
  margin-top: -3.5rem !important;
  margin-bottom: -3.5rem !important;
}

.my-n7 {
  margin-top: -4rem !important;
  margin-bottom: -4rem !important;
}

.my-n8 {
  margin-top: -5rem !important;
  margin-bottom: -5rem !important;
}

.my-n9 {
  margin-top: -6rem !important;
  margin-bottom: -6rem !important;
}

.my-n10 {
  margin-top: -7rem !important;
  margin-bottom: -7rem !important;
}

.mt-n1 {
  margin-top: -0.25rem !important;
}

.mt-n2 {
  margin-top: -0.5rem !important;
}

.mt-n3 {
  margin-top: -1rem !important;
}

.mt-n4 {
  margin-top: -1.5rem !important;
}

.mt-n5 {
  margin-top: -3rem !important;
}

.mt-n6 {
  margin-top: -3.5rem !important;
}

.mt-n7 {
  margin-top: -4rem !important;
}

.mt-n8 {
  margin-top: -5rem !important;
}

.mt-n9 {
  margin-top: -6rem !important;
}

.mt-n10 {
  margin-top: -7rem !important;
}

.me-n1 {
  margin-right: -0.25rem !important;
}

.me-n2 {
  margin-right: -0.5rem !important;
}

.me-n3 {
  margin-right: -1rem !important;
}

.me-n4 {
  margin-right: -1.5rem !important;
}

.me-n5 {
  margin-right: -3rem !important;
}

.me-n6 {
  margin-right: -3.5rem !important;
}

.me-n7 {
  margin-right: -4rem !important;
}

.me-n8 {
  margin-right: -5rem !important;
}

.me-n9 {
  margin-right: -6rem !important;
}

.me-n10 {
  margin-right: -7rem !important;
}

.mb-n1 {
  margin-bottom: -0.25rem !important;
}

.mb-n2 {
  margin-bottom: -0.5rem !important;
}

.mb-n3 {
  margin-bottom: -1rem !important;
}

.mb-n4 {
  margin-bottom: -1.5rem !important;
}

.mb-n5 {
  margin-bottom: -3rem !important;
}

.mb-n6 {
  margin-bottom: -3.5rem !important;
}

.mb-n7 {
  margin-bottom: -4rem !important;
}

.mb-n8 {
  margin-bottom: -5rem !important;
}

.mb-n9 {
  margin-bottom: -6rem !important;
}

.mb-n10 {
  margin-bottom: -7rem !important;
}

.ms-n1 {
  margin-left: -0.25rem !important;
}

.ms-n2 {
  margin-left: -0.5rem !important;
}

.ms-n3 {
  margin-left: -1rem !important;
}

.ms-n4 {
  margin-left: -1.5rem !important;
}

.ms-n5 {
  margin-left: -3rem !important;
}

.ms-n6 {
  margin-left: -3.5rem !important;
}

.ms-n7 {
  margin-left: -4rem !important;
}

.ms-n8 {
  margin-left: -5rem !important;
}

.ms-n9 {
  margin-left: -6rem !important;
}

.ms-n10 {
  margin-left: -7rem !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.p-6 {
  padding: 3.5rem !important;
}

.p-7 {
  padding: 4rem !important;
}

.p-8 {
  padding: 5rem !important;
}

.p-9 {
  padding: 6rem !important;
}

.p-10 {
  padding: 7rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.px-6 {
  padding-right: 3.5rem !important;
  padding-left: 3.5rem !important;
}

.px-7 {
  padding-right: 4rem !important;
  padding-left: 4rem !important;
}

.px-8 {
  padding-right: 5rem !important;
  padding-left: 5rem !important;
}

.px-9 {
  padding-right: 6rem !important;
  padding-left: 6rem !important;
}

.px-10 {
  padding-right: 7rem !important;
  padding-left: 7rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.py-6 {
  padding-top: 3.5rem !important;
  padding-bottom: 3.5rem !important;
}

.py-7 {
  padding-top: 4rem !important;
  padding-bottom: 4rem !important;
}

.py-8 {
  padding-top: 5rem !important;
  padding-bottom: 5rem !important;
}

.py-9 {
  padding-top: 6rem !important;
  padding-bottom: 6rem !important;
}

.py-10 {
  padding-top: 7rem !important;
  padding-bottom: 7rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pt-6 {
  padding-top: 3.5rem !important;
}

.pt-7 {
  padding-top: 4rem !important;
}

.pt-8 {
  padding-top: 5rem !important;
}

.pt-9 {
  padding-top: 6rem !important;
}

.pt-10 {
  padding-top: 7rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pe-6 {
  padding-right: 3.5rem !important;
}

.pe-7 {
  padding-right: 4rem !important;
}

.pe-8 {
  padding-right: 5rem !important;
}

.pe-9 {
  padding-right: 6rem !important;
}

.pe-10 {
  padding-right: 7rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.pb-6 {
  padding-bottom: 3.5rem !important;
}

.pb-7 {
  padding-bottom: 4rem !important;
}

.pb-8 {
  padding-bottom: 5rem !important;
}

.pb-9 {
  padding-bottom: 6rem !important;
}

.pb-10 {
  padding-bottom: 7rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.ps-6 {
  padding-left: 3.5rem !important;
}

.ps-7 {
  padding-left: 4rem !important;
}

.ps-8 {
  padding-left: 5rem !important;
}

.ps-9 {
  padding-left: 6rem !important;
}

.ps-10 {
  padding-left: 7rem !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

.gap-6 {
  gap: 3.5rem !important;
}

.gap-7 {
  gap: 4rem !important;
}

.gap-8 {
  gap: 5rem !important;
}

.gap-9 {
  gap: 6rem !important;
}

.gap-10 {
  gap: 7rem !important;
}

.row-gap-0 {
  row-gap: 0 !important;
}

.row-gap-1 {
  row-gap: 0.25rem !important;
}

.row-gap-2 {
  row-gap: 0.5rem !important;
}

.row-gap-3 {
  row-gap: 1rem !important;
}

.row-gap-4 {
  row-gap: 1.5rem !important;
}

.row-gap-5 {
  row-gap: 3rem !important;
}

.row-gap-6 {
  row-gap: 3.5rem !important;
}

.row-gap-7 {
  row-gap: 4rem !important;
}

.row-gap-8 {
  row-gap: 5rem !important;
}

.row-gap-9 {
  row-gap: 6rem !important;
}

.row-gap-10 {
  row-gap: 7rem !important;
}

.column-gap-0 {
  column-gap: 0 !important;
}

.column-gap-1 {
  column-gap: 0.25rem !important;
}

.column-gap-2 {
  column-gap: 0.5rem !important;
}

.column-gap-3 {
  column-gap: 1rem !important;
}

.column-gap-4 {
  column-gap: 1.5rem !important;
}

.column-gap-5 {
  column-gap: 3rem !important;
}

.column-gap-6 {
  column-gap: 3.5rem !important;
}

.column-gap-7 {
  column-gap: 4rem !important;
}

.column-gap-8 {
  column-gap: 5rem !important;
}

.column-gap-9 {
  column-gap: 6rem !important;
}

.column-gap-10 {
  column-gap: 7rem !important;
}

.font-monospace {
  font-family: var(--gt-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.4rem + 1.8vw) !important;
}

.fs-2 {
  font-size: calc(1.345rem + 1.14vw) !important;
}

.fs-3 {
  font-size: calc(1.3175rem + 0.81vw) !important;
}

.fs-4 {
  font-size: calc(1.29rem + 0.48vw) !important;
}

.fs-5 {
  font-size: calc(1.2625rem + 0.15vw) !important;
}

.fs-6 {
  font-size: 1.1rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-medium {
  font-weight: 500 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.fw-black {
  font-weight: 800 !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.65 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
  --gt-text-opacity: 1;
  color: rgba(var(--gt-primary-rgb), var(--gt-text-opacity)) !important;
}

.text-primary-hover:hover {
  --gt-text-opacity: 1;
  color: rgba(var(--gt-primary-rgb), var(--gt-text-opacity)) !important;
}

.text-primary-focus:focus {
  --gt-text-opacity: 1;
  color: rgba(var(--gt-primary-rgb), var(--gt-text-opacity)) !important;
}

.text-primary-active:active {
  --gt-text-opacity: 1;
  color: rgba(var(--gt-primary-rgb), var(--gt-text-opacity)) !important;
}

.text-secondary {
  --gt-text-opacity: 1;
  color: rgba(var(--gt-secondary-rgb), var(--gt-text-opacity)) !important;
}

.text-secondary-hover:hover {
  --gt-text-opacity: 1;
  color: rgba(var(--gt-secondary-rgb), var(--gt-text-opacity)) !important;
}

.text-secondary-focus:focus {
  --gt-text-opacity: 1;
  color: rgba(var(--gt-secondary-rgb), var(--gt-text-opacity)) !important;
}

.text-secondary-active:active {
  --gt-text-opacity: 1;
  color: rgba(var(--gt-secondary-rgb), var(--gt-text-opacity)) !important;
}

.text-success {
  --gt-text-opacity: 1;
  color: rgba(var(--gt-success-rgb), var(--gt-text-opacity)) !important;
}

.text-success-hover:hover {
  --gt-text-opacity: 1;
  color: rgba(var(--gt-success-rgb), var(--gt-text-opacity)) !important;
}

.text-success-focus:focus {
  --gt-text-opacity: 1;
  color: rgba(var(--gt-success-rgb), var(--gt-text-opacity)) !important;
}

.text-success-active:active {
  --gt-text-opacity: 1;
  color: rgba(var(--gt-success-rgb), var(--gt-text-opacity)) !important;
}

.text-info {
  --gt-text-opacity: 1;
  color: rgba(var(--gt-info-rgb), var(--gt-text-opacity)) !important;
}

.text-info-hover:hover {
  --gt-text-opacity: 1;
  color: rgba(var(--gt-info-rgb), var(--gt-text-opacity)) !important;
}

.text-info-focus:focus {
  --gt-text-opacity: 1;
  color: rgba(var(--gt-info-rgb), var(--gt-text-opacity)) !important;
}

.text-info-active:active {
  --gt-text-opacity: 1;
  color: rgba(var(--gt-info-rgb), var(--gt-text-opacity)) !important;
}

.text-warning {
  --gt-text-opacity: 1;
  color: rgba(var(--gt-warning-rgb), var(--gt-text-opacity)) !important;
}

.text-warning-hover:hover {
  --gt-text-opacity: 1;
  color: rgba(var(--gt-warning-rgb), var(--gt-text-opacity)) !important;
}

.text-warning-focus:focus {
  --gt-text-opacity: 1;
  color: rgba(var(--gt-warning-rgb), var(--gt-text-opacity)) !important;
}

.text-warning-active:active {
  --gt-text-opacity: 1;
  color: rgba(var(--gt-warning-rgb), var(--gt-text-opacity)) !important;
}

.text-danger {
  --gt-text-opacity: 1;
  color: rgba(var(--gt-danger-rgb), var(--gt-text-opacity)) !important;
}

.text-danger-hover:hover {
  --gt-text-opacity: 1;
  color: rgba(var(--gt-danger-rgb), var(--gt-text-opacity)) !important;
}

.text-danger-focus:focus {
  --gt-text-opacity: 1;
  color: rgba(var(--gt-danger-rgb), var(--gt-text-opacity)) !important;
}

.text-danger-active:active {
  --gt-text-opacity: 1;
  color: rgba(var(--gt-danger-rgb), var(--gt-text-opacity)) !important;
}

.text-light {
  --gt-text-opacity: 1;
  color: rgba(var(--gt-light-rgb), var(--gt-text-opacity)) !important;
}

.text-light-hover:hover {
  --gt-text-opacity: 1;
  color: rgba(var(--gt-light-rgb), var(--gt-text-opacity)) !important;
}

.text-light-focus:focus {
  --gt-text-opacity: 1;
  color: rgba(var(--gt-light-rgb), var(--gt-text-opacity)) !important;
}

.text-light-active:active {
  --gt-text-opacity: 1;
  color: rgba(var(--gt-light-rgb), var(--gt-text-opacity)) !important;
}

.text-dark {
  --gt-text-opacity: 1;
  color: rgba(var(--gt-dark-rgb), var(--gt-text-opacity)) !important;
}

.text-dark-hover:hover {
  --gt-text-opacity: 1;
  color: rgba(var(--gt-dark-rgb), var(--gt-text-opacity)) !important;
}

.text-dark-focus:focus {
  --gt-text-opacity: 1;
  color: rgba(var(--gt-dark-rgb), var(--gt-text-opacity)) !important;
}

.text-dark-active:active {
  --gt-text-opacity: 1;
  color: rgba(var(--gt-dark-rgb), var(--gt-text-opacity)) !important;
}

.text-white {
  --gt-text-opacity: 1;
  color: rgba(var(--gt-white-rgb), var(--gt-text-opacity)) !important;
}

.text-white-hover:hover {
  --gt-text-opacity: 1;
  color: rgba(var(--gt-white-rgb), var(--gt-text-opacity)) !important;
}

.text-white-focus:focus {
  --gt-text-opacity: 1;
  color: rgba(var(--gt-white-rgb), var(--gt-text-opacity)) !important;
}

.text-white-active:active {
  --gt-text-opacity: 1;
  color: rgba(var(--gt-white-rgb), var(--gt-text-opacity)) !important;
}

.text-orange {
  --gt-text-opacity: 1;
  color: rgba(var(--gt-orange-rgb), var(--gt-text-opacity)) !important;
}

.text-orange-hover:hover {
  --gt-text-opacity: 1;
  color: rgba(var(--gt-orange-rgb), var(--gt-text-opacity)) !important;
}

.text-orange-focus:focus {
  --gt-text-opacity: 1;
  color: rgba(var(--gt-orange-rgb), var(--gt-text-opacity)) !important;
}

.text-orange-active:active {
  --gt-text-opacity: 1;
  color: rgba(var(--gt-orange-rgb), var(--gt-text-opacity)) !important;
}

.text-black {
  --gt-text-opacity: 1;
  color: rgba(var(--gt-black-rgb), var(--gt-text-opacity)) !important;
}

.text-black-hover:hover {
  --gt-text-opacity: 1;
  color: rgba(var(--gt-black-rgb), var(--gt-text-opacity)) !important;
}

.text-black-focus:focus {
  --gt-text-opacity: 1;
  color: rgba(var(--gt-black-rgb), var(--gt-text-opacity)) !important;
}

.text-black-active:active {
  --gt-text-opacity: 1;
  color: rgba(var(--gt-black-rgb), var(--gt-text-opacity)) !important;
}

.text-body {
  --gt-text-opacity: 1;
  color: rgba(var(--gt-body-color-rgb), var(--gt-text-opacity)) !important;
}

.text-body-hover:hover {
  --gt-text-opacity: 1;
  color: rgba(var(--gt-body-color-rgb), var(--gt-text-opacity)) !important;
}

.text-body-focus:focus {
  --gt-text-opacity: 1;
  color: rgba(var(--gt-body-color-rgb), var(--gt-text-opacity)) !important;
}

.text-body-active:active {
  --gt-text-opacity: 1;
  color: rgba(var(--gt-body-color-rgb), var(--gt-text-opacity)) !important;
}

.text-muted {
  --gt-text-opacity: 1;
  color: #64748B !important;
}

.text-muted-hover:hover {
  --gt-text-opacity: 1;
  color: #64748B !important;
}

.text-muted-focus:focus {
  --gt-text-opacity: 1;
  color: #64748B !important;
}

.text-muted-active:active {
  --gt-text-opacity: 1;
  color: #64748B !important;
}

.text-black-50 {
  --gt-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-black-50-hover:hover {
  --gt-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-black-50-focus:focus {
  --gt-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-black-50-active:active {
  --gt-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  --gt-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-white-50-hover:hover {
  --gt-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-white-50-focus:focus {
  --gt-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-white-50-active:active {
  --gt-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-body-secondary {
  --gt-text-opacity: 1;
  color: var(--gt-secondary-color) !important;
}

.text-body-secondary-hover:hover {
  --gt-text-opacity: 1;
  color: var(--gt-secondary-color) !important;
}

.text-body-secondary-focus:focus {
  --gt-text-opacity: 1;
  color: var(--gt-secondary-color) !important;
}

.text-body-secondary-active:active {
  --gt-text-opacity: 1;
  color: var(--gt-secondary-color) !important;
}

.text-body-tertiary {
  --gt-text-opacity: 1;
  color: var(--gt-tertiary-color) !important;
}

.text-body-tertiary-hover:hover {
  --gt-text-opacity: 1;
  color: var(--gt-tertiary-color) !important;
}

.text-body-tertiary-focus:focus {
  --gt-text-opacity: 1;
  color: var(--gt-tertiary-color) !important;
}

.text-body-tertiary-active:active {
  --gt-text-opacity: 1;
  color: var(--gt-tertiary-color) !important;
}

.text-body-emphasis {
  --gt-text-opacity: 1;
  color: var(--gt-emphasis-color) !important;
}

.text-body-emphasis-hover:hover {
  --gt-text-opacity: 1;
  color: var(--gt-emphasis-color) !important;
}

.text-body-emphasis-focus:focus {
  --gt-text-opacity: 1;
  color: var(--gt-emphasis-color) !important;
}

.text-body-emphasis-active:active {
  --gt-text-opacity: 1;
  color: var(--gt-emphasis-color) !important;
}

.text-reset {
  --gt-text-opacity: 1;
  color: inherit !important;
}

.text-reset-hover:hover {
  --gt-text-opacity: 1;
  color: inherit !important;
}

.text-reset-focus:focus {
  --gt-text-opacity: 1;
  color: inherit !important;
}

.text-reset-active:active {
  --gt-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --gt-text-opacity: 0.25;
}

.text-opacity-50 {
  --gt-text-opacity: 0.5;
}

.text-opacity-75 {
  --gt-text-opacity: 0.75;
}

.text-opacity-100 {
  --gt-text-opacity: 1;
}

.text-primary-emphasis {
  color: var(--gt-primary-text) !important;
}

.text-secondary-emphasis {
  color: var(--gt-secondary-text) !important;
}

.text-success-emphasis {
  color: var(--gt-success-text) !important;
}

.text-info-emphasis {
  color: var(--gt-info-text) !important;
}

.text-warning-emphasis {
  color: var(--gt-warning-text) !important;
}

.text-danger-emphasis {
  color: var(--gt-danger-text) !important;
}

.text-light-emphasis {
  color: var(--gt-light-text) !important;
}

.text-dark-emphasis {
  color: var(--gt-dark-text) !important;
}

.bg-primary {
  --gt-bg-opacity: 1;
  background-color: rgba(var(--gt-primary-rgb), var(--gt-bg-opacity)) !important;
}

.bg-secondary {
  --gt-bg-opacity: 1;
  background-color: rgba(var(--gt-secondary-rgb), var(--gt-bg-opacity)) !important;
}

.bg-success {
  --gt-bg-opacity: 1;
  background-color: rgba(var(--gt-success-rgb), var(--gt-bg-opacity)) !important;
}

.bg-info {
  --gt-bg-opacity: 1;
  background-color: rgba(var(--gt-info-rgb), var(--gt-bg-opacity)) !important;
}

.bg-warning {
  --gt-bg-opacity: 1;
  background-color: rgba(var(--gt-warning-rgb), var(--gt-bg-opacity)) !important;
}

.bg-danger {
  --gt-bg-opacity: 1;
  background-color: rgba(var(--gt-danger-rgb), var(--gt-bg-opacity)) !important;
}

.bg-light {
  --gt-bg-opacity: 1;
  background-color: rgba(var(--gt-light-rgb), var(--gt-bg-opacity)) !important;
}

.bg-dark {
  --gt-bg-opacity: 1;
  background-color: rgba(var(--gt-dark-rgb), var(--gt-bg-opacity)) !important;
}

.bg-white {
  --gt-bg-opacity: 1;
  background-color: rgba(var(--gt-white-rgb), var(--gt-bg-opacity)) !important;
}

.bg-orange {
  --gt-bg-opacity: 1;
  background-color: rgba(var(--gt-orange-rgb), var(--gt-bg-opacity)) !important;
}

.bg-black {
  --gt-bg-opacity: 1;
  background-color: rgba(var(--gt-black-rgb), var(--gt-bg-opacity)) !important;
}

.bg-body {
  --gt-bg-opacity: 1;
  background-color: rgba(var(--gt-body-bg-rgb), var(--gt-bg-opacity)) !important;
}

.bg-transparent {
  --gt-bg-opacity: 1;
  background-color: transparent !important;
}

.bg-body-secondary {
  --gt-bg-opacity: 1;
  background-color: rgba(var(--gt-secondary-bg-rgb), var(--gt-bg-opacity)) !important;
}

.bg-body-tertiary {
  --gt-bg-opacity: 1;
  background-color: rgba(var(--gt-tertiary-bg-rgb), var(--gt-bg-opacity)) !important;
}

.bg-body-emphasis {
  --gt-bg-opacity: 1;
  background-color: rgba(var(--gt-emphasis-bg-rgb), var(--gt-bg-opacity)) !important;
}

.bg-opacity-10 {
  --gt-bg-opacity: 0.1;
}

.bg-opacity-25 {
  --gt-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --gt-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --gt-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --gt-bg-opacity: 1;
}

.bg-primary-subtle {
  background-color: var(--gt-primary-bg-subtle) !important;
}

.bg-secondary-subtle {
  background-color: var(--gt-secondary-bg-subtle) !important;
}

.bg-success-subtle {
  background-color: var(--gt-success-bg-subtle) !important;
}

.bg-info-subtle {
  background-color: var(--gt-info-bg-subtle) !important;
}

.bg-warning-subtle {
  background-color: var(--gt-warning-bg-subtle) !important;
}

.bg-danger-subtle {
  background-color: var(--gt-danger-bg-subtle) !important;
}

.bg-light-subtle {
  background-color: var(--gt-light-bg-subtle) !important;
}

.bg-dark-subtle {
  background-color: var(--gt-dark-bg-subtle) !important;
}

.bg-gradient {
  background-image: var(--gt-gradient) !important;
}

.user-select-all {
  -webkit-user-select: all !important;
          user-select: all !important;
}

.user-select-auto {
  -webkit-user-select: auto !important;
          user-select: auto !important;
}

.user-select-none {
  -webkit-user-select: none !important;
          user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: var(--gt-border-radius) !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: var(--gt-border-radius-sm) !important;
}

.rounded-2 {
  border-radius: var(--gt-border-radius) !important;
}

.rounded-3 {
  border-radius: var(--gt-border-radius-lg) !important;
}

.rounded-4 {
  border-radius: 1rem !important;
}

.rounded-5 {
  border-radius: 1.5rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: var(--gt-border-radius-pill) !important;
}

.rounded-top {
  border-top-left-radius: var(--gt-border-radius) !important;
  border-top-right-radius: var(--gt-border-radius) !important;
}

.rounded-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.rounded-top-1 {
  border-top-left-radius: var(--gt-border-radius-sm) !important;
  border-top-right-radius: var(--gt-border-radius-sm) !important;
}

.rounded-top-2 {
  border-top-left-radius: var(--gt-border-radius) !important;
  border-top-right-radius: var(--gt-border-radius) !important;
}

.rounded-top-3 {
  border-top-left-radius: var(--gt-border-radius-lg) !important;
  border-top-right-radius: var(--gt-border-radius-lg) !important;
}

.rounded-top-4 {
  border-top-left-radius: var(--gt-border-radius-xl) !important;
  border-top-right-radius: var(--gt-border-radius-xl) !important;
}

.rounded-top-5 {
  border-top-left-radius: var(--gt-border-radius-2xl) !important;
  border-top-right-radius: var(--gt-border-radius-2xl) !important;
}

.rounded-top-circle {
  border-top-left-radius: 50% !important;
  border-top-right-radius: 50% !important;
}

.rounded-top-pill {
  border-top-left-radius: var(--gt-border-radius-pill) !important;
  border-top-right-radius: var(--gt-border-radius-pill) !important;
}

.rounded-end {
  border-top-right-radius: var(--gt-border-radius) !important;
  border-bottom-right-radius: var(--gt-border-radius) !important;
}

.rounded-end-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-end-1 {
  border-top-right-radius: var(--gt-border-radius-sm) !important;
  border-bottom-right-radius: var(--gt-border-radius-sm) !important;
}

.rounded-end-2 {
  border-top-right-radius: var(--gt-border-radius) !important;
  border-bottom-right-radius: var(--gt-border-radius) !important;
}

.rounded-end-3 {
  border-top-right-radius: var(--gt-border-radius-lg) !important;
  border-bottom-right-radius: var(--gt-border-radius-lg) !important;
}

.rounded-end-4 {
  border-top-right-radius: var(--gt-border-radius-xl) !important;
  border-bottom-right-radius: var(--gt-border-radius-xl) !important;
}

.rounded-end-5 {
  border-top-right-radius: var(--gt-border-radius-2xl) !important;
  border-bottom-right-radius: var(--gt-border-radius-2xl) !important;
}

.rounded-end-circle {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

.rounded-end-pill {
  border-top-right-radius: var(--gt-border-radius-pill) !important;
  border-bottom-right-radius: var(--gt-border-radius-pill) !important;
}

.rounded-bottom {
  border-bottom-right-radius: var(--gt-border-radius) !important;
  border-bottom-left-radius: var(--gt-border-radius) !important;
}

.rounded-bottom-0 {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-bottom-1 {
  border-bottom-right-radius: var(--gt-border-radius-sm) !important;
  border-bottom-left-radius: var(--gt-border-radius-sm) !important;
}

.rounded-bottom-2 {
  border-bottom-right-radius: var(--gt-border-radius) !important;
  border-bottom-left-radius: var(--gt-border-radius) !important;
}

.rounded-bottom-3 {
  border-bottom-right-radius: var(--gt-border-radius-lg) !important;
  border-bottom-left-radius: var(--gt-border-radius-lg) !important;
}

.rounded-bottom-4 {
  border-bottom-right-radius: var(--gt-border-radius-xl) !important;
  border-bottom-left-radius: var(--gt-border-radius-xl) !important;
}

.rounded-bottom-5 {
  border-bottom-right-radius: var(--gt-border-radius-2xl) !important;
  border-bottom-left-radius: var(--gt-border-radius-2xl) !important;
}

.rounded-bottom-circle {
  border-bottom-right-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.rounded-bottom-pill {
  border-bottom-right-radius: var(--gt-border-radius-pill) !important;
  border-bottom-left-radius: var(--gt-border-radius-pill) !important;
}

.rounded-start {
  border-bottom-left-radius: var(--gt-border-radius) !important;
  border-top-left-radius: var(--gt-border-radius) !important;
}

.rounded-start-0 {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.rounded-start-1 {
  border-bottom-left-radius: var(--gt-border-radius-sm) !important;
  border-top-left-radius: var(--gt-border-radius-sm) !important;
}

.rounded-start-2 {
  border-bottom-left-radius: var(--gt-border-radius) !important;
  border-top-left-radius: var(--gt-border-radius) !important;
}

.rounded-start-3 {
  border-bottom-left-radius: var(--gt-border-radius-lg) !important;
  border-top-left-radius: var(--gt-border-radius-lg) !important;
}

.rounded-start-4 {
  border-bottom-left-radius: var(--gt-border-radius-xl) !important;
  border-top-left-radius: var(--gt-border-radius-xl) !important;
}

.rounded-start-5 {
  border-bottom-left-radius: var(--gt-border-radius-2xl) !important;
  border-top-left-radius: var(--gt-border-radius-2xl) !important;
}

.rounded-start-circle {
  border-bottom-left-radius: 50% !important;
  border-top-left-radius: 50% !important;
}

.rounded-start-pill {
  border-bottom-left-radius: var(--gt-border-radius-pill) !important;
  border-top-left-radius: var(--gt-border-radius-pill) !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.z-index-n1 {
  z-index: -1 !important;
}

.z-index-0 {
  z-index: 0 !important;
}

.z-index-1 {
  z-index: 1 !important;
}

.z-index-10 {
  z-index: 10 !important;
}

.z-index-20 {
  z-index: 20 !important;
}

.z-index-30 {
  z-index: 30 !important;
}

.z-index-40 {
  z-index: 40 !important;
}

.z-index-50 {
  z-index: 50 !important;
}

.cursor-auto {
  cursor: auto !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.cursor-grab {
  cursor: grab !important;
}

.f-w-1 {
  width: 0.25rem !important;
}

.f-w-2 {
  width: 0.5rem !important;
}

.f-w-3 {
  width: 0.75rem !important;
}

.f-w-4 {
  width: 1rem !important;
}

.f-w-5 {
  width: 1.25rem !important;
}

.f-w-6 {
  width: 1.5rem !important;
}

.f-w-7 {
  width: 1.75rem !important;
}

.f-w-8 {
  width: 2rem !important;
}

.f-w-9 {
  width: 2.25rem !important;
}

.f-w-10 {
  width: 2.5rem !important;
}

.f-w-12 {
  width: 3rem !important;
}

.f-w-14 {
  width: 3.5rem !important;
}

.f-w-16 {
  width: 4rem !important;
}

.f-w-20 {
  width: 5rem !important;
}

.f-w-24 {
  width: 6rem !important;
}

.f-w-28 {
  width: 7rem !important;
}

.f-w-30 {
  width: 7.25rem !important;
}

.f-w-32 {
  width: 8rem !important;
}

.f-w-36 {
  width: 9rem !important;
}

.f-w-40 {
  width: 10rem !important;
}

.f-w-44 {
  width: 11rem !important;
}

.f-w-48 {
  width: 12rem !important;
}

.f-w-52 {
  width: 15rem !important;
}

.f-w-56 {
  width: 18rem !important;
}

.f-w-60 {
  width: 21rem !important;
}

.f-w-64 {
  width: 24rem !important;
}

.f-w-68 {
  width: 27rem !important;
}

.f-w-72 {
  width: 30rem !important;
}

.f-w-76 {
  width: 33rem !important;
}

.f-w-80 {
  width: 36rem !important;
}

.f-w-84 {
  width: 40rem !important;
}

.f-w-88 {
  width: 44rem !important;
}

.f-w-92 {
  width: 50rem !important;
}

.f-w-96 {
  width: 56rem !important;
}

.f-w-100 {
  width: 60rem !important;
}

.f-w-0 {
  width: 0 !important;
}

.f-h-1 {
  height: 0.25rem !important;
}

.f-h-2 {
  height: 0.5rem !important;
}

.f-h-3 {
  height: 0.75rem !important;
}

.f-h-4 {
  height: 1rem !important;
}

.f-h-5 {
  height: 1.25rem !important;
}

.f-h-6 {
  height: 1.5rem !important;
}

.f-h-7 {
  height: 1.75rem !important;
}

.f-h-8 {
  height: 2rem !important;
}

.f-h-9 {
  height: 2.25rem !important;
}

.f-h-10 {
  height: 2.5rem !important;
}

.f-h-12 {
  height: 3rem !important;
}

.f-h-14 {
  height: 3.5rem !important;
}

.f-h-16 {
  height: 4rem !important;
}

.f-h-20 {
  height: 5rem !important;
}

.f-h-24 {
  height: 6rem !important;
}

.f-h-28 {
  height: 7rem !important;
}

.f-h-30 {
  height: 7.25rem !important;
}

.f-h-32 {
  height: 8rem !important;
}

.f-h-36 {
  height: 9rem !important;
}

.f-h-40 {
  height: 10rem !important;
}

.f-h-44 {
  height: 11rem !important;
}

.f-h-48 {
  height: 12rem !important;
}

.f-h-52 {
  height: 15rem !important;
}

.f-h-56 {
  height: 18rem !important;
}

.f-h-60 {
  height: 21rem !important;
}

.f-h-64 {
  height: 24rem !important;
}

.f-h-68 {
  height: 27rem !important;
}

.f-h-72 {
  height: 30rem !important;
}

.f-h-76 {
  height: 33rem !important;
}

.f-h-80 {
  height: 36rem !important;
}

.f-h-84 {
  height: 40rem !important;
}

.f-h-88 {
  height: 44rem !important;
}

.f-h-92 {
  height: 50rem !important;
}

.f-h-96 {
  height: 56rem !important;
}

.f-h-100 {
  height: 60rem !important;
}

.f-h-0 {
  height: 0 !important;
}

.tracking-tighter {
  letter-spacing: -0.05em !important;
}

.tracking-tight {
  letter-spacing: -0.025em !important;
}

.tracking-wide {
  letter-spacing: 0.065em !important;
}

.tracking-wider {
  letter-spacing: 0.25em !important;
}

.tracking-widest {
  letter-spacing: 0.4em !important;
}

.transition-all {
  transition: all ease-in 0.3s !important;
}

.transition-color {
  transition: color ease-in 0.3s !important;
}

.transition-background {
  transition: background-color ease-in 0.3s !important;
}

.transition-opacity {
  transition: opacity ease-in 0.3s !important;
}

.border-white-10 {
  border-color: rgba(255, 255, 255, 0.1) !important;
}

.border-white-25 {
  border-color: rgba(255, 255, 255, 0.25) !important;
}

.border-white-50 {
  border-color: rgba(255, 255, 255, 0.5) !important;
}

.border-white-75 {
  border-color: rgba(255, 255, 255, 0.75) !important;
}

.border-white-90 {
  border-color: rgba(255, 255, 255, 0.9) !important;
}

.transform-12 {
  transform: rotate(12deg) !important;
}

.transform-n12 {
  transform: rotate(-12deg) !important;
}

.transform-45 {
  transform: rotate(45deg) !important;
}

.transform-n45 {
  transform: rotate(-45deg) !important;
}

.transform-90 {
  transform: rotate(90deg) !important;
}

.transform-n90 {
  transform: rotate(-90deg) !important;
}

.transform-180 {
  transform: rotate(180deg) !important;
}

.transform-n180 {
  transform: rotate(-180deg) !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }
  .float-sm-end {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
  .object-fit-sm-contain {
    -o-object-fit: contain !important;
       object-fit: contain !important;
  }
  .object-fit-sm-cover {
    -o-object-fit: cover !important;
       object-fit: cover !important;
  }
  .object-fit-sm-fill {
    -o-object-fit: fill !important;
       object-fit: fill !important;
  }
  .object-fit-sm-scale {
    -o-object-fit: scale-down !important;
       object-fit: scale-down !important;
  }
  .object-fit-sm-none {
    -o-object-fit: none !important;
       object-fit: none !important;
  }
  .opacity-sm-0 {
    opacity: 0 !important;
  }
  .opacity-sm-0-hover:hover {
    opacity: 0 !important;
  }
  .opacity-sm-25 {
    opacity: 0.25 !important;
  }
  .opacity-sm-25-hover:hover {
    opacity: 0.25 !important;
  }
  .opacity-sm-50 {
    opacity: 0.5 !important;
  }
  .opacity-sm-50-hover:hover {
    opacity: 0.5 !important;
  }
  .opacity-sm-75 {
    opacity: 0.75 !important;
  }
  .opacity-sm-75-hover:hover {
    opacity: 0.75 !important;
  }
  .opacity-sm-100 {
    opacity: 1 !important;
  }
  .opacity-sm-100-hover:hover {
    opacity: 1 !important;
  }
  .opacity-sm-5 {
    opacity: 0.05 !important;
  }
  .opacity-sm-5-hover:hover {
    opacity: 0.05 !important;
  }
  .opacity-sm-10 {
    opacity: 0.1 !important;
  }
  .opacity-sm-10-hover:hover {
    opacity: 0.1 !important;
  }
  .opacity-sm-90 {
    opacity: 0.9 !important;
  }
  .opacity-sm-90-hover:hover {
    opacity: 0.9 !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-grid {
    display: grid !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .w-sm-25 {
    width: 25% !important;
  }
  .w-sm-50 {
    width: 50% !important;
  }
  .w-sm-75 {
    width: 75% !important;
  }
  .w-sm-100 {
    width: 100% !important;
  }
  .w-sm-auto {
    width: auto !important;
  }
  .w-sm-10 {
    width: 10% !important;
  }
  .w-sm-20 {
    width: 20% !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .order-sm-first {
    order: -1 !important;
  }
  .order-sm-0 {
    order: 0 !important;
  }
  .order-sm-1 {
    order: 1 !important;
  }
  .order-sm-2 {
    order: 2 !important;
  }
  .order-sm-3 {
    order: 3 !important;
  }
  .order-sm-4 {
    order: 4 !important;
  }
  .order-sm-5 {
    order: 5 !important;
  }
  .order-sm-last {
    order: 6 !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .m-sm-6 {
    margin: 3.5rem !important;
  }
  .m-sm-7 {
    margin: 4rem !important;
  }
  .m-sm-8 {
    margin: 5rem !important;
  }
  .m-sm-9 {
    margin: 6rem !important;
  }
  .m-sm-10 {
    margin: 7rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-sm-6 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }
  .mx-sm-7 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }
  .mx-sm-8 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }
  .mx-sm-9 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }
  .mx-sm-10 {
    margin-right: 7rem !important;
    margin-left: 7rem !important;
  }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-sm-6 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }
  .my-sm-7 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .my-sm-8 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .my-sm-9 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }
  .my-sm-10 {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important;
  }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mt-sm-3 {
    margin-top: 1rem !important;
  }
  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mt-sm-5 {
    margin-top: 3rem !important;
  }
  .mt-sm-6 {
    margin-top: 3.5rem !important;
  }
  .mt-sm-7 {
    margin-top: 4rem !important;
  }
  .mt-sm-8 {
    margin-top: 5rem !important;
  }
  .mt-sm-9 {
    margin-top: 6rem !important;
  }
  .mt-sm-10 {
    margin-top: 7rem !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .me-sm-0 {
    margin-right: 0 !important;
  }
  .me-sm-1 {
    margin-right: 0.25rem !important;
  }
  .me-sm-2 {
    margin-right: 0.5rem !important;
  }
  .me-sm-3 {
    margin-right: 1rem !important;
  }
  .me-sm-4 {
    margin-right: 1.5rem !important;
  }
  .me-sm-5 {
    margin-right: 3rem !important;
  }
  .me-sm-6 {
    margin-right: 3.5rem !important;
  }
  .me-sm-7 {
    margin-right: 4rem !important;
  }
  .me-sm-8 {
    margin-right: 5rem !important;
  }
  .me-sm-9 {
    margin-right: 6rem !important;
  }
  .me-sm-10 {
    margin-right: 7rem !important;
  }
  .me-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }
  .mb-sm-6 {
    margin-bottom: 3.5rem !important;
  }
  .mb-sm-7 {
    margin-bottom: 4rem !important;
  }
  .mb-sm-8 {
    margin-bottom: 5rem !important;
  }
  .mb-sm-9 {
    margin-bottom: 6rem !important;
  }
  .mb-sm-10 {
    margin-bottom: 7rem !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ms-sm-0 {
    margin-left: 0 !important;
  }
  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }
  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }
  .ms-sm-3 {
    margin-left: 1rem !important;
  }
  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }
  .ms-sm-5 {
    margin-left: 3rem !important;
  }
  .ms-sm-6 {
    margin-left: 3.5rem !important;
  }
  .ms-sm-7 {
    margin-left: 4rem !important;
  }
  .ms-sm-8 {
    margin-left: 5rem !important;
  }
  .ms-sm-9 {
    margin-left: 6rem !important;
  }
  .ms-sm-10 {
    margin-left: 7rem !important;
  }
  .ms-sm-auto {
    margin-left: auto !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .m-sm-n6 {
    margin: -3.5rem !important;
  }
  .m-sm-n7 {
    margin: -4rem !important;
  }
  .m-sm-n8 {
    margin: -5rem !important;
  }
  .m-sm-n9 {
    margin: -6rem !important;
  }
  .m-sm-n10 {
    margin: -7rem !important;
  }
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-sm-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-sm-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .mx-sm-n6 {
    margin-right: -3.5rem !important;
    margin-left: -3.5rem !important;
  }
  .mx-sm-n7 {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }
  .mx-sm-n8 {
    margin-right: -5rem !important;
    margin-left: -5rem !important;
  }
  .mx-sm-n9 {
    margin-right: -6rem !important;
    margin-left: -6rem !important;
  }
  .mx-sm-n10 {
    margin-right: -7rem !important;
    margin-left: -7rem !important;
  }
  .my-sm-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-sm-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-sm-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-sm-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-sm-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .my-sm-n6 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }
  .my-sm-n7 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }
  .my-sm-n8 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }
  .my-sm-n9 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }
  .my-sm-n10 {
    margin-top: -7rem !important;
    margin-bottom: -7rem !important;
  }
  .mt-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-sm-n3 {
    margin-top: -1rem !important;
  }
  .mt-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-sm-n5 {
    margin-top: -3rem !important;
  }
  .mt-sm-n6 {
    margin-top: -3.5rem !important;
  }
  .mt-sm-n7 {
    margin-top: -4rem !important;
  }
  .mt-sm-n8 {
    margin-top: -5rem !important;
  }
  .mt-sm-n9 {
    margin-top: -6rem !important;
  }
  .mt-sm-n10 {
    margin-top: -7rem !important;
  }
  .me-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .me-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .me-sm-n3 {
    margin-right: -1rem !important;
  }
  .me-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .me-sm-n5 {
    margin-right: -3rem !important;
  }
  .me-sm-n6 {
    margin-right: -3.5rem !important;
  }
  .me-sm-n7 {
    margin-right: -4rem !important;
  }
  .me-sm-n8 {
    margin-right: -5rem !important;
  }
  .me-sm-n9 {
    margin-right: -6rem !important;
  }
  .me-sm-n10 {
    margin-right: -7rem !important;
  }
  .mb-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .mb-sm-n6 {
    margin-bottom: -3.5rem !important;
  }
  .mb-sm-n7 {
    margin-bottom: -4rem !important;
  }
  .mb-sm-n8 {
    margin-bottom: -5rem !important;
  }
  .mb-sm-n9 {
    margin-bottom: -6rem !important;
  }
  .mb-sm-n10 {
    margin-bottom: -7rem !important;
  }
  .ms-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-sm-n3 {
    margin-left: -1rem !important;
  }
  .ms-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-sm-n5 {
    margin-left: -3rem !important;
  }
  .ms-sm-n6 {
    margin-left: -3.5rem !important;
  }
  .ms-sm-n7 {
    margin-left: -4rem !important;
  }
  .ms-sm-n8 {
    margin-left: -5rem !important;
  }
  .ms-sm-n9 {
    margin-left: -6rem !important;
  }
  .ms-sm-n10 {
    margin-left: -7rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .p-sm-6 {
    padding: 3.5rem !important;
  }
  .p-sm-7 {
    padding: 4rem !important;
  }
  .p-sm-8 {
    padding: 5rem !important;
  }
  .p-sm-9 {
    padding: 6rem !important;
  }
  .p-sm-10 {
    padding: 7rem !important;
  }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-sm-6 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }
  .px-sm-7 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }
  .px-sm-8 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }
  .px-sm-9 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }
  .px-sm-10 {
    padding-right: 7rem !important;
    padding-left: 7rem !important;
  }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-sm-6 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }
  .py-sm-7 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .py-sm-8 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .py-sm-9 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }
  .py-sm-10 {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pt-sm-3 {
    padding-top: 1rem !important;
  }
  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pt-sm-5 {
    padding-top: 3rem !important;
  }
  .pt-sm-6 {
    padding-top: 3.5rem !important;
  }
  .pt-sm-7 {
    padding-top: 4rem !important;
  }
  .pt-sm-8 {
    padding-top: 5rem !important;
  }
  .pt-sm-9 {
    padding-top: 6rem !important;
  }
  .pt-sm-10 {
    padding-top: 7rem !important;
  }
  .pe-sm-0 {
    padding-right: 0 !important;
  }
  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pe-sm-3 {
    padding-right: 1rem !important;
  }
  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pe-sm-5 {
    padding-right: 3rem !important;
  }
  .pe-sm-6 {
    padding-right: 3.5rem !important;
  }
  .pe-sm-7 {
    padding-right: 4rem !important;
  }
  .pe-sm-8 {
    padding-right: 5rem !important;
  }
  .pe-sm-9 {
    padding-right: 6rem !important;
  }
  .pe-sm-10 {
    padding-right: 7rem !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pb-sm-6 {
    padding-bottom: 3.5rem !important;
  }
  .pb-sm-7 {
    padding-bottom: 4rem !important;
  }
  .pb-sm-8 {
    padding-bottom: 5rem !important;
  }
  .pb-sm-9 {
    padding-bottom: 6rem !important;
  }
  .pb-sm-10 {
    padding-bottom: 7rem !important;
  }
  .ps-sm-0 {
    padding-left: 0 !important;
  }
  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }
  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }
  .ps-sm-3 {
    padding-left: 1rem !important;
  }
  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }
  .ps-sm-5 {
    padding-left: 3rem !important;
  }
  .ps-sm-6 {
    padding-left: 3.5rem !important;
  }
  .ps-sm-7 {
    padding-left: 4rem !important;
  }
  .ps-sm-8 {
    padding-left: 5rem !important;
  }
  .ps-sm-9 {
    padding-left: 6rem !important;
  }
  .ps-sm-10 {
    padding-left: 7rem !important;
  }
  .gap-sm-0 {
    gap: 0 !important;
  }
  .gap-sm-1 {
    gap: 0.25rem !important;
  }
  .gap-sm-2 {
    gap: 0.5rem !important;
  }
  .gap-sm-3 {
    gap: 1rem !important;
  }
  .gap-sm-4 {
    gap: 1.5rem !important;
  }
  .gap-sm-5 {
    gap: 3rem !important;
  }
  .gap-sm-6 {
    gap: 3.5rem !important;
  }
  .gap-sm-7 {
    gap: 4rem !important;
  }
  .gap-sm-8 {
    gap: 5rem !important;
  }
  .gap-sm-9 {
    gap: 6rem !important;
  }
  .gap-sm-10 {
    gap: 7rem !important;
  }
  .row-gap-sm-0 {
    row-gap: 0 !important;
  }
  .row-gap-sm-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-sm-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-sm-3 {
    row-gap: 1rem !important;
  }
  .row-gap-sm-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-sm-5 {
    row-gap: 3rem !important;
  }
  .row-gap-sm-6 {
    row-gap: 3.5rem !important;
  }
  .row-gap-sm-7 {
    row-gap: 4rem !important;
  }
  .row-gap-sm-8 {
    row-gap: 5rem !important;
  }
  .row-gap-sm-9 {
    row-gap: 6rem !important;
  }
  .row-gap-sm-10 {
    row-gap: 7rem !important;
  }
  .column-gap-sm-0 {
    column-gap: 0 !important;
  }
  .column-gap-sm-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-sm-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-sm-3 {
    column-gap: 1rem !important;
  }
  .column-gap-sm-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-sm-5 {
    column-gap: 3rem !important;
  }
  .column-gap-sm-6 {
    column-gap: 3.5rem !important;
  }
  .column-gap-sm-7 {
    column-gap: 4rem !important;
  }
  .column-gap-sm-8 {
    column-gap: 5rem !important;
  }
  .column-gap-sm-9 {
    column-gap: 6rem !important;
  }
  .column-gap-sm-10 {
    column-gap: 7rem !important;
  }
  .text-sm-start {
    text-align: left !important;
  }
  .text-sm-end {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
  .cursor-sm-auto {
    cursor: auto !important;
  }
  .cursor-sm-pointer {
    cursor: pointer !important;
  }
  .cursor-sm-grab {
    cursor: grab !important;
  }
  .f-w-sm-1 {
    width: 0.25rem !important;
  }
  .f-w-sm-2 {
    width: 0.5rem !important;
  }
  .f-w-sm-3 {
    width: 0.75rem !important;
  }
  .f-w-sm-4 {
    width: 1rem !important;
  }
  .f-w-sm-5 {
    width: 1.25rem !important;
  }
  .f-w-sm-6 {
    width: 1.5rem !important;
  }
  .f-w-sm-7 {
    width: 1.75rem !important;
  }
  .f-w-sm-8 {
    width: 2rem !important;
  }
  .f-w-sm-9 {
    width: 2.25rem !important;
  }
  .f-w-sm-10 {
    width: 2.5rem !important;
  }
  .f-w-sm-12 {
    width: 3rem !important;
  }
  .f-w-sm-14 {
    width: 3.5rem !important;
  }
  .f-w-sm-16 {
    width: 4rem !important;
  }
  .f-w-sm-20 {
    width: 5rem !important;
  }
  .f-w-sm-24 {
    width: 6rem !important;
  }
  .f-w-sm-28 {
    width: 7rem !important;
  }
  .f-w-sm-30 {
    width: 7.25rem !important;
  }
  .f-w-sm-32 {
    width: 8rem !important;
  }
  .f-w-sm-36 {
    width: 9rem !important;
  }
  .f-w-sm-40 {
    width: 10rem !important;
  }
  .f-w-sm-44 {
    width: 11rem !important;
  }
  .f-w-sm-48 {
    width: 12rem !important;
  }
  .f-w-sm-52 {
    width: 15rem !important;
  }
  .f-w-sm-56 {
    width: 18rem !important;
  }
  .f-w-sm-60 {
    width: 21rem !important;
  }
  .f-w-sm-64 {
    width: 24rem !important;
  }
  .f-w-sm-68 {
    width: 27rem !important;
  }
  .f-w-sm-72 {
    width: 30rem !important;
  }
  .f-w-sm-76 {
    width: 33rem !important;
  }
  .f-w-sm-80 {
    width: 36rem !important;
  }
  .f-w-sm-84 {
    width: 40rem !important;
  }
  .f-w-sm-88 {
    width: 44rem !important;
  }
  .f-w-sm-92 {
    width: 50rem !important;
  }
  .f-w-sm-96 {
    width: 56rem !important;
  }
  .f-w-sm-100 {
    width: 60rem !important;
  }
  .f-w-sm-0 {
    width: 0 !important;
  }
  .f-h-sm-1 {
    height: 0.25rem !important;
  }
  .f-h-sm-2 {
    height: 0.5rem !important;
  }
  .f-h-sm-3 {
    height: 0.75rem !important;
  }
  .f-h-sm-4 {
    height: 1rem !important;
  }
  .f-h-sm-5 {
    height: 1.25rem !important;
  }
  .f-h-sm-6 {
    height: 1.5rem !important;
  }
  .f-h-sm-7 {
    height: 1.75rem !important;
  }
  .f-h-sm-8 {
    height: 2rem !important;
  }
  .f-h-sm-9 {
    height: 2.25rem !important;
  }
  .f-h-sm-10 {
    height: 2.5rem !important;
  }
  .f-h-sm-12 {
    height: 3rem !important;
  }
  .f-h-sm-14 {
    height: 3.5rem !important;
  }
  .f-h-sm-16 {
    height: 4rem !important;
  }
  .f-h-sm-20 {
    height: 5rem !important;
  }
  .f-h-sm-24 {
    height: 6rem !important;
  }
  .f-h-sm-28 {
    height: 7rem !important;
  }
  .f-h-sm-30 {
    height: 7.25rem !important;
  }
  .f-h-sm-32 {
    height: 8rem !important;
  }
  .f-h-sm-36 {
    height: 9rem !important;
  }
  .f-h-sm-40 {
    height: 10rem !important;
  }
  .f-h-sm-44 {
    height: 11rem !important;
  }
  .f-h-sm-48 {
    height: 12rem !important;
  }
  .f-h-sm-52 {
    height: 15rem !important;
  }
  .f-h-sm-56 {
    height: 18rem !important;
  }
  .f-h-sm-60 {
    height: 21rem !important;
  }
  .f-h-sm-64 {
    height: 24rem !important;
  }
  .f-h-sm-68 {
    height: 27rem !important;
  }
  .f-h-sm-72 {
    height: 30rem !important;
  }
  .f-h-sm-76 {
    height: 33rem !important;
  }
  .f-h-sm-80 {
    height: 36rem !important;
  }
  .f-h-sm-84 {
    height: 40rem !important;
  }
  .f-h-sm-88 {
    height: 44rem !important;
  }
  .f-h-sm-92 {
    height: 50rem !important;
  }
  .f-h-sm-96 {
    height: 56rem !important;
  }
  .f-h-sm-100 {
    height: 60rem !important;
  }
  .f-h-sm-0 {
    height: 0 !important;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }
  .float-md-end {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
  .object-fit-md-contain {
    -o-object-fit: contain !important;
       object-fit: contain !important;
  }
  .object-fit-md-cover {
    -o-object-fit: cover !important;
       object-fit: cover !important;
  }
  .object-fit-md-fill {
    -o-object-fit: fill !important;
       object-fit: fill !important;
  }
  .object-fit-md-scale {
    -o-object-fit: scale-down !important;
       object-fit: scale-down !important;
  }
  .object-fit-md-none {
    -o-object-fit: none !important;
       object-fit: none !important;
  }
  .opacity-md-0 {
    opacity: 0 !important;
  }
  .opacity-md-0-hover:hover {
    opacity: 0 !important;
  }
  .opacity-md-25 {
    opacity: 0.25 !important;
  }
  .opacity-md-25-hover:hover {
    opacity: 0.25 !important;
  }
  .opacity-md-50 {
    opacity: 0.5 !important;
  }
  .opacity-md-50-hover:hover {
    opacity: 0.5 !important;
  }
  .opacity-md-75 {
    opacity: 0.75 !important;
  }
  .opacity-md-75-hover:hover {
    opacity: 0.75 !important;
  }
  .opacity-md-100 {
    opacity: 1 !important;
  }
  .opacity-md-100-hover:hover {
    opacity: 1 !important;
  }
  .opacity-md-5 {
    opacity: 0.05 !important;
  }
  .opacity-md-5-hover:hover {
    opacity: 0.05 !important;
  }
  .opacity-md-10 {
    opacity: 0.1 !important;
  }
  .opacity-md-10-hover:hover {
    opacity: 0.1 !important;
  }
  .opacity-md-90 {
    opacity: 0.9 !important;
  }
  .opacity-md-90-hover:hover {
    opacity: 0.9 !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-grid {
    display: grid !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .d-md-none {
    display: none !important;
  }
  .w-md-25 {
    width: 25% !important;
  }
  .w-md-50 {
    width: 50% !important;
  }
  .w-md-75 {
    width: 75% !important;
  }
  .w-md-100 {
    width: 100% !important;
  }
  .w-md-auto {
    width: auto !important;
  }
  .w-md-10 {
    width: 10% !important;
  }
  .w-md-20 {
    width: 20% !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
  .order-md-first {
    order: -1 !important;
  }
  .order-md-0 {
    order: 0 !important;
  }
  .order-md-1 {
    order: 1 !important;
  }
  .order-md-2 {
    order: 2 !important;
  }
  .order-md-3 {
    order: 3 !important;
  }
  .order-md-4 {
    order: 4 !important;
  }
  .order-md-5 {
    order: 5 !important;
  }
  .order-md-last {
    order: 6 !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .m-md-6 {
    margin: 3.5rem !important;
  }
  .m-md-7 {
    margin: 4rem !important;
  }
  .m-md-8 {
    margin: 5rem !important;
  }
  .m-md-9 {
    margin: 6rem !important;
  }
  .m-md-10 {
    margin: 7rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-md-6 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }
  .mx-md-7 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }
  .mx-md-8 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }
  .mx-md-9 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }
  .mx-md-10 {
    margin-right: 7rem !important;
    margin-left: 7rem !important;
  }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-md-6 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }
  .my-md-7 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .my-md-8 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .my-md-9 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }
  .my-md-10 {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important;
  }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mt-md-1 {
    margin-top: 0.25rem !important;
  }
  .mt-md-2 {
    margin-top: 0.5rem !important;
  }
  .mt-md-3 {
    margin-top: 1rem !important;
  }
  .mt-md-4 {
    margin-top: 1.5rem !important;
  }
  .mt-md-5 {
    margin-top: 3rem !important;
  }
  .mt-md-6 {
    margin-top: 3.5rem !important;
  }
  .mt-md-7 {
    margin-top: 4rem !important;
  }
  .mt-md-8 {
    margin-top: 5rem !important;
  }
  .mt-md-9 {
    margin-top: 6rem !important;
  }
  .mt-md-10 {
    margin-top: 7rem !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .me-md-0 {
    margin-right: 0 !important;
  }
  .me-md-1 {
    margin-right: 0.25rem !important;
  }
  .me-md-2 {
    margin-right: 0.5rem !important;
  }
  .me-md-3 {
    margin-right: 1rem !important;
  }
  .me-md-4 {
    margin-right: 1.5rem !important;
  }
  .me-md-5 {
    margin-right: 3rem !important;
  }
  .me-md-6 {
    margin-right: 3.5rem !important;
  }
  .me-md-7 {
    margin-right: 4rem !important;
  }
  .me-md-8 {
    margin-right: 5rem !important;
  }
  .me-md-9 {
    margin-right: 6rem !important;
  }
  .me-md-10 {
    margin-right: 7rem !important;
  }
  .me-md-auto {
    margin-right: auto !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-md-3 {
    margin-bottom: 1rem !important;
  }
  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-md-5 {
    margin-bottom: 3rem !important;
  }
  .mb-md-6 {
    margin-bottom: 3.5rem !important;
  }
  .mb-md-7 {
    margin-bottom: 4rem !important;
  }
  .mb-md-8 {
    margin-bottom: 5rem !important;
  }
  .mb-md-9 {
    margin-bottom: 6rem !important;
  }
  .mb-md-10 {
    margin-bottom: 7rem !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ms-md-0 {
    margin-left: 0 !important;
  }
  .ms-md-1 {
    margin-left: 0.25rem !important;
  }
  .ms-md-2 {
    margin-left: 0.5rem !important;
  }
  .ms-md-3 {
    margin-left: 1rem !important;
  }
  .ms-md-4 {
    margin-left: 1.5rem !important;
  }
  .ms-md-5 {
    margin-left: 3rem !important;
  }
  .ms-md-6 {
    margin-left: 3.5rem !important;
  }
  .ms-md-7 {
    margin-left: 4rem !important;
  }
  .ms-md-8 {
    margin-left: 5rem !important;
  }
  .ms-md-9 {
    margin-left: 6rem !important;
  }
  .ms-md-10 {
    margin-left: 7rem !important;
  }
  .ms-md-auto {
    margin-left: auto !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .m-md-n6 {
    margin: -3.5rem !important;
  }
  .m-md-n7 {
    margin: -4rem !important;
  }
  .m-md-n8 {
    margin: -5rem !important;
  }
  .m-md-n9 {
    margin: -6rem !important;
  }
  .m-md-n10 {
    margin: -7rem !important;
  }
  .mx-md-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-md-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-md-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-md-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-md-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .mx-md-n6 {
    margin-right: -3.5rem !important;
    margin-left: -3.5rem !important;
  }
  .mx-md-n7 {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }
  .mx-md-n8 {
    margin-right: -5rem !important;
    margin-left: -5rem !important;
  }
  .mx-md-n9 {
    margin-right: -6rem !important;
    margin-left: -6rem !important;
  }
  .mx-md-n10 {
    margin-right: -7rem !important;
    margin-left: -7rem !important;
  }
  .my-md-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-md-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-md-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-md-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-md-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .my-md-n6 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }
  .my-md-n7 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }
  .my-md-n8 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }
  .my-md-n9 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }
  .my-md-n10 {
    margin-top: -7rem !important;
    margin-bottom: -7rem !important;
  }
  .mt-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-md-n3 {
    margin-top: -1rem !important;
  }
  .mt-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-md-n5 {
    margin-top: -3rem !important;
  }
  .mt-md-n6 {
    margin-top: -3.5rem !important;
  }
  .mt-md-n7 {
    margin-top: -4rem !important;
  }
  .mt-md-n8 {
    margin-top: -5rem !important;
  }
  .mt-md-n9 {
    margin-top: -6rem !important;
  }
  .mt-md-n10 {
    margin-top: -7rem !important;
  }
  .me-md-n1 {
    margin-right: -0.25rem !important;
  }
  .me-md-n2 {
    margin-right: -0.5rem !important;
  }
  .me-md-n3 {
    margin-right: -1rem !important;
  }
  .me-md-n4 {
    margin-right: -1.5rem !important;
  }
  .me-md-n5 {
    margin-right: -3rem !important;
  }
  .me-md-n6 {
    margin-right: -3.5rem !important;
  }
  .me-md-n7 {
    margin-right: -4rem !important;
  }
  .me-md-n8 {
    margin-right: -5rem !important;
  }
  .me-md-n9 {
    margin-right: -6rem !important;
  }
  .me-md-n10 {
    margin-right: -7rem !important;
  }
  .mb-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-md-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-md-n5 {
    margin-bottom: -3rem !important;
  }
  .mb-md-n6 {
    margin-bottom: -3.5rem !important;
  }
  .mb-md-n7 {
    margin-bottom: -4rem !important;
  }
  .mb-md-n8 {
    margin-bottom: -5rem !important;
  }
  .mb-md-n9 {
    margin-bottom: -6rem !important;
  }
  .mb-md-n10 {
    margin-bottom: -7rem !important;
  }
  .ms-md-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-md-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-md-n3 {
    margin-left: -1rem !important;
  }
  .ms-md-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-md-n5 {
    margin-left: -3rem !important;
  }
  .ms-md-n6 {
    margin-left: -3.5rem !important;
  }
  .ms-md-n7 {
    margin-left: -4rem !important;
  }
  .ms-md-n8 {
    margin-left: -5rem !important;
  }
  .ms-md-n9 {
    margin-left: -6rem !important;
  }
  .ms-md-n10 {
    margin-left: -7rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .p-md-6 {
    padding: 3.5rem !important;
  }
  .p-md-7 {
    padding: 4rem !important;
  }
  .p-md-8 {
    padding: 5rem !important;
  }
  .p-md-9 {
    padding: 6rem !important;
  }
  .p-md-10 {
    padding: 7rem !important;
  }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-md-6 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }
  .px-md-7 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }
  .px-md-8 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }
  .px-md-9 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }
  .px-md-10 {
    padding-right: 7rem !important;
    padding-left: 7rem !important;
  }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-md-6 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }
  .py-md-7 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .py-md-8 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .py-md-9 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }
  .py-md-10 {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pt-md-1 {
    padding-top: 0.25rem !important;
  }
  .pt-md-2 {
    padding-top: 0.5rem !important;
  }
  .pt-md-3 {
    padding-top: 1rem !important;
  }
  .pt-md-4 {
    padding-top: 1.5rem !important;
  }
  .pt-md-5 {
    padding-top: 3rem !important;
  }
  .pt-md-6 {
    padding-top: 3.5rem !important;
  }
  .pt-md-7 {
    padding-top: 4rem !important;
  }
  .pt-md-8 {
    padding-top: 5rem !important;
  }
  .pt-md-9 {
    padding-top: 6rem !important;
  }
  .pt-md-10 {
    padding-top: 7rem !important;
  }
  .pe-md-0 {
    padding-right: 0 !important;
  }
  .pe-md-1 {
    padding-right: 0.25rem !important;
  }
  .pe-md-2 {
    padding-right: 0.5rem !important;
  }
  .pe-md-3 {
    padding-right: 1rem !important;
  }
  .pe-md-4 {
    padding-right: 1.5rem !important;
  }
  .pe-md-5 {
    padding-right: 3rem !important;
  }
  .pe-md-6 {
    padding-right: 3.5rem !important;
  }
  .pe-md-7 {
    padding-right: 4rem !important;
  }
  .pe-md-8 {
    padding-right: 5rem !important;
  }
  .pe-md-9 {
    padding-right: 6rem !important;
  }
  .pe-md-10 {
    padding-right: 7rem !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-md-3 {
    padding-bottom: 1rem !important;
  }
  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-md-5 {
    padding-bottom: 3rem !important;
  }
  .pb-md-6 {
    padding-bottom: 3.5rem !important;
  }
  .pb-md-7 {
    padding-bottom: 4rem !important;
  }
  .pb-md-8 {
    padding-bottom: 5rem !important;
  }
  .pb-md-9 {
    padding-bottom: 6rem !important;
  }
  .pb-md-10 {
    padding-bottom: 7rem !important;
  }
  .ps-md-0 {
    padding-left: 0 !important;
  }
  .ps-md-1 {
    padding-left: 0.25rem !important;
  }
  .ps-md-2 {
    padding-left: 0.5rem !important;
  }
  .ps-md-3 {
    padding-left: 1rem !important;
  }
  .ps-md-4 {
    padding-left: 1.5rem !important;
  }
  .ps-md-5 {
    padding-left: 3rem !important;
  }
  .ps-md-6 {
    padding-left: 3.5rem !important;
  }
  .ps-md-7 {
    padding-left: 4rem !important;
  }
  .ps-md-8 {
    padding-left: 5rem !important;
  }
  .ps-md-9 {
    padding-left: 6rem !important;
  }
  .ps-md-10 {
    padding-left: 7rem !important;
  }
  .gap-md-0 {
    gap: 0 !important;
  }
  .gap-md-1 {
    gap: 0.25rem !important;
  }
  .gap-md-2 {
    gap: 0.5rem !important;
  }
  .gap-md-3 {
    gap: 1rem !important;
  }
  .gap-md-4 {
    gap: 1.5rem !important;
  }
  .gap-md-5 {
    gap: 3rem !important;
  }
  .gap-md-6 {
    gap: 3.5rem !important;
  }
  .gap-md-7 {
    gap: 4rem !important;
  }
  .gap-md-8 {
    gap: 5rem !important;
  }
  .gap-md-9 {
    gap: 6rem !important;
  }
  .gap-md-10 {
    gap: 7rem !important;
  }
  .row-gap-md-0 {
    row-gap: 0 !important;
  }
  .row-gap-md-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-md-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-md-3 {
    row-gap: 1rem !important;
  }
  .row-gap-md-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-md-5 {
    row-gap: 3rem !important;
  }
  .row-gap-md-6 {
    row-gap: 3.5rem !important;
  }
  .row-gap-md-7 {
    row-gap: 4rem !important;
  }
  .row-gap-md-8 {
    row-gap: 5rem !important;
  }
  .row-gap-md-9 {
    row-gap: 6rem !important;
  }
  .row-gap-md-10 {
    row-gap: 7rem !important;
  }
  .column-gap-md-0 {
    column-gap: 0 !important;
  }
  .column-gap-md-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-md-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-md-3 {
    column-gap: 1rem !important;
  }
  .column-gap-md-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-md-5 {
    column-gap: 3rem !important;
  }
  .column-gap-md-6 {
    column-gap: 3.5rem !important;
  }
  .column-gap-md-7 {
    column-gap: 4rem !important;
  }
  .column-gap-md-8 {
    column-gap: 5rem !important;
  }
  .column-gap-md-9 {
    column-gap: 6rem !important;
  }
  .column-gap-md-10 {
    column-gap: 7rem !important;
  }
  .text-md-start {
    text-align: left !important;
  }
  .text-md-end {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
  .cursor-md-auto {
    cursor: auto !important;
  }
  .cursor-md-pointer {
    cursor: pointer !important;
  }
  .cursor-md-grab {
    cursor: grab !important;
  }
  .f-w-md-1 {
    width: 0.25rem !important;
  }
  .f-w-md-2 {
    width: 0.5rem !important;
  }
  .f-w-md-3 {
    width: 0.75rem !important;
  }
  .f-w-md-4 {
    width: 1rem !important;
  }
  .f-w-md-5 {
    width: 1.25rem !important;
  }
  .f-w-md-6 {
    width: 1.5rem !important;
  }
  .f-w-md-7 {
    width: 1.75rem !important;
  }
  .f-w-md-8 {
    width: 2rem !important;
  }
  .f-w-md-9 {
    width: 2.25rem !important;
  }
  .f-w-md-10 {
    width: 2.5rem !important;
  }
  .f-w-md-12 {
    width: 3rem !important;
  }
  .f-w-md-14 {
    width: 3.5rem !important;
  }
  .f-w-md-16 {
    width: 4rem !important;
  }
  .f-w-md-20 {
    width: 5rem !important;
  }
  .f-w-md-24 {
    width: 6rem !important;
  }
  .f-w-md-28 {
    width: 7rem !important;
  }
  .f-w-md-30 {
    width: 7.25rem !important;
  }
  .f-w-md-32 {
    width: 8rem !important;
  }
  .f-w-md-36 {
    width: 9rem !important;
  }
  .f-w-md-40 {
    width: 10rem !important;
  }
  .f-w-md-44 {
    width: 11rem !important;
  }
  .f-w-md-48 {
    width: 12rem !important;
  }
  .f-w-md-52 {
    width: 15rem !important;
  }
  .f-w-md-56 {
    width: 18rem !important;
  }
  .f-w-md-60 {
    width: 21rem !important;
  }
  .f-w-md-64 {
    width: 24rem !important;
  }
  .f-w-md-68 {
    width: 27rem !important;
  }
  .f-w-md-72 {
    width: 30rem !important;
  }
  .f-w-md-76 {
    width: 33rem !important;
  }
  .f-w-md-80 {
    width: 36rem !important;
  }
  .f-w-md-84 {
    width: 40rem !important;
  }
  .f-w-md-88 {
    width: 44rem !important;
  }
  .f-w-md-92 {
    width: 50rem !important;
  }
  .f-w-md-96 {
    width: 56rem !important;
  }
  .f-w-md-100 {
    width: 60rem !important;
  }
  .f-w-md-0 {
    width: 0 !important;
  }
  .f-h-md-1 {
    height: 0.25rem !important;
  }
  .f-h-md-2 {
    height: 0.5rem !important;
  }
  .f-h-md-3 {
    height: 0.75rem !important;
  }
  .f-h-md-4 {
    height: 1rem !important;
  }
  .f-h-md-5 {
    height: 1.25rem !important;
  }
  .f-h-md-6 {
    height: 1.5rem !important;
  }
  .f-h-md-7 {
    height: 1.75rem !important;
  }
  .f-h-md-8 {
    height: 2rem !important;
  }
  .f-h-md-9 {
    height: 2.25rem !important;
  }
  .f-h-md-10 {
    height: 2.5rem !important;
  }
  .f-h-md-12 {
    height: 3rem !important;
  }
  .f-h-md-14 {
    height: 3.5rem !important;
  }
  .f-h-md-16 {
    height: 4rem !important;
  }
  .f-h-md-20 {
    height: 5rem !important;
  }
  .f-h-md-24 {
    height: 6rem !important;
  }
  .f-h-md-28 {
    height: 7rem !important;
  }
  .f-h-md-30 {
    height: 7.25rem !important;
  }
  .f-h-md-32 {
    height: 8rem !important;
  }
  .f-h-md-36 {
    height: 9rem !important;
  }
  .f-h-md-40 {
    height: 10rem !important;
  }
  .f-h-md-44 {
    height: 11rem !important;
  }
  .f-h-md-48 {
    height: 12rem !important;
  }
  .f-h-md-52 {
    height: 15rem !important;
  }
  .f-h-md-56 {
    height: 18rem !important;
  }
  .f-h-md-60 {
    height: 21rem !important;
  }
  .f-h-md-64 {
    height: 24rem !important;
  }
  .f-h-md-68 {
    height: 27rem !important;
  }
  .f-h-md-72 {
    height: 30rem !important;
  }
  .f-h-md-76 {
    height: 33rem !important;
  }
  .f-h-md-80 {
    height: 36rem !important;
  }
  .f-h-md-84 {
    height: 40rem !important;
  }
  .f-h-md-88 {
    height: 44rem !important;
  }
  .f-h-md-92 {
    height: 50rem !important;
  }
  .f-h-md-96 {
    height: 56rem !important;
  }
  .f-h-md-100 {
    height: 60rem !important;
  }
  .f-h-md-0 {
    height: 0 !important;
  }
}
@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }
  .float-lg-end {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
  .object-fit-lg-contain {
    -o-object-fit: contain !important;
       object-fit: contain !important;
  }
  .object-fit-lg-cover {
    -o-object-fit: cover !important;
       object-fit: cover !important;
  }
  .object-fit-lg-fill {
    -o-object-fit: fill !important;
       object-fit: fill !important;
  }
  .object-fit-lg-scale {
    -o-object-fit: scale-down !important;
       object-fit: scale-down !important;
  }
  .object-fit-lg-none {
    -o-object-fit: none !important;
       object-fit: none !important;
  }
  .opacity-lg-0 {
    opacity: 0 !important;
  }
  .opacity-lg-0-hover:hover {
    opacity: 0 !important;
  }
  .opacity-lg-25 {
    opacity: 0.25 !important;
  }
  .opacity-lg-25-hover:hover {
    opacity: 0.25 !important;
  }
  .opacity-lg-50 {
    opacity: 0.5 !important;
  }
  .opacity-lg-50-hover:hover {
    opacity: 0.5 !important;
  }
  .opacity-lg-75 {
    opacity: 0.75 !important;
  }
  .opacity-lg-75-hover:hover {
    opacity: 0.75 !important;
  }
  .opacity-lg-100 {
    opacity: 1 !important;
  }
  .opacity-lg-100-hover:hover {
    opacity: 1 !important;
  }
  .opacity-lg-5 {
    opacity: 0.05 !important;
  }
  .opacity-lg-5-hover:hover {
    opacity: 0.05 !important;
  }
  .opacity-lg-10 {
    opacity: 0.1 !important;
  }
  .opacity-lg-10-hover:hover {
    opacity: 0.1 !important;
  }
  .opacity-lg-90 {
    opacity: 0.9 !important;
  }
  .opacity-lg-90-hover:hover {
    opacity: 0.9 !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-grid {
    display: grid !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .w-lg-25 {
    width: 25% !important;
  }
  .w-lg-50 {
    width: 50% !important;
  }
  .w-lg-75 {
    width: 75% !important;
  }
  .w-lg-100 {
    width: 100% !important;
  }
  .w-lg-auto {
    width: auto !important;
  }
  .w-lg-10 {
    width: 10% !important;
  }
  .w-lg-20 {
    width: 20% !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .order-lg-first {
    order: -1 !important;
  }
  .order-lg-0 {
    order: 0 !important;
  }
  .order-lg-1 {
    order: 1 !important;
  }
  .order-lg-2 {
    order: 2 !important;
  }
  .order-lg-3 {
    order: 3 !important;
  }
  .order-lg-4 {
    order: 4 !important;
  }
  .order-lg-5 {
    order: 5 !important;
  }
  .order-lg-last {
    order: 6 !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .m-lg-6 {
    margin: 3.5rem !important;
  }
  .m-lg-7 {
    margin: 4rem !important;
  }
  .m-lg-8 {
    margin: 5rem !important;
  }
  .m-lg-9 {
    margin: 6rem !important;
  }
  .m-lg-10 {
    margin: 7rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-lg-6 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }
  .mx-lg-7 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }
  .mx-lg-8 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }
  .mx-lg-9 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }
  .mx-lg-10 {
    margin-right: 7rem !important;
    margin-left: 7rem !important;
  }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-lg-6 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }
  .my-lg-7 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .my-lg-8 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .my-lg-9 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }
  .my-lg-10 {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important;
  }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mt-lg-3 {
    margin-top: 1rem !important;
  }
  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mt-lg-5 {
    margin-top: 3rem !important;
  }
  .mt-lg-6 {
    margin-top: 3.5rem !important;
  }
  .mt-lg-7 {
    margin-top: 4rem !important;
  }
  .mt-lg-8 {
    margin-top: 5rem !important;
  }
  .mt-lg-9 {
    margin-top: 6rem !important;
  }
  .mt-lg-10 {
    margin-top: 7rem !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .me-lg-0 {
    margin-right: 0 !important;
  }
  .me-lg-1 {
    margin-right: 0.25rem !important;
  }
  .me-lg-2 {
    margin-right: 0.5rem !important;
  }
  .me-lg-3 {
    margin-right: 1rem !important;
  }
  .me-lg-4 {
    margin-right: 1.5rem !important;
  }
  .me-lg-5 {
    margin-right: 3rem !important;
  }
  .me-lg-6 {
    margin-right: 3.5rem !important;
  }
  .me-lg-7 {
    margin-right: 4rem !important;
  }
  .me-lg-8 {
    margin-right: 5rem !important;
  }
  .me-lg-9 {
    margin-right: 6rem !important;
  }
  .me-lg-10 {
    margin-right: 7rem !important;
  }
  .me-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }
  .mb-lg-6 {
    margin-bottom: 3.5rem !important;
  }
  .mb-lg-7 {
    margin-bottom: 4rem !important;
  }
  .mb-lg-8 {
    margin-bottom: 5rem !important;
  }
  .mb-lg-9 {
    margin-bottom: 6rem !important;
  }
  .mb-lg-10 {
    margin-bottom: 7rem !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ms-lg-0 {
    margin-left: 0 !important;
  }
  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }
  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }
  .ms-lg-3 {
    margin-left: 1rem !important;
  }
  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }
  .ms-lg-5 {
    margin-left: 3rem !important;
  }
  .ms-lg-6 {
    margin-left: 3.5rem !important;
  }
  .ms-lg-7 {
    margin-left: 4rem !important;
  }
  .ms-lg-8 {
    margin-left: 5rem !important;
  }
  .ms-lg-9 {
    margin-left: 6rem !important;
  }
  .ms-lg-10 {
    margin-left: 7rem !important;
  }
  .ms-lg-auto {
    margin-left: auto !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .m-lg-n6 {
    margin: -3.5rem !important;
  }
  .m-lg-n7 {
    margin: -4rem !important;
  }
  .m-lg-n8 {
    margin: -5rem !important;
  }
  .m-lg-n9 {
    margin: -6rem !important;
  }
  .m-lg-n10 {
    margin: -7rem !important;
  }
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-lg-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-lg-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .mx-lg-n6 {
    margin-right: -3.5rem !important;
    margin-left: -3.5rem !important;
  }
  .mx-lg-n7 {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }
  .mx-lg-n8 {
    margin-right: -5rem !important;
    margin-left: -5rem !important;
  }
  .mx-lg-n9 {
    margin-right: -6rem !important;
    margin-left: -6rem !important;
  }
  .mx-lg-n10 {
    margin-right: -7rem !important;
    margin-left: -7rem !important;
  }
  .my-lg-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-lg-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-lg-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-lg-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-lg-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .my-lg-n6 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }
  .my-lg-n7 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }
  .my-lg-n8 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }
  .my-lg-n9 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }
  .my-lg-n10 {
    margin-top: -7rem !important;
    margin-bottom: -7rem !important;
  }
  .mt-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-lg-n3 {
    margin-top: -1rem !important;
  }
  .mt-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-lg-n5 {
    margin-top: -3rem !important;
  }
  .mt-lg-n6 {
    margin-top: -3.5rem !important;
  }
  .mt-lg-n7 {
    margin-top: -4rem !important;
  }
  .mt-lg-n8 {
    margin-top: -5rem !important;
  }
  .mt-lg-n9 {
    margin-top: -6rem !important;
  }
  .mt-lg-n10 {
    margin-top: -7rem !important;
  }
  .me-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .me-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .me-lg-n3 {
    margin-right: -1rem !important;
  }
  .me-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .me-lg-n5 {
    margin-right: -3rem !important;
  }
  .me-lg-n6 {
    margin-right: -3.5rem !important;
  }
  .me-lg-n7 {
    margin-right: -4rem !important;
  }
  .me-lg-n8 {
    margin-right: -5rem !important;
  }
  .me-lg-n9 {
    margin-right: -6rem !important;
  }
  .me-lg-n10 {
    margin-right: -7rem !important;
  }
  .mb-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .mb-lg-n6 {
    margin-bottom: -3.5rem !important;
  }
  .mb-lg-n7 {
    margin-bottom: -4rem !important;
  }
  .mb-lg-n8 {
    margin-bottom: -5rem !important;
  }
  .mb-lg-n9 {
    margin-bottom: -6rem !important;
  }
  .mb-lg-n10 {
    margin-bottom: -7rem !important;
  }
  .ms-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-lg-n3 {
    margin-left: -1rem !important;
  }
  .ms-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-lg-n5 {
    margin-left: -3rem !important;
  }
  .ms-lg-n6 {
    margin-left: -3.5rem !important;
  }
  .ms-lg-n7 {
    margin-left: -4rem !important;
  }
  .ms-lg-n8 {
    margin-left: -5rem !important;
  }
  .ms-lg-n9 {
    margin-left: -6rem !important;
  }
  .ms-lg-n10 {
    margin-left: -7rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .p-lg-6 {
    padding: 3.5rem !important;
  }
  .p-lg-7 {
    padding: 4rem !important;
  }
  .p-lg-8 {
    padding: 5rem !important;
  }
  .p-lg-9 {
    padding: 6rem !important;
  }
  .p-lg-10 {
    padding: 7rem !important;
  }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-lg-6 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }
  .px-lg-7 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }
  .px-lg-8 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }
  .px-lg-9 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }
  .px-lg-10 {
    padding-right: 7rem !important;
    padding-left: 7rem !important;
  }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-lg-6 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }
  .py-lg-7 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .py-lg-8 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .py-lg-9 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }
  .py-lg-10 {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pt-lg-3 {
    padding-top: 1rem !important;
  }
  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pt-lg-5 {
    padding-top: 3rem !important;
  }
  .pt-lg-6 {
    padding-top: 3.5rem !important;
  }
  .pt-lg-7 {
    padding-top: 4rem !important;
  }
  .pt-lg-8 {
    padding-top: 5rem !important;
  }
  .pt-lg-9 {
    padding-top: 6rem !important;
  }
  .pt-lg-10 {
    padding-top: 7rem !important;
  }
  .pe-lg-0 {
    padding-right: 0 !important;
  }
  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pe-lg-3 {
    padding-right: 1rem !important;
  }
  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pe-lg-5 {
    padding-right: 3rem !important;
  }
  .pe-lg-6 {
    padding-right: 3.5rem !important;
  }
  .pe-lg-7 {
    padding-right: 4rem !important;
  }
  .pe-lg-8 {
    padding-right: 5rem !important;
  }
  .pe-lg-9 {
    padding-right: 6rem !important;
  }
  .pe-lg-10 {
    padding-right: 7rem !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pb-lg-6 {
    padding-bottom: 3.5rem !important;
  }
  .pb-lg-7 {
    padding-bottom: 4rem !important;
  }
  .pb-lg-8 {
    padding-bottom: 5rem !important;
  }
  .pb-lg-9 {
    padding-bottom: 6rem !important;
  }
  .pb-lg-10 {
    padding-bottom: 7rem !important;
  }
  .ps-lg-0 {
    padding-left: 0 !important;
  }
  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }
  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }
  .ps-lg-3 {
    padding-left: 1rem !important;
  }
  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }
  .ps-lg-5 {
    padding-left: 3rem !important;
  }
  .ps-lg-6 {
    padding-left: 3.5rem !important;
  }
  .ps-lg-7 {
    padding-left: 4rem !important;
  }
  .ps-lg-8 {
    padding-left: 5rem !important;
  }
  .ps-lg-9 {
    padding-left: 6rem !important;
  }
  .ps-lg-10 {
    padding-left: 7rem !important;
  }
  .gap-lg-0 {
    gap: 0 !important;
  }
  .gap-lg-1 {
    gap: 0.25rem !important;
  }
  .gap-lg-2 {
    gap: 0.5rem !important;
  }
  .gap-lg-3 {
    gap: 1rem !important;
  }
  .gap-lg-4 {
    gap: 1.5rem !important;
  }
  .gap-lg-5 {
    gap: 3rem !important;
  }
  .gap-lg-6 {
    gap: 3.5rem !important;
  }
  .gap-lg-7 {
    gap: 4rem !important;
  }
  .gap-lg-8 {
    gap: 5rem !important;
  }
  .gap-lg-9 {
    gap: 6rem !important;
  }
  .gap-lg-10 {
    gap: 7rem !important;
  }
  .row-gap-lg-0 {
    row-gap: 0 !important;
  }
  .row-gap-lg-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-lg-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-lg-3 {
    row-gap: 1rem !important;
  }
  .row-gap-lg-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-lg-5 {
    row-gap: 3rem !important;
  }
  .row-gap-lg-6 {
    row-gap: 3.5rem !important;
  }
  .row-gap-lg-7 {
    row-gap: 4rem !important;
  }
  .row-gap-lg-8 {
    row-gap: 5rem !important;
  }
  .row-gap-lg-9 {
    row-gap: 6rem !important;
  }
  .row-gap-lg-10 {
    row-gap: 7rem !important;
  }
  .column-gap-lg-0 {
    column-gap: 0 !important;
  }
  .column-gap-lg-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-lg-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-lg-3 {
    column-gap: 1rem !important;
  }
  .column-gap-lg-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-lg-5 {
    column-gap: 3rem !important;
  }
  .column-gap-lg-6 {
    column-gap: 3.5rem !important;
  }
  .column-gap-lg-7 {
    column-gap: 4rem !important;
  }
  .column-gap-lg-8 {
    column-gap: 5rem !important;
  }
  .column-gap-lg-9 {
    column-gap: 6rem !important;
  }
  .column-gap-lg-10 {
    column-gap: 7rem !important;
  }
  .text-lg-start {
    text-align: left !important;
  }
  .text-lg-end {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
  .cursor-lg-auto {
    cursor: auto !important;
  }
  .cursor-lg-pointer {
    cursor: pointer !important;
  }
  .cursor-lg-grab {
    cursor: grab !important;
  }
  .f-w-lg-1 {
    width: 0.25rem !important;
  }
  .f-w-lg-2 {
    width: 0.5rem !important;
  }
  .f-w-lg-3 {
    width: 0.75rem !important;
  }
  .f-w-lg-4 {
    width: 1rem !important;
  }
  .f-w-lg-5 {
    width: 1.25rem !important;
  }
  .f-w-lg-6 {
    width: 1.5rem !important;
  }
  .f-w-lg-7 {
    width: 1.75rem !important;
  }
  .f-w-lg-8 {
    width: 2rem !important;
  }
  .f-w-lg-9 {
    width: 2.25rem !important;
  }
  .f-w-lg-10 {
    width: 2.5rem !important;
  }
  .f-w-lg-12 {
    width: 3rem !important;
  }
  .f-w-lg-14 {
    width: 3.5rem !important;
  }
  .f-w-lg-16 {
    width: 4rem !important;
  }
  .f-w-lg-20 {
    width: 5rem !important;
  }
  .f-w-lg-24 {
    width: 6rem !important;
  }
  .f-w-lg-28 {
    width: 7rem !important;
  }
  .f-w-lg-30 {
    width: 7.25rem !important;
  }
  .f-w-lg-32 {
    width: 8rem !important;
  }
  .f-w-lg-36 {
    width: 9rem !important;
  }
  .f-w-lg-40 {
    width: 10rem !important;
  }
  .f-w-lg-44 {
    width: 11rem !important;
  }
  .f-w-lg-48 {
    width: 12rem !important;
  }
  .f-w-lg-52 {
    width: 15rem !important;
  }
  .f-w-lg-56 {
    width: 18rem !important;
  }
  .f-w-lg-60 {
    width: 21rem !important;
  }
  .f-w-lg-64 {
    width: 24rem !important;
  }
  .f-w-lg-68 {
    width: 27rem !important;
  }
  .f-w-lg-72 {
    width: 30rem !important;
  }
  .f-w-lg-76 {
    width: 33rem !important;
  }
  .f-w-lg-80 {
    width: 36rem !important;
  }
  .f-w-lg-84 {
    width: 40rem !important;
  }
  .f-w-lg-88 {
    width: 44rem !important;
  }
  .f-w-lg-92 {
    width: 50rem !important;
  }
  .f-w-lg-96 {
    width: 56rem !important;
  }
  .f-w-lg-100 {
    width: 60rem !important;
  }
  .f-w-lg-0 {
    width: 0 !important;
  }
  .f-h-lg-1 {
    height: 0.25rem !important;
  }
  .f-h-lg-2 {
    height: 0.5rem !important;
  }
  .f-h-lg-3 {
    height: 0.75rem !important;
  }
  .f-h-lg-4 {
    height: 1rem !important;
  }
  .f-h-lg-5 {
    height: 1.25rem !important;
  }
  .f-h-lg-6 {
    height: 1.5rem !important;
  }
  .f-h-lg-7 {
    height: 1.75rem !important;
  }
  .f-h-lg-8 {
    height: 2rem !important;
  }
  .f-h-lg-9 {
    height: 2.25rem !important;
  }
  .f-h-lg-10 {
    height: 2.5rem !important;
  }
  .f-h-lg-12 {
    height: 3rem !important;
  }
  .f-h-lg-14 {
    height: 3.5rem !important;
  }
  .f-h-lg-16 {
    height: 4rem !important;
  }
  .f-h-lg-20 {
    height: 5rem !important;
  }
  .f-h-lg-24 {
    height: 6rem !important;
  }
  .f-h-lg-28 {
    height: 7rem !important;
  }
  .f-h-lg-30 {
    height: 7.25rem !important;
  }
  .f-h-lg-32 {
    height: 8rem !important;
  }
  .f-h-lg-36 {
    height: 9rem !important;
  }
  .f-h-lg-40 {
    height: 10rem !important;
  }
  .f-h-lg-44 {
    height: 11rem !important;
  }
  .f-h-lg-48 {
    height: 12rem !important;
  }
  .f-h-lg-52 {
    height: 15rem !important;
  }
  .f-h-lg-56 {
    height: 18rem !important;
  }
  .f-h-lg-60 {
    height: 21rem !important;
  }
  .f-h-lg-64 {
    height: 24rem !important;
  }
  .f-h-lg-68 {
    height: 27rem !important;
  }
  .f-h-lg-72 {
    height: 30rem !important;
  }
  .f-h-lg-76 {
    height: 33rem !important;
  }
  .f-h-lg-80 {
    height: 36rem !important;
  }
  .f-h-lg-84 {
    height: 40rem !important;
  }
  .f-h-lg-88 {
    height: 44rem !important;
  }
  .f-h-lg-92 {
    height: 50rem !important;
  }
  .f-h-lg-96 {
    height: 56rem !important;
  }
  .f-h-lg-100 {
    height: 60rem !important;
  }
  .f-h-lg-0 {
    height: 0 !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }
  .float-xl-end {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
  .object-fit-xl-contain {
    -o-object-fit: contain !important;
       object-fit: contain !important;
  }
  .object-fit-xl-cover {
    -o-object-fit: cover !important;
       object-fit: cover !important;
  }
  .object-fit-xl-fill {
    -o-object-fit: fill !important;
       object-fit: fill !important;
  }
  .object-fit-xl-scale {
    -o-object-fit: scale-down !important;
       object-fit: scale-down !important;
  }
  .object-fit-xl-none {
    -o-object-fit: none !important;
       object-fit: none !important;
  }
  .opacity-xl-0 {
    opacity: 0 !important;
  }
  .opacity-xl-0-hover:hover {
    opacity: 0 !important;
  }
  .opacity-xl-25 {
    opacity: 0.25 !important;
  }
  .opacity-xl-25-hover:hover {
    opacity: 0.25 !important;
  }
  .opacity-xl-50 {
    opacity: 0.5 !important;
  }
  .opacity-xl-50-hover:hover {
    opacity: 0.5 !important;
  }
  .opacity-xl-75 {
    opacity: 0.75 !important;
  }
  .opacity-xl-75-hover:hover {
    opacity: 0.75 !important;
  }
  .opacity-xl-100 {
    opacity: 1 !important;
  }
  .opacity-xl-100-hover:hover {
    opacity: 1 !important;
  }
  .opacity-xl-5 {
    opacity: 0.05 !important;
  }
  .opacity-xl-5-hover:hover {
    opacity: 0.05 !important;
  }
  .opacity-xl-10 {
    opacity: 0.1 !important;
  }
  .opacity-xl-10-hover:hover {
    opacity: 0.1 !important;
  }
  .opacity-xl-90 {
    opacity: 0.9 !important;
  }
  .opacity-xl-90-hover:hover {
    opacity: 0.9 !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-grid {
    display: grid !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .w-xl-25 {
    width: 25% !important;
  }
  .w-xl-50 {
    width: 50% !important;
  }
  .w-xl-75 {
    width: 75% !important;
  }
  .w-xl-100 {
    width: 100% !important;
  }
  .w-xl-auto {
    width: auto !important;
  }
  .w-xl-10 {
    width: 10% !important;
  }
  .w-xl-20 {
    width: 20% !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .order-xl-first {
    order: -1 !important;
  }
  .order-xl-0 {
    order: 0 !important;
  }
  .order-xl-1 {
    order: 1 !important;
  }
  .order-xl-2 {
    order: 2 !important;
  }
  .order-xl-3 {
    order: 3 !important;
  }
  .order-xl-4 {
    order: 4 !important;
  }
  .order-xl-5 {
    order: 5 !important;
  }
  .order-xl-last {
    order: 6 !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .m-xl-6 {
    margin: 3.5rem !important;
  }
  .m-xl-7 {
    margin: 4rem !important;
  }
  .m-xl-8 {
    margin: 5rem !important;
  }
  .m-xl-9 {
    margin: 6rem !important;
  }
  .m-xl-10 {
    margin: 7rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xl-6 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }
  .mx-xl-7 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }
  .mx-xl-8 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }
  .mx-xl-9 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }
  .mx-xl-10 {
    margin-right: 7rem !important;
    margin-left: 7rem !important;
  }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xl-6 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }
  .my-xl-7 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .my-xl-8 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .my-xl-9 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }
  .my-xl-10 {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important;
  }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xl-3 {
    margin-top: 1rem !important;
  }
  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xl-5 {
    margin-top: 3rem !important;
  }
  .mt-xl-6 {
    margin-top: 3.5rem !important;
  }
  .mt-xl-7 {
    margin-top: 4rem !important;
  }
  .mt-xl-8 {
    margin-top: 5rem !important;
  }
  .mt-xl-9 {
    margin-top: 6rem !important;
  }
  .mt-xl-10 {
    margin-top: 7rem !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  .me-xl-0 {
    margin-right: 0 !important;
  }
  .me-xl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xl-3 {
    margin-right: 1rem !important;
  }
  .me-xl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xl-5 {
    margin-right: 3rem !important;
  }
  .me-xl-6 {
    margin-right: 3.5rem !important;
  }
  .me-xl-7 {
    margin-right: 4rem !important;
  }
  .me-xl-8 {
    margin-right: 5rem !important;
  }
  .me-xl-9 {
    margin-right: 6rem !important;
  }
  .me-xl-10 {
    margin-right: 7rem !important;
  }
  .me-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xl-6 {
    margin-bottom: 3.5rem !important;
  }
  .mb-xl-7 {
    margin-bottom: 4rem !important;
  }
  .mb-xl-8 {
    margin-bottom: 5rem !important;
  }
  .mb-xl-9 {
    margin-bottom: 6rem !important;
  }
  .mb-xl-10 {
    margin-bottom: 7rem !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ms-xl-0 {
    margin-left: 0 !important;
  }
  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xl-3 {
    margin-left: 1rem !important;
  }
  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xl-5 {
    margin-left: 3rem !important;
  }
  .ms-xl-6 {
    margin-left: 3.5rem !important;
  }
  .ms-xl-7 {
    margin-left: 4rem !important;
  }
  .ms-xl-8 {
    margin-left: 5rem !important;
  }
  .ms-xl-9 {
    margin-left: 6rem !important;
  }
  .ms-xl-10 {
    margin-left: 7rem !important;
  }
  .ms-xl-auto {
    margin-left: auto !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .m-xl-n6 {
    margin: -3.5rem !important;
  }
  .m-xl-n7 {
    margin: -4rem !important;
  }
  .m-xl-n8 {
    margin: -5rem !important;
  }
  .m-xl-n9 {
    margin: -6rem !important;
  }
  .m-xl-n10 {
    margin: -7rem !important;
  }
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-xl-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-xl-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .mx-xl-n6 {
    margin-right: -3.5rem !important;
    margin-left: -3.5rem !important;
  }
  .mx-xl-n7 {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }
  .mx-xl-n8 {
    margin-right: -5rem !important;
    margin-left: -5rem !important;
  }
  .mx-xl-n9 {
    margin-right: -6rem !important;
    margin-left: -6rem !important;
  }
  .mx-xl-n10 {
    margin-right: -7rem !important;
    margin-left: -7rem !important;
  }
  .my-xl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-xl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-xl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-xl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-xl-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .my-xl-n6 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }
  .my-xl-n7 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }
  .my-xl-n8 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }
  .my-xl-n9 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }
  .my-xl-n10 {
    margin-top: -7rem !important;
    margin-bottom: -7rem !important;
  }
  .mt-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-xl-n3 {
    margin-top: -1rem !important;
  }
  .mt-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-xl-n5 {
    margin-top: -3rem !important;
  }
  .mt-xl-n6 {
    margin-top: -3.5rem !important;
  }
  .mt-xl-n7 {
    margin-top: -4rem !important;
  }
  .mt-xl-n8 {
    margin-top: -5rem !important;
  }
  .mt-xl-n9 {
    margin-top: -6rem !important;
  }
  .mt-xl-n10 {
    margin-top: -7rem !important;
  }
  .me-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .me-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .me-xl-n3 {
    margin-right: -1rem !important;
  }
  .me-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .me-xl-n5 {
    margin-right: -3rem !important;
  }
  .me-xl-n6 {
    margin-right: -3.5rem !important;
  }
  .me-xl-n7 {
    margin-right: -4rem !important;
  }
  .me-xl-n8 {
    margin-right: -5rem !important;
  }
  .me-xl-n9 {
    margin-right: -6rem !important;
  }
  .me-xl-n10 {
    margin-right: -7rem !important;
  }
  .mb-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .mb-xl-n6 {
    margin-bottom: -3.5rem !important;
  }
  .mb-xl-n7 {
    margin-bottom: -4rem !important;
  }
  .mb-xl-n8 {
    margin-bottom: -5rem !important;
  }
  .mb-xl-n9 {
    margin-bottom: -6rem !important;
  }
  .mb-xl-n10 {
    margin-bottom: -7rem !important;
  }
  .ms-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-xl-n3 {
    margin-left: -1rem !important;
  }
  .ms-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-xl-n5 {
    margin-left: -3rem !important;
  }
  .ms-xl-n6 {
    margin-left: -3.5rem !important;
  }
  .ms-xl-n7 {
    margin-left: -4rem !important;
  }
  .ms-xl-n8 {
    margin-left: -5rem !important;
  }
  .ms-xl-n9 {
    margin-left: -6rem !important;
  }
  .ms-xl-n10 {
    margin-left: -7rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .p-xl-6 {
    padding: 3.5rem !important;
  }
  .p-xl-7 {
    padding: 4rem !important;
  }
  .p-xl-8 {
    padding: 5rem !important;
  }
  .p-xl-9 {
    padding: 6rem !important;
  }
  .p-xl-10 {
    padding: 7rem !important;
  }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-xl-6 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }
  .px-xl-7 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }
  .px-xl-8 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }
  .px-xl-9 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }
  .px-xl-10 {
    padding-right: 7rem !important;
    padding-left: 7rem !important;
  }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-xl-6 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }
  .py-xl-7 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .py-xl-8 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .py-xl-9 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }
  .py-xl-10 {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xl-3 {
    padding-top: 1rem !important;
  }
  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xl-5 {
    padding-top: 3rem !important;
  }
  .pt-xl-6 {
    padding-top: 3.5rem !important;
  }
  .pt-xl-7 {
    padding-top: 4rem !important;
  }
  .pt-xl-8 {
    padding-top: 5rem !important;
  }
  .pt-xl-9 {
    padding-top: 6rem !important;
  }
  .pt-xl-10 {
    padding-top: 7rem !important;
  }
  .pe-xl-0 {
    padding-right: 0 !important;
  }
  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xl-3 {
    padding-right: 1rem !important;
  }
  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xl-5 {
    padding-right: 3rem !important;
  }
  .pe-xl-6 {
    padding-right: 3.5rem !important;
  }
  .pe-xl-7 {
    padding-right: 4rem !important;
  }
  .pe-xl-8 {
    padding-right: 5rem !important;
  }
  .pe-xl-9 {
    padding-right: 6rem !important;
  }
  .pe-xl-10 {
    padding-right: 7rem !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pb-xl-6 {
    padding-bottom: 3.5rem !important;
  }
  .pb-xl-7 {
    padding-bottom: 4rem !important;
  }
  .pb-xl-8 {
    padding-bottom: 5rem !important;
  }
  .pb-xl-9 {
    padding-bottom: 6rem !important;
  }
  .pb-xl-10 {
    padding-bottom: 7rem !important;
  }
  .ps-xl-0 {
    padding-left: 0 !important;
  }
  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xl-3 {
    padding-left: 1rem !important;
  }
  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xl-5 {
    padding-left: 3rem !important;
  }
  .ps-xl-6 {
    padding-left: 3.5rem !important;
  }
  .ps-xl-7 {
    padding-left: 4rem !important;
  }
  .ps-xl-8 {
    padding-left: 5rem !important;
  }
  .ps-xl-9 {
    padding-left: 6rem !important;
  }
  .ps-xl-10 {
    padding-left: 7rem !important;
  }
  .gap-xl-0 {
    gap: 0 !important;
  }
  .gap-xl-1 {
    gap: 0.25rem !important;
  }
  .gap-xl-2 {
    gap: 0.5rem !important;
  }
  .gap-xl-3 {
    gap: 1rem !important;
  }
  .gap-xl-4 {
    gap: 1.5rem !important;
  }
  .gap-xl-5 {
    gap: 3rem !important;
  }
  .gap-xl-6 {
    gap: 3.5rem !important;
  }
  .gap-xl-7 {
    gap: 4rem !important;
  }
  .gap-xl-8 {
    gap: 5rem !important;
  }
  .gap-xl-9 {
    gap: 6rem !important;
  }
  .gap-xl-10 {
    gap: 7rem !important;
  }
  .row-gap-xl-0 {
    row-gap: 0 !important;
  }
  .row-gap-xl-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-xl-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-xl-3 {
    row-gap: 1rem !important;
  }
  .row-gap-xl-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-xl-5 {
    row-gap: 3rem !important;
  }
  .row-gap-xl-6 {
    row-gap: 3.5rem !important;
  }
  .row-gap-xl-7 {
    row-gap: 4rem !important;
  }
  .row-gap-xl-8 {
    row-gap: 5rem !important;
  }
  .row-gap-xl-9 {
    row-gap: 6rem !important;
  }
  .row-gap-xl-10 {
    row-gap: 7rem !important;
  }
  .column-gap-xl-0 {
    column-gap: 0 !important;
  }
  .column-gap-xl-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-xl-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-xl-3 {
    column-gap: 1rem !important;
  }
  .column-gap-xl-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-xl-5 {
    column-gap: 3rem !important;
  }
  .column-gap-xl-6 {
    column-gap: 3.5rem !important;
  }
  .column-gap-xl-7 {
    column-gap: 4rem !important;
  }
  .column-gap-xl-8 {
    column-gap: 5rem !important;
  }
  .column-gap-xl-9 {
    column-gap: 6rem !important;
  }
  .column-gap-xl-10 {
    column-gap: 7rem !important;
  }
  .text-xl-start {
    text-align: left !important;
  }
  .text-xl-end {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
  .cursor-xl-auto {
    cursor: auto !important;
  }
  .cursor-xl-pointer {
    cursor: pointer !important;
  }
  .cursor-xl-grab {
    cursor: grab !important;
  }
  .f-w-xl-1 {
    width: 0.25rem !important;
  }
  .f-w-xl-2 {
    width: 0.5rem !important;
  }
  .f-w-xl-3 {
    width: 0.75rem !important;
  }
  .f-w-xl-4 {
    width: 1rem !important;
  }
  .f-w-xl-5 {
    width: 1.25rem !important;
  }
  .f-w-xl-6 {
    width: 1.5rem !important;
  }
  .f-w-xl-7 {
    width: 1.75rem !important;
  }
  .f-w-xl-8 {
    width: 2rem !important;
  }
  .f-w-xl-9 {
    width: 2.25rem !important;
  }
  .f-w-xl-10 {
    width: 2.5rem !important;
  }
  .f-w-xl-12 {
    width: 3rem !important;
  }
  .f-w-xl-14 {
    width: 3.5rem !important;
  }
  .f-w-xl-16 {
    width: 4rem !important;
  }
  .f-w-xl-20 {
    width: 5rem !important;
  }
  .f-w-xl-24 {
    width: 6rem !important;
  }
  .f-w-xl-28 {
    width: 7rem !important;
  }
  .f-w-xl-30 {
    width: 7.25rem !important;
  }
  .f-w-xl-32 {
    width: 8rem !important;
  }
  .f-w-xl-36 {
    width: 9rem !important;
  }
  .f-w-xl-40 {
    width: 10rem !important;
  }
  .f-w-xl-44 {
    width: 11rem !important;
  }
  .f-w-xl-48 {
    width: 12rem !important;
  }
  .f-w-xl-52 {
    width: 15rem !important;
  }
  .f-w-xl-56 {
    width: 18rem !important;
  }
  .f-w-xl-60 {
    width: 21rem !important;
  }
  .f-w-xl-64 {
    width: 24rem !important;
  }
  .f-w-xl-68 {
    width: 27rem !important;
  }
  .f-w-xl-72 {
    width: 30rem !important;
  }
  .f-w-xl-76 {
    width: 33rem !important;
  }
  .f-w-xl-80 {
    width: 36rem !important;
  }
  .f-w-xl-84 {
    width: 40rem !important;
  }
  .f-w-xl-88 {
    width: 44rem !important;
  }
  .f-w-xl-92 {
    width: 50rem !important;
  }
  .f-w-xl-96 {
    width: 56rem !important;
  }
  .f-w-xl-100 {
    width: 60rem !important;
  }
  .f-w-xl-0 {
    width: 0 !important;
  }
  .f-h-xl-1 {
    height: 0.25rem !important;
  }
  .f-h-xl-2 {
    height: 0.5rem !important;
  }
  .f-h-xl-3 {
    height: 0.75rem !important;
  }
  .f-h-xl-4 {
    height: 1rem !important;
  }
  .f-h-xl-5 {
    height: 1.25rem !important;
  }
  .f-h-xl-6 {
    height: 1.5rem !important;
  }
  .f-h-xl-7 {
    height: 1.75rem !important;
  }
  .f-h-xl-8 {
    height: 2rem !important;
  }
  .f-h-xl-9 {
    height: 2.25rem !important;
  }
  .f-h-xl-10 {
    height: 2.5rem !important;
  }
  .f-h-xl-12 {
    height: 3rem !important;
  }
  .f-h-xl-14 {
    height: 3.5rem !important;
  }
  .f-h-xl-16 {
    height: 4rem !important;
  }
  .f-h-xl-20 {
    height: 5rem !important;
  }
  .f-h-xl-24 {
    height: 6rem !important;
  }
  .f-h-xl-28 {
    height: 7rem !important;
  }
  .f-h-xl-30 {
    height: 7.25rem !important;
  }
  .f-h-xl-32 {
    height: 8rem !important;
  }
  .f-h-xl-36 {
    height: 9rem !important;
  }
  .f-h-xl-40 {
    height: 10rem !important;
  }
  .f-h-xl-44 {
    height: 11rem !important;
  }
  .f-h-xl-48 {
    height: 12rem !important;
  }
  .f-h-xl-52 {
    height: 15rem !important;
  }
  .f-h-xl-56 {
    height: 18rem !important;
  }
  .f-h-xl-60 {
    height: 21rem !important;
  }
  .f-h-xl-64 {
    height: 24rem !important;
  }
  .f-h-xl-68 {
    height: 27rem !important;
  }
  .f-h-xl-72 {
    height: 30rem !important;
  }
  .f-h-xl-76 {
    height: 33rem !important;
  }
  .f-h-xl-80 {
    height: 36rem !important;
  }
  .f-h-xl-84 {
    height: 40rem !important;
  }
  .f-h-xl-88 {
    height: 44rem !important;
  }
  .f-h-xl-92 {
    height: 50rem !important;
  }
  .f-h-xl-96 {
    height: 56rem !important;
  }
  .f-h-xl-100 {
    height: 60rem !important;
  }
  .f-h-xl-0 {
    height: 0 !important;
  }
}
@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important;
  }
  .float-xxl-end {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
  .object-fit-xxl-contain {
    -o-object-fit: contain !important;
       object-fit: contain !important;
  }
  .object-fit-xxl-cover {
    -o-object-fit: cover !important;
       object-fit: cover !important;
  }
  .object-fit-xxl-fill {
    -o-object-fit: fill !important;
       object-fit: fill !important;
  }
  .object-fit-xxl-scale {
    -o-object-fit: scale-down !important;
       object-fit: scale-down !important;
  }
  .object-fit-xxl-none {
    -o-object-fit: none !important;
       object-fit: none !important;
  }
  .opacity-xxl-0 {
    opacity: 0 !important;
  }
  .opacity-xxl-0-hover:hover {
    opacity: 0 !important;
  }
  .opacity-xxl-25 {
    opacity: 0.25 !important;
  }
  .opacity-xxl-25-hover:hover {
    opacity: 0.25 !important;
  }
  .opacity-xxl-50 {
    opacity: 0.5 !important;
  }
  .opacity-xxl-50-hover:hover {
    opacity: 0.5 !important;
  }
  .opacity-xxl-75 {
    opacity: 0.75 !important;
  }
  .opacity-xxl-75-hover:hover {
    opacity: 0.75 !important;
  }
  .opacity-xxl-100 {
    opacity: 1 !important;
  }
  .opacity-xxl-100-hover:hover {
    opacity: 1 !important;
  }
  .opacity-xxl-5 {
    opacity: 0.05 !important;
  }
  .opacity-xxl-5-hover:hover {
    opacity: 0.05 !important;
  }
  .opacity-xxl-10 {
    opacity: 0.1 !important;
  }
  .opacity-xxl-10-hover:hover {
    opacity: 0.1 !important;
  }
  .opacity-xxl-90 {
    opacity: 0.9 !important;
  }
  .opacity-xxl-90-hover:hover {
    opacity: 0.9 !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-grid {
    display: grid !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
  .d-xxl-none {
    display: none !important;
  }
  .w-xxl-25 {
    width: 25% !important;
  }
  .w-xxl-50 {
    width: 50% !important;
  }
  .w-xxl-75 {
    width: 75% !important;
  }
  .w-xxl-100 {
    width: 100% !important;
  }
  .w-xxl-auto {
    width: auto !important;
  }
  .w-xxl-10 {
    width: 10% !important;
  }
  .w-xxl-20 {
    width: 20% !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
  .order-xxl-first {
    order: -1 !important;
  }
  .order-xxl-0 {
    order: 0 !important;
  }
  .order-xxl-1 {
    order: 1 !important;
  }
  .order-xxl-2 {
    order: 2 !important;
  }
  .order-xxl-3 {
    order: 3 !important;
  }
  .order-xxl-4 {
    order: 4 !important;
  }
  .order-xxl-5 {
    order: 5 !important;
  }
  .order-xxl-last {
    order: 6 !important;
  }
  .m-xxl-0 {
    margin: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.25rem !important;
  }
  .m-xxl-2 {
    margin: 0.5rem !important;
  }
  .m-xxl-3 {
    margin: 1rem !important;
  }
  .m-xxl-4 {
    margin: 1.5rem !important;
  }
  .m-xxl-5 {
    margin: 3rem !important;
  }
  .m-xxl-6 {
    margin: 3.5rem !important;
  }
  .m-xxl-7 {
    margin: 4rem !important;
  }
  .m-xxl-8 {
    margin: 5rem !important;
  }
  .m-xxl-9 {
    margin: 6rem !important;
  }
  .m-xxl-10 {
    margin: 7rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xxl-6 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }
  .mx-xxl-7 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }
  .mx-xxl-8 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }
  .mx-xxl-9 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }
  .mx-xxl-10 {
    margin-right: 7rem !important;
    margin-left: 7rem !important;
  }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xxl-6 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }
  .my-xxl-7 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .my-xxl-8 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .my-xxl-9 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }
  .my-xxl-10 {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important;
  }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xxl-0 {
    margin-top: 0 !important;
  }
  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xxl-3 {
    margin-top: 1rem !important;
  }
  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xxl-5 {
    margin-top: 3rem !important;
  }
  .mt-xxl-6 {
    margin-top: 3.5rem !important;
  }
  .mt-xxl-7 {
    margin-top: 4rem !important;
  }
  .mt-xxl-8 {
    margin-top: 5rem !important;
  }
  .mt-xxl-9 {
    margin-top: 6rem !important;
  }
  .mt-xxl-10 {
    margin-top: 7rem !important;
  }
  .mt-xxl-auto {
    margin-top: auto !important;
  }
  .me-xxl-0 {
    margin-right: 0 !important;
  }
  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xxl-3 {
    margin-right: 1rem !important;
  }
  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xxl-5 {
    margin-right: 3rem !important;
  }
  .me-xxl-6 {
    margin-right: 3.5rem !important;
  }
  .me-xxl-7 {
    margin-right: 4rem !important;
  }
  .me-xxl-8 {
    margin-right: 5rem !important;
  }
  .me-xxl-9 {
    margin-right: 6rem !important;
  }
  .me-xxl-10 {
    margin-right: 7rem !important;
  }
  .me-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xxl-6 {
    margin-bottom: 3.5rem !important;
  }
  .mb-xxl-7 {
    margin-bottom: 4rem !important;
  }
  .mb-xxl-8 {
    margin-bottom: 5rem !important;
  }
  .mb-xxl-9 {
    margin-bottom: 6rem !important;
  }
  .mb-xxl-10 {
    margin-bottom: 7rem !important;
  }
  .mb-xxl-auto {
    margin-bottom: auto !important;
  }
  .ms-xxl-0 {
    margin-left: 0 !important;
  }
  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xxl-3 {
    margin-left: 1rem !important;
  }
  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xxl-5 {
    margin-left: 3rem !important;
  }
  .ms-xxl-6 {
    margin-left: 3.5rem !important;
  }
  .ms-xxl-7 {
    margin-left: 4rem !important;
  }
  .ms-xxl-8 {
    margin-left: 5rem !important;
  }
  .ms-xxl-9 {
    margin-left: 6rem !important;
  }
  .ms-xxl-10 {
    margin-left: 7rem !important;
  }
  .ms-xxl-auto {
    margin-left: auto !important;
  }
  .m-xxl-n1 {
    margin: -0.25rem !important;
  }
  .m-xxl-n2 {
    margin: -0.5rem !important;
  }
  .m-xxl-n3 {
    margin: -1rem !important;
  }
  .m-xxl-n4 {
    margin: -1.5rem !important;
  }
  .m-xxl-n5 {
    margin: -3rem !important;
  }
  .m-xxl-n6 {
    margin: -3.5rem !important;
  }
  .m-xxl-n7 {
    margin: -4rem !important;
  }
  .m-xxl-n8 {
    margin: -5rem !important;
  }
  .m-xxl-n9 {
    margin: -6rem !important;
  }
  .m-xxl-n10 {
    margin: -7rem !important;
  }
  .mx-xxl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-xxl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-xxl-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-xxl-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-xxl-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .mx-xxl-n6 {
    margin-right: -3.5rem !important;
    margin-left: -3.5rem !important;
  }
  .mx-xxl-n7 {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }
  .mx-xxl-n8 {
    margin-right: -5rem !important;
    margin-left: -5rem !important;
  }
  .mx-xxl-n9 {
    margin-right: -6rem !important;
    margin-left: -6rem !important;
  }
  .mx-xxl-n10 {
    margin-right: -7rem !important;
    margin-left: -7rem !important;
  }
  .my-xxl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-xxl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-xxl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-xxl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-xxl-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .my-xxl-n6 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }
  .my-xxl-n7 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }
  .my-xxl-n8 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }
  .my-xxl-n9 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }
  .my-xxl-n10 {
    margin-top: -7rem !important;
    margin-bottom: -7rem !important;
  }
  .mt-xxl-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-xxl-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-xxl-n3 {
    margin-top: -1rem !important;
  }
  .mt-xxl-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-xxl-n5 {
    margin-top: -3rem !important;
  }
  .mt-xxl-n6 {
    margin-top: -3.5rem !important;
  }
  .mt-xxl-n7 {
    margin-top: -4rem !important;
  }
  .mt-xxl-n8 {
    margin-top: -5rem !important;
  }
  .mt-xxl-n9 {
    margin-top: -6rem !important;
  }
  .mt-xxl-n10 {
    margin-top: -7rem !important;
  }
  .me-xxl-n1 {
    margin-right: -0.25rem !important;
  }
  .me-xxl-n2 {
    margin-right: -0.5rem !important;
  }
  .me-xxl-n3 {
    margin-right: -1rem !important;
  }
  .me-xxl-n4 {
    margin-right: -1.5rem !important;
  }
  .me-xxl-n5 {
    margin-right: -3rem !important;
  }
  .me-xxl-n6 {
    margin-right: -3.5rem !important;
  }
  .me-xxl-n7 {
    margin-right: -4rem !important;
  }
  .me-xxl-n8 {
    margin-right: -5rem !important;
  }
  .me-xxl-n9 {
    margin-right: -6rem !important;
  }
  .me-xxl-n10 {
    margin-right: -7rem !important;
  }
  .mb-xxl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-xxl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-xxl-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-xxl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-xxl-n5 {
    margin-bottom: -3rem !important;
  }
  .mb-xxl-n6 {
    margin-bottom: -3.5rem !important;
  }
  .mb-xxl-n7 {
    margin-bottom: -4rem !important;
  }
  .mb-xxl-n8 {
    margin-bottom: -5rem !important;
  }
  .mb-xxl-n9 {
    margin-bottom: -6rem !important;
  }
  .mb-xxl-n10 {
    margin-bottom: -7rem !important;
  }
  .ms-xxl-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-xxl-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-xxl-n3 {
    margin-left: -1rem !important;
  }
  .ms-xxl-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-xxl-n5 {
    margin-left: -3rem !important;
  }
  .ms-xxl-n6 {
    margin-left: -3.5rem !important;
  }
  .ms-xxl-n7 {
    margin-left: -4rem !important;
  }
  .ms-xxl-n8 {
    margin-left: -5rem !important;
  }
  .ms-xxl-n9 {
    margin-left: -6rem !important;
  }
  .ms-xxl-n10 {
    margin-left: -7rem !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.25rem !important;
  }
  .p-xxl-2 {
    padding: 0.5rem !important;
  }
  .p-xxl-3 {
    padding: 1rem !important;
  }
  .p-xxl-4 {
    padding: 1.5rem !important;
  }
  .p-xxl-5 {
    padding: 3rem !important;
  }
  .p-xxl-6 {
    padding: 3.5rem !important;
  }
  .p-xxl-7 {
    padding: 4rem !important;
  }
  .p-xxl-8 {
    padding: 5rem !important;
  }
  .p-xxl-9 {
    padding: 6rem !important;
  }
  .p-xxl-10 {
    padding: 7rem !important;
  }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-xxl-6 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }
  .px-xxl-7 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }
  .px-xxl-8 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }
  .px-xxl-9 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }
  .px-xxl-10 {
    padding-right: 7rem !important;
    padding-left: 7rem !important;
  }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-xxl-6 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }
  .py-xxl-7 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .py-xxl-8 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .py-xxl-9 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }
  .py-xxl-10 {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important;
  }
  .pt-xxl-0 {
    padding-top: 0 !important;
  }
  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xxl-3 {
    padding-top: 1rem !important;
  }
  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xxl-5 {
    padding-top: 3rem !important;
  }
  .pt-xxl-6 {
    padding-top: 3.5rem !important;
  }
  .pt-xxl-7 {
    padding-top: 4rem !important;
  }
  .pt-xxl-8 {
    padding-top: 5rem !important;
  }
  .pt-xxl-9 {
    padding-top: 6rem !important;
  }
  .pt-xxl-10 {
    padding-top: 7rem !important;
  }
  .pe-xxl-0 {
    padding-right: 0 !important;
  }
  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xxl-3 {
    padding-right: 1rem !important;
  }
  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xxl-5 {
    padding-right: 3rem !important;
  }
  .pe-xxl-6 {
    padding-right: 3.5rem !important;
  }
  .pe-xxl-7 {
    padding-right: 4rem !important;
  }
  .pe-xxl-8 {
    padding-right: 5rem !important;
  }
  .pe-xxl-9 {
    padding-right: 6rem !important;
  }
  .pe-xxl-10 {
    padding-right: 7rem !important;
  }
  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }
  .pb-xxl-6 {
    padding-bottom: 3.5rem !important;
  }
  .pb-xxl-7 {
    padding-bottom: 4rem !important;
  }
  .pb-xxl-8 {
    padding-bottom: 5rem !important;
  }
  .pb-xxl-9 {
    padding-bottom: 6rem !important;
  }
  .pb-xxl-10 {
    padding-bottom: 7rem !important;
  }
  .ps-xxl-0 {
    padding-left: 0 !important;
  }
  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xxl-3 {
    padding-left: 1rem !important;
  }
  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xxl-5 {
    padding-left: 3rem !important;
  }
  .ps-xxl-6 {
    padding-left: 3.5rem !important;
  }
  .ps-xxl-7 {
    padding-left: 4rem !important;
  }
  .ps-xxl-8 {
    padding-left: 5rem !important;
  }
  .ps-xxl-9 {
    padding-left: 6rem !important;
  }
  .ps-xxl-10 {
    padding-left: 7rem !important;
  }
  .gap-xxl-0 {
    gap: 0 !important;
  }
  .gap-xxl-1 {
    gap: 0.25rem !important;
  }
  .gap-xxl-2 {
    gap: 0.5rem !important;
  }
  .gap-xxl-3 {
    gap: 1rem !important;
  }
  .gap-xxl-4 {
    gap: 1.5rem !important;
  }
  .gap-xxl-5 {
    gap: 3rem !important;
  }
  .gap-xxl-6 {
    gap: 3.5rem !important;
  }
  .gap-xxl-7 {
    gap: 4rem !important;
  }
  .gap-xxl-8 {
    gap: 5rem !important;
  }
  .gap-xxl-9 {
    gap: 6rem !important;
  }
  .gap-xxl-10 {
    gap: 7rem !important;
  }
  .row-gap-xxl-0 {
    row-gap: 0 !important;
  }
  .row-gap-xxl-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-xxl-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-xxl-3 {
    row-gap: 1rem !important;
  }
  .row-gap-xxl-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-xxl-5 {
    row-gap: 3rem !important;
  }
  .row-gap-xxl-6 {
    row-gap: 3.5rem !important;
  }
  .row-gap-xxl-7 {
    row-gap: 4rem !important;
  }
  .row-gap-xxl-8 {
    row-gap: 5rem !important;
  }
  .row-gap-xxl-9 {
    row-gap: 6rem !important;
  }
  .row-gap-xxl-10 {
    row-gap: 7rem !important;
  }
  .column-gap-xxl-0 {
    column-gap: 0 !important;
  }
  .column-gap-xxl-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-xxl-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-xxl-3 {
    column-gap: 1rem !important;
  }
  .column-gap-xxl-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-xxl-5 {
    column-gap: 3rem !important;
  }
  .column-gap-xxl-6 {
    column-gap: 3.5rem !important;
  }
  .column-gap-xxl-7 {
    column-gap: 4rem !important;
  }
  .column-gap-xxl-8 {
    column-gap: 5rem !important;
  }
  .column-gap-xxl-9 {
    column-gap: 6rem !important;
  }
  .column-gap-xxl-10 {
    column-gap: 7rem !important;
  }
  .text-xxl-start {
    text-align: left !important;
  }
  .text-xxl-end {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
  .cursor-xxl-auto {
    cursor: auto !important;
  }
  .cursor-xxl-pointer {
    cursor: pointer !important;
  }
  .cursor-xxl-grab {
    cursor: grab !important;
  }
  .f-w-xxl-1 {
    width: 0.25rem !important;
  }
  .f-w-xxl-2 {
    width: 0.5rem !important;
  }
  .f-w-xxl-3 {
    width: 0.75rem !important;
  }
  .f-w-xxl-4 {
    width: 1rem !important;
  }
  .f-w-xxl-5 {
    width: 1.25rem !important;
  }
  .f-w-xxl-6 {
    width: 1.5rem !important;
  }
  .f-w-xxl-7 {
    width: 1.75rem !important;
  }
  .f-w-xxl-8 {
    width: 2rem !important;
  }
  .f-w-xxl-9 {
    width: 2.25rem !important;
  }
  .f-w-xxl-10 {
    width: 2.5rem !important;
  }
  .f-w-xxl-12 {
    width: 3rem !important;
  }
  .f-w-xxl-14 {
    width: 3.5rem !important;
  }
  .f-w-xxl-16 {
    width: 4rem !important;
  }
  .f-w-xxl-20 {
    width: 5rem !important;
  }
  .f-w-xxl-24 {
    width: 6rem !important;
  }
  .f-w-xxl-28 {
    width: 7rem !important;
  }
  .f-w-xxl-30 {
    width: 7.25rem !important;
  }
  .f-w-xxl-32 {
    width: 8rem !important;
  }
  .f-w-xxl-36 {
    width: 9rem !important;
  }
  .f-w-xxl-40 {
    width: 10rem !important;
  }
  .f-w-xxl-44 {
    width: 11rem !important;
  }
  .f-w-xxl-48 {
    width: 12rem !important;
  }
  .f-w-xxl-52 {
    width: 15rem !important;
  }
  .f-w-xxl-56 {
    width: 18rem !important;
  }
  .f-w-xxl-60 {
    width: 21rem !important;
  }
  .f-w-xxl-64 {
    width: 24rem !important;
  }
  .f-w-xxl-68 {
    width: 27rem !important;
  }
  .f-w-xxl-72 {
    width: 30rem !important;
  }
  .f-w-xxl-76 {
    width: 33rem !important;
  }
  .f-w-xxl-80 {
    width: 36rem !important;
  }
  .f-w-xxl-84 {
    width: 40rem !important;
  }
  .f-w-xxl-88 {
    width: 44rem !important;
  }
  .f-w-xxl-92 {
    width: 50rem !important;
  }
  .f-w-xxl-96 {
    width: 56rem !important;
  }
  .f-w-xxl-100 {
    width: 60rem !important;
  }
  .f-w-xxl-0 {
    width: 0 !important;
  }
  .f-h-xxl-1 {
    height: 0.25rem !important;
  }
  .f-h-xxl-2 {
    height: 0.5rem !important;
  }
  .f-h-xxl-3 {
    height: 0.75rem !important;
  }
  .f-h-xxl-4 {
    height: 1rem !important;
  }
  .f-h-xxl-5 {
    height: 1.25rem !important;
  }
  .f-h-xxl-6 {
    height: 1.5rem !important;
  }
  .f-h-xxl-7 {
    height: 1.75rem !important;
  }
  .f-h-xxl-8 {
    height: 2rem !important;
  }
  .f-h-xxl-9 {
    height: 2.25rem !important;
  }
  .f-h-xxl-10 {
    height: 2.5rem !important;
  }
  .f-h-xxl-12 {
    height: 3rem !important;
  }
  .f-h-xxl-14 {
    height: 3.5rem !important;
  }
  .f-h-xxl-16 {
    height: 4rem !important;
  }
  .f-h-xxl-20 {
    height: 5rem !important;
  }
  .f-h-xxl-24 {
    height: 6rem !important;
  }
  .f-h-xxl-28 {
    height: 7rem !important;
  }
  .f-h-xxl-30 {
    height: 7.25rem !important;
  }
  .f-h-xxl-32 {
    height: 8rem !important;
  }
  .f-h-xxl-36 {
    height: 9rem !important;
  }
  .f-h-xxl-40 {
    height: 10rem !important;
  }
  .f-h-xxl-44 {
    height: 11rem !important;
  }
  .f-h-xxl-48 {
    height: 12rem !important;
  }
  .f-h-xxl-52 {
    height: 15rem !important;
  }
  .f-h-xxl-56 {
    height: 18rem !important;
  }
  .f-h-xxl-60 {
    height: 21rem !important;
  }
  .f-h-xxl-64 {
    height: 24rem !important;
  }
  .f-h-xxl-68 {
    height: 27rem !important;
  }
  .f-h-xxl-72 {
    height: 30rem !important;
  }
  .f-h-xxl-76 {
    height: 33rem !important;
  }
  .f-h-xxl-80 {
    height: 36rem !important;
  }
  .f-h-xxl-84 {
    height: 40rem !important;
  }
  .f-h-xxl-88 {
    height: 44rem !important;
  }
  .f-h-xxl-92 {
    height: 50rem !important;
  }
  .f-h-xxl-96 {
    height: 56rem !important;
  }
  .f-h-xxl-100 {
    height: 60rem !important;
  }
  .f-h-xxl-0 {
    height: 0 !important;
  }
}
@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.75rem !important;
  }
  .fs-2 {
    font-size: 2.2rem !important;
  }
  .fs-3 {
    font-size: 1.925rem !important;
  }
  .fs-4 {
    font-size: 1.65rem !important;
  }
  .fs-5 {
    font-size: 1.375rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-grid {
    display: grid !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
  .d-print-none {
    display: none !important;
  }
}
/* Framework Custom Properties (CSS Variables)
–––––––––––––––––––––––––––––––––––––––––––––––––– */
:root {
  /* Bootstrap vars */
  --bs-font-sans-serif: "Hanken Grotesk", sans-serif;
  /* Icon font family */
  --theme-font-icon: "remixicon";
  --theme-font-icon-style: "normal";
  /* Avatar */
  --theme-avatar-size: 90px;
  --theme-breakpoint-xs: 0;
  --theme-breakpoint-sm: 576px;
  --theme-breakpoint-md: 768px;
  --theme-breakpoint-lg: 992px;
  --theme-breakpoint-xl: 1200px;
  --theme-breakpoint-xxl: 1400px;
}

/* Framework Base
–––––––––––––––––––––––––––––––––––––––––––––––––– */
body {
  overflow-x: hidden;
}

.disable-child-pointer * {
  pointer-events: none;
}

/* Custom font face */
@font-face {
  font-family: "Metropolis";
  src: url("../fonts/Metropolis-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: auto;
}
@font-face {
  font-family: "Metropolis";
  src: url("../fonts/Metropolis-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: auto;
}
@font-face {
  font-family: "Metropolis";
  src: url("../fonts/Metropolis-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: auto;
}
@font-face {
  font-family: "Metropolis";
  src: url("../fonts/Metropolis-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: auto;
}
@font-face {
  font-family: "Metropolis";
  src: url("../fonts/Metropolis-Black.woff") format("woff");
  font-weight: 800;
  font-style: normal;
}
/* hanken-grotesk-100 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Hanken Grotesk";
  font-style: normal;
  font-weight: 100;
  src: url("../fonts/hanken-grotesk-v5-latin-100.woff2") format("woff2"), url("../fonts/hanken-grotesk-v5-latin-100.woff") format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* hanken-grotesk-200 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Hanken Grotesk";
  font-style: normal;
  font-weight: 200;
  src: url("../fonts/hanken-grotesk-v5-latin-200.woff2") format("woff2"), url("../fonts/hanken-grotesk-v5-latin-200.woff") format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* hanken-grotesk-300 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Hanken Grotesk";
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/hanken-grotesk-v5-latin-300.woff2") format("woff2"), url("../fonts/hanken-grotesk-v5-latin-300.woff") format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* hanken-grotesk-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Hanken Grotesk";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/hanken-grotesk-v5-latin-regular.woff2") format("woff2"), url("../fonts/hanken-grotesk-v5-latin-regular.woff") format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* hanken-grotesk-500 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Hanken Grotesk";
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/hanken-grotesk-v5-latin-500.woff2") format("woff2"), url("../fonts/hanken-grotesk-v5-latin-500.woff") format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* hanken-grotesk-600 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Hanken Grotesk";
  font-style: normal;
  font-weight: 600;
  src: url("../fonts/hanken-grotesk-v5-latin-600.woff2") format("woff2"), url("../fonts/hanken-grotesk-v5-latin-600.woff") format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* hanken-grotesk-700 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Hanken Grotesk";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/hanken-grotesk-v5-latin-700.woff2") format("woff2"), url("../fonts/hanken-grotesk-v5-latin-700.woff") format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* hanken-grotesk-800 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Hanken Grotesk";
  font-style: normal;
  font-weight: 800;
  src: url("../fonts/hanken-grotesk-v5-latin-800.woff2") format("woff2"), url("../fonts/hanken-grotesk-v5-latin-800.woff") format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* hanken-grotesk-900 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Hanken Grotesk";
  font-style: normal;
  font-weight: 900;
  src: url("../fonts/hanken-grotesk-v5-latin-900.woff2") format("woff2"), url("../fonts/hanken-grotesk-v5-latin-900.woff") format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* Framework Components
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.badge {
  margin-left: 25px;
}

.nav-link {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.dropdown-menu {
  box-shadow: 0 1rem 2.5rem rgba(29, 48, 61, 0.125);
}

.dropdown-toggle {
  position: relative;
}
.dropdown-toggle:after {
  border: 0;
  font-family: var(--theme-font-icon);
  font-style: var(--theme-font-icon-style);
  content: "\ea50";
  margin: 0 !important;
  font-size: 1.15rem;
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
}
.dropdown-toggle.show:after {
  content: "\ea56";
}

.dropdown-toggle {
  padding-right: 1.25rem !important;
}

.dropdown-heading {
  text-transform: uppercase;
  letter-spacing: 0.25em;
  font-size: 80%;
  margin-bottom: 1rem;
  font-weight: 600;
}

@media (max-width: 991.98px) {
  .dropdown-menu {
    box-shadow: none;
  }
  .nav-link {
    padding: 0.5rem 0;
    margin: 0;
  }
  .nav-item .container {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}
@media (min-width: 992px) {
  .dropdown-megamenu {
    width: 100%;
    left: 0;
    right: 0;
    border-radius: 0 !important;
    padding: 0 !important;
    background: #fff;
    margin: 0 !important;
    border: 0 !important;
    border-top: 1px solid #E2E8F0 !important;
    box-shadow: 0 1.5rem 31px -17px rgba(22, 28, 45, 0.08);
  }
  .dropdown-megamenu.show {
    animation: fadeInUp 0.3s;
  }
}
.footer-heading {
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 0.25em;
  opacity: 0.5;
  color: #fff;
  margin-bottom: 1.5rem;
}

.footer-nav li {
  margin-bottom: 0.35rem;
}
.footer-nav a {
  color: rgba(255, 255, 255, 0.85);
  transition: color ease-in 0.3s;
  font-size: 1.025rem;
}
.footer-nav a:hover {
  text-decoration: none;
  color: #91B408;
}

.card-vcard {
  border: 0;
  box-shadow: 0 1rem 2.5rem rgba(29, 48, 61, 0.125);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 3rem;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
}

.vcard-title {
  font-weight: 600;
}

.vcard-subtitle {
  font-size: 80%;
  font-weight: 600;
}

.avatar {
  width: var(--theme-avatar-size);
  height: var(--theme-avatar-size);
  display: block;
}

.pricing-table {
  background: #fff;
  box-shadow: 0 1rem 2.5rem rgba(29, 48, 61, 0.125);
  border: 1px solid #E2E8F0;
  border-radius: 0.375rem;
}

.pricing-table-heading {
  height: 27rem;
  width: 100%;
  padding: 1.5rem;
  border-bottom: 1px solid #E2E8F0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.pricing-table-row {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #E2E8F0;
  height: 5rem;
}

@media (min-width: 768px) {
  .pricing-table-heading {
    padding: 3rem;
  }
  .pricing-table-row {
    padding-left: 4rem;
    padding-right: 4rem;
  }
}
@media (min-width: 992px) {
  .pricing-table-heading {
    padding: 4rem 3.5rem;
  }
}
.accordion-button:after {
  background-image: none;
  font-family: var(--theme-font-icon);
  font-style: var(--theme-font-icon-style);
  content: "\ea13";
}

.accordion-button:not(.collapsed):after {
  background-image: none;
  content: "\f1ae";
}

.marquee {
  overflow-x: hidden;
}

.marquee-body {
  display: flex;
  flex-wrap: nowrap;
  white-space: nowrap;
  min-width: 100%;
}

.marquee-section {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  overflow: hidden;
}

.marquee-hover-pause:hover * {
  animation-play-state: paused;
}

.feature-icon {
  width: 4rem;
  height: 4rem;
  border-radius: 0.75rem;
}

.icon-link > .bi {
  margin-top: 0.125rem;
  margin-left: 0.125rem;
  fill: currentcolor;
  transition: transform 0.25s ease-in-out;
}

.icon-link:hover > .bi {
  transform: translate(0.25rem);
}

.icon-square {
  width: 3rem;
  height: 3rem;
  border-radius: 0.75rem;
}

.text-shadow-1 {
  text-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.25);
}

.text-shadow-2 {
  text-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.25);
}

.text-shadow-3 {
  text-shadow: 0 0.5rem 1.5rem rgba(0, 0, 0, 0.25);
}

.card-cover {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.feature-icon-small {
  width: 3rem;
  height: 3rem;
}

.service-item {
  gap: 0.5rem;
  display: flex;
  flex-direction: column;
}
.service-item .service-icon {
  display: inline-flex;
  fill: #91B408;
  justify-content: flex-start;
  align-items: center;
}
.service-item .service-icon svg {
  width: 3rem;
  height: 3rem;
}
/* Framework Vendor Overrides
–––––––––––––––––––––––––––––––––––––––––––––––––– */
/* AOS */ /* Reveal Animation. */
[data-aos=reveal-right], [data-aos=reveal-left], [data-aos=reveal-up], [data-aos=reveal-down] {
  position: relative;
  overflow: hidden;
}
[data-aos=reveal-right]:after, [data-aos=reveal-left]:after, [data-aos=reveal-up]:after, [data-aos=reveal-down]:after {
  content: "";
  transition-property: transform;
  transition-timing-function: var(--theme-slideout-transition-in);
  transition-duration: 0.5s;
  transition-delay: inherit;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
}

[data-aos=reveal-right].aos-animate:after {
  transform: translateX(100%);
}

[data-aos=reveal-left].aos-animate:after {
  transform: translateX(-100%);
}

[data-aos=reveal-up].aos-animate:after {
  transform: translateY(-100%);
}

[data-aos=reveal-down].aos-animate:after {
  transform: translateY(100%);
}

/* Remix Icons */
/*
* Remix Icon v2.5.0
* https://remixicon.com
* https://github.com/Remix-Design/RemixIcon
*
* Copyright RemixIcon.com
* Released under the Apache License Version 2.0
*
* Date: 2020-05-23
*/
@font-face {
  font-family: "remixicon";
  src: url("../fonts/remixicon.woff") format("woff");
  font-display: auto;
}
[class^=ri-], [class*=" ri-"] {
  font-family: "remixicon" !important;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ri-lg {
  font-size: 1.3333em;
  line-height: 0.75em;
  vertical-align: -0.0667em;
}

.ri-xl {
  font-size: 1.5em;
  line-height: 0.6666em;
  vertical-align: -0.075em;
}

.ri-xxs {
  font-size: 0.5em;
}

.ri-xs {
  font-size: 0.75em;
}

.ri-sm {
  font-size: 0.875em;
}

.ri-1x {
  font-size: 1em;
}

.ri-2x {
  font-size: 2em;
}

.ri-3x {
  font-size: 3em;
}

.ri-4x {
  font-size: 4em;
}

.ri-5x {
  font-size: 5em;
}

.ri-6x {
  font-size: 6em;
}

.ri-7x {
  font-size: 7em;
}

.ri-8x {
  font-size: 8em;
}

.ri-9x {
  font-size: 9em;
}

.ri-10x {
  font-size: 10em;
}

.ri-fw {
  text-align: center;
  width: 1.25em;
}

.ri-24-hours-fill:before {
  content: "\ea01";
}

.ri-24-hours-line:before {
  content: "\ea02";
}

.ri-4k-fill:before {
  content: "\ea03";
}

.ri-4k-line:before {
  content: "\ea04";
}

.ri-a-b:before {
  content: "\ea05";
}

.ri-account-box-fill:before {
  content: "\ea06";
}

.ri-account-box-line:before {
  content: "\ea07";
}

.ri-account-circle-fill:before {
  content: "\ea08";
}

.ri-account-circle-line:before {
  content: "\ea09";
}

.ri-account-pin-box-fill:before {
  content: "\ea0a";
}

.ri-account-pin-box-line:before {
  content: "\ea0b";
}

.ri-account-pin-circle-fill:before {
  content: "\ea0c";
}

.ri-account-pin-circle-line:before {
  content: "\ea0d";
}

.ri-add-box-fill:before {
  content: "\ea0e";
}

.ri-add-box-line:before {
  content: "\ea0f";
}

.ri-add-circle-fill:before {
  content: "\ea10";
}

.ri-add-circle-line:before {
  content: "\ea11";
}

.ri-add-fill:before {
  content: "\ea12";
}

.ri-add-line:before {
  content: "\ea13";
}

.ri-admin-fill:before {
  content: "\ea14";
}

.ri-admin-line:before {
  content: "\ea15";
}

.ri-advertisement-fill:before {
  content: "\ea16";
}

.ri-advertisement-line:before {
  content: "\ea17";
}

.ri-airplay-fill:before {
  content: "\ea18";
}

.ri-airplay-line:before {
  content: "\ea19";
}

.ri-alarm-fill:before {
  content: "\ea1a";
}

.ri-alarm-line:before {
  content: "\ea1b";
}

.ri-alarm-warning-fill:before {
  content: "\ea1c";
}

.ri-alarm-warning-line:before {
  content: "\ea1d";
}

.ri-album-fill:before {
  content: "\ea1e";
}

.ri-album-line:before {
  content: "\ea1f";
}

.ri-alert-fill:before {
  content: "\ea20";
}

.ri-alert-line:before {
  content: "\ea21";
}

.ri-aliens-fill:before {
  content: "\ea22";
}

.ri-aliens-line:before {
  content: "\ea23";
}

.ri-align-bottom:before {
  content: "\ea24";
}

.ri-align-center:before {
  content: "\ea25";
}

.ri-align-justify:before {
  content: "\ea26";
}

.ri-align-left:before {
  content: "\ea27";
}

.ri-align-right:before {
  content: "\ea28";
}

.ri-align-top:before {
  content: "\ea29";
}

.ri-align-vertically:before {
  content: "\ea2a";
}

.ri-alipay-fill:before {
  content: "\ea2b";
}

.ri-alipay-line:before {
  content: "\ea2c";
}

.ri-amazon-fill:before {
  content: "\ea2d";
}

.ri-amazon-line:before {
  content: "\ea2e";
}

.ri-anchor-fill:before {
  content: "\ea2f";
}

.ri-anchor-line:before {
  content: "\ea30";
}

.ri-ancient-gate-fill:before {
  content: "\ea31";
}

.ri-ancient-gate-line:before {
  content: "\ea32";
}

.ri-ancient-pavilion-fill:before {
  content: "\ea33";
}

.ri-ancient-pavilion-line:before {
  content: "\ea34";
}

.ri-android-fill:before {
  content: "\ea35";
}

.ri-android-line:before {
  content: "\ea36";
}

.ri-angularjs-fill:before {
  content: "\ea37";
}

.ri-angularjs-line:before {
  content: "\ea38";
}

.ri-anticlockwise-2-fill:before {
  content: "\ea39";
}

.ri-anticlockwise-2-line:before {
  content: "\ea3a";
}

.ri-anticlockwise-fill:before {
  content: "\ea3b";
}

.ri-anticlockwise-line:before {
  content: "\ea3c";
}

.ri-app-store-fill:before {
  content: "\ea3d";
}

.ri-app-store-line:before {
  content: "\ea3e";
}

.ri-apple-fill:before {
  content: "\ea3f";
}

.ri-apple-line:before {
  content: "\ea40";
}

.ri-apps-2-fill:before {
  content: "\ea41";
}

.ri-apps-2-line:before {
  content: "\ea42";
}

.ri-apps-fill:before {
  content: "\ea43";
}

.ri-apps-line:before {
  content: "\ea44";
}

.ri-archive-drawer-fill:before {
  content: "\ea45";
}

.ri-archive-drawer-line:before {
  content: "\ea46";
}

.ri-archive-fill:before {
  content: "\ea47";
}

.ri-archive-line:before {
  content: "\ea48";
}

.ri-arrow-down-circle-fill:before {
  content: "\ea49";
}

.ri-arrow-down-circle-line:before {
  content: "\ea4a";
}

.ri-arrow-down-fill:before {
  content: "\ea4b";
}

.ri-arrow-down-line:before {
  content: "\ea4c";
}

.ri-arrow-down-s-fill:before {
  content: "\ea4d";
}

.ri-arrow-down-s-line:before {
  content: "\ea4e";
}

.ri-arrow-drop-down-fill:before {
  content: "\ea4f";
}

.ri-arrow-drop-down-line:before {
  content: "\ea50";
}

.ri-arrow-drop-left-fill:before {
  content: "\ea51";
}

.ri-arrow-drop-left-line:before {
  content: "\ea52";
}

.ri-arrow-drop-right-fill:before {
  content: "\ea53";
}

.ri-arrow-drop-right-line:before {
  content: "\ea54";
}

.ri-arrow-drop-up-fill:before {
  content: "\ea55";
}

.ri-arrow-drop-up-line:before {
  content: "\ea56";
}

.ri-arrow-go-back-fill:before {
  content: "\ea57";
}

.ri-arrow-go-back-line:before {
  content: "\ea58";
}

.ri-arrow-go-forward-fill:before {
  content: "\ea59";
}

.ri-arrow-go-forward-line:before {
  content: "\ea5a";
}

.ri-arrow-left-circle-fill:before {
  content: "\ea5b";
}

.ri-arrow-left-circle-line:before {
  content: "\ea5c";
}

.ri-arrow-left-down-fill:before {
  content: "\ea5d";
}

.ri-arrow-left-down-line:before {
  content: "\ea5e";
}

.ri-arrow-left-fill:before {
  content: "\ea5f";
}

.ri-arrow-left-line:before {
  content: "\ea60";
}

.ri-arrow-left-right-fill:before {
  content: "\ea61";
}

.ri-arrow-left-right-line:before {
  content: "\ea62";
}

.ri-arrow-left-s-fill:before {
  content: "\ea63";
}

.ri-arrow-left-s-line:before {
  content: "\ea64";
}

.ri-arrow-left-up-fill:before {
  content: "\ea65";
}

.ri-arrow-left-up-line:before {
  content: "\ea66";
}

.ri-arrow-right-circle-fill:before {
  content: "\ea67";
}

.ri-arrow-right-circle-line:before {
  content: "\ea68";
}

.ri-arrow-right-down-fill:before {
  content: "\ea69";
}

.ri-arrow-right-down-line:before {
  content: "\ea6a";
}

.ri-arrow-right-fill:before {
  content: "\ea6b";
}

.ri-arrow-right-line:before {
  content: "\ea6c";
}

.ri-arrow-right-s-fill:before {
  content: "\ea6d";
}

.ri-arrow-right-s-line:before {
  content: "\ea6e";
}

.ri-arrow-right-up-fill:before {
  content: "\ea6f";
}

.ri-arrow-right-up-line:before {
  content: "\ea70";
}

.ri-arrow-up-circle-fill:before {
  content: "\ea71";
}

.ri-arrow-up-circle-line:before {
  content: "\ea72";
}

.ri-arrow-up-down-fill:before {
  content: "\ea73";
}

.ri-arrow-up-down-line:before {
  content: "\ea74";
}

.ri-arrow-up-fill:before {
  content: "\ea75";
}

.ri-arrow-up-line:before {
  content: "\ea76";
}

.ri-arrow-up-s-fill:before {
  content: "\ea77";
}

.ri-arrow-up-s-line:before {
  content: "\ea78";
}

.ri-artboard-2-fill:before {
  content: "\ea79";
}

.ri-artboard-2-line:before {
  content: "\ea7a";
}

.ri-artboard-fill:before {
  content: "\ea7b";
}

.ri-artboard-line:before {
  content: "\ea7c";
}

.ri-article-fill:before {
  content: "\ea7d";
}

.ri-article-line:before {
  content: "\ea7e";
}

.ri-aspect-ratio-fill:before {
  content: "\ea7f";
}

.ri-aspect-ratio-line:before {
  content: "\ea80";
}

.ri-asterisk:before {
  content: "\ea81";
}

.ri-at-fill:before {
  content: "\ea82";
}

.ri-at-line:before {
  content: "\ea83";
}

.ri-attachment-2:before {
  content: "\ea84";
}

.ri-attachment-fill:before {
  content: "\ea85";
}

.ri-attachment-line:before {
  content: "\ea86";
}

.ri-auction-fill:before {
  content: "\ea87";
}

.ri-auction-line:before {
  content: "\ea88";
}

.ri-award-fill:before {
  content: "\ea89";
}

.ri-award-line:before {
  content: "\ea8a";
}

.ri-baidu-fill:before {
  content: "\ea8b";
}

.ri-baidu-line:before {
  content: "\ea8c";
}

.ri-ball-pen-fill:before {
  content: "\ea8d";
}

.ri-ball-pen-line:before {
  content: "\ea8e";
}

.ri-bank-card-2-fill:before {
  content: "\ea8f";
}

.ri-bank-card-2-line:before {
  content: "\ea90";
}

.ri-bank-card-fill:before {
  content: "\ea91";
}

.ri-bank-card-line:before {
  content: "\ea92";
}

.ri-bank-fill:before {
  content: "\ea93";
}

.ri-bank-line:before {
  content: "\ea94";
}

.ri-bar-chart-2-fill:before {
  content: "\ea95";
}

.ri-bar-chart-2-line:before {
  content: "\ea96";
}

.ri-bar-chart-box-fill:before {
  content: "\ea97";
}

.ri-bar-chart-box-line:before {
  content: "\ea98";
}

.ri-bar-chart-fill:before {
  content: "\ea99";
}

.ri-bar-chart-grouped-fill:before {
  content: "\ea9a";
}

.ri-bar-chart-grouped-line:before {
  content: "\ea9b";
}

.ri-bar-chart-horizontal-fill:before {
  content: "\ea9c";
}

.ri-bar-chart-horizontal-line:before {
  content: "\ea9d";
}

.ri-bar-chart-line:before {
  content: "\ea9e";
}

.ri-barcode-box-fill:before {
  content: "\ea9f";
}

.ri-barcode-box-line:before {
  content: "\eaa0";
}

.ri-barcode-fill:before {
  content: "\eaa1";
}

.ri-barcode-line:before {
  content: "\eaa2";
}

.ri-barricade-fill:before {
  content: "\eaa3";
}

.ri-barricade-line:before {
  content: "\eaa4";
}

.ri-base-station-fill:before {
  content: "\eaa5";
}

.ri-base-station-line:before {
  content: "\eaa6";
}

.ri-basketball-fill:before {
  content: "\eaa7";
}

.ri-basketball-line:before {
  content: "\eaa8";
}

.ri-battery-2-charge-fill:before {
  content: "\eaa9";
}

.ri-battery-2-charge-line:before {
  content: "\eaaa";
}

.ri-battery-2-fill:before {
  content: "\eaab";
}

.ri-battery-2-line:before {
  content: "\eaac";
}

.ri-battery-charge-fill:before {
  content: "\eaad";
}

.ri-battery-charge-line:before {
  content: "\eaae";
}

.ri-battery-fill:before {
  content: "\eaaf";
}

.ri-battery-line:before {
  content: "\eab0";
}

.ri-battery-low-fill:before {
  content: "\eab1";
}

.ri-battery-low-line:before {
  content: "\eab2";
}

.ri-battery-saver-fill:before {
  content: "\eab3";
}

.ri-battery-saver-line:before {
  content: "\eab4";
}

.ri-battery-share-fill:before {
  content: "\eab5";
}

.ri-battery-share-line:before {
  content: "\eab6";
}

.ri-bear-smile-fill:before {
  content: "\eab7";
}

.ri-bear-smile-line:before {
  content: "\eab8";
}

.ri-behance-fill:before {
  content: "\eab9";
}

.ri-behance-line:before {
  content: "\eaba";
}

.ri-bell-fill:before {
  content: "\eabb";
}

.ri-bell-line:before {
  content: "\eabc";
}

.ri-bike-fill:before {
  content: "\eabd";
}

.ri-bike-line:before {
  content: "\eabe";
}

.ri-bilibili-fill:before {
  content: "\eabf";
}

.ri-bilibili-line:before {
  content: "\eac0";
}

.ri-bill-fill:before {
  content: "\eac1";
}

.ri-bill-line:before {
  content: "\eac2";
}

.ri-billiards-fill:before {
  content: "\eac3";
}

.ri-billiards-line:before {
  content: "\eac4";
}

.ri-bit-coin-fill:before {
  content: "\eac5";
}

.ri-bit-coin-line:before {
  content: "\eac6";
}

.ri-blaze-fill:before {
  content: "\eac7";
}

.ri-blaze-line:before {
  content: "\eac8";
}

.ri-bluetooth-connect-fill:before {
  content: "\eac9";
}

.ri-bluetooth-connect-line:before {
  content: "\eaca";
}

.ri-bluetooth-fill:before {
  content: "\eacb";
}

.ri-bluetooth-line:before {
  content: "\eacc";
}

.ri-blur-off-fill:before {
  content: "\eacd";
}

.ri-blur-off-line:before {
  content: "\eace";
}

.ri-body-scan-fill:before {
  content: "\eacf";
}

.ri-body-scan-line:before {
  content: "\ead0";
}

.ri-bold:before {
  content: "\ead1";
}

.ri-book-2-fill:before {
  content: "\ead2";
}

.ri-book-2-line:before {
  content: "\ead3";
}

.ri-book-3-fill:before {
  content: "\ead4";
}

.ri-book-3-line:before {
  content: "\ead5";
}

.ri-book-fill:before {
  content: "\ead6";
}

.ri-book-line:before {
  content: "\ead7";
}

.ri-book-mark-fill:before {
  content: "\ead8";
}

.ri-book-mark-line:before {
  content: "\ead9";
}

.ri-book-open-fill:before {
  content: "\eada";
}

.ri-book-open-line:before {
  content: "\eadb";
}

.ri-book-read-fill:before {
  content: "\eadc";
}

.ri-book-read-line:before {
  content: "\eadd";
}

.ri-booklet-fill:before {
  content: "\eade";
}

.ri-booklet-line:before {
  content: "\eadf";
}

.ri-bookmark-2-fill:before {
  content: "\eae0";
}

.ri-bookmark-2-line:before {
  content: "\eae1";
}

.ri-bookmark-3-fill:before {
  content: "\eae2";
}

.ri-bookmark-3-line:before {
  content: "\eae3";
}

.ri-bookmark-fill:before {
  content: "\eae4";
}

.ri-bookmark-line:before {
  content: "\eae5";
}

.ri-boxing-fill:before {
  content: "\eae6";
}

.ri-boxing-line:before {
  content: "\eae7";
}

.ri-braces-fill:before {
  content: "\eae8";
}

.ri-braces-line:before {
  content: "\eae9";
}

.ri-brackets-fill:before {
  content: "\eaea";
}

.ri-brackets-line:before {
  content: "\eaeb";
}

.ri-briefcase-2-fill:before {
  content: "\eaec";
}

.ri-briefcase-2-line:before {
  content: "\eaed";
}

.ri-briefcase-3-fill:before {
  content: "\eaee";
}

.ri-briefcase-3-line:before {
  content: "\eaef";
}

.ri-briefcase-4-fill:before {
  content: "\eaf0";
}

.ri-briefcase-4-line:before {
  content: "\eaf1";
}

.ri-briefcase-5-fill:before {
  content: "\eaf2";
}

.ri-briefcase-5-line:before {
  content: "\eaf3";
}

.ri-briefcase-fill:before {
  content: "\eaf4";
}

.ri-briefcase-line:before {
  content: "\eaf5";
}

.ri-bring-forward:before {
  content: "\eaf6";
}

.ri-bring-to-front:before {
  content: "\eaf7";
}

.ri-broadcast-fill:before {
  content: "\eaf8";
}

.ri-broadcast-line:before {
  content: "\eaf9";
}

.ri-brush-2-fill:before {
  content: "\eafa";
}

.ri-brush-2-line:before {
  content: "\eafb";
}

.ri-brush-3-fill:before {
  content: "\eafc";
}

.ri-brush-3-line:before {
  content: "\eafd";
}

.ri-brush-4-fill:before {
  content: "\eafe";
}

.ri-brush-4-line:before {
  content: "\eaff";
}

.ri-brush-fill:before {
  content: "\eb00";
}

.ri-brush-line:before {
  content: "\eb01";
}

.ri-bubble-chart-fill:before {
  content: "\eb02";
}

.ri-bubble-chart-line:before {
  content: "\eb03";
}

.ri-bug-2-fill:before {
  content: "\eb04";
}

.ri-bug-2-line:before {
  content: "\eb05";
}

.ri-bug-fill:before {
  content: "\eb06";
}

.ri-bug-line:before {
  content: "\eb07";
}

.ri-building-2-fill:before {
  content: "\eb08";
}

.ri-building-2-line:before {
  content: "\eb09";
}

.ri-building-3-fill:before {
  content: "\eb0a";
}

.ri-building-3-line:before {
  content: "\eb0b";
}

.ri-building-4-fill:before {
  content: "\eb0c";
}

.ri-building-4-line:before {
  content: "\eb0d";
}

.ri-building-fill:before {
  content: "\eb0e";
}

.ri-building-line:before {
  content: "\eb0f";
}

.ri-bus-2-fill:before {
  content: "\eb10";
}

.ri-bus-2-line:before {
  content: "\eb11";
}

.ri-bus-fill:before {
  content: "\eb12";
}

.ri-bus-line:before {
  content: "\eb13";
}

.ri-bus-wifi-fill:before {
  content: "\eb14";
}

.ri-bus-wifi-line:before {
  content: "\eb15";
}

.ri-cactus-fill:before {
  content: "\eb16";
}

.ri-cactus-line:before {
  content: "\eb17";
}

.ri-cake-2-fill:before {
  content: "\eb18";
}

.ri-cake-2-line:before {
  content: "\eb19";
}

.ri-cake-3-fill:before {
  content: "\eb1a";
}

.ri-cake-3-line:before {
  content: "\eb1b";
}

.ri-cake-fill:before {
  content: "\eb1c";
}

.ri-cake-line:before {
  content: "\eb1d";
}

.ri-calculator-fill:before {
  content: "\eb1e";
}

.ri-calculator-line:before {
  content: "\eb1f";
}

.ri-calendar-2-fill:before {
  content: "\eb20";
}

.ri-calendar-2-line:before {
  content: "\eb21";
}

.ri-calendar-check-fill:before {
  content: "\eb22";
}

.ri-calendar-check-line:before {
  content: "\eb23";
}

.ri-calendar-event-fill:before {
  content: "\eb24";
}

.ri-calendar-event-line:before {
  content: "\eb25";
}

.ri-calendar-fill:before {
  content: "\eb26";
}

.ri-calendar-line:before {
  content: "\eb27";
}

.ri-calendar-todo-fill:before {
  content: "\eb28";
}

.ri-calendar-todo-line:before {
  content: "\eb29";
}

.ri-camera-2-fill:before {
  content: "\eb2a";
}

.ri-camera-2-line:before {
  content: "\eb2b";
}

.ri-camera-3-fill:before {
  content: "\eb2c";
}

.ri-camera-3-line:before {
  content: "\eb2d";
}

.ri-camera-fill:before {
  content: "\eb2e";
}

.ri-camera-lens-fill:before {
  content: "\eb2f";
}

.ri-camera-lens-line:before {
  content: "\eb30";
}

.ri-camera-line:before {
  content: "\eb31";
}

.ri-camera-off-fill:before {
  content: "\eb32";
}

.ri-camera-off-line:before {
  content: "\eb33";
}

.ri-camera-switch-fill:before {
  content: "\eb34";
}

.ri-camera-switch-line:before {
  content: "\eb35";
}

.ri-capsule-fill:before {
  content: "\eb36";
}

.ri-capsule-line:before {
  content: "\eb37";
}

.ri-car-fill:before {
  content: "\eb38";
}

.ri-car-line:before {
  content: "\eb39";
}

.ri-car-washing-fill:before {
  content: "\eb3a";
}

.ri-car-washing-line:before {
  content: "\eb3b";
}

.ri-caravan-fill:before {
  content: "\eb3c";
}

.ri-caravan-line:before {
  content: "\eb3d";
}

.ri-cast-fill:before {
  content: "\eb3e";
}

.ri-cast-line:before {
  content: "\eb3f";
}

.ri-cellphone-fill:before {
  content: "\eb40";
}

.ri-cellphone-line:before {
  content: "\eb41";
}

.ri-celsius-fill:before {
  content: "\eb42";
}

.ri-celsius-line:before {
  content: "\eb43";
}

.ri-centos-fill:before {
  content: "\eb44";
}

.ri-centos-line:before {
  content: "\eb45";
}

.ri-character-recognition-fill:before {
  content: "\eb46";
}

.ri-character-recognition-line:before {
  content: "\eb47";
}

.ri-charging-pile-2-fill:before {
  content: "\eb48";
}

.ri-charging-pile-2-line:before {
  content: "\eb49";
}

.ri-charging-pile-fill:before {
  content: "\eb4a";
}

.ri-charging-pile-line:before {
  content: "\eb4b";
}

.ri-chat-1-fill:before {
  content: "\eb4c";
}

.ri-chat-1-line:before {
  content: "\eb4d";
}

.ri-chat-2-fill:before {
  content: "\eb4e";
}

.ri-chat-2-line:before {
  content: "\eb4f";
}

.ri-chat-3-fill:before {
  content: "\eb50";
}

.ri-chat-3-line:before {
  content: "\eb51";
}

.ri-chat-4-fill:before {
  content: "\eb52";
}

.ri-chat-4-line:before {
  content: "\eb53";
}

.ri-chat-check-fill:before {
  content: "\eb54";
}

.ri-chat-check-line:before {
  content: "\eb55";
}

.ri-chat-delete-fill:before {
  content: "\eb56";
}

.ri-chat-delete-line:before {
  content: "\eb57";
}

.ri-chat-download-fill:before {
  content: "\eb58";
}

.ri-chat-download-line:before {
  content: "\eb59";
}

.ri-chat-follow-up-fill:before {
  content: "\eb5a";
}

.ri-chat-follow-up-line:before {
  content: "\eb5b";
}

.ri-chat-forward-fill:before {
  content: "\eb5c";
}

.ri-chat-forward-line:before {
  content: "\eb5d";
}

.ri-chat-heart-fill:before {
  content: "\eb5e";
}

.ri-chat-heart-line:before {
  content: "\eb5f";
}

.ri-chat-history-fill:before {
  content: "\eb60";
}

.ri-chat-history-line:before {
  content: "\eb61";
}

.ri-chat-new-fill:before {
  content: "\eb62";
}

.ri-chat-new-line:before {
  content: "\eb63";
}

.ri-chat-off-fill:before {
  content: "\eb64";
}

.ri-chat-off-line:before {
  content: "\eb65";
}

.ri-chat-poll-fill:before {
  content: "\eb66";
}

.ri-chat-poll-line:before {
  content: "\eb67";
}

.ri-chat-private-fill:before {
  content: "\eb68";
}

.ri-chat-private-line:before {
  content: "\eb69";
}

.ri-chat-quote-fill:before {
  content: "\eb6a";
}

.ri-chat-quote-line:before {
  content: "\eb6b";
}

.ri-chat-settings-fill:before {
  content: "\eb6c";
}

.ri-chat-settings-line:before {
  content: "\eb6d";
}

.ri-chat-smile-2-fill:before {
  content: "\eb6e";
}

.ri-chat-smile-2-line:before {
  content: "\eb6f";
}

.ri-chat-smile-3-fill:before {
  content: "\eb70";
}

.ri-chat-smile-3-line:before {
  content: "\eb71";
}

.ri-chat-smile-fill:before {
  content: "\eb72";
}

.ri-chat-smile-line:before {
  content: "\eb73";
}

.ri-chat-upload-fill:before {
  content: "\eb74";
}

.ri-chat-upload-line:before {
  content: "\eb75";
}

.ri-chat-voice-fill:before {
  content: "\eb76";
}

.ri-chat-voice-line:before {
  content: "\eb77";
}

.ri-check-double-fill:before {
  content: "\eb78";
}

.ri-check-double-line:before {
  content: "\eb79";
}

.ri-check-fill:before {
  content: "\eb7a";
}

.ri-check-line:before {
  content: "\eb7b";
}

.ri-checkbox-blank-circle-fill:before {
  content: "\eb7c";
}

.ri-checkbox-blank-circle-line:before {
  content: "\eb7d";
}

.ri-checkbox-blank-fill:before {
  content: "\eb7e";
}

.ri-checkbox-blank-line:before {
  content: "\eb7f";
}

.ri-checkbox-circle-fill:before {
  content: "\eb80";
}

.ri-checkbox-circle-line:before {
  content: "\eb81";
}

.ri-checkbox-fill:before {
  content: "\eb82";
}

.ri-checkbox-indeterminate-fill:before {
  content: "\eb83";
}

.ri-checkbox-indeterminate-line:before {
  content: "\eb84";
}

.ri-checkbox-line:before {
  content: "\eb85";
}

.ri-checkbox-multiple-blank-fill:before {
  content: "\eb86";
}

.ri-checkbox-multiple-blank-line:before {
  content: "\eb87";
}

.ri-checkbox-multiple-fill:before {
  content: "\eb88";
}

.ri-checkbox-multiple-line:before {
  content: "\eb89";
}

.ri-china-railway-fill:before {
  content: "\eb8a";
}

.ri-china-railway-line:before {
  content: "\eb8b";
}

.ri-chrome-fill:before {
  content: "\eb8c";
}

.ri-chrome-line:before {
  content: "\eb8d";
}

.ri-clapperboard-fill:before {
  content: "\eb8e";
}

.ri-clapperboard-line:before {
  content: "\eb8f";
}

.ri-clipboard-fill:before {
  content: "\eb90";
}

.ri-clipboard-line:before {
  content: "\eb91";
}

.ri-clockwise-2-fill:before {
  content: "\eb92";
}

.ri-clockwise-2-line:before {
  content: "\eb93";
}

.ri-clockwise-fill:before {
  content: "\eb94";
}

.ri-clockwise-line:before {
  content: "\eb95";
}

.ri-close-circle-fill:before {
  content: "\eb96";
}

.ri-close-circle-line:before {
  content: "\eb97";
}

.ri-close-fill:before {
  content: "\eb98";
}

.ri-close-line:before {
  content: "\eb99";
}

.ri-closed-captioning-fill:before {
  content: "\eb9a";
}

.ri-closed-captioning-line:before {
  content: "\eb9b";
}

.ri-cloud-fill:before {
  content: "\eb9c";
}

.ri-cloud-line:before {
  content: "\eb9d";
}

.ri-cloud-off-fill:before {
  content: "\eb9e";
}

.ri-cloud-off-line:before {
  content: "\eb9f";
}

.ri-cloud-windy-fill:before {
  content: "\eba0";
}

.ri-cloud-windy-line:before {
  content: "\eba1";
}

.ri-cloudy-2-fill:before {
  content: "\eba2";
}

.ri-cloudy-2-line:before {
  content: "\eba3";
}

.ri-cloudy-fill:before {
  content: "\eba4";
}

.ri-cloudy-line:before {
  content: "\eba5";
}

.ri-code-box-fill:before {
  content: "\eba6";
}

.ri-code-box-line:before {
  content: "\eba7";
}

.ri-code-fill:before {
  content: "\eba8";
}

.ri-code-line:before {
  content: "\eba9";
}

.ri-code-s-fill:before {
  content: "\ebaa";
}

.ri-code-s-line:before {
  content: "\ebab";
}

.ri-code-s-slash-fill:before {
  content: "\ebac";
}

.ri-code-s-slash-line:before {
  content: "\ebad";
}

.ri-code-view:before {
  content: "\ebae";
}

.ri-codepen-fill:before {
  content: "\ebaf";
}

.ri-codepen-line:before {
  content: "\ebb0";
}

.ri-coin-fill:before {
  content: "\ebb1";
}

.ri-coin-line:before {
  content: "\ebb2";
}

.ri-coins-fill:before {
  content: "\ebb3";
}

.ri-coins-line:before {
  content: "\ebb4";
}

.ri-collage-fill:before {
  content: "\ebb5";
}

.ri-collage-line:before {
  content: "\ebb6";
}

.ri-command-fill:before {
  content: "\ebb7";
}

.ri-command-line:before {
  content: "\ebb8";
}

.ri-community-fill:before {
  content: "\ebb9";
}

.ri-community-line:before {
  content: "\ebba";
}

.ri-compass-2-fill:before {
  content: "\ebbb";
}

.ri-compass-2-line:before {
  content: "\ebbc";
}

.ri-compass-3-fill:before {
  content: "\ebbd";
}

.ri-compass-3-line:before {
  content: "\ebbe";
}

.ri-compass-4-fill:before {
  content: "\ebbf";
}

.ri-compass-4-line:before {
  content: "\ebc0";
}

.ri-compass-discover-fill:before {
  content: "\ebc1";
}

.ri-compass-discover-line:before {
  content: "\ebc2";
}

.ri-compass-fill:before {
  content: "\ebc3";
}

.ri-compass-line:before {
  content: "\ebc4";
}

.ri-compasses-2-fill:before {
  content: "\ebc5";
}

.ri-compasses-2-line:before {
  content: "\ebc6";
}

.ri-compasses-fill:before {
  content: "\ebc7";
}

.ri-compasses-line:before {
  content: "\ebc8";
}

.ri-computer-fill:before {
  content: "\ebc9";
}

.ri-computer-line:before {
  content: "\ebca";
}

.ri-contacts-book-2-fill:before {
  content: "\ebcb";
}

.ri-contacts-book-2-line:before {
  content: "\ebcc";
}

.ri-contacts-book-fill:before {
  content: "\ebcd";
}

.ri-contacts-book-line:before {
  content: "\ebce";
}

.ri-contacts-book-upload-fill:before {
  content: "\ebcf";
}

.ri-contacts-book-upload-line:before {
  content: "\ebd0";
}

.ri-contacts-fill:before {
  content: "\ebd1";
}

.ri-contacts-line:before {
  content: "\ebd2";
}

.ri-contrast-2-fill:before {
  content: "\ebd3";
}

.ri-contrast-2-line:before {
  content: "\ebd4";
}

.ri-contrast-drop-2-fill:before {
  content: "\ebd5";
}

.ri-contrast-drop-2-line:before {
  content: "\ebd6";
}

.ri-contrast-drop-fill:before {
  content: "\ebd7";
}

.ri-contrast-drop-line:before {
  content: "\ebd8";
}

.ri-contrast-fill:before {
  content: "\ebd9";
}

.ri-contrast-line:before {
  content: "\ebda";
}

.ri-copper-coin-fill:before {
  content: "\ebdb";
}

.ri-copper-coin-line:before {
  content: "\ebdc";
}

.ri-copper-diamond-fill:before {
  content: "\ebdd";
}

.ri-copper-diamond-line:before {
  content: "\ebde";
}

.ri-copyleft-fill:before {
  content: "\ebdf";
}

.ri-copyleft-line:before {
  content: "\ebe0";
}

.ri-copyright-fill:before {
  content: "\ebe1";
}

.ri-copyright-line:before {
  content: "\ebe2";
}

.ri-coreos-fill:before {
  content: "\ebe3";
}

.ri-coreos-line:before {
  content: "\ebe4";
}

.ri-coupon-2-fill:before {
  content: "\ebe5";
}

.ri-coupon-2-line:before {
  content: "\ebe6";
}

.ri-coupon-3-fill:before {
  content: "\ebe7";
}

.ri-coupon-3-line:before {
  content: "\ebe8";
}

.ri-coupon-4-fill:before {
  content: "\ebe9";
}

.ri-coupon-4-line:before {
  content: "\ebea";
}

.ri-coupon-5-fill:before {
  content: "\ebeb";
}

.ri-coupon-5-line:before {
  content: "\ebec";
}

.ri-coupon-fill:before {
  content: "\ebed";
}

.ri-coupon-line:before {
  content: "\ebee";
}

.ri-cpu-fill:before {
  content: "\ebef";
}

.ri-cpu-line:before {
  content: "\ebf0";
}

.ri-creative-commons-by-fill:before {
  content: "\ebf1";
}

.ri-creative-commons-by-line:before {
  content: "\ebf2";
}

.ri-creative-commons-fill:before {
  content: "\ebf3";
}

.ri-creative-commons-line:before {
  content: "\ebf4";
}

.ri-creative-commons-nc-fill:before {
  content: "\ebf5";
}

.ri-creative-commons-nc-line:before {
  content: "\ebf6";
}

.ri-creative-commons-nd-fill:before {
  content: "\ebf7";
}

.ri-creative-commons-nd-line:before {
  content: "\ebf8";
}

.ri-creative-commons-sa-fill:before {
  content: "\ebf9";
}

.ri-creative-commons-sa-line:before {
  content: "\ebfa";
}

.ri-creative-commons-zero-fill:before {
  content: "\ebfb";
}

.ri-creative-commons-zero-line:before {
  content: "\ebfc";
}

.ri-criminal-fill:before {
  content: "\ebfd";
}

.ri-criminal-line:before {
  content: "\ebfe";
}

.ri-crop-2-fill:before {
  content: "\ebff";
}

.ri-crop-2-line:before {
  content: "\ec00";
}

.ri-crop-fill:before {
  content: "\ec01";
}

.ri-crop-line:before {
  content: "\ec02";
}

.ri-css3-fill:before {
  content: "\ec03";
}

.ri-css3-line:before {
  content: "\ec04";
}

.ri-cup-fill:before {
  content: "\ec05";
}

.ri-cup-line:before {
  content: "\ec06";
}

.ri-currency-fill:before {
  content: "\ec07";
}

.ri-currency-line:before {
  content: "\ec08";
}

.ri-cursor-fill:before {
  content: "\ec09";
}

.ri-cursor-line:before {
  content: "\ec0a";
}

.ri-customer-service-2-fill:before {
  content: "\ec0b";
}

.ri-customer-service-2-line:before {
  content: "\ec0c";
}

.ri-customer-service-fill:before {
  content: "\ec0d";
}

.ri-customer-service-line:before {
  content: "\ec0e";
}

.ri-dashboard-2-fill:before {
  content: "\ec0f";
}

.ri-dashboard-2-line:before {
  content: "\ec10";
}

.ri-dashboard-3-fill:before {
  content: "\ec11";
}

.ri-dashboard-3-line:before {
  content: "\ec12";
}

.ri-dashboard-fill:before {
  content: "\ec13";
}

.ri-dashboard-line:before {
  content: "\ec14";
}

.ri-database-2-fill:before {
  content: "\ec15";
}

.ri-database-2-line:before {
  content: "\ec16";
}

.ri-database-fill:before {
  content: "\ec17";
}

.ri-database-line:before {
  content: "\ec18";
}

.ri-delete-back-2-fill:before {
  content: "\ec19";
}

.ri-delete-back-2-line:before {
  content: "\ec1a";
}

.ri-delete-back-fill:before {
  content: "\ec1b";
}

.ri-delete-back-line:before {
  content: "\ec1c";
}

.ri-delete-bin-2-fill:before {
  content: "\ec1d";
}

.ri-delete-bin-2-line:before {
  content: "\ec1e";
}

.ri-delete-bin-3-fill:before {
  content: "\ec1f";
}

.ri-delete-bin-3-line:before {
  content: "\ec20";
}

.ri-delete-bin-4-fill:before {
  content: "\ec21";
}

.ri-delete-bin-4-line:before {
  content: "\ec22";
}

.ri-delete-bin-5-fill:before {
  content: "\ec23";
}

.ri-delete-bin-5-line:before {
  content: "\ec24";
}

.ri-delete-bin-6-fill:before {
  content: "\ec25";
}

.ri-delete-bin-6-line:before {
  content: "\ec26";
}

.ri-delete-bin-7-fill:before {
  content: "\ec27";
}

.ri-delete-bin-7-line:before {
  content: "\ec28";
}

.ri-delete-bin-fill:before {
  content: "\ec29";
}

.ri-delete-bin-line:before {
  content: "\ec2a";
}

.ri-delete-column:before {
  content: "\ec2b";
}

.ri-delete-row:before {
  content: "\ec2c";
}

.ri-device-fill:before {
  content: "\ec2d";
}

.ri-device-line:before {
  content: "\ec2e";
}

.ri-device-recover-fill:before {
  content: "\ec2f";
}

.ri-device-recover-line:before {
  content: "\ec30";
}

.ri-dingding-fill:before {
  content: "\ec31";
}

.ri-dingding-line:before {
  content: "\ec32";
}

.ri-direction-fill:before {
  content: "\ec33";
}

.ri-direction-line:before {
  content: "\ec34";
}

.ri-disc-fill:before {
  content: "\ec35";
}

.ri-disc-line:before {
  content: "\ec36";
}

.ri-discord-fill:before {
  content: "\ec37";
}

.ri-discord-line:before {
  content: "\ec38";
}

.ri-discuss-fill:before {
  content: "\ec39";
}

.ri-discuss-line:before {
  content: "\ec3a";
}

.ri-dislike-fill:before {
  content: "\ec3b";
}

.ri-dislike-line:before {
  content: "\ec3c";
}

.ri-disqus-fill:before {
  content: "\ec3d";
}

.ri-disqus-line:before {
  content: "\ec3e";
}

.ri-divide-fill:before {
  content: "\ec3f";
}

.ri-divide-line:before {
  content: "\ec40";
}

.ri-donut-chart-fill:before {
  content: "\ec41";
}

.ri-donut-chart-line:before {
  content: "\ec42";
}

.ri-door-closed-fill:before {
  content: "\ec43";
}

.ri-door-closed-line:before {
  content: "\ec44";
}

.ri-door-fill:before {
  content: "\ec45";
}

.ri-door-line:before {
  content: "\ec46";
}

.ri-door-lock-box-fill:before {
  content: "\ec47";
}

.ri-door-lock-box-line:before {
  content: "\ec48";
}

.ri-door-lock-fill:before {
  content: "\ec49";
}

.ri-door-lock-line:before {
  content: "\ec4a";
}

.ri-door-open-fill:before {
  content: "\ec4b";
}

.ri-door-open-line:before {
  content: "\ec4c";
}

.ri-dossier-fill:before {
  content: "\ec4d";
}

.ri-dossier-line:before {
  content: "\ec4e";
}

.ri-douban-fill:before {
  content: "\ec4f";
}

.ri-douban-line:before {
  content: "\ec50";
}

.ri-double-quotes-l:before {
  content: "\ec51";
}

.ri-double-quotes-r:before {
  content: "\ec52";
}

.ri-download-2-fill:before {
  content: "\ec53";
}

.ri-download-2-line:before {
  content: "\ec54";
}

.ri-download-cloud-2-fill:before {
  content: "\ec55";
}

.ri-download-cloud-2-line:before {
  content: "\ec56";
}

.ri-download-cloud-fill:before {
  content: "\ec57";
}

.ri-download-cloud-line:before {
  content: "\ec58";
}

.ri-download-fill:before {
  content: "\ec59";
}

.ri-download-line:before {
  content: "\ec5a";
}

.ri-draft-fill:before {
  content: "\ec5b";
}

.ri-draft-line:before {
  content: "\ec5c";
}

.ri-drag-drop-fill:before {
  content: "\ec5d";
}

.ri-drag-drop-line:before {
  content: "\ec5e";
}

.ri-drag-move-2-fill:before {
  content: "\ec5f";
}

.ri-drag-move-2-line:before {
  content: "\ec60";
}

.ri-drag-move-fill:before {
  content: "\ec61";
}

.ri-drag-move-line:before {
  content: "\ec62";
}

.ri-dribbble-fill:before {
  content: "\ec63";
}

.ri-dribbble-line:before {
  content: "\ec64";
}

.ri-drive-fill:before {
  content: "\ec65";
}

.ri-drive-line:before {
  content: "\ec66";
}

.ri-drizzle-fill:before {
  content: "\ec67";
}

.ri-drizzle-line:before {
  content: "\ec68";
}

.ri-drop-fill:before {
  content: "\ec69";
}

.ri-drop-line:before {
  content: "\ec6a";
}

.ri-dropbox-fill:before {
  content: "\ec6b";
}

.ri-dropbox-line:before {
  content: "\ec6c";
}

.ri-dual-sim-1-fill:before {
  content: "\ec6d";
}

.ri-dual-sim-1-line:before {
  content: "\ec6e";
}

.ri-dual-sim-2-fill:before {
  content: "\ec6f";
}

.ri-dual-sim-2-line:before {
  content: "\ec70";
}

.ri-dv-fill:before {
  content: "\ec71";
}

.ri-dv-line:before {
  content: "\ec72";
}

.ri-dvd-fill:before {
  content: "\ec73";
}

.ri-dvd-line:before {
  content: "\ec74";
}

.ri-e-bike-2-fill:before {
  content: "\ec75";
}

.ri-e-bike-2-line:before {
  content: "\ec76";
}

.ri-e-bike-fill:before {
  content: "\ec77";
}

.ri-e-bike-line:before {
  content: "\ec78";
}

.ri-earth-fill:before {
  content: "\ec79";
}

.ri-earth-line:before {
  content: "\ec7a";
}

.ri-earthquake-fill:before {
  content: "\ec7b";
}

.ri-earthquake-line:before {
  content: "\ec7c";
}

.ri-edge-fill:before {
  content: "\ec7d";
}

.ri-edge-line:before {
  content: "\ec7e";
}

.ri-edit-2-fill:before {
  content: "\ec7f";
}

.ri-edit-2-line:before {
  content: "\ec80";
}

.ri-edit-box-fill:before {
  content: "\ec81";
}

.ri-edit-box-line:before {
  content: "\ec82";
}

.ri-edit-circle-fill:before {
  content: "\ec83";
}

.ri-edit-circle-line:before {
  content: "\ec84";
}

.ri-edit-fill:before {
  content: "\ec85";
}

.ri-edit-line:before {
  content: "\ec86";
}

.ri-eject-fill:before {
  content: "\ec87";
}

.ri-eject-line:before {
  content: "\ec88";
}

.ri-emotion-2-fill:before {
  content: "\ec89";
}

.ri-emotion-2-line:before {
  content: "\ec8a";
}

.ri-emotion-fill:before {
  content: "\ec8b";
}

.ri-emotion-happy-fill:before {
  content: "\ec8c";
}

.ri-emotion-happy-line:before {
  content: "\ec8d";
}

.ri-emotion-laugh-fill:before {
  content: "\ec8e";
}

.ri-emotion-laugh-line:before {
  content: "\ec8f";
}

.ri-emotion-line:before {
  content: "\ec90";
}

.ri-emotion-normal-fill:before {
  content: "\ec91";
}

.ri-emotion-normal-line:before {
  content: "\ec92";
}

.ri-emotion-sad-fill:before {
  content: "\ec93";
}

.ri-emotion-sad-line:before {
  content: "\ec94";
}

.ri-emotion-unhappy-fill:before {
  content: "\ec95";
}

.ri-emotion-unhappy-line:before {
  content: "\ec96";
}

.ri-empathize-fill:before {
  content: "\ec97";
}

.ri-empathize-line:before {
  content: "\ec98";
}

.ri-emphasis-cn:before {
  content: "\ec99";
}

.ri-emphasis:before {
  content: "\ec9a";
}

.ri-english-input:before {
  content: "\ec9b";
}

.ri-equalizer-fill:before {
  content: "\ec9c";
}

.ri-equalizer-line:before {
  content: "\ec9d";
}

.ri-eraser-fill:before {
  content: "\ec9e";
}

.ri-eraser-line:before {
  content: "\ec9f";
}

.ri-error-warning-fill:before {
  content: "\eca0";
}

.ri-error-warning-line:before {
  content: "\eca1";
}

.ri-evernote-fill:before {
  content: "\eca2";
}

.ri-evernote-line:before {
  content: "\eca3";
}

.ri-exchange-box-fill:before {
  content: "\eca4";
}

.ri-exchange-box-line:before {
  content: "\eca5";
}

.ri-exchange-cny-fill:before {
  content: "\eca6";
}

.ri-exchange-cny-line:before {
  content: "\eca7";
}

.ri-exchange-dollar-fill:before {
  content: "\eca8";
}

.ri-exchange-dollar-line:before {
  content: "\eca9";
}

.ri-exchange-fill:before {
  content: "\ecaa";
}

.ri-exchange-funds-fill:before {
  content: "\ecab";
}

.ri-exchange-funds-line:before {
  content: "\ecac";
}

.ri-exchange-line:before {
  content: "\ecad";
}

.ri-external-link-fill:before {
  content: "\ecae";
}

.ri-external-link-line:before {
  content: "\ecaf";
}

.ri-eye-2-fill:before {
  content: "\ecb0";
}

.ri-eye-2-line:before {
  content: "\ecb1";
}

.ri-eye-close-fill:before {
  content: "\ecb2";
}

.ri-eye-close-line:before {
  content: "\ecb3";
}

.ri-eye-fill:before {
  content: "\ecb4";
}

.ri-eye-line:before {
  content: "\ecb5";
}

.ri-eye-off-fill:before {
  content: "\ecb6";
}

.ri-eye-off-line:before {
  content: "\ecb7";
}

.ri-facebook-box-fill:before {
  content: "\ecb8";
}

.ri-facebook-box-line:before {
  content: "\ecb9";
}

.ri-facebook-circle-fill:before {
  content: "\ecba";
}

.ri-facebook-circle-line:before {
  content: "\ecbb";
}

.ri-facebook-fill:before {
  content: "\ecbc";
}

.ri-facebook-line:before {
  content: "\ecbd";
}

.ri-fahrenheit-fill:before {
  content: "\ecbe";
}

.ri-fahrenheit-line:before {
  content: "\ecbf";
}

.ri-feedback-fill:before {
  content: "\ecc0";
}

.ri-feedback-line:before {
  content: "\ecc1";
}

.ri-file-2-fill:before {
  content: "\ecc2";
}

.ri-file-2-line:before {
  content: "\ecc3";
}

.ri-file-3-fill:before {
  content: "\ecc4";
}

.ri-file-3-line:before {
  content: "\ecc5";
}

.ri-file-4-fill:before {
  content: "\ecc6";
}

.ri-file-4-line:before {
  content: "\ecc7";
}

.ri-file-add-fill:before {
  content: "\ecc8";
}

.ri-file-add-line:before {
  content: "\ecc9";
}

.ri-file-chart-2-fill:before {
  content: "\ecca";
}

.ri-file-chart-2-line:before {
  content: "\eccb";
}

.ri-file-chart-fill:before {
  content: "\eccc";
}

.ri-file-chart-line:before {
  content: "\eccd";
}

.ri-file-cloud-fill:before {
  content: "\ecce";
}

.ri-file-cloud-line:before {
  content: "\eccf";
}

.ri-file-code-fill:before {
  content: "\ecd0";
}

.ri-file-code-line:before {
  content: "\ecd1";
}

.ri-file-copy-2-fill:before {
  content: "\ecd2";
}

.ri-file-copy-2-line:before {
  content: "\ecd3";
}

.ri-file-copy-fill:before {
  content: "\ecd4";
}

.ri-file-copy-line:before {
  content: "\ecd5";
}

.ri-file-damage-fill:before {
  content: "\ecd6";
}

.ri-file-damage-line:before {
  content: "\ecd7";
}

.ri-file-download-fill:before {
  content: "\ecd8";
}

.ri-file-download-line:before {
  content: "\ecd9";
}

.ri-file-edit-fill:before {
  content: "\ecda";
}

.ri-file-edit-line:before {
  content: "\ecdb";
}

.ri-file-excel-2-fill:before {
  content: "\ecdc";
}

.ri-file-excel-2-line:before {
  content: "\ecdd";
}

.ri-file-excel-fill:before {
  content: "\ecde";
}

.ri-file-excel-line:before {
  content: "\ecdf";
}

.ri-file-fill:before {
  content: "\ece0";
}

.ri-file-forbid-fill:before {
  content: "\ece1";
}

.ri-file-forbid-line:before {
  content: "\ece2";
}

.ri-file-gif-fill:before {
  content: "\ece3";
}

.ri-file-gif-line:before {
  content: "\ece4";
}

.ri-file-history-fill:before {
  content: "\ece5";
}

.ri-file-history-line:before {
  content: "\ece6";
}

.ri-file-hwp-fill:before {
  content: "\ece7";
}

.ri-file-hwp-line:before {
  content: "\ece8";
}

.ri-file-info-fill:before {
  content: "\ece9";
}

.ri-file-info-line:before {
  content: "\ecea";
}

.ri-file-line:before {
  content: "\eceb";
}

.ri-file-list-2-fill:before {
  content: "\ecec";
}

.ri-file-list-2-line:before {
  content: "\eced";
}

.ri-file-list-3-fill:before {
  content: "\ecee";
}

.ri-file-list-3-line:before {
  content: "\ecef";
}

.ri-file-list-fill:before {
  content: "\ecf0";
}

.ri-file-list-line:before {
  content: "\ecf1";
}

.ri-file-lock-fill:before {
  content: "\ecf2";
}

.ri-file-lock-line:before {
  content: "\ecf3";
}

.ri-file-mark-fill:before {
  content: "\ecf4";
}

.ri-file-mark-line:before {
  content: "\ecf5";
}

.ri-file-music-fill:before {
  content: "\ecf6";
}

.ri-file-music-line:before {
  content: "\ecf7";
}

.ri-file-paper-2-fill:before {
  content: "\ecf8";
}

.ri-file-paper-2-line:before {
  content: "\ecf9";
}

.ri-file-paper-fill:before {
  content: "\ecfa";
}

.ri-file-paper-line:before {
  content: "\ecfb";
}

.ri-file-pdf-fill:before {
  content: "\ecfc";
}

.ri-file-pdf-line:before {
  content: "\ecfd";
}

.ri-file-ppt-2-fill:before {
  content: "\ecfe";
}

.ri-file-ppt-2-line:before {
  content: "\ecff";
}

.ri-file-ppt-fill:before {
  content: "\ed00";
}

.ri-file-ppt-line:before {
  content: "\ed01";
}

.ri-file-reduce-fill:before {
  content: "\ed02";
}

.ri-file-reduce-line:before {
  content: "\ed03";
}

.ri-file-search-fill:before {
  content: "\ed04";
}

.ri-file-search-line:before {
  content: "\ed05";
}

.ri-file-settings-fill:before {
  content: "\ed06";
}

.ri-file-settings-line:before {
  content: "\ed07";
}

.ri-file-shield-2-fill:before {
  content: "\ed08";
}

.ri-file-shield-2-line:before {
  content: "\ed09";
}

.ri-file-shield-fill:before {
  content: "\ed0a";
}

.ri-file-shield-line:before {
  content: "\ed0b";
}

.ri-file-shred-fill:before {
  content: "\ed0c";
}

.ri-file-shred-line:before {
  content: "\ed0d";
}

.ri-file-text-fill:before {
  content: "\ed0e";
}

.ri-file-text-line:before {
  content: "\ed0f";
}

.ri-file-transfer-fill:before {
  content: "\ed10";
}

.ri-file-transfer-line:before {
  content: "\ed11";
}

.ri-file-unknow-fill:before {
  content: "\ed12";
}

.ri-file-unknow-line:before {
  content: "\ed13";
}

.ri-file-upload-fill:before {
  content: "\ed14";
}

.ri-file-upload-line:before {
  content: "\ed15";
}

.ri-file-user-fill:before {
  content: "\ed16";
}

.ri-file-user-line:before {
  content: "\ed17";
}

.ri-file-warning-fill:before {
  content: "\ed18";
}

.ri-file-warning-line:before {
  content: "\ed19";
}

.ri-file-word-2-fill:before {
  content: "\ed1a";
}

.ri-file-word-2-line:before {
  content: "\ed1b";
}

.ri-file-word-fill:before {
  content: "\ed1c";
}

.ri-file-word-line:before {
  content: "\ed1d";
}

.ri-file-zip-fill:before {
  content: "\ed1e";
}

.ri-file-zip-line:before {
  content: "\ed1f";
}

.ri-film-fill:before {
  content: "\ed20";
}

.ri-film-line:before {
  content: "\ed21";
}

.ri-filter-2-fill:before {
  content: "\ed22";
}

.ri-filter-2-line:before {
  content: "\ed23";
}

.ri-filter-3-fill:before {
  content: "\ed24";
}

.ri-filter-3-line:before {
  content: "\ed25";
}

.ri-filter-fill:before {
  content: "\ed26";
}

.ri-filter-line:before {
  content: "\ed27";
}

.ri-filter-off-fill:before {
  content: "\ed28";
}

.ri-filter-off-line:before {
  content: "\ed29";
}

.ri-find-replace-fill:before {
  content: "\ed2a";
}

.ri-find-replace-line:before {
  content: "\ed2b";
}

.ri-finder-fill:before {
  content: "\ed2c";
}

.ri-finder-line:before {
  content: "\ed2d";
}

.ri-fingerprint-2-fill:before {
  content: "\ed2e";
}

.ri-fingerprint-2-line:before {
  content: "\ed2f";
}

.ri-fingerprint-fill:before {
  content: "\ed30";
}

.ri-fingerprint-line:before {
  content: "\ed31";
}

.ri-fire-fill:before {
  content: "\ed32";
}

.ri-fire-line:before {
  content: "\ed33";
}

.ri-firefox-fill:before {
  content: "\ed34";
}

.ri-firefox-line:before {
  content: "\ed35";
}

.ri-first-aid-kit-fill:before {
  content: "\ed36";
}

.ri-first-aid-kit-line:before {
  content: "\ed37";
}

.ri-flag-2-fill:before {
  content: "\ed38";
}

.ri-flag-2-line:before {
  content: "\ed39";
}

.ri-flag-fill:before {
  content: "\ed3a";
}

.ri-flag-line:before {
  content: "\ed3b";
}

.ri-flashlight-fill:before {
  content: "\ed3c";
}

.ri-flashlight-line:before {
  content: "\ed3d";
}

.ri-flask-fill:before {
  content: "\ed3e";
}

.ri-flask-line:before {
  content: "\ed3f";
}

.ri-flight-land-fill:before {
  content: "\ed40";
}

.ri-flight-land-line:before {
  content: "\ed41";
}

.ri-flight-takeoff-fill:before {
  content: "\ed42";
}

.ri-flight-takeoff-line:before {
  content: "\ed43";
}

.ri-flood-fill:before {
  content: "\ed44";
}

.ri-flood-line:before {
  content: "\ed45";
}

.ri-flow-chart:before {
  content: "\ed46";
}

.ri-flutter-fill:before {
  content: "\ed47";
}

.ri-flutter-line:before {
  content: "\ed48";
}

.ri-focus-2-fill:before {
  content: "\ed49";
}

.ri-focus-2-line:before {
  content: "\ed4a";
}

.ri-focus-3-fill:before {
  content: "\ed4b";
}

.ri-focus-3-line:before {
  content: "\ed4c";
}

.ri-focus-fill:before {
  content: "\ed4d";
}

.ri-focus-line:before {
  content: "\ed4e";
}

.ri-foggy-fill:before {
  content: "\ed4f";
}

.ri-foggy-line:before {
  content: "\ed50";
}

.ri-folder-2-fill:before {
  content: "\ed51";
}

.ri-folder-2-line:before {
  content: "\ed52";
}

.ri-folder-3-fill:before {
  content: "\ed53";
}

.ri-folder-3-line:before {
  content: "\ed54";
}

.ri-folder-4-fill:before {
  content: "\ed55";
}

.ri-folder-4-line:before {
  content: "\ed56";
}

.ri-folder-5-fill:before {
  content: "\ed57";
}

.ri-folder-5-line:before {
  content: "\ed58";
}

.ri-folder-add-fill:before {
  content: "\ed59";
}

.ri-folder-add-line:before {
  content: "\ed5a";
}

.ri-folder-chart-2-fill:before {
  content: "\ed5b";
}

.ri-folder-chart-2-line:before {
  content: "\ed5c";
}

.ri-folder-chart-fill:before {
  content: "\ed5d";
}

.ri-folder-chart-line:before {
  content: "\ed5e";
}

.ri-folder-download-fill:before {
  content: "\ed5f";
}

.ri-folder-download-line:before {
  content: "\ed60";
}

.ri-folder-fill:before {
  content: "\ed61";
}

.ri-folder-forbid-fill:before {
  content: "\ed62";
}

.ri-folder-forbid-line:before {
  content: "\ed63";
}

.ri-folder-history-fill:before {
  content: "\ed64";
}

.ri-folder-history-line:before {
  content: "\ed65";
}

.ri-folder-info-fill:before {
  content: "\ed66";
}

.ri-folder-info-line:before {
  content: "\ed67";
}

.ri-folder-keyhole-fill:before {
  content: "\ed68";
}

.ri-folder-keyhole-line:before {
  content: "\ed69";
}

.ri-folder-line:before {
  content: "\ed6a";
}

.ri-folder-lock-fill:before {
  content: "\ed6b";
}

.ri-folder-lock-line:before {
  content: "\ed6c";
}

.ri-folder-music-fill:before {
  content: "\ed6d";
}

.ri-folder-music-line:before {
  content: "\ed6e";
}

.ri-folder-open-fill:before {
  content: "\ed6f";
}

.ri-folder-open-line:before {
  content: "\ed70";
}

.ri-folder-received-fill:before {
  content: "\ed71";
}

.ri-folder-received-line:before {
  content: "\ed72";
}

.ri-folder-reduce-fill:before {
  content: "\ed73";
}

.ri-folder-reduce-line:before {
  content: "\ed74";
}

.ri-folder-settings-fill:before {
  content: "\ed75";
}

.ri-folder-settings-line:before {
  content: "\ed76";
}

.ri-folder-shared-fill:before {
  content: "\ed77";
}

.ri-folder-shared-line:before {
  content: "\ed78";
}

.ri-folder-shield-2-fill:before {
  content: "\ed79";
}

.ri-folder-shield-2-line:before {
  content: "\ed7a";
}

.ri-folder-shield-fill:before {
  content: "\ed7b";
}

.ri-folder-shield-line:before {
  content: "\ed7c";
}

.ri-folder-transfer-fill:before {
  content: "\ed7d";
}

.ri-folder-transfer-line:before {
  content: "\ed7e";
}

.ri-folder-unknow-fill:before {
  content: "\ed7f";
}

.ri-folder-unknow-line:before {
  content: "\ed80";
}

.ri-folder-upload-fill:before {
  content: "\ed81";
}

.ri-folder-upload-line:before {
  content: "\ed82";
}

.ri-folder-user-fill:before {
  content: "\ed83";
}

.ri-folder-user-line:before {
  content: "\ed84";
}

.ri-folder-warning-fill:before {
  content: "\ed85";
}

.ri-folder-warning-line:before {
  content: "\ed86";
}

.ri-folder-zip-fill:before {
  content: "\ed87";
}

.ri-folder-zip-line:before {
  content: "\ed88";
}

.ri-folders-fill:before {
  content: "\ed89";
}

.ri-folders-line:before {
  content: "\ed8a";
}

.ri-font-color:before {
  content: "\ed8b";
}

.ri-font-size-2:before {
  content: "\ed8c";
}

.ri-font-size:before {
  content: "\ed8d";
}

.ri-football-fill:before {
  content: "\ed8e";
}

.ri-football-line:before {
  content: "\ed8f";
}

.ri-footprint-fill:before {
  content: "\ed90";
}

.ri-footprint-line:before {
  content: "\ed91";
}

.ri-forbid-2-fill:before {
  content: "\ed92";
}

.ri-forbid-2-line:before {
  content: "\ed93";
}

.ri-forbid-fill:before {
  content: "\ed94";
}

.ri-forbid-line:before {
  content: "\ed95";
}

.ri-format-clear:before {
  content: "\ed96";
}

.ri-fridge-fill:before {
  content: "\ed97";
}

.ri-fridge-line:before {
  content: "\ed98";
}

.ri-fullscreen-exit-fill:before {
  content: "\ed99";
}

.ri-fullscreen-exit-line:before {
  content: "\ed9a";
}

.ri-fullscreen-fill:before {
  content: "\ed9b";
}

.ri-fullscreen-line:before {
  content: "\ed9c";
}

.ri-function-fill:before {
  content: "\ed9d";
}

.ri-function-line:before {
  content: "\ed9e";
}

.ri-functions:before {
  content: "\ed9f";
}

.ri-funds-box-fill:before {
  content: "\eda0";
}

.ri-funds-box-line:before {
  content: "\eda1";
}

.ri-funds-fill:before {
  content: "\eda2";
}

.ri-funds-line:before {
  content: "\eda3";
}

.ri-gallery-fill:before {
  content: "\eda4";
}

.ri-gallery-line:before {
  content: "\eda5";
}

.ri-gallery-upload-fill:before {
  content: "\eda6";
}

.ri-gallery-upload-line:before {
  content: "\eda7";
}

.ri-game-fill:before {
  content: "\eda8";
}

.ri-game-line:before {
  content: "\eda9";
}

.ri-gamepad-fill:before {
  content: "\edaa";
}

.ri-gamepad-line:before {
  content: "\edab";
}

.ri-gas-station-fill:before {
  content: "\edac";
}

.ri-gas-station-line:before {
  content: "\edad";
}

.ri-gatsby-fill:before {
  content: "\edae";
}

.ri-gatsby-line:before {
  content: "\edaf";
}

.ri-genderless-fill:before {
  content: "\edb0";
}

.ri-genderless-line:before {
  content: "\edb1";
}

.ri-ghost-2-fill:before {
  content: "\edb2";
}

.ri-ghost-2-line:before {
  content: "\edb3";
}

.ri-ghost-fill:before {
  content: "\edb4";
}

.ri-ghost-line:before {
  content: "\edb5";
}

.ri-ghost-smile-fill:before {
  content: "\edb6";
}

.ri-ghost-smile-line:before {
  content: "\edb7";
}

.ri-gift-2-fill:before {
  content: "\edb8";
}

.ri-gift-2-line:before {
  content: "\edb9";
}

.ri-gift-fill:before {
  content: "\edba";
}

.ri-gift-line:before {
  content: "\edbb";
}

.ri-git-branch-fill:before {
  content: "\edbc";
}

.ri-git-branch-line:before {
  content: "\edbd";
}

.ri-git-commit-fill:before {
  content: "\edbe";
}

.ri-git-commit-line:before {
  content: "\edbf";
}

.ri-git-merge-fill:before {
  content: "\edc0";
}

.ri-git-merge-line:before {
  content: "\edc1";
}

.ri-git-pull-request-fill:before {
  content: "\edc2";
}

.ri-git-pull-request-line:before {
  content: "\edc3";
}

.ri-git-repository-commits-fill:before {
  content: "\edc4";
}

.ri-git-repository-commits-line:before {
  content: "\edc5";
}

.ri-git-repository-fill:before {
  content: "\edc6";
}

.ri-git-repository-line:before {
  content: "\edc7";
}

.ri-git-repository-private-fill:before {
  content: "\edc8";
}

.ri-git-repository-private-line:before {
  content: "\edc9";
}

.ri-github-fill:before {
  content: "\edca";
}

.ri-github-line:before {
  content: "\edcb";
}

.ri-gitlab-fill:before {
  content: "\edcc";
}

.ri-gitlab-line:before {
  content: "\edcd";
}

.ri-global-fill:before {
  content: "\edce";
}

.ri-global-line:before {
  content: "\edcf";
}

.ri-globe-fill:before {
  content: "\edd0";
}

.ri-globe-line:before {
  content: "\edd1";
}

.ri-goblet-fill:before {
  content: "\edd2";
}

.ri-goblet-line:before {
  content: "\edd3";
}

.ri-google-fill:before {
  content: "\edd4";
}

.ri-google-line:before {
  content: "\edd5";
}

.ri-google-play-fill:before {
  content: "\edd6";
}

.ri-google-play-line:before {
  content: "\edd7";
}

.ri-government-fill:before {
  content: "\edd8";
}

.ri-government-line:before {
  content: "\edd9";
}

.ri-gps-fill:before {
  content: "\edda";
}

.ri-gps-line:before {
  content: "\eddb";
}

.ri-gradienter-fill:before {
  content: "\eddc";
}

.ri-gradienter-line:before {
  content: "\eddd";
}

.ri-grid-fill:before {
  content: "\edde";
}

.ri-grid-line:before {
  content: "\eddf";
}

.ri-group-2-fill:before {
  content: "\ede0";
}

.ri-group-2-line:before {
  content: "\ede1";
}

.ri-group-fill:before {
  content: "\ede2";
}

.ri-group-line:before {
  content: "\ede3";
}

.ri-guide-fill:before {
  content: "\ede4";
}

.ri-guide-line:before {
  content: "\ede5";
}

.ri-h-1:before {
  content: "\ede6";
}

.ri-h-2:before {
  content: "\ede7";
}

.ri-h-3:before {
  content: "\ede8";
}

.ri-h-4:before {
  content: "\ede9";
}

.ri-h-5:before {
  content: "\edea";
}

.ri-h-6:before {
  content: "\edeb";
}

.ri-hail-fill:before {
  content: "\edec";
}

.ri-hail-line:before {
  content: "\eded";
}

.ri-hammer-fill:before {
  content: "\edee";
}

.ri-hammer-line:before {
  content: "\edef";
}

.ri-hand-coin-fill:before {
  content: "\edf0";
}

.ri-hand-coin-line:before {
  content: "\edf1";
}

.ri-hand-heart-fill:before {
  content: "\edf2";
}

.ri-hand-heart-line:before {
  content: "\edf3";
}

.ri-hand-sanitizer-fill:before {
  content: "\edf4";
}

.ri-hand-sanitizer-line:before {
  content: "\edf5";
}

.ri-handbag-fill:before {
  content: "\edf6";
}

.ri-handbag-line:before {
  content: "\edf7";
}

.ri-hard-drive-2-fill:before {
  content: "\edf8";
}

.ri-hard-drive-2-line:before {
  content: "\edf9";
}

.ri-hard-drive-fill:before {
  content: "\edfa";
}

.ri-hard-drive-line:before {
  content: "\edfb";
}

.ri-hashtag:before {
  content: "\edfc";
}

.ri-haze-2-fill:before {
  content: "\edfd";
}

.ri-haze-2-line:before {
  content: "\edfe";
}

.ri-haze-fill:before {
  content: "\edff";
}

.ri-haze-line:before {
  content: "\ee00";
}

.ri-hd-fill:before {
  content: "\ee01";
}

.ri-hd-line:before {
  content: "\ee02";
}

.ri-heading:before {
  content: "\ee03";
}

.ri-headphone-fill:before {
  content: "\ee04";
}

.ri-headphone-line:before {
  content: "\ee05";
}

.ri-health-book-fill:before {
  content: "\ee06";
}

.ri-health-book-line:before {
  content: "\ee07";
}

.ri-heart-2-fill:before {
  content: "\ee08";
}

.ri-heart-2-line:before {
  content: "\ee09";
}

.ri-heart-3-fill:before {
  content: "\ee0a";
}

.ri-heart-3-line:before {
  content: "\ee0b";
}

.ri-heart-add-fill:before {
  content: "\ee0c";
}

.ri-heart-add-line:before {
  content: "\ee0d";
}

.ri-heart-fill:before {
  content: "\ee0e";
}

.ri-heart-line:before {
  content: "\ee0f";
}

.ri-heart-pulse-fill:before {
  content: "\ee10";
}

.ri-heart-pulse-line:before {
  content: "\ee11";
}

.ri-hearts-fill:before {
  content: "\ee12";
}

.ri-hearts-line:before {
  content: "\ee13";
}

.ri-heavy-showers-fill:before {
  content: "\ee14";
}

.ri-heavy-showers-line:before {
  content: "\ee15";
}

.ri-history-fill:before {
  content: "\ee16";
}

.ri-history-line:before {
  content: "\ee17";
}

.ri-home-2-fill:before {
  content: "\ee18";
}

.ri-home-2-line:before {
  content: "\ee19";
}

.ri-home-3-fill:before {
  content: "\ee1a";
}

.ri-home-3-line:before {
  content: "\ee1b";
}

.ri-home-4-fill:before {
  content: "\ee1c";
}

.ri-home-4-line:before {
  content: "\ee1d";
}

.ri-home-5-fill:before {
  content: "\ee1e";
}

.ri-home-5-line:before {
  content: "\ee1f";
}

.ri-home-6-fill:before {
  content: "\ee20";
}

.ri-home-6-line:before {
  content: "\ee21";
}

.ri-home-7-fill:before {
  content: "\ee22";
}

.ri-home-7-line:before {
  content: "\ee23";
}

.ri-home-8-fill:before {
  content: "\ee24";
}

.ri-home-8-line:before {
  content: "\ee25";
}

.ri-home-fill:before {
  content: "\ee26";
}

.ri-home-gear-fill:before {
  content: "\ee27";
}

.ri-home-gear-line:before {
  content: "\ee28";
}

.ri-home-heart-fill:before {
  content: "\ee29";
}

.ri-home-heart-line:before {
  content: "\ee2a";
}

.ri-home-line:before {
  content: "\ee2b";
}

.ri-home-smile-2-fill:before {
  content: "\ee2c";
}

.ri-home-smile-2-line:before {
  content: "\ee2d";
}

.ri-home-smile-fill:before {
  content: "\ee2e";
}

.ri-home-smile-line:before {
  content: "\ee2f";
}

.ri-home-wifi-fill:before {
  content: "\ee30";
}

.ri-home-wifi-line:before {
  content: "\ee31";
}

.ri-honor-of-kings-fill:before {
  content: "\ee32";
}

.ri-honor-of-kings-line:before {
  content: "\ee33";
}

.ri-honour-fill:before {
  content: "\ee34";
}

.ri-honour-line:before {
  content: "\ee35";
}

.ri-hospital-fill:before {
  content: "\ee36";
}

.ri-hospital-line:before {
  content: "\ee37";
}

.ri-hotel-bed-fill:before {
  content: "\ee38";
}

.ri-hotel-bed-line:before {
  content: "\ee39";
}

.ri-hotel-fill:before {
  content: "\ee3a";
}

.ri-hotel-line:before {
  content: "\ee3b";
}

.ri-hotspot-fill:before {
  content: "\ee3c";
}

.ri-hotspot-line:before {
  content: "\ee3d";
}

.ri-hq-fill:before {
  content: "\ee3e";
}

.ri-hq-line:before {
  content: "\ee3f";
}

.ri-html5-fill:before {
  content: "\ee40";
}

.ri-html5-line:before {
  content: "\ee41";
}

.ri-ie-fill:before {
  content: "\ee42";
}

.ri-ie-line:before {
  content: "\ee43";
}

.ri-image-2-fill:before {
  content: "\ee44";
}

.ri-image-2-line:before {
  content: "\ee45";
}

.ri-image-add-fill:before {
  content: "\ee46";
}

.ri-image-add-line:before {
  content: "\ee47";
}

.ri-image-edit-fill:before {
  content: "\ee48";
}

.ri-image-edit-line:before {
  content: "\ee49";
}

.ri-image-fill:before {
  content: "\ee4a";
}

.ri-image-line:before {
  content: "\ee4b";
}

.ri-inbox-archive-fill:before {
  content: "\ee4c";
}

.ri-inbox-archive-line:before {
  content: "\ee4d";
}

.ri-inbox-fill:before {
  content: "\ee4e";
}

.ri-inbox-line:before {
  content: "\ee4f";
}

.ri-inbox-unarchive-fill:before {
  content: "\ee50";
}

.ri-inbox-unarchive-line:before {
  content: "\ee51";
}

.ri-increase-decrease-fill:before {
  content: "\ee52";
}

.ri-increase-decrease-line:before {
  content: "\ee53";
}

.ri-indent-decrease:before {
  content: "\ee54";
}

.ri-indent-increase:before {
  content: "\ee55";
}

.ri-indeterminate-circle-fill:before {
  content: "\ee56";
}

.ri-indeterminate-circle-line:before {
  content: "\ee57";
}

.ri-information-fill:before {
  content: "\ee58";
}

.ri-information-line:before {
  content: "\ee59";
}

.ri-infrared-thermometer-fill:before {
  content: "\ee5a";
}

.ri-infrared-thermometer-line:before {
  content: "\ee5b";
}

.ri-ink-bottle-fill:before {
  content: "\ee5c";
}

.ri-ink-bottle-line:before {
  content: "\ee5d";
}

.ri-input-cursor-move:before {
  content: "\ee5e";
}

.ri-input-method-fill:before {
  content: "\ee5f";
}

.ri-input-method-line:before {
  content: "\ee60";
}

.ri-insert-column-left:before {
  content: "\ee61";
}

.ri-insert-column-right:before {
  content: "\ee62";
}

.ri-insert-row-bottom:before {
  content: "\ee63";
}

.ri-insert-row-top:before {
  content: "\ee64";
}

.ri-instagram-fill:before {
  content: "\ee65";
}

.ri-instagram-line:before {
  content: "\ee66";
}

.ri-install-fill:before {
  content: "\ee67";
}

.ri-install-line:before {
  content: "\ee68";
}

.ri-invision-fill:before {
  content: "\ee69";
}

.ri-invision-line:before {
  content: "\ee6a";
}

.ri-italic:before {
  content: "\ee6b";
}

.ri-kakao-talk-fill:before {
  content: "\ee6c";
}

.ri-kakao-talk-line:before {
  content: "\ee6d";
}

.ri-key-2-fill:before {
  content: "\ee6e";
}

.ri-key-2-line:before {
  content: "\ee6f";
}

.ri-key-fill:before {
  content: "\ee70";
}

.ri-key-line:before {
  content: "\ee71";
}

.ri-keyboard-box-fill:before {
  content: "\ee72";
}

.ri-keyboard-box-line:before {
  content: "\ee73";
}

.ri-keyboard-fill:before {
  content: "\ee74";
}

.ri-keyboard-line:before {
  content: "\ee75";
}

.ri-keynote-fill:before {
  content: "\ee76";
}

.ri-keynote-line:before {
  content: "\ee77";
}

.ri-knife-blood-fill:before {
  content: "\ee78";
}

.ri-knife-blood-line:before {
  content: "\ee79";
}

.ri-knife-fill:before {
  content: "\ee7a";
}

.ri-knife-line:before {
  content: "\ee7b";
}

.ri-landscape-fill:before {
  content: "\ee7c";
}

.ri-landscape-line:before {
  content: "\ee7d";
}

.ri-layout-2-fill:before {
  content: "\ee7e";
}

.ri-layout-2-line:before {
  content: "\ee7f";
}

.ri-layout-3-fill:before {
  content: "\ee80";
}

.ri-layout-3-line:before {
  content: "\ee81";
}

.ri-layout-4-fill:before {
  content: "\ee82";
}

.ri-layout-4-line:before {
  content: "\ee83";
}

.ri-layout-5-fill:before {
  content: "\ee84";
}

.ri-layout-5-line:before {
  content: "\ee85";
}

.ri-layout-6-fill:before {
  content: "\ee86";
}

.ri-layout-6-line:before {
  content: "\ee87";
}

.ri-layout-bottom-2-fill:before {
  content: "\ee88";
}

.ri-layout-bottom-2-line:before {
  content: "\ee89";
}

.ri-layout-bottom-fill:before {
  content: "\ee8a";
}

.ri-layout-bottom-line:before {
  content: "\ee8b";
}

.ri-layout-column-fill:before {
  content: "\ee8c";
}

.ri-layout-column-line:before {
  content: "\ee8d";
}

.ri-layout-fill:before {
  content: "\ee8e";
}

.ri-layout-grid-fill:before {
  content: "\ee8f";
}

.ri-layout-grid-line:before {
  content: "\ee90";
}

.ri-layout-left-2-fill:before {
  content: "\ee91";
}

.ri-layout-left-2-line:before {
  content: "\ee92";
}

.ri-layout-left-fill:before {
  content: "\ee93";
}

.ri-layout-left-line:before {
  content: "\ee94";
}

.ri-layout-line:before {
  content: "\ee95";
}

.ri-layout-masonry-fill:before {
  content: "\ee96";
}

.ri-layout-masonry-line:before {
  content: "\ee97";
}

.ri-layout-right-2-fill:before {
  content: "\ee98";
}

.ri-layout-right-2-line:before {
  content: "\ee99";
}

.ri-layout-right-fill:before {
  content: "\ee9a";
}

.ri-layout-right-line:before {
  content: "\ee9b";
}

.ri-layout-row-fill:before {
  content: "\ee9c";
}

.ri-layout-row-line:before {
  content: "\ee9d";
}

.ri-layout-top-2-fill:before {
  content: "\ee9e";
}

.ri-layout-top-2-line:before {
  content: "\ee9f";
}

.ri-layout-top-fill:before {
  content: "\eea0";
}

.ri-layout-top-line:before {
  content: "\eea1";
}

.ri-leaf-fill:before {
  content: "\eea2";
}

.ri-leaf-line:before {
  content: "\eea3";
}

.ri-lifebuoy-fill:before {
  content: "\eea4";
}

.ri-lifebuoy-line:before {
  content: "\eea5";
}

.ri-lightbulb-fill:before {
  content: "\eea6";
}

.ri-lightbulb-flash-fill:before {
  content: "\eea7";
}

.ri-lightbulb-flash-line:before {
  content: "\eea8";
}

.ri-lightbulb-line:before {
  content: "\eea9";
}

.ri-line-chart-fill:before {
  content: "\eeaa";
}

.ri-line-chart-line:before {
  content: "\eeab";
}

.ri-line-fill:before {
  content: "\eeac";
}

.ri-line-height:before {
  content: "\eead";
}

.ri-line-line:before {
  content: "\eeae";
}

.ri-link-m:before {
  content: "\eeaf";
}

.ri-link-unlink-m:before {
  content: "\eeb0";
}

.ri-link-unlink:before {
  content: "\eeb1";
}

.ri-link:before {
  content: "\eeb2";
}

.ri-linkedin-box-fill:before {
  content: "\eeb3";
}

.ri-linkedin-box-line:before {
  content: "\eeb4";
}

.ri-linkedin-fill:before {
  content: "\eeb5";
}

.ri-linkedin-line:before {
  content: "\eeb6";
}

.ri-links-fill:before {
  content: "\eeb7";
}

.ri-links-line:before {
  content: "\eeb8";
}

.ri-list-check-2:before {
  content: "\eeb9";
}

.ri-list-check:before {
  content: "\eeba";
}

.ri-list-ordered:before {
  content: "\eebb";
}

.ri-list-settings-fill:before {
  content: "\eebc";
}

.ri-list-settings-line:before {
  content: "\eebd";
}

.ri-list-unordered:before {
  content: "\eebe";
}

.ri-live-fill:before {
  content: "\eebf";
}

.ri-live-line:before {
  content: "\eec0";
}

.ri-loader-2-fill:before {
  content: "\eec1";
}

.ri-loader-2-line:before {
  content: "\eec2";
}

.ri-loader-3-fill:before {
  content: "\eec3";
}

.ri-loader-3-line:before {
  content: "\eec4";
}

.ri-loader-4-fill:before {
  content: "\eec5";
}

.ri-loader-4-line:before {
  content: "\eec6";
}

.ri-loader-5-fill:before {
  content: "\eec7";
}

.ri-loader-5-line:before {
  content: "\eec8";
}

.ri-loader-fill:before {
  content: "\eec9";
}

.ri-loader-line:before {
  content: "\eeca";
}

.ri-lock-2-fill:before {
  content: "\eecb";
}

.ri-lock-2-line:before {
  content: "\eecc";
}

.ri-lock-fill:before {
  content: "\eecd";
}

.ri-lock-line:before {
  content: "\eece";
}

.ri-lock-password-fill:before {
  content: "\eecf";
}

.ri-lock-password-line:before {
  content: "\eed0";
}

.ri-lock-unlock-fill:before {
  content: "\eed1";
}

.ri-lock-unlock-line:before {
  content: "\eed2";
}

.ri-login-box-fill:before {
  content: "\eed3";
}

.ri-login-box-line:before {
  content: "\eed4";
}

.ri-login-circle-fill:before {
  content: "\eed5";
}

.ri-login-circle-line:before {
  content: "\eed6";
}

.ri-logout-box-fill:before {
  content: "\eed7";
}

.ri-logout-box-line:before {
  content: "\eed8";
}

.ri-logout-box-r-fill:before {
  content: "\eed9";
}

.ri-logout-box-r-line:before {
  content: "\eeda";
}

.ri-logout-circle-fill:before {
  content: "\eedb";
}

.ri-logout-circle-line:before {
  content: "\eedc";
}

.ri-logout-circle-r-fill:before {
  content: "\eedd";
}

.ri-logout-circle-r-line:before {
  content: "\eede";
}

.ri-luggage-cart-fill:before {
  content: "\eedf";
}

.ri-luggage-cart-line:before {
  content: "\eee0";
}

.ri-luggage-deposit-fill:before {
  content: "\eee1";
}

.ri-luggage-deposit-line:before {
  content: "\eee2";
}

.ri-lungs-fill:before {
  content: "\eee3";
}

.ri-lungs-line:before {
  content: "\eee4";
}

.ri-mac-fill:before {
  content: "\eee5";
}

.ri-mac-line:before {
  content: "\eee6";
}

.ri-macbook-fill:before {
  content: "\eee7";
}

.ri-macbook-line:before {
  content: "\eee8";
}

.ri-magic-fill:before {
  content: "\eee9";
}

.ri-magic-line:before {
  content: "\eeea";
}

.ri-mail-add-fill:before {
  content: "\eeeb";
}

.ri-mail-add-line:before {
  content: "\eeec";
}

.ri-mail-check-fill:before {
  content: "\eeed";
}

.ri-mail-check-line:before {
  content: "\eeee";
}

.ri-mail-close-fill:before {
  content: "\eeef";
}

.ri-mail-close-line:before {
  content: "\eef0";
}

.ri-mail-download-fill:before {
  content: "\eef1";
}

.ri-mail-download-line:before {
  content: "\eef2";
}

.ri-mail-fill:before {
  content: "\eef3";
}

.ri-mail-forbid-fill:before {
  content: "\eef4";
}

.ri-mail-forbid-line:before {
  content: "\eef5";
}

.ri-mail-line:before {
  content: "\eef6";
}

.ri-mail-lock-fill:before {
  content: "\eef7";
}

.ri-mail-lock-line:before {
  content: "\eef8";
}

.ri-mail-open-fill:before {
  content: "\eef9";
}

.ri-mail-open-line:before {
  content: "\eefa";
}

.ri-mail-send-fill:before {
  content: "\eefb";
}

.ri-mail-send-line:before {
  content: "\eefc";
}

.ri-mail-settings-fill:before {
  content: "\eefd";
}

.ri-mail-settings-line:before {
  content: "\eefe";
}

.ri-mail-star-fill:before {
  content: "\eeff";
}

.ri-mail-star-line:before {
  content: "\ef00";
}

.ri-mail-unread-fill:before {
  content: "\ef01";
}

.ri-mail-unread-line:before {
  content: "\ef02";
}

.ri-mail-volume-fill:before {
  content: "\ef03";
}

.ri-mail-volume-line:before {
  content: "\ef04";
}

.ri-map-2-fill:before {
  content: "\ef05";
}

.ri-map-2-line:before {
  content: "\ef06";
}

.ri-map-fill:before {
  content: "\ef07";
}

.ri-map-line:before {
  content: "\ef08";
}

.ri-map-pin-2-fill:before {
  content: "\ef09";
}

.ri-map-pin-2-line:before {
  content: "\ef0a";
}

.ri-map-pin-3-fill:before {
  content: "\ef0b";
}

.ri-map-pin-3-line:before {
  content: "\ef0c";
}

.ri-map-pin-4-fill:before {
  content: "\ef0d";
}

.ri-map-pin-4-line:before {
  content: "\ef0e";
}

.ri-map-pin-5-fill:before {
  content: "\ef0f";
}

.ri-map-pin-5-line:before {
  content: "\ef10";
}

.ri-map-pin-add-fill:before {
  content: "\ef11";
}

.ri-map-pin-add-line:before {
  content: "\ef12";
}

.ri-map-pin-fill:before {
  content: "\ef13";
}

.ri-map-pin-line:before {
  content: "\ef14";
}

.ri-map-pin-range-fill:before {
  content: "\ef15";
}

.ri-map-pin-range-line:before {
  content: "\ef16";
}

.ri-map-pin-time-fill:before {
  content: "\ef17";
}

.ri-map-pin-time-line:before {
  content: "\ef18";
}

.ri-map-pin-user-fill:before {
  content: "\ef19";
}

.ri-map-pin-user-line:before {
  content: "\ef1a";
}

.ri-mark-pen-fill:before {
  content: "\ef1b";
}

.ri-mark-pen-line:before {
  content: "\ef1c";
}

.ri-markdown-fill:before {
  content: "\ef1d";
}

.ri-markdown-line:before {
  content: "\ef1e";
}

.ri-markup-fill:before {
  content: "\ef1f";
}

.ri-markup-line:before {
  content: "\ef20";
}

.ri-mastercard-fill:before {
  content: "\ef21";
}

.ri-mastercard-line:before {
  content: "\ef22";
}

.ri-mastodon-fill:before {
  content: "\ef23";
}

.ri-mastodon-line:before {
  content: "\ef24";
}

.ri-medal-2-fill:before {
  content: "\ef25";
}

.ri-medal-2-line:before {
  content: "\ef26";
}

.ri-medal-fill:before {
  content: "\ef27";
}

.ri-medal-line:before {
  content: "\ef28";
}

.ri-medicine-bottle-fill:before {
  content: "\ef29";
}

.ri-medicine-bottle-line:before {
  content: "\ef2a";
}

.ri-medium-fill:before {
  content: "\ef2b";
}

.ri-medium-line:before {
  content: "\ef2c";
}

.ri-men-fill:before {
  content: "\ef2d";
}

.ri-men-line:before {
  content: "\ef2e";
}

.ri-mental-health-fill:before {
  content: "\ef2f";
}

.ri-mental-health-line:before {
  content: "\ef30";
}

.ri-menu-2-fill:before {
  content: "\ef31";
}

.ri-menu-2-line:before {
  content: "\ef32";
}

.ri-menu-3-fill:before {
  content: "\ef33";
}

.ri-menu-3-line:before {
  content: "\ef34";
}

.ri-menu-4-fill:before {
  content: "\ef35";
}

.ri-menu-4-line:before {
  content: "\ef36";
}

.ri-menu-5-fill:before {
  content: "\ef37";
}

.ri-menu-5-line:before {
  content: "\ef38";
}

.ri-menu-add-fill:before {
  content: "\ef39";
}

.ri-menu-add-line:before {
  content: "\ef3a";
}

.ri-menu-fill:before {
  content: "\ef3b";
}

.ri-menu-fold-fill:before {
  content: "\ef3c";
}

.ri-menu-fold-line:before {
  content: "\ef3d";
}

.ri-menu-line:before {
  content: "\ef3e";
}

.ri-menu-unfold-fill:before {
  content: "\ef3f";
}

.ri-menu-unfold-line:before {
  content: "\ef40";
}

.ri-merge-cells-horizontal:before {
  content: "\ef41";
}

.ri-merge-cells-vertical:before {
  content: "\ef42";
}

.ri-message-2-fill:before {
  content: "\ef43";
}

.ri-message-2-line:before {
  content: "\ef44";
}

.ri-message-3-fill:before {
  content: "\ef45";
}

.ri-message-3-line:before {
  content: "\ef46";
}

.ri-message-fill:before {
  content: "\ef47";
}

.ri-message-line:before {
  content: "\ef48";
}

.ri-messenger-fill:before {
  content: "\ef49";
}

.ri-messenger-line:before {
  content: "\ef4a";
}

.ri-meteor-fill:before {
  content: "\ef4b";
}

.ri-meteor-line:before {
  content: "\ef4c";
}

.ri-mic-2-fill:before {
  content: "\ef4d";
}

.ri-mic-2-line:before {
  content: "\ef4e";
}

.ri-mic-fill:before {
  content: "\ef4f";
}

.ri-mic-line:before {
  content: "\ef50";
}

.ri-mic-off-fill:before {
  content: "\ef51";
}

.ri-mic-off-line:before {
  content: "\ef52";
}

.ri-mickey-fill:before {
  content: "\ef53";
}

.ri-mickey-line:before {
  content: "\ef54";
}

.ri-microscope-fill:before {
  content: "\ef55";
}

.ri-microscope-line:before {
  content: "\ef56";
}

.ri-microsoft-fill:before {
  content: "\ef57";
}

.ri-microsoft-line:before {
  content: "\ef58";
}

.ri-mind-map:before {
  content: "\ef59";
}

.ri-mini-program-fill:before {
  content: "\ef5a";
}

.ri-mini-program-line:before {
  content: "\ef5b";
}

.ri-mist-fill:before {
  content: "\ef5c";
}

.ri-mist-line:before {
  content: "\ef5d";
}

.ri-money-cny-box-fill:before {
  content: "\ef5e";
}

.ri-money-cny-box-line:before {
  content: "\ef5f";
}

.ri-money-cny-circle-fill:before {
  content: "\ef60";
}

.ri-money-cny-circle-line:before {
  content: "\ef61";
}

.ri-money-dollar-box-fill:before {
  content: "\ef62";
}

.ri-money-dollar-box-line:before {
  content: "\ef63";
}

.ri-money-dollar-circle-fill:before {
  content: "\ef64";
}

.ri-money-dollar-circle-line:before {
  content: "\ef65";
}

.ri-money-euro-box-fill:before {
  content: "\ef66";
}

.ri-money-euro-box-line:before {
  content: "\ef67";
}

.ri-money-euro-circle-fill:before {
  content: "\ef68";
}

.ri-money-euro-circle-line:before {
  content: "\ef69";
}

.ri-money-pound-box-fill:before {
  content: "\ef6a";
}

.ri-money-pound-box-line:before {
  content: "\ef6b";
}

.ri-money-pound-circle-fill:before {
  content: "\ef6c";
}

.ri-money-pound-circle-line:before {
  content: "\ef6d";
}

.ri-moon-clear-fill:before {
  content: "\ef6e";
}

.ri-moon-clear-line:before {
  content: "\ef6f";
}

.ri-moon-cloudy-fill:before {
  content: "\ef70";
}

.ri-moon-cloudy-line:before {
  content: "\ef71";
}

.ri-moon-fill:before {
  content: "\ef72";
}

.ri-moon-foggy-fill:before {
  content: "\ef73";
}

.ri-moon-foggy-line:before {
  content: "\ef74";
}

.ri-moon-line:before {
  content: "\ef75";
}

.ri-more-2-fill:before {
  content: "\ef76";
}

.ri-more-2-line:before {
  content: "\ef77";
}

.ri-more-fill:before {
  content: "\ef78";
}

.ri-more-line:before {
  content: "\ef79";
}

.ri-motorbike-fill:before {
  content: "\ef7a";
}

.ri-motorbike-line:before {
  content: "\ef7b";
}

.ri-mouse-fill:before {
  content: "\ef7c";
}

.ri-mouse-line:before {
  content: "\ef7d";
}

.ri-movie-2-fill:before {
  content: "\ef7e";
}

.ri-movie-2-line:before {
  content: "\ef7f";
}

.ri-movie-fill:before {
  content: "\ef80";
}

.ri-movie-line:before {
  content: "\ef81";
}

.ri-music-2-fill:before {
  content: "\ef82";
}

.ri-music-2-line:before {
  content: "\ef83";
}

.ri-music-fill:before {
  content: "\ef84";
}

.ri-music-line:before {
  content: "\ef85";
}

.ri-mv-fill:before {
  content: "\ef86";
}

.ri-mv-line:before {
  content: "\ef87";
}

.ri-navigation-fill:before {
  content: "\ef88";
}

.ri-navigation-line:before {
  content: "\ef89";
}

.ri-netease-cloud-music-fill:before {
  content: "\ef8a";
}

.ri-netease-cloud-music-line:before {
  content: "\ef8b";
}

.ri-netflix-fill:before {
  content: "\ef8c";
}

.ri-netflix-line:before {
  content: "\ef8d";
}

.ri-newspaper-fill:before {
  content: "\ef8e";
}

.ri-newspaper-line:before {
  content: "\ef8f";
}

.ri-node-tree:before {
  content: "\ef90";
}

.ri-notification-2-fill:before {
  content: "\ef91";
}

.ri-notification-2-line:before {
  content: "\ef92";
}

.ri-notification-3-fill:before {
  content: "\ef93";
}

.ri-notification-3-line:before {
  content: "\ef94";
}

.ri-notification-4-fill:before {
  content: "\ef95";
}

.ri-notification-4-line:before {
  content: "\ef96";
}

.ri-notification-badge-fill:before {
  content: "\ef97";
}

.ri-notification-badge-line:before {
  content: "\ef98";
}

.ri-notification-fill:before {
  content: "\ef99";
}

.ri-notification-line:before {
  content: "\ef9a";
}

.ri-notification-off-fill:before {
  content: "\ef9b";
}

.ri-notification-off-line:before {
  content: "\ef9c";
}

.ri-npmjs-fill:before {
  content: "\ef9d";
}

.ri-npmjs-line:before {
  content: "\ef9e";
}

.ri-number-0:before {
  content: "\ef9f";
}

.ri-number-1:before {
  content: "\efa0";
}

.ri-number-2:before {
  content: "\efa1";
}

.ri-number-3:before {
  content: "\efa2";
}

.ri-number-4:before {
  content: "\efa3";
}

.ri-number-5:before {
  content: "\efa4";
}

.ri-number-6:before {
  content: "\efa5";
}

.ri-number-7:before {
  content: "\efa6";
}

.ri-number-8:before {
  content: "\efa7";
}

.ri-number-9:before {
  content: "\efa8";
}

.ri-numbers-fill:before {
  content: "\efa9";
}

.ri-numbers-line:before {
  content: "\efaa";
}

.ri-nurse-fill:before {
  content: "\efab";
}

.ri-nurse-line:before {
  content: "\efac";
}

.ri-oil-fill:before {
  content: "\efad";
}

.ri-oil-line:before {
  content: "\efae";
}

.ri-omega:before {
  content: "\efaf";
}

.ri-open-arm-fill:before {
  content: "\efb0";
}

.ri-open-arm-line:before {
  content: "\efb1";
}

.ri-open-source-fill:before {
  content: "\efb2";
}

.ri-open-source-line:before {
  content: "\efb3";
}

.ri-opera-fill:before {
  content: "\efb4";
}

.ri-opera-line:before {
  content: "\efb5";
}

.ri-order-play-fill:before {
  content: "\efb6";
}

.ri-order-play-line:before {
  content: "\efb7";
}

.ri-organization-chart:before {
  content: "\efb8";
}

.ri-outlet-2-fill:before {
  content: "\efb9";
}

.ri-outlet-2-line:before {
  content: "\efba";
}

.ri-outlet-fill:before {
  content: "\efbb";
}

.ri-outlet-line:before {
  content: "\efbc";
}

.ri-page-separator:before {
  content: "\efbd";
}

.ri-pages-fill:before {
  content: "\efbe";
}

.ri-pages-line:before {
  content: "\efbf";
}

.ri-paint-brush-fill:before {
  content: "\efc0";
}

.ri-paint-brush-line:before {
  content: "\efc1";
}

.ri-paint-fill:before {
  content: "\efc2";
}

.ri-paint-line:before {
  content: "\efc3";
}

.ri-palette-fill:before {
  content: "\efc4";
}

.ri-palette-line:before {
  content: "\efc5";
}

.ri-pantone-fill:before {
  content: "\efc6";
}

.ri-pantone-line:before {
  content: "\efc7";
}

.ri-paragraph:before {
  content: "\efc8";
}

.ri-parent-fill:before {
  content: "\efc9";
}

.ri-parent-line:before {
  content: "\efca";
}

.ri-parentheses-fill:before {
  content: "\efcb";
}

.ri-parentheses-line:before {
  content: "\efcc";
}

.ri-parking-box-fill:before {
  content: "\efcd";
}

.ri-parking-box-line:before {
  content: "\efce";
}

.ri-parking-fill:before {
  content: "\efcf";
}

.ri-parking-line:before {
  content: "\efd0";
}

.ri-passport-fill:before {
  content: "\efd1";
}

.ri-passport-line:before {
  content: "\efd2";
}

.ri-patreon-fill:before {
  content: "\efd3";
}

.ri-patreon-line:before {
  content: "\efd4";
}

.ri-pause-circle-fill:before {
  content: "\efd5";
}

.ri-pause-circle-line:before {
  content: "\efd6";
}

.ri-pause-fill:before {
  content: "\efd7";
}

.ri-pause-line:before {
  content: "\efd8";
}

.ri-pause-mini-fill:before {
  content: "\efd9";
}

.ri-pause-mini-line:before {
  content: "\efda";
}

.ri-paypal-fill:before {
  content: "\efdb";
}

.ri-paypal-line:before {
  content: "\efdc";
}

.ri-pen-nib-fill:before {
  content: "\efdd";
}

.ri-pen-nib-line:before {
  content: "\efde";
}

.ri-pencil-fill:before {
  content: "\efdf";
}

.ri-pencil-line:before {
  content: "\efe0";
}

.ri-pencil-ruler-2-fill:before {
  content: "\efe1";
}

.ri-pencil-ruler-2-line:before {
  content: "\efe2";
}

.ri-pencil-ruler-fill:before {
  content: "\efe3";
}

.ri-pencil-ruler-line:before {
  content: "\efe4";
}

.ri-percent-fill:before {
  content: "\efe5";
}

.ri-percent-line:before {
  content: "\efe6";
}

.ri-phone-camera-fill:before {
  content: "\efe7";
}

.ri-phone-camera-line:before {
  content: "\efe8";
}

.ri-phone-fill:before {
  content: "\efe9";
}

.ri-phone-find-fill:before {
  content: "\efea";
}

.ri-phone-find-line:before {
  content: "\efeb";
}

.ri-phone-line:before {
  content: "\efec";
}

.ri-phone-lock-fill:before {
  content: "\efed";
}

.ri-phone-lock-line:before {
  content: "\efee";
}

.ri-picture-in-picture-2-fill:before {
  content: "\efef";
}

.ri-picture-in-picture-2-line:before {
  content: "\eff0";
}

.ri-picture-in-picture-exit-fill:before {
  content: "\eff1";
}

.ri-picture-in-picture-exit-line:before {
  content: "\eff2";
}

.ri-picture-in-picture-fill:before {
  content: "\eff3";
}

.ri-picture-in-picture-line:before {
  content: "\eff4";
}

.ri-pie-chart-2-fill:before {
  content: "\eff5";
}

.ri-pie-chart-2-line:before {
  content: "\eff6";
}

.ri-pie-chart-box-fill:before {
  content: "\eff7";
}

.ri-pie-chart-box-line:before {
  content: "\eff8";
}

.ri-pie-chart-fill:before {
  content: "\eff9";
}

.ri-pie-chart-line:before {
  content: "\effa";
}

.ri-pin-distance-fill:before {
  content: "\effb";
}

.ri-pin-distance-line:before {
  content: "\effc";
}

.ri-ping-pong-fill:before {
  content: "\effd";
}

.ri-ping-pong-line:before {
  content: "\effe";
}

.ri-pinterest-fill:before {
  content: "\efff";
}

.ri-pinterest-line:before {
  content: "\f000";
}

.ri-pinyin-input:before {
  content: "\f001";
}

.ri-pixelfed-fill:before {
  content: "\f002";
}

.ri-pixelfed-line:before {
  content: "\f003";
}

.ri-plane-fill:before {
  content: "\f004";
}

.ri-plane-line:before {
  content: "\f005";
}

.ri-plant-fill:before {
  content: "\f006";
}

.ri-plant-line:before {
  content: "\f007";
}

.ri-play-circle-fill:before {
  content: "\f008";
}

.ri-play-circle-line:before {
  content: "\f009";
}

.ri-play-fill:before {
  content: "\f00a";
}

.ri-play-line:before {
  content: "\f00b";
}

.ri-play-list-2-fill:before {
  content: "\f00c";
}

.ri-play-list-2-line:before {
  content: "\f00d";
}

.ri-play-list-add-fill:before {
  content: "\f00e";
}

.ri-play-list-add-line:before {
  content: "\f00f";
}

.ri-play-list-fill:before {
  content: "\f010";
}

.ri-play-list-line:before {
  content: "\f011";
}

.ri-play-mini-fill:before {
  content: "\f012";
}

.ri-play-mini-line:before {
  content: "\f013";
}

.ri-playstation-fill:before {
  content: "\f014";
}

.ri-playstation-line:before {
  content: "\f015";
}

.ri-plug-2-fill:before {
  content: "\f016";
}

.ri-plug-2-line:before {
  content: "\f017";
}

.ri-plug-fill:before {
  content: "\f018";
}

.ri-plug-line:before {
  content: "\f019";
}

.ri-polaroid-2-fill:before {
  content: "\f01a";
}

.ri-polaroid-2-line:before {
  content: "\f01b";
}

.ri-polaroid-fill:before {
  content: "\f01c";
}

.ri-polaroid-line:before {
  content: "\f01d";
}

.ri-police-car-fill:before {
  content: "\f01e";
}

.ri-police-car-line:before {
  content: "\f01f";
}

.ri-price-tag-2-fill:before {
  content: "\f020";
}

.ri-price-tag-2-line:before {
  content: "\f021";
}

.ri-price-tag-3-fill:before {
  content: "\f022";
}

.ri-price-tag-3-line:before {
  content: "\f023";
}

.ri-price-tag-fill:before {
  content: "\f024";
}

.ri-price-tag-line:before {
  content: "\f025";
}

.ri-printer-cloud-fill:before {
  content: "\f026";
}

.ri-printer-cloud-line:before {
  content: "\f027";
}

.ri-printer-fill:before {
  content: "\f028";
}

.ri-printer-line:before {
  content: "\f029";
}

.ri-product-hunt-fill:before {
  content: "\f02a";
}

.ri-product-hunt-line:before {
  content: "\f02b";
}

.ri-profile-fill:before {
  content: "\f02c";
}

.ri-profile-line:before {
  content: "\f02d";
}

.ri-projector-2-fill:before {
  content: "\f02e";
}

.ri-projector-2-line:before {
  content: "\f02f";
}

.ri-projector-fill:before {
  content: "\f030";
}

.ri-projector-line:before {
  content: "\f031";
}

.ri-psychotherapy-fill:before {
  content: "\f032";
}

.ri-psychotherapy-line:before {
  content: "\f033";
}

.ri-pulse-fill:before {
  content: "\f034";
}

.ri-pulse-line:before {
  content: "\f035";
}

.ri-pushpin-2-fill:before {
  content: "\f036";
}

.ri-pushpin-2-line:before {
  content: "\f037";
}

.ri-pushpin-fill:before {
  content: "\f038";
}

.ri-pushpin-line:before {
  content: "\f039";
}

.ri-qq-fill:before {
  content: "\f03a";
}

.ri-qq-line:before {
  content: "\f03b";
}

.ri-qr-code-fill:before {
  content: "\f03c";
}

.ri-qr-code-line:before {
  content: "\f03d";
}

.ri-qr-scan-2-fill:before {
  content: "\f03e";
}

.ri-qr-scan-2-line:before {
  content: "\f03f";
}

.ri-qr-scan-fill:before {
  content: "\f040";
}

.ri-qr-scan-line:before {
  content: "\f041";
}

.ri-question-answer-fill:before {
  content: "\f042";
}

.ri-question-answer-line:before {
  content: "\f043";
}

.ri-question-fill:before {
  content: "\f044";
}

.ri-question-line:before {
  content: "\f045";
}

.ri-question-mark:before {
  content: "\f046";
}

.ri-questionnaire-fill:before {
  content: "\f047";
}

.ri-questionnaire-line:before {
  content: "\f048";
}

.ri-quill-pen-fill:before {
  content: "\f049";
}

.ri-quill-pen-line:before {
  content: "\f04a";
}

.ri-radar-fill:before {
  content: "\f04b";
}

.ri-radar-line:before {
  content: "\f04c";
}

.ri-radio-2-fill:before {
  content: "\f04d";
}

.ri-radio-2-line:before {
  content: "\f04e";
}

.ri-radio-button-fill:before {
  content: "\f04f";
}

.ri-radio-button-line:before {
  content: "\f050";
}

.ri-radio-fill:before {
  content: "\f051";
}

.ri-radio-line:before {
  content: "\f052";
}

.ri-rainbow-fill:before {
  content: "\f053";
}

.ri-rainbow-line:before {
  content: "\f054";
}

.ri-rainy-fill:before {
  content: "\f055";
}

.ri-rainy-line:before {
  content: "\f056";
}

.ri-reactjs-fill:before {
  content: "\f057";
}

.ri-reactjs-line:before {
  content: "\f058";
}

.ri-record-circle-fill:before {
  content: "\f059";
}

.ri-record-circle-line:before {
  content: "\f05a";
}

.ri-record-mail-fill:before {
  content: "\f05b";
}

.ri-record-mail-line:before {
  content: "\f05c";
}

.ri-recycle-fill:before {
  content: "\f05d";
}

.ri-recycle-line:before {
  content: "\f05e";
}

.ri-red-packet-fill:before {
  content: "\f05f";
}

.ri-red-packet-line:before {
  content: "\f060";
}

.ri-reddit-fill:before {
  content: "\f061";
}

.ri-reddit-line:before {
  content: "\f062";
}

.ri-refresh-fill:before {
  content: "\f063";
}

.ri-refresh-line:before {
  content: "\f064";
}

.ri-refund-2-fill:before {
  content: "\f065";
}

.ri-refund-2-line:before {
  content: "\f066";
}

.ri-refund-fill:before {
  content: "\f067";
}

.ri-refund-line:before {
  content: "\f068";
}

.ri-registered-fill:before {
  content: "\f069";
}

.ri-registered-line:before {
  content: "\f06a";
}

.ri-remixicon-fill:before {
  content: "\f06b";
}

.ri-remixicon-line:before {
  content: "\f06c";
}

.ri-remote-control-2-fill:before {
  content: "\f06d";
}

.ri-remote-control-2-line:before {
  content: "\f06e";
}

.ri-remote-control-fill:before {
  content: "\f06f";
}

.ri-remote-control-line:before {
  content: "\f070";
}

.ri-repeat-2-fill:before {
  content: "\f071";
}

.ri-repeat-2-line:before {
  content: "\f072";
}

.ri-repeat-fill:before {
  content: "\f073";
}

.ri-repeat-line:before {
  content: "\f074";
}

.ri-repeat-one-fill:before {
  content: "\f075";
}

.ri-repeat-one-line:before {
  content: "\f076";
}

.ri-reply-all-fill:before {
  content: "\f077";
}

.ri-reply-all-line:before {
  content: "\f078";
}

.ri-reply-fill:before {
  content: "\f079";
}

.ri-reply-line:before {
  content: "\f07a";
}

.ri-reserved-fill:before {
  content: "\f07b";
}

.ri-reserved-line:before {
  content: "\f07c";
}

.ri-rest-time-fill:before {
  content: "\f07d";
}

.ri-rest-time-line:before {
  content: "\f07e";
}

.ri-restart-fill:before {
  content: "\f07f";
}

.ri-restart-line:before {
  content: "\f080";
}

.ri-restaurant-2-fill:before {
  content: "\f081";
}

.ri-restaurant-2-line:before {
  content: "\f082";
}

.ri-restaurant-fill:before {
  content: "\f083";
}

.ri-restaurant-line:before {
  content: "\f084";
}

.ri-rewind-fill:before {
  content: "\f085";
}

.ri-rewind-line:before {
  content: "\f086";
}

.ri-rewind-mini-fill:before {
  content: "\f087";
}

.ri-rewind-mini-line:before {
  content: "\f088";
}

.ri-rhythm-fill:before {
  content: "\f089";
}

.ri-rhythm-line:before {
  content: "\f08a";
}

.ri-riding-fill:before {
  content: "\f08b";
}

.ri-riding-line:before {
  content: "\f08c";
}

.ri-road-map-fill:before {
  content: "\f08d";
}

.ri-road-map-line:before {
  content: "\f08e";
}

.ri-roadster-fill:before {
  content: "\f08f";
}

.ri-roadster-line:before {
  content: "\f090";
}

.ri-robot-fill:before {
  content: "\f091";
}

.ri-robot-line:before {
  content: "\f092";
}

.ri-rocket-2-fill:before {
  content: "\f093";
}

.ri-rocket-2-line:before {
  content: "\f094";
}

.ri-rocket-fill:before {
  content: "\f095";
}

.ri-rocket-line:before {
  content: "\f096";
}

.ri-rotate-lock-fill:before {
  content: "\f097";
}

.ri-rotate-lock-line:before {
  content: "\f098";
}

.ri-rounded-corner:before {
  content: "\f099";
}

.ri-route-fill:before {
  content: "\f09a";
}

.ri-route-line:before {
  content: "\f09b";
}

.ri-router-fill:before {
  content: "\f09c";
}

.ri-router-line:before {
  content: "\f09d";
}

.ri-rss-fill:before {
  content: "\f09e";
}

.ri-rss-line:before {
  content: "\f09f";
}

.ri-ruler-2-fill:before {
  content: "\f0a0";
}

.ri-ruler-2-line:before {
  content: "\f0a1";
}

.ri-ruler-fill:before {
  content: "\f0a2";
}

.ri-ruler-line:before {
  content: "\f0a3";
}

.ri-run-fill:before {
  content: "\f0a4";
}

.ri-run-line:before {
  content: "\f0a5";
}

.ri-safari-fill:before {
  content: "\f0a6";
}

.ri-safari-line:before {
  content: "\f0a7";
}

.ri-safe-2-fill:before {
  content: "\f0a8";
}

.ri-safe-2-line:before {
  content: "\f0a9";
}

.ri-safe-fill:before {
  content: "\f0aa";
}

.ri-safe-line:before {
  content: "\f0ab";
}

.ri-sailboat-fill:before {
  content: "\f0ac";
}

.ri-sailboat-line:before {
  content: "\f0ad";
}

.ri-save-2-fill:before {
  content: "\f0ae";
}

.ri-save-2-line:before {
  content: "\f0af";
}

.ri-save-3-fill:before {
  content: "\f0b0";
}

.ri-save-3-line:before {
  content: "\f0b1";
}

.ri-save-fill:before {
  content: "\f0b2";
}

.ri-save-line:before {
  content: "\f0b3";
}

.ri-scales-2-fill:before {
  content: "\f0b4";
}

.ri-scales-2-line:before {
  content: "\f0b5";
}

.ri-scales-3-fill:before {
  content: "\f0b6";
}

.ri-scales-3-line:before {
  content: "\f0b7";
}

.ri-scales-fill:before {
  content: "\f0b8";
}

.ri-scales-line:before {
  content: "\f0b9";
}

.ri-scan-2-fill:before {
  content: "\f0ba";
}

.ri-scan-2-line:before {
  content: "\f0bb";
}

.ri-scan-fill:before {
  content: "\f0bc";
}

.ri-scan-line:before {
  content: "\f0bd";
}

.ri-scissors-2-fill:before {
  content: "\f0be";
}

.ri-scissors-2-line:before {
  content: "\f0bf";
}

.ri-scissors-cut-fill:before {
  content: "\f0c0";
}

.ri-scissors-cut-line:before {
  content: "\f0c1";
}

.ri-scissors-fill:before {
  content: "\f0c2";
}

.ri-scissors-line:before {
  content: "\f0c3";
}

.ri-screenshot-2-fill:before {
  content: "\f0c4";
}

.ri-screenshot-2-line:before {
  content: "\f0c5";
}

.ri-screenshot-fill:before {
  content: "\f0c6";
}

.ri-screenshot-line:before {
  content: "\f0c7";
}

.ri-sd-card-fill:before {
  content: "\f0c8";
}

.ri-sd-card-line:before {
  content: "\f0c9";
}

.ri-sd-card-mini-fill:before {
  content: "\f0ca";
}

.ri-sd-card-mini-line:before {
  content: "\f0cb";
}

.ri-search-2-fill:before {
  content: "\f0cc";
}

.ri-search-2-line:before {
  content: "\f0cd";
}

.ri-search-eye-fill:before {
  content: "\f0ce";
}

.ri-search-eye-line:before {
  content: "\f0cf";
}

.ri-search-fill:before {
  content: "\f0d0";
}

.ri-search-line:before {
  content: "\f0d1";
}

.ri-secure-payment-fill:before {
  content: "\f0d2";
}

.ri-secure-payment-line:before {
  content: "\f0d3";
}

.ri-seedling-fill:before {
  content: "\f0d4";
}

.ri-seedling-line:before {
  content: "\f0d5";
}

.ri-send-backward:before {
  content: "\f0d6";
}

.ri-send-plane-2-fill:before {
  content: "\f0d7";
}

.ri-send-plane-2-line:before {
  content: "\f0d8";
}

.ri-send-plane-fill:before {
  content: "\f0d9";
}

.ri-send-plane-line:before {
  content: "\f0da";
}

.ri-send-to-back:before {
  content: "\f0db";
}

.ri-sensor-fill:before {
  content: "\f0dc";
}

.ri-sensor-line:before {
  content: "\f0dd";
}

.ri-separator:before {
  content: "\f0de";
}

.ri-server-fill:before {
  content: "\f0df";
}

.ri-server-line:before {
  content: "\f0e0";
}

.ri-service-fill:before {
  content: "\f0e1";
}

.ri-service-line:before {
  content: "\f0e2";
}

.ri-settings-2-fill:before {
  content: "\f0e3";
}

.ri-settings-2-line:before {
  content: "\f0e4";
}

.ri-settings-3-fill:before {
  content: "\f0e5";
}

.ri-settings-3-line:before {
  content: "\f0e6";
}

.ri-settings-4-fill:before {
  content: "\f0e7";
}

.ri-settings-4-line:before {
  content: "\f0e8";
}

.ri-settings-5-fill:before {
  content: "\f0e9";
}

.ri-settings-5-line:before {
  content: "\f0ea";
}

.ri-settings-6-fill:before {
  content: "\f0eb";
}

.ri-settings-6-line:before {
  content: "\f0ec";
}

.ri-settings-fill:before {
  content: "\f0ed";
}

.ri-settings-line:before {
  content: "\f0ee";
}

.ri-shape-2-fill:before {
  content: "\f0ef";
}

.ri-shape-2-line:before {
  content: "\f0f0";
}

.ri-shape-fill:before {
  content: "\f0f1";
}

.ri-shape-line:before {
  content: "\f0f2";
}

.ri-share-box-fill:before {
  content: "\f0f3";
}

.ri-share-box-line:before {
  content: "\f0f4";
}

.ri-share-circle-fill:before {
  content: "\f0f5";
}

.ri-share-circle-line:before {
  content: "\f0f6";
}

.ri-share-fill:before {
  content: "\f0f7";
}

.ri-share-forward-2-fill:before {
  content: "\f0f8";
}

.ri-share-forward-2-line:before {
  content: "\f0f9";
}

.ri-share-forward-box-fill:before {
  content: "\f0fa";
}

.ri-share-forward-box-line:before {
  content: "\f0fb";
}

.ri-share-forward-fill:before {
  content: "\f0fc";
}

.ri-share-forward-line:before {
  content: "\f0fd";
}

.ri-share-line:before {
  content: "\f0fe";
}

.ri-shield-check-fill:before {
  content: "\f0ff";
}

.ri-shield-check-line:before {
  content: "\f100";
}

.ri-shield-cross-fill:before {
  content: "\f101";
}

.ri-shield-cross-line:before {
  content: "\f102";
}

.ri-shield-fill:before {
  content: "\f103";
}

.ri-shield-flash-fill:before {
  content: "\f104";
}

.ri-shield-flash-line:before {
  content: "\f105";
}

.ri-shield-keyhole-fill:before {
  content: "\f106";
}

.ri-shield-keyhole-line:before {
  content: "\f107";
}

.ri-shield-line:before {
  content: "\f108";
}

.ri-shield-star-fill:before {
  content: "\f109";
}

.ri-shield-star-line:before {
  content: "\f10a";
}

.ri-shield-user-fill:before {
  content: "\f10b";
}

.ri-shield-user-line:before {
  content: "\f10c";
}

.ri-ship-2-fill:before {
  content: "\f10d";
}

.ri-ship-2-line:before {
  content: "\f10e";
}

.ri-ship-fill:before {
  content: "\f10f";
}

.ri-ship-line:before {
  content: "\f110";
}

.ri-shirt-fill:before {
  content: "\f111";
}

.ri-shirt-line:before {
  content: "\f112";
}

.ri-shopping-bag-2-fill:before {
  content: "\f113";
}

.ri-shopping-bag-2-line:before {
  content: "\f114";
}

.ri-shopping-bag-3-fill:before {
  content: "\f115";
}

.ri-shopping-bag-3-line:before {
  content: "\f116";
}

.ri-shopping-bag-fill:before {
  content: "\f117";
}

.ri-shopping-bag-line:before {
  content: "\f118";
}

.ri-shopping-basket-2-fill:before {
  content: "\f119";
}

.ri-shopping-basket-2-line:before {
  content: "\f11a";
}

.ri-shopping-basket-fill:before {
  content: "\f11b";
}

.ri-shopping-basket-line:before {
  content: "\f11c";
}

.ri-shopping-cart-2-fill:before {
  content: "\f11d";
}

.ri-shopping-cart-2-line:before {
  content: "\f11e";
}

.ri-shopping-cart-fill:before {
  content: "\f11f";
}

.ri-shopping-cart-line:before {
  content: "\f120";
}

.ri-showers-fill:before {
  content: "\f121";
}

.ri-showers-line:before {
  content: "\f122";
}

.ri-shuffle-fill:before {
  content: "\f123";
}

.ri-shuffle-line:before {
  content: "\f124";
}

.ri-shut-down-fill:before {
  content: "\f125";
}

.ri-shut-down-line:before {
  content: "\f126";
}

.ri-side-bar-fill:before {
  content: "\f127";
}

.ri-side-bar-line:before {
  content: "\f128";
}

.ri-signal-tower-fill:before {
  content: "\f129";
}

.ri-signal-tower-line:before {
  content: "\f12a";
}

.ri-signal-wifi-1-fill:before {
  content: "\f12b";
}

.ri-signal-wifi-1-line:before {
  content: "\f12c";
}

.ri-signal-wifi-2-fill:before {
  content: "\f12d";
}

.ri-signal-wifi-2-line:before {
  content: "\f12e";
}

.ri-signal-wifi-3-fill:before {
  content: "\f12f";
}

.ri-signal-wifi-3-line:before {
  content: "\f130";
}

.ri-signal-wifi-error-fill:before {
  content: "\f131";
}

.ri-signal-wifi-error-line:before {
  content: "\f132";
}

.ri-signal-wifi-fill:before {
  content: "\f133";
}

.ri-signal-wifi-line:before {
  content: "\f134";
}

.ri-signal-wifi-off-fill:before {
  content: "\f135";
}

.ri-signal-wifi-off-line:before {
  content: "\f136";
}

.ri-sim-card-2-fill:before {
  content: "\f137";
}

.ri-sim-card-2-line:before {
  content: "\f138";
}

.ri-sim-card-fill:before {
  content: "\f139";
}

.ri-sim-card-line:before {
  content: "\f13a";
}

.ri-single-quotes-l:before {
  content: "\f13b";
}

.ri-single-quotes-r:before {
  content: "\f13c";
}

.ri-sip-fill:before {
  content: "\f13d";
}

.ri-sip-line:before {
  content: "\f13e";
}

.ri-skip-back-fill:before {
  content: "\f13f";
}

.ri-skip-back-line:before {
  content: "\f140";
}

.ri-skip-back-mini-fill:before {
  content: "\f141";
}

.ri-skip-back-mini-line:before {
  content: "\f142";
}

.ri-skip-forward-fill:before {
  content: "\f143";
}

.ri-skip-forward-line:before {
  content: "\f144";
}

.ri-skip-forward-mini-fill:before {
  content: "\f145";
}

.ri-skip-forward-mini-line:before {
  content: "\f146";
}

.ri-skull-2-fill:before {
  content: "\f147";
}

.ri-skull-2-line:before {
  content: "\f148";
}

.ri-skull-fill:before {
  content: "\f149";
}

.ri-skull-line:before {
  content: "\f14a";
}

.ri-skype-fill:before {
  content: "\f14b";
}

.ri-skype-line:before {
  content: "\f14c";
}

.ri-slack-fill:before {
  content: "\f14d";
}

.ri-slack-line:before {
  content: "\f14e";
}

.ri-slice-fill:before {
  content: "\f14f";
}

.ri-slice-line:before {
  content: "\f150";
}

.ri-slideshow-2-fill:before {
  content: "\f151";
}

.ri-slideshow-2-line:before {
  content: "\f152";
}

.ri-slideshow-3-fill:before {
  content: "\f153";
}

.ri-slideshow-3-line:before {
  content: "\f154";
}

.ri-slideshow-4-fill:before {
  content: "\f155";
}

.ri-slideshow-4-line:before {
  content: "\f156";
}

.ri-slideshow-fill:before {
  content: "\f157";
}

.ri-slideshow-line:before {
  content: "\f158";
}

.ri-smartphone-fill:before {
  content: "\f159";
}

.ri-smartphone-line:before {
  content: "\f15a";
}

.ri-snapchat-fill:before {
  content: "\f15b";
}

.ri-snapchat-line:before {
  content: "\f15c";
}

.ri-snowy-fill:before {
  content: "\f15d";
}

.ri-snowy-line:before {
  content: "\f15e";
}

.ri-sort-asc:before {
  content: "\f15f";
}

.ri-sort-desc:before {
  content: "\f160";
}

.ri-sound-module-fill:before {
  content: "\f161";
}

.ri-sound-module-line:before {
  content: "\f162";
}

.ri-soundcloud-fill:before {
  content: "\f163";
}

.ri-soundcloud-line:before {
  content: "\f164";
}

.ri-space-ship-fill:before {
  content: "\f165";
}

.ri-space-ship-line:before {
  content: "\f166";
}

.ri-space:before {
  content: "\f167";
}

.ri-spam-2-fill:before {
  content: "\f168";
}

.ri-spam-2-line:before {
  content: "\f169";
}

.ri-spam-3-fill:before {
  content: "\f16a";
}

.ri-spam-3-line:before {
  content: "\f16b";
}

.ri-spam-fill:before {
  content: "\f16c";
}

.ri-spam-line:before {
  content: "\f16d";
}

.ri-speaker-2-fill:before {
  content: "\f16e";
}

.ri-speaker-2-line:before {
  content: "\f16f";
}

.ri-speaker-3-fill:before {
  content: "\f170";
}

.ri-speaker-3-line:before {
  content: "\f171";
}

.ri-speaker-fill:before {
  content: "\f172";
}

.ri-speaker-line:before {
  content: "\f173";
}

.ri-spectrum-fill:before {
  content: "\f174";
}

.ri-spectrum-line:before {
  content: "\f175";
}

.ri-speed-fill:before {
  content: "\f176";
}

.ri-speed-line:before {
  content: "\f177";
}

.ri-speed-mini-fill:before {
  content: "\f178";
}

.ri-speed-mini-line:before {
  content: "\f179";
}

.ri-split-cells-horizontal:before {
  content: "\f17a";
}

.ri-split-cells-vertical:before {
  content: "\f17b";
}

.ri-spotify-fill:before {
  content: "\f17c";
}

.ri-spotify-line:before {
  content: "\f17d";
}

.ri-spy-fill:before {
  content: "\f17e";
}

.ri-spy-line:before {
  content: "\f17f";
}

.ri-stack-fill:before {
  content: "\f180";
}

.ri-stack-line:before {
  content: "\f181";
}

.ri-stack-overflow-fill:before {
  content: "\f182";
}

.ri-stack-overflow-line:before {
  content: "\f183";
}

.ri-stackshare-fill:before {
  content: "\f184";
}

.ri-stackshare-line:before {
  content: "\f185";
}

.ri-star-fill:before {
  content: "\f186";
}

.ri-star-half-fill:before {
  content: "\f187";
}

.ri-star-half-line:before {
  content: "\f188";
}

.ri-star-half-s-fill:before {
  content: "\f189";
}

.ri-star-half-s-line:before {
  content: "\f18a";
}

.ri-star-line:before {
  content: "\f18b";
}

.ri-star-s-fill:before {
  content: "\f18c";
}

.ri-star-s-line:before {
  content: "\f18d";
}

.ri-star-smile-fill:before {
  content: "\f18e";
}

.ri-star-smile-line:before {
  content: "\f18f";
}

.ri-steam-fill:before {
  content: "\f190";
}

.ri-steam-line:before {
  content: "\f191";
}

.ri-steering-2-fill:before {
  content: "\f192";
}

.ri-steering-2-line:before {
  content: "\f193";
}

.ri-steering-fill:before {
  content: "\f194";
}

.ri-steering-line:before {
  content: "\f195";
}

.ri-stethoscope-fill:before {
  content: "\f196";
}

.ri-stethoscope-line:before {
  content: "\f197";
}

.ri-sticky-note-2-fill:before {
  content: "\f198";
}

.ri-sticky-note-2-line:before {
  content: "\f199";
}

.ri-sticky-note-fill:before {
  content: "\f19a";
}

.ri-sticky-note-line:before {
  content: "\f19b";
}

.ri-stock-fill:before {
  content: "\f19c";
}

.ri-stock-line:before {
  content: "\f19d";
}

.ri-stop-circle-fill:before {
  content: "\f19e";
}

.ri-stop-circle-line:before {
  content: "\f19f";
}

.ri-stop-fill:before {
  content: "\f1a0";
}

.ri-stop-line:before {
  content: "\f1a1";
}

.ri-stop-mini-fill:before {
  content: "\f1a2";
}

.ri-stop-mini-line:before {
  content: "\f1a3";
}

.ri-store-2-fill:before {
  content: "\f1a4";
}

.ri-store-2-line:before {
  content: "\f1a5";
}

.ri-store-3-fill:before {
  content: "\f1a6";
}

.ri-store-3-line:before {
  content: "\f1a7";
}

.ri-store-fill:before {
  content: "\f1a8";
}

.ri-store-line:before {
  content: "\f1a9";
}

.ri-strikethrough-2:before {
  content: "\f1aa";
}

.ri-strikethrough:before {
  content: "\f1ab";
}

.ri-subscript-2:before {
  content: "\f1ac";
}

.ri-subscript:before {
  content: "\f1ad";
}

.ri-subtract-fill:before {
  content: "\f1ae";
}

.ri-subtract-line:before {
  content: "\f1af";
}

.ri-subway-fill:before {
  content: "\f1b0";
}

.ri-subway-line:before {
  content: "\f1b1";
}

.ri-subway-wifi-fill:before {
  content: "\f1b2";
}

.ri-subway-wifi-line:before {
  content: "\f1b3";
}

.ri-suitcase-2-fill:before {
  content: "\f1b4";
}

.ri-suitcase-2-line:before {
  content: "\f1b5";
}

.ri-suitcase-3-fill:before {
  content: "\f1b6";
}

.ri-suitcase-3-line:before {
  content: "\f1b7";
}

.ri-suitcase-fill:before {
  content: "\f1b8";
}

.ri-suitcase-line:before {
  content: "\f1b9";
}

.ri-sun-cloudy-fill:before {
  content: "\f1ba";
}

.ri-sun-cloudy-line:before {
  content: "\f1bb";
}

.ri-sun-fill:before {
  content: "\f1bc";
}

.ri-sun-foggy-fill:before {
  content: "\f1bd";
}

.ri-sun-foggy-line:before {
  content: "\f1be";
}

.ri-sun-line:before {
  content: "\f1bf";
}

.ri-superscript-2:before {
  content: "\f1c0";
}

.ri-superscript:before {
  content: "\f1c1";
}

.ri-surgical-mask-fill:before {
  content: "\f1c2";
}

.ri-surgical-mask-line:before {
  content: "\f1c3";
}

.ri-surround-sound-fill:before {
  content: "\f1c4";
}

.ri-surround-sound-line:before {
  content: "\f1c5";
}

.ri-survey-fill:before {
  content: "\f1c6";
}

.ri-survey-line:before {
  content: "\f1c7";
}

.ri-swap-box-fill:before {
  content: "\f1c8";
}

.ri-swap-box-line:before {
  content: "\f1c9";
}

.ri-swap-fill:before {
  content: "\f1ca";
}

.ri-swap-line:before {
  content: "\f1cb";
}

.ri-switch-fill:before {
  content: "\f1cc";
}

.ri-switch-line:before {
  content: "\f1cd";
}

.ri-sword-fill:before {
  content: "\f1ce";
}

.ri-sword-line:before {
  content: "\f1cf";
}

.ri-syringe-fill:before {
  content: "\f1d0";
}

.ri-syringe-line:before {
  content: "\f1d1";
}

.ri-t-box-fill:before {
  content: "\f1d2";
}

.ri-t-box-line:before {
  content: "\f1d3";
}

.ri-t-shirt-2-fill:before {
  content: "\f1d4";
}

.ri-t-shirt-2-line:before {
  content: "\f1d5";
}

.ri-t-shirt-air-fill:before {
  content: "\f1d6";
}

.ri-t-shirt-air-line:before {
  content: "\f1d7";
}

.ri-t-shirt-fill:before {
  content: "\f1d8";
}

.ri-t-shirt-line:before {
  content: "\f1d9";
}

.ri-table-2:before {
  content: "\f1da";
}

.ri-table-alt-fill:before {
  content: "\f1db";
}

.ri-table-alt-line:before {
  content: "\f1dc";
}

.ri-table-fill:before {
  content: "\f1dd";
}

.ri-table-line:before {
  content: "\f1de";
}

.ri-tablet-fill:before {
  content: "\f1df";
}

.ri-tablet-line:before {
  content: "\f1e0";
}

.ri-takeaway-fill:before {
  content: "\f1e1";
}

.ri-takeaway-line:before {
  content: "\f1e2";
}

.ri-taobao-fill:before {
  content: "\f1e3";
}

.ri-taobao-line:before {
  content: "\f1e4";
}

.ri-tape-fill:before {
  content: "\f1e5";
}

.ri-tape-line:before {
  content: "\f1e6";
}

.ri-task-fill:before {
  content: "\f1e7";
}

.ri-task-line:before {
  content: "\f1e8";
}

.ri-taxi-fill:before {
  content: "\f1e9";
}

.ri-taxi-line:before {
  content: "\f1ea";
}

.ri-taxi-wifi-fill:before {
  content: "\f1eb";
}

.ri-taxi-wifi-line:before {
  content: "\f1ec";
}

.ri-team-fill:before {
  content: "\f1ed";
}

.ri-team-line:before {
  content: "\f1ee";
}

.ri-telegram-fill:before {
  content: "\f1ef";
}

.ri-telegram-line:before {
  content: "\f1f0";
}

.ri-temp-cold-fill:before {
  content: "\f1f1";
}

.ri-temp-cold-line:before {
  content: "\f1f2";
}

.ri-temp-hot-fill:before {
  content: "\f1f3";
}

.ri-temp-hot-line:before {
  content: "\f1f4";
}

.ri-terminal-box-fill:before {
  content: "\f1f5";
}

.ri-terminal-box-line:before {
  content: "\f1f6";
}

.ri-terminal-fill:before {
  content: "\f1f7";
}

.ri-terminal-line:before {
  content: "\f1f8";
}

.ri-terminal-window-fill:before {
  content: "\f1f9";
}

.ri-terminal-window-line:before {
  content: "\f1fa";
}

.ri-test-tube-fill:before {
  content: "\f1fb";
}

.ri-test-tube-line:before {
  content: "\f1fc";
}

.ri-text-direction-l:before {
  content: "\f1fd";
}

.ri-text-direction-r:before {
  content: "\f1fe";
}

.ri-text-spacing:before {
  content: "\f1ff";
}

.ri-text-wrap:before {
  content: "\f200";
}

.ri-text:before {
  content: "\f201";
}

.ri-thermometer-fill:before {
  content: "\f202";
}

.ri-thermometer-line:before {
  content: "\f203";
}

.ri-thumb-down-fill:before {
  content: "\f204";
}

.ri-thumb-down-line:before {
  content: "\f205";
}

.ri-thumb-up-fill:before {
  content: "\f206";
}

.ri-thumb-up-line:before {
  content: "\f207";
}

.ri-thunderstorms-fill:before {
  content: "\f208";
}

.ri-thunderstorms-line:before {
  content: "\f209";
}

.ri-ticket-2-fill:before {
  content: "\f20a";
}

.ri-ticket-2-line:before {
  content: "\f20b";
}

.ri-ticket-fill:before {
  content: "\f20c";
}

.ri-ticket-line:before {
  content: "\f20d";
}

.ri-time-fill:before {
  content: "\f20e";
}

.ri-time-line:before {
  content: "\f20f";
}

.ri-timer-2-fill:before {
  content: "\f210";
}

.ri-timer-2-line:before {
  content: "\f211";
}

.ri-timer-fill:before {
  content: "\f212";
}

.ri-timer-flash-fill:before {
  content: "\f213";
}

.ri-timer-flash-line:before {
  content: "\f214";
}

.ri-timer-line:before {
  content: "\f215";
}

.ri-todo-fill:before {
  content: "\f216";
}

.ri-todo-line:before {
  content: "\f217";
}

.ri-toggle-fill:before {
  content: "\f218";
}

.ri-toggle-line:before {
  content: "\f219";
}

.ri-tools-fill:before {
  content: "\f21a";
}

.ri-tools-line:before {
  content: "\f21b";
}

.ri-tornado-fill:before {
  content: "\f21c";
}

.ri-tornado-line:before {
  content: "\f21d";
}

.ri-trademark-fill:before {
  content: "\f21e";
}

.ri-trademark-line:before {
  content: "\f21f";
}

.ri-traffic-light-fill:before {
  content: "\f220";
}

.ri-traffic-light-line:before {
  content: "\f221";
}

.ri-train-fill:before {
  content: "\f222";
}

.ri-train-line:before {
  content: "\f223";
}

.ri-train-wifi-fill:before {
  content: "\f224";
}

.ri-train-wifi-line:before {
  content: "\f225";
}

.ri-translate-2:before {
  content: "\f226";
}

.ri-translate:before {
  content: "\f227";
}

.ri-travesti-fill:before {
  content: "\f228";
}

.ri-travesti-line:before {
  content: "\f229";
}

.ri-treasure-map-fill:before {
  content: "\f22a";
}

.ri-treasure-map-line:before {
  content: "\f22b";
}

.ri-trello-fill:before {
  content: "\f22c";
}

.ri-trello-line:before {
  content: "\f22d";
}

.ri-trophy-fill:before {
  content: "\f22e";
}

.ri-trophy-line:before {
  content: "\f22f";
}

.ri-truck-fill:before {
  content: "\f230";
}

.ri-truck-line:before {
  content: "\f231";
}

.ri-tumblr-fill:before {
  content: "\f232";
}

.ri-tumblr-line:before {
  content: "\f233";
}

.ri-tv-2-fill:before {
  content: "\f234";
}

.ri-tv-2-line:before {
  content: "\f235";
}

.ri-tv-fill:before {
  content: "\f236";
}

.ri-tv-line:before {
  content: "\f237";
}

.ri-twitch-fill:before {
  content: "\f238";
}

.ri-twitch-line:before {
  content: "\f239";
}

.ri-twitter-fill:before {
  content: "\f23a";
}

.ri-twitter-line:before {
  content: "\f23b";
}

.ri-typhoon-fill:before {
  content: "\f23c";
}

.ri-typhoon-line:before {
  content: "\f23d";
}

.ri-u-disk-fill:before {
  content: "\f23e";
}

.ri-u-disk-line:before {
  content: "\f23f";
}

.ri-ubuntu-fill:before {
  content: "\f240";
}

.ri-ubuntu-line:before {
  content: "\f241";
}

.ri-umbrella-fill:before {
  content: "\f242";
}

.ri-umbrella-line:before {
  content: "\f243";
}

.ri-underline:before {
  content: "\f244";
}

.ri-uninstall-fill:before {
  content: "\f245";
}

.ri-uninstall-line:before {
  content: "\f246";
}

.ri-unsplash-fill:before {
  content: "\f247";
}

.ri-unsplash-line:before {
  content: "\f248";
}

.ri-upload-2-fill:before {
  content: "\f249";
}

.ri-upload-2-line:before {
  content: "\f24a";
}

.ri-upload-cloud-2-fill:before {
  content: "\f24b";
}

.ri-upload-cloud-2-line:before {
  content: "\f24c";
}

.ri-upload-cloud-fill:before {
  content: "\f24d";
}

.ri-upload-cloud-line:before {
  content: "\f24e";
}

.ri-upload-fill:before {
  content: "\f24f";
}

.ri-upload-line:before {
  content: "\f250";
}

.ri-usb-fill:before {
  content: "\f251";
}

.ri-usb-line:before {
  content: "\f252";
}

.ri-user-2-fill:before {
  content: "\f253";
}

.ri-user-2-line:before {
  content: "\f254";
}

.ri-user-3-fill:before {
  content: "\f255";
}

.ri-user-3-line:before {
  content: "\f256";
}

.ri-user-4-fill:before {
  content: "\f257";
}

.ri-user-4-line:before {
  content: "\f258";
}

.ri-user-5-fill:before {
  content: "\f259";
}

.ri-user-5-line:before {
  content: "\f25a";
}

.ri-user-6-fill:before {
  content: "\f25b";
}

.ri-user-6-line:before {
  content: "\f25c";
}

.ri-user-add-fill:before {
  content: "\f25d";
}

.ri-user-add-line:before {
  content: "\f25e";
}

.ri-user-fill:before {
  content: "\f25f";
}

.ri-user-follow-fill:before {
  content: "\f260";
}

.ri-user-follow-line:before {
  content: "\f261";
}

.ri-user-heart-fill:before {
  content: "\f262";
}

.ri-user-heart-line:before {
  content: "\f263";
}

.ri-user-line:before {
  content: "\f264";
}

.ri-user-location-fill:before {
  content: "\f265";
}

.ri-user-location-line:before {
  content: "\f266";
}

.ri-user-received-2-fill:before {
  content: "\f267";
}

.ri-user-received-2-line:before {
  content: "\f268";
}

.ri-user-received-fill:before {
  content: "\f269";
}

.ri-user-received-line:before {
  content: "\f26a";
}

.ri-user-search-fill:before {
  content: "\f26b";
}

.ri-user-search-line:before {
  content: "\f26c";
}

.ri-user-settings-fill:before {
  content: "\f26d";
}

.ri-user-settings-line:before {
  content: "\f26e";
}

.ri-user-shared-2-fill:before {
  content: "\f26f";
}

.ri-user-shared-2-line:before {
  content: "\f270";
}

.ri-user-shared-fill:before {
  content: "\f271";
}

.ri-user-shared-line:before {
  content: "\f272";
}

.ri-user-smile-fill:before {
  content: "\f273";
}

.ri-user-smile-line:before {
  content: "\f274";
}

.ri-user-star-fill:before {
  content: "\f275";
}

.ri-user-star-line:before {
  content: "\f276";
}

.ri-user-unfollow-fill:before {
  content: "\f277";
}

.ri-user-unfollow-line:before {
  content: "\f278";
}

.ri-user-voice-fill:before {
  content: "\f279";
}

.ri-user-voice-line:before {
  content: "\f27a";
}

.ri-video-add-fill:before {
  content: "\f27b";
}

.ri-video-add-line:before {
  content: "\f27c";
}

.ri-video-chat-fill:before {
  content: "\f27d";
}

.ri-video-chat-line:before {
  content: "\f27e";
}

.ri-video-download-fill:before {
  content: "\f27f";
}

.ri-video-download-line:before {
  content: "\f280";
}

.ri-video-fill:before {
  content: "\f281";
}

.ri-video-line:before {
  content: "\f282";
}

.ri-video-upload-fill:before {
  content: "\f283";
}

.ri-video-upload-line:before {
  content: "\f284";
}

.ri-vidicon-2-fill:before {
  content: "\f285";
}

.ri-vidicon-2-line:before {
  content: "\f286";
}

.ri-vidicon-fill:before {
  content: "\f287";
}

.ri-vidicon-line:before {
  content: "\f288";
}

.ri-vimeo-fill:before {
  content: "\f289";
}

.ri-vimeo-line:before {
  content: "\f28a";
}

.ri-vip-crown-2-fill:before {
  content: "\f28b";
}

.ri-vip-crown-2-line:before {
  content: "\f28c";
}

.ri-vip-crown-fill:before {
  content: "\f28d";
}

.ri-vip-crown-line:before {
  content: "\f28e";
}

.ri-vip-diamond-fill:before {
  content: "\f28f";
}

.ri-vip-diamond-line:before {
  content: "\f290";
}

.ri-vip-fill:before {
  content: "\f291";
}

.ri-vip-line:before {
  content: "\f292";
}

.ri-virus-fill:before {
  content: "\f293";
}

.ri-virus-line:before {
  content: "\f294";
}

.ri-visa-fill:before {
  content: "\f295";
}

.ri-visa-line:before {
  content: "\f296";
}

.ri-voice-recognition-fill:before {
  content: "\f297";
}

.ri-voice-recognition-line:before {
  content: "\f298";
}

.ri-voiceprint-fill:before {
  content: "\f299";
}

.ri-voiceprint-line:before {
  content: "\f29a";
}

.ri-volume-down-fill:before {
  content: "\f29b";
}

.ri-volume-down-line:before {
  content: "\f29c";
}

.ri-volume-mute-fill:before {
  content: "\f29d";
}

.ri-volume-mute-line:before {
  content: "\f29e";
}

.ri-volume-off-vibrate-fill:before {
  content: "\f29f";
}

.ri-volume-off-vibrate-line:before {
  content: "\f2a0";
}

.ri-volume-up-fill:before {
  content: "\f2a1";
}

.ri-volume-up-line:before {
  content: "\f2a2";
}

.ri-volume-vibrate-fill:before {
  content: "\f2a3";
}

.ri-volume-vibrate-line:before {
  content: "\f2a4";
}

.ri-vuejs-fill:before {
  content: "\f2a5";
}

.ri-vuejs-line:before {
  content: "\f2a6";
}

.ri-walk-fill:before {
  content: "\f2a7";
}

.ri-walk-line:before {
  content: "\f2a8";
}

.ri-wallet-2-fill:before {
  content: "\f2a9";
}

.ri-wallet-2-line:before {
  content: "\f2aa";
}

.ri-wallet-3-fill:before {
  content: "\f2ab";
}

.ri-wallet-3-line:before {
  content: "\f2ac";
}

.ri-wallet-fill:before {
  content: "\f2ad";
}

.ri-wallet-line:before {
  content: "\f2ae";
}

.ri-water-flash-fill:before {
  content: "\f2af";
}

.ri-water-flash-line:before {
  content: "\f2b0";
}

.ri-webcam-fill:before {
  content: "\f2b1";
}

.ri-webcam-line:before {
  content: "\f2b2";
}

.ri-wechat-2-fill:before {
  content: "\f2b3";
}

.ri-wechat-2-line:before {
  content: "\f2b4";
}

.ri-wechat-fill:before {
  content: "\f2b5";
}

.ri-wechat-line:before {
  content: "\f2b6";
}

.ri-wechat-pay-fill:before {
  content: "\f2b7";
}

.ri-wechat-pay-line:before {
  content: "\f2b8";
}

.ri-weibo-fill:before {
  content: "\f2b9";
}

.ri-weibo-line:before {
  content: "\f2ba";
}

.ri-whatsapp-fill:before {
  content: "\f2bb";
}

.ri-whatsapp-line:before {
  content: "\f2bc";
}

.ri-wheelchair-fill:before {
  content: "\f2bd";
}

.ri-wheelchair-line:before {
  content: "\f2be";
}

.ri-wifi-fill:before {
  content: "\f2bf";
}

.ri-wifi-line:before {
  content: "\f2c0";
}

.ri-wifi-off-fill:before {
  content: "\f2c1";
}

.ri-wifi-off-line:before {
  content: "\f2c2";
}

.ri-window-2-fill:before {
  content: "\f2c3";
}

.ri-window-2-line:before {
  content: "\f2c4";
}

.ri-window-fill:before {
  content: "\f2c5";
}

.ri-window-line:before {
  content: "\f2c6";
}

.ri-windows-fill:before {
  content: "\f2c7";
}

.ri-windows-line:before {
  content: "\f2c8";
}

.ri-windy-fill:before {
  content: "\f2c9";
}

.ri-windy-line:before {
  content: "\f2ca";
}

.ri-wireless-charging-fill:before {
  content: "\f2cb";
}

.ri-wireless-charging-line:before {
  content: "\f2cc";
}

.ri-women-fill:before {
  content: "\f2cd";
}

.ri-women-line:before {
  content: "\f2ce";
}

.ri-wubi-input:before {
  content: "\f2cf";
}

.ri-xbox-fill:before {
  content: "\f2d0";
}

.ri-xbox-line:before {
  content: "\f2d1";
}

.ri-xing-fill:before {
  content: "\f2d2";
}

.ri-xing-line:before {
  content: "\f2d3";
}

.ri-youtube-fill:before {
  content: "\f2d4";
}

.ri-youtube-line:before {
  content: "\f2d5";
}

.ri-zcool-fill:before {
  content: "\f2d6";
}

.ri-zcool-line:before {
  content: "\f2d7";
}

.ri-zhihu-fill:before {
  content: "\f2d8";
}

.ri-zhihu-line:before {
  content: "\f2d9";
}

.ri-zoom-in-fill:before {
  content: "\f2da";
}

.ri-zoom-in-line:before {
  content: "\f2db";
}

.ri-zoom-out-fill:before {
  content: "\f2dc";
}

.ri-zoom-out-line:before {
  content: "\f2dd";
}

.ri-zzz-fill:before {
  content: "\f2de";
}

.ri-zzz-line:before {
  content: "\f2df";
}

/* Swiper.JS */
/* Plyr */
.plyr-hide-controls .plyr__controls, .plyr-hide-controls .plyr__control {
  opacity: 0 !important;
}

.plyr-object-fit video {
  -o-object-fit: cover;
     object-fit: cover;
}

/* Template Custom CSS - Add your own CSS to this file
–––––––––––––––––––––––––––––––––––––––––––––––––– */
